import React, {
  ChangeEventHandler,
  FocusEventHandler,
  HTMLAttributes,
  LabelHTMLAttributes,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import Occupancy, { OccupancyProps, OccupancyValue } from "../index";
import useClx from "../../../hooks/use-clx";
import ExpandIcon from "../../Select/ExpandIcon";
import clxs from "./occupancy-select.module.css";

export interface OccupancySelectProps extends Omit<OccupancyProps, "onChange"> {
  name?: string;
  onChange?: ChangeEventHandler<any>;
  onFocus?: FocusEventHandler<any>;
  onBlur?: FocusEventHandler<any>;
  containerProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
  label?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  error?: string;
  isShowOccupancyDialog?: boolean;
  occupancySuccessText?: string;
  handleDoneClick?: (e: React.ChangeEvent<any>) => void;
}

function OccupancySelect(props: OccupancySelectProps) {
  const {
      className,
      onChange,
      onFocus,
      onBlur,
      containerProps = {},
      label,
      labelProps = {},
      error,
      value: _value,
      isShowOccupancyDialog,
      occupancySuccessText,
      handleDoneClick,
      ...rest
    } = props,
    { name, defaultValue = { adult_count: 1, child_count: 0 } } = rest,
    [focused, setFocused] = useState<boolean>(false),
    [value, setValue] = useState<OccupancyValue>(
      () => _value || defaultValue,
    ),
    placeholder = useMemo(
      () => {
        const { adult_count, child_count } = value;

        const placeholder: string[] = [];

        if (adult_count) {
          placeholder.push(`${adult_count} Adult`);
        }

        if (child_count) {
          placeholder.push(`${child_count} Children`);
        }

        if (!placeholder.length) {
          placeholder.push("Select guests");
        }

        return placeholder.join(", ");
      },
      [value],
    ),
    { className: _lcx } = labelProps,
    ccx = useClx(clxs.container, className),
    lcx = useClx(clxs.label, _lcx),
    icx = useClx(clxs.input, "input"),
    ecx = useClx(clxs.expandIcon, "expand"),
    handleValueChange = (value: OccupancyValue) => {
      setValue(value);

      if (!onChange) {
        return;
      }

      const target = { name: name, value: value },
        payload: any = { target: target, currentTarget: target };

      onChange(payload);
    },
    handleFocus = () => {
      setFocused(true);

      if (!onFocus) {
        return;
      }

      const target = { name: name, value: value, focused: true },
        payload: any = { target: target, currentTarget: target };

      onFocus(payload);
    },
    handleBlur = () => {
      setFocused(false);

      if (!onBlur) {
        return;
      }

      const target = { name: name, value: value, focused: true },
        payload: any = { target: target, currentTarget: target };

      onBlur(payload);
    },
    handleClick = () => {
      setFocused(true);
    },
    handleDone = (e: MouseEvent<HTMLButtonElement>) => {
      handleDoneClick && handleDoneClick(e);

      e.stopPropagation();

      setFocused(false);

      return handleValueChange(value);
    },
    handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
    };

  useEffect(() => {
    if (!_value) {
      return;
    }

    setValue(_value);
  }, [_value]);

  useEffect(
    () => {
      if(isShowOccupancyDialog) {
        setFocused(true);
      }
    },
    [isShowOccupancyDialog],
  );

  return (
    <div
      {...containerProps}
      data-focus={focused}
      className={ccx}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleClick}
    >
      {label && (
        <label
          {...labelProps}
          htmlFor={name}
          className={lcx}
        >
          {label}
        </label>
      )}
      <div
        tabIndex={1}
        className={icx}
        data-error={Boolean(error).valueOf()}
        data-value={placeholder}
        suppressHydrationWarning={true}
      >
        {placeholder}
      </div>
      {error && <div className={clxs.error}>{error}</div>}
      <ExpandIcon className={ecx} />
      {focused && (
        <div
          className={clxs.optionsContainer}
          onMouseDown={handleMouseDown}
        >
          <Occupancy
            {...rest}
            value={value}
            onChange={handleValueChange}
          />
          <button
            className={clxs.done}
            onClick={handleDone}
            type="button"
          >
            {occupancySuccessText ? occupancySuccessText : "Done"}
          </button>
        </div>
      )}
    </div>
  );
}

export default OccupancySelect;
