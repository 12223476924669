import React, { MouseEvent, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import useClx from "../../hooks/use-clx";
import clxs from "./occupancy.module.css";
import ADD_CIRCLE_GREY_ICON from "../../assets/icons/add-circle-grey.svg";
import REMOVE_CIRCLE_GREY_ICON from "../../assets/icons/remove-circle-grey.svg";
import { useSnackbar } from "../../hooks/use-snackbar";

export interface OccupancyProps {
  defaultValue?: OccupancyValue;
  value?: OccupancyValue;
  onChange?: (value: OccupancyValue) => void;
  name?: string;
  className?: string;
  maxAdultCount?: number;
  maxChildCount?: number;
  disabled?: boolean;
  error?: string;
}

function Occupancy(props: OccupancyProps) {
  const { defaultValue, value: _value } = props,
    enqueueSnackbar = useSnackbar(),
    [adultCount, setAdultCount] = useState<number>(
      () => _value?.adult_count || defaultValue?.adult_count || 1,
    ),
    [childCount, setChildCount] = useState<number>(
      () => _value?.child_count || defaultValue?.child_count || 0,
    ), {
      value,
      onChange,
      className,
      maxAdultCount = 100,
      maxChildCount = 100,
      disabled,
      error,
    } = props,
    ccx = useClx(clxs.container, className),
    handleChange = (adult_count: number, child_count: number) => {
      if (!onChange) {
        return;
      }

      const value: OccupancyValue = {
        adult_count: adult_count,
        child_count: child_count,
      };

      _dOnChange(onChange, value);
    },
    handleAdd = (e: MouseEvent<HTMLElement>) => {
      if (disabled) {
        return;
      }

      const target = e.currentTarget as HTMLElement;

      const name = target.dataset.name ?? "";

      let ac = adultCount, cc = childCount;

      if (name === "adult_count") {
        ac = Math.min(maxAdultCount, adultCount + 1);
        if ((adultCount + 1) > maxAdultCount) {
          enqueueSnackbar(("This property can accomodate a maximum of " + maxAdultCount + " adults."), "error")
        }
      }

      if (name === "child_count") {
        cc = Math.min(maxChildCount, childCount + 1);
        if ((childCount + 1) > maxChildCount) {
          enqueueSnackbar(("This property can accomodate a maximum of " + maxChildCount + " children."), "error")
        }
      }

      setAdultCount(ac);

      setChildCount(cc);

      handleChange(ac, cc);
    },
    handleSubtract = (e: MouseEvent<HTMLElement>) => {
      if (disabled) {
        return;
      }

      const target = e.currentTarget as HTMLElement;

      const name = target.dataset.name ?? "";

      let ac = adultCount, cc = childCount;

      if (name === "adult_count") {
        ac = Math.max(0, adultCount - 1);
      }

      if (name === "child_count") {
        cc = Math.max(0, childCount - 1);
      }

      setAdultCount(ac);

      setChildCount(cc);

      handleChange(ac, cc);
    },
    handleValueChange = (value?: OccupancyValue) => {
      if (!value) {
        return;
      }

      const { adult_count, child_count } = value;

      if (adult_count !== adultCount) {
        setAdultCount(adult_count);
      }

      if (child_count !== childCount) {
        setChildCount(child_count);
      }
    };

  useEffect(
    () => handleValueChange(value),
    [value],
  );

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Adults
      </div>
      <button
        type="button"
        className={clxs.button}
        data-name="adult_count"
        onClick={handleSubtract}
      >
        <img
          src={REMOVE_CIRCLE_GREY_ICON}
          alt="subtract"
        />
      </button>
      <div
        className={clxs.number}
        suppressHydrationWarning={true}
      >
        {adultCount.toLocaleString("en-IN")}
      </div>
      <button
        type="button"
        className={clxs.button}
        data-name="adult_count"
        onClick={handleAdd}
      >
        <img
          src={ADD_CIRCLE_GREY_ICON}
          alt="add"
        />
      </button>
      <div className={clxs.separator}>&nbsp;</div>
      <div className={clxs.title}>
        Children
      </div>
      <button
        type="button"
        className={clxs.button}
        data-name="child_count"
        onClick={handleSubtract}
      >
        <img
          src={REMOVE_CIRCLE_GREY_ICON}
          alt="subtract"
        />
      </button>
      <div
        className={clxs.number}
        suppressHydrationWarning={true}
      >
        {childCount.toLocaleString("en-IN")}
      </div>
      <button
        type="button"
        className={clxs.button}
        data-name="child_count"
        onClick={handleAdd}
      >
        <img
          src={ADD_CIRCLE_GREY_ICON}
          alt="add"
        />
      </button>
      <div className={clxs.childrenSubtitle}>Below 5 years of age</div>
      {error && <div className={clxs.error}>{error}</div>}
    </div>
  );
}

export default Occupancy;

export type OccupancyValue = {
  adult_count: number;
  child_count: number;
};

const _dOnChange = debounce(
  (cb: (value: OccupancyValue) => void, value: OccupancyValue) => cb(value),
  500,
);
