import { useCallback } from "react";

function useWindowNavigate () {
  const push = useCallback(
    (to: To, options?: NavigateOptions) => {
      const {
        host = window.location.host,
        pathname = window.location.pathname,
        search,
      } = to;

      const url = new URL(window.location.href);

      url.host = host;

      url.pathname = pathname;

      const safeParams = search instanceof URLSearchParams
        ? search : new URLSearchParams(search);

      url.search = safeParams.toString();

      const { replace = false } = options ?? {};

      if (replace) {
        return window.location.replace(url);
      }

      return window.location.assign(url);
    },
    [],
  );

  return push;
}

export default useWindowNavigate;

type To = {
  host?: string;
  pathname?: string;
  search?: Record<string, string> | URLSearchParams;
};

type NavigateOptions = {
  replace?: boolean;
};