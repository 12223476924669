import React, { useMemo } from "react";
import clxs from "./referral-points.module.css";
import LOGO from "../../assets/refer-n-earn/refer-logo.svg";
import LINE from "../../assets/refer-n-earn/line.svg";

interface ReferralPointsProps {
  points: number;
  user?: User | null;
  isKnowMore?: boolean;
}

const ReferralPoints = (props: ReferralPointsProps) => {
  const { points, user, isKnowMore } = props;
  const isTierPresent = useMemo(() => {
      const tier = user?.loyalty_point_metadata?.tier;
      return tier && tier != "no_tier";
    }, [user]),
    // Points component will not be shown when user has logged in without referral code or no tier is present.
    isShowPointsComponent = useMemo(() => {
      const tier = user?.loyalty_point_metadata?.tier,
        isTierPresent = tier && tier != "no_tier",
        referralCode = user?.sign_up_referral_code;

      //No tier or no referral code then hide the points component.
      return isTierPresent || referralCode;
    }, [user]),
    refereePoints = useMemo(() => {
      const points = user?.referee_points;
      return points;
    }, [user]),
    pointsText = useMemo(() => {
      const text = points ? `Available ${points.toLocaleString()} points` : `${points} points`;
      return text;
    },[points])

  return isShowPointsComponent ? (
    <div className={clxs.container}>
      <img
        src={LOGO}
        alt="refer-logo"
        className={clxs.logo}
      />
      <div className={clxs.points}>
        {pointsText}
      </div>
      {/* User has no tier which means no booking yet but has referral points. */}
      {!isTierPresent && refereePoints ? (
        <div className={clxs.criteria}>
          <div className={clxs.criteriaTitle}>
            Book 3 or more bedroom stay and redeem points on checkout.
          </div>
          <div className={clxs.criteriaSubtitle}>
            *Valid for minimum 2 nights bookings
          </div>
        </div>
      ) : (
        ""
      )}
      {/* User has tier which means booking done in past, but has used all the points. */}
      {isTierPresent && !refereePoints ? (
        <div className={clxs.criteria}>
          <div className={clxs.criteriaTitle}>
            Earn more points by referring more people to a Lohono vacation.
          </div>
        </div>
      ) : (
        ""
      )}
      {/* User has no tier and no points. Which means user has consumed points for a booking but tier has not been alloted yet. Tier is alloted after 7 days of checkout. */}
      {!isTierPresent && !refereePoints ? (
        <div className={clxs.criteria}>
          <div className={clxs.criteriaTitle}>
            Earn more points by enrolling in Lohono Infinity and unlocking
            access to our referral programme.
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={clxs.buttonContainer}>
        {isKnowMore ? (
          <a
            href="/refer"
            className={clxs.redeemButton}
          >
            Know More
          </a>
        ) : points ? (
          <a
            href="/#locations"
            className={clxs.redeemButton}
          >
            Redeem Now
          </a>
        ) : (
          ""
        )}
        {isTierPresent && (
          <>
            <img
              src={LINE}
              alt="divider"
              className={clxs.divider}
            />
            <a
              href="/refer-history"
              className={clxs.referHistory}
            >
              Refer History
            </a>
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ReferralPoints;
