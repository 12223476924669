import { useAtomState } from "./use-atom";

function useCountryCode (): CountryCodeMeta {
  const [countryIsoCode] = useAtomState<CountryCodeMeta>(
    COUNTRY_ISO_CODE_KEY,
    DEFAULT_COUNTRY_CODE_META,
  );

  return countryIsoCode;
}

export default useCountryCode;

export const COUNTRY_ISO_CODE_KEY = "COUNTRY_ISO_CODE";

const DEFAULT_COUNTRY_CODE_META: CountryCodeMeta = {
  countryCode: "",
  defaultCountryCode: "",
  supported: [],
};

export type CountryCodeMeta = {
  supported: string[];
  countryCode: string;
  defaultCountryCode: string;
}