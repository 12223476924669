import { useAtomState } from "./use-atom";

class _DefaultAnalyticsService implements IAnalyticsService {
  track(): void {
    return;
  }
}

const DefaultAnalyticsService = new _DefaultAnalyticsService();

export const ANALYTICS_SERVICE_KEY = "ANALYTICS_SERVICE";

function useAnalyticsService() {
  const [service, setService] =
    useAtomState<IAnalyticsService>(
      ANALYTICS_SERVICE_KEY,
      DefaultAnalyticsService,
    );

  return {
    service: service,
    setService: setService,
  };
}

export default useAnalyticsService;
