import React from "react";
import clxs from "./location-experience.module.css";
import useClx from "../../../hooks/use-clx";
import { FEATURES_LIST } from "../../../core/services/lohono-experience";

interface LocationExperienceProps {
  className?: string;
}

function LocationExperience(props: LocationExperienceProps) {
  const { className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.header}>Lohono Experience</div>
      <div className={clxs.features}>
        {FEATURES_LIST.map((each, i) => (
          <div 
            key={i} 
            className={clxs.featuresContainer}
          >
            <img 
              src={each.icon} 
              alt={each.title} 
              className={clxs.icon}
            />
            <div className={clxs.content}>
            <div className={clxs.title}>{each.title}</div>
            <div className={clxs.subtitle}>{each.subtitle}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LocationExperience;
