import { STATIC_DATA_KEY } from "../../../../core/constants";
import { useAtomState } from "../../../hooks/use-atom";

function usePropertyBannerTemplate () {

  const [staticData] = useAtomState<StaticData>(STATIC_DATA_KEY, DEFAULT_DATA);

  return staticData.banners;
}

export default usePropertyBannerTemplate;

const DEFAULT_DATA: StaticData = {curated_list: [],banners: []};

// const DEFAULT_BANNERS = [
//   {
//     name: "Villa Habu",
//     desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/villa-habu-desktop.jpg",
//     mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/villa-habu-mobile.jpg",
//     address: "Udaipur, Rajasthan, India",
//     path: "/villas/india/udaipur/villa-habu",
//     destination: "india",
//     location: "udaipur",
//     property: "villa-habu",
//   },
//   {
//     name: "Siena Villa C",
//     desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/sienna-villa-desktop.jpg",
//     mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/sienna-villa-mobile.jpg",
//     address: "Alibaug, Maharashtra, India",
//     path: "/villas/india/alibaug/sienna-villa-c",
//     destination: "india",
//     location: "alibaug",
//     property: "sienna-villa-c",
//   },
//   {
//     name: "Haldu Tola",
//     desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/haldu-tola-desktop.jpg",
//     mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/haldu-tola-mobile.jpg",
//     address: "Pench, Madhya Pradesh, India",
//     path: "/villas/india/pench/haldu-tola",
//     destination: "india",
//     location: "pench",
//     property: "haldu-tola",
//   },
//   {
//     name: "Glasshouse On The Bay",
//     desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/glasshouse-villa-desktop.jpg",
//     mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/glasshouse-villa-mobile.jpg",
//     address: "Chapora, Goa, India",
//     path: "/villas/india/goa/glasshouse-on-the-bay",
//     destination: "india",
//     location: "goa",
//     property: "glasshouse-on-the-bay",
//   },
//   {
//     name: "Srinivas - The Royal Residence",
//     desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/srinivas-desktop.jpg",
//     mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/srinivas-mobile.jpg",
//     address: "Jaipur, Rajasthan, India",
//     path: "/villas/india/jaipur/srinivas-the-royal-residence",
//     destination: "india",
//     location: "jaipur",
//     property: "srinivas-the-royal-residence",
//   },
//   {
//     name: "The Ray",
//     desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/ray-villa-desktop.jpeg",
//     mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/ray-villa-mobile.jpeg",
//     address: "Alibaug, Maharashtra, India",
//     path: "/villas/india/alibaug/rays",
//     destination: "india",
//     location: "alibaug",
//     property: "rays",
//   },
//   {
//     name: "Sacri Borod Hill 9",
//     desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/sacri-villa-desktop.jpg",
//     mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/sacri-villa-mobile.jpg",
//     address: "Mandrem, Goa, India",
//     path: "/villas/india/goa/sacri-borod-hill-9",
//     destination: "india",
//     location: "goa",
//     property: "sacri-borod-hill-9",
//   },
// ];

export const INTERNATIONAL_PROPERTY_BANNERS = [
  {
    name: "Villa Batujimbar",
    desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/villa-batujimbar-desktop.jpg",
    mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/filters:quality(75)/400x600/hp-banners/villa-batujimbar-mobile.jpg",
    address: "Sanur, Bali, Indonesia",
    path: "/villas/indonesia/bali/villa-batujimbar",
    destination: "indonesia",
    location: "bali",
    property: "villa-batujimbar",
  },
  {
    name: "Baan Paa Talee Estate",
    desktop_image_url: "https://d3oo9u3p09egds.cloudfront.net/hp-banners/baan-paa-talee-estate-phuket-desktop.jpg",
    mobile_image_url: "https://d3oo9u3p09egds.cloudfront.net/filters:quality(75)/400x600/hp-banners/baan-paa-talee-estate-phuket-mobile.jpg",
    address: "Kamala, Phuket, Thailand",
    path: "/villas/thailand/phuket/baan-paa-talee-estate",
    destination: "thailand",
    location: "phuket",
    property: "baan-paa-talee-estate",
  },
];
