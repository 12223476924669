import React, {useRef, useEffect} from "react";

interface InnerHtmlProps {
    htmlString: string;
    classString?: string;
}

function InnerHtml(props: InnerHtmlProps) {
    const divRef = useRef<HTMLDivElement>(null)
    // const isFirstRender = useRef(true)
    const { htmlString, classString } = props;
  
    useEffect(() => {
    //   if (!htmlString || !divRef.current) throw new Error("html prop can't be null")
    //   if (!isFirstRender.current) return
    //   isFirstRender.current = false;
        
      if(divRef && divRef.current) {
        const slotHtml = document.createRange().createContextualFragment(htmlString) // Create a 'tiny' document and parse the html string
        divRef.current.innerHTML = "" // Clear the container
        divRef.current.appendChild(slotHtml) // Append the new content
      }
    }, [htmlString])
  
    // eslint-disable-next-line react/react-in-jsx-scope
    return <div 
      			className={classString} 
      			ref={divRef}
           />
}

export default InnerHtml;