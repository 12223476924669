import React from "react";
import FaqItem from "./FaqItem";
import FAQ_BANNER from "../../assets/faq-banner.svg";
import useClx from "../../hooks/use-clx";
import useFaq from "../../hooks/use-faq";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./faq.module.css";

function FAQ () {
  const { faq } = useFaq(),
    bcx = useClx(fwclxs.container, clxs.bannerContainer);

  return (
    <>
      <div className={bcx}>
        <img
          src={FAQ_BANNER}
          alt="faq-banner"
          className={clxs.banner}
        />
        <h1 className={clxs.title}>
          Frequently Asked Questions
        </h1>
      </div>
      <div className={clxs.container}>
        {faq.map(
          (each, key) => (
            <FaqItem
              key={key}
              faq={each}
              className={clxs.item}
            />
          ),
        )}
      </div>
    </>
  );
}

export default FAQ;
