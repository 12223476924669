import React, { Fragment } from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./faq.module.css";

interface FaqProps {
  className?: string;
  titleClassName?: string;
  faq: FAQ[];
}

function Faq (props: FaqProps) {
  const { titleClassName, className, faq } = props;

  const ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={titleClassName}>
        FAQs
      </div>
      {faq.map(
        (each, key) => (
          <Fragment key={key}>
            <div className={clxs.question}>
              {each.question}
            </div>
            <div className={clxs.answer}>
              {each.answer}
            </div>
          </Fragment>
        ),
      )}
    </div>
  );
}

export default Faq;