import React from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./experience-item.module.css";
import CHEVRON_RIGHT from "../../../assets/icons/chevron-right-primary.svg";

interface ExperienceItemProps {
  className?: string;
  thumbnail: string;
  title: string;
  description: string;
  href: string;
  hideJumpLink?: boolean;
}

function ExperienceItem (props: ExperienceItemProps) {
  const {
      className,
      thumbnail,
      title,
      description,
      href,
      hideJumpLink = false,
    } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <img
        src={thumbnail}
        alt="thumbnail"
        className={clxs.thumbnail}
      />
      <div className={clxs.title}>{title}</div>
      <div className={clxs.description}>{description}</div>
      {!hideJumpLink && (
        <>
          <a
            href={href}
            className={clxs.vpLink}
          >
            View Properties
            <img
              src={CHEVRON_RIGHT}
              alt="jump"
            />
          </a>
          <a
            className={clxs.vpButton}
            href={href}
          >
            View Properties
          </a>
        </>
      )}
    </div>
  );
}

export default ExperienceItem;
