import { useEffect } from "react";
import useCountryCode from "./use-country-code";
import useUtm from "./use-utm";
import useSeoService from "./use-seo";
import { useStorageString } from "./use-storage";

function useContactUsPhoneNo (
  isLohonoBlack: boolean = false,
  isEventsPage: boolean = false,
): ContactUsMeta {
  const { countryCode } = useCountryCode(),
  // {value: original_campaign} = useStorageString("localStorage", UTM_CAMPAIGN),
  {value: customerSupportNumber, setValue: setCustomerSupportNumber} = useStorageString("localStorage", CUSTOMER_SUPPORT),
  {utm} = useUtm(),
  {campaign} = utm,
  {service: seoService} = useSeoService(),
  // [contactUsPhoneNo, setContactUsPhoneNo] = useState<ContactUsMeta|null>(),
  // contactUsPhoneNo = useMemo(
  //   () => {
  //     const contact = isLohonoBlack && countryCode === "IN"
  //       ? LOHONO_BLACK_IN_PHONE_NO
  //       : (COUNTRY_CODE_PHONE_NO_MAP[countryCode] ?? COUNTRY_CODE_PHONE_NO_MAP.GB);

  //     return {
  //       contact: contact,
  //       friendly: contact,
  //       tel: `tel:${contact.replaceAll(" ", "")}`,
  //     };
  //   },
  //   [countryCode, isLohonoBlack],
  // ),
  setDefaultSupportNumber = () => {
    if (isEventsPage) {
      const contact = EVENTS_AND_EXPERIENCE_PHONE_NO;
      return setCustomerSupportNumber(contact);
    } else {
      const contact = isLohonoBlack && countryCode === "IN"
        ? LOHONO_BLACK_IN_PHONE_NO
        : (COUNTRY_CODE_PHONE_NO_MAP[countryCode] ?? COUNTRY_CODE_PHONE_NO_MAP.GB);
      return setCustomerSupportNumber(contact);
    }
    // if(!utm_campaign) {
      // const contactObj = {
      //   contact: contact,
      //   friendly: contact,
      //   tel: `tel:${contact.replaceAll(" ", "")}`,
      // };
      // setContactUsPhoneNo(contactObj);
    //setCustomerSupportNumber(contact);
    // }
  },
  handleGetPhoneNumber = async (utm_campaign: string) => {

    if(!utm_campaign) {
      return setDefaultSupportNumber();
    }

    const { error, response } = await seoService.getUtmMobileNumber(utm_campaign);

    if(error || !response) {
      return;
    }

    const {mobile} = response;

    // // const contactObj = {
    // //   contact: mobile,
    // //   friendly: mobile,
    // //   tel: `tel:${mobile.replaceAll(" ", "")}`,
    // // };
    // // setContactUsPhoneNo(contactObj);
    setCustomerSupportNumber(mobile);
    
  };

  useEffect(
    () => {
      handleGetPhoneNumber(campaign);
    },[campaign],
  );

  return {
      contact: customerSupportNumber,
      friendly: customerSupportNumber,
      tel: `tel:${customerSupportNumber.replaceAll(" ", "")}`,
    };
}

export default useContactUsPhoneNo;

const COUNTRY_CODE_PHONE_NO_MAP : any= {
  IN: "+91 84306 00600",
  GB: "+44 80825 87722",
};

const LOHONO_BLACK_IN_PHONE_NO = "+91 75388 80808";

const EVENTS_AND_EXPERIENCE_PHONE_NO = "+91 95132 33422";

const CUSTOMER_SUPPORT = "customer_support_number";

type ContactUsMeta = {
  contact: string;
  friendly: string;
  tel: string;
}