import React, { useMemo } from "react";
import decodeLocationSlug from "../../../../utils/decode-location-slug";
import { getPropertySearchUrl } from "../../../../utils/property-search";
import useClx from "../../../hooks/use-clx";
import useSendRequest from "../../../hooks/use-send-request";
import { Track } from "../../AnalyticsManager";
import LocationAnchor from "../../LocationAnchor";
import useNearbyLocationsTemplate from "./use-nearby-location-template";
import clxs from "./nearby-locations.module.css";

interface NearbyLocationsProps {
  locationSlug: string;
  className?: string;
  checkinDate?: Date | null,
  checkoutDate?: Date | null,
  adultCount?: number,
  childCount?: number,
  title? : string,
  subtitle? :string,
  category? : string
}

function NearbyLocations (props: NearbyLocationsProps) {
  const {
      locationSlug,
      className,
      checkinDate,
      checkoutDate,
      adultCount,
      childCount,
      title,
      subtitle,
      category,
    } = props,
    { showSendRequestModal } = useSendRequest(),
    handleComingSoon = (location: PropertyLocation) => {
      if (!location.coming_soon) {
        return;
      }

      return showSendRequestModal(
        { interest: "rent", location: location.slug },
      );
    },
    compiled = useNearbyLocationsTemplate(locationSlug),
    ccx = useClx(clxs.container, className),
    cccx = useClx(clxs.collectionContainer, className),
    decodeLocation = useMemo(
      () => decodeLocationSlug(locationSlug),
      [locationSlug],
    );

  if (!compiled.length) {
    return null;
  }

  return (
    <div className={category == "collection" ? cccx : ccx}>
      <div className={clxs.titleContainer}>
        {title}
        {subtitle && (
          <div className={clxs.sectionSubtitle}>
            {subtitle}
          </div>
        )}
      </div>
      <div className={clxs.locationContainer}>
        {compiled.map((location, index) => {
          const {
              name,
              slug,
              thumbnail,
              property_count,
              coming_soon,
              country,
            } = location,
            url = getPropertySearchUrl(
              slug,
              checkinDate,
              checkoutDate,
              adultCount,
              childCount,
            ),
            decodedLocation = decodeLocationSlug(slug);

          return (
            <Track
              event="nearby_location_clicked"
              payload={{
                ...location,
                ...decodedLocation,
                params: {
                  checkinDate: checkinDate,
                  checkoutDate: checkoutDate,
                  adultCount: adultCount,
                  childCount: childCount,
                  ...decodeLocation,
                },
                href: url,
                index: index,
              }}
              key={slug}
            >
              <LocationAnchor
                name={name}
                slug={slug}
                property_count={property_count}
                coming_soon={coming_soon}
                country={country}
                thumbnail={thumbnail}
                key={slug}
                url={url}
                onClick={handleComingSoon.bind(null, location)}
              />
            </Track>
          );
        })}
      </div>
    </div>
  );
}

export default NearbyLocations;
