import React, { useMemo } from "react";
import usePathParams from "../../hooks/use-path-params";
import NotFound from "../NotFound";
import clxs from "./payment-status.module.css";
import GREEN_CHECKMARK_ICON from "../../assets/icons/green-checkmark.svg";
import RED_EXCLAIMATION_ICON from "../../assets/icons/exclamation-red-icon.svg";
import usePaymentStatus from "../../hooks/use-payment-status";
import useAnalytics from "../../hooks/use-analytics";

function PaymentStatus() {
  const paymentId = usePathParams(PATH, "payment_id"),
    { objPaymentStatus } = usePaymentStatus(paymentId),
    { track } = useAnalytics(),
    description = useMemo(
      () => {
        if (!objPaymentStatus) {
          return null;
        }

        const {
            amount,
            paymentStatus,
          } = objPaymentStatus,
          isPaymentSuccess = paymentStatus == "success",
          currency_symbol = "₹";

          if(isPaymentSuccess) {
            const trackingPayload = localStorage.getItem("trackingPayload");
            track("payment_success", trackingPayload ? JSON.parse(trackingPayload) : {});
          }

        return isPaymentSuccess ? (
          <>
            <div className={clxs.thankYou}>
              {`Thank you, ${objPaymentStatus.name}.`}
            </div>
            <div className={clxs.successMessage}>
              {"Your payment of "}<b>{`${currency_symbol} ${amount?.toLocaleString("en-IN")}`}</b>{" was successful."}
              <br/>
            </div>
          </>
        ) : (
          <>
            <div className={clxs.thankYou}>
              {`Oops! ${objPaymentStatus.name}.`}
            </div>
            <div className={clxs.failMessage}>
              {"Your payment of "}<b>{`${currency_symbol} ${amount?.toLocaleString("en-IN")}`}</b>{" was unsuccessful."}
              <br/>
          </div>
          </>
        );
      },
      [objPaymentStatus],
    );

  if (!paymentId) {
    return <NotFound/>;
  }

  if (!objPaymentStatus) {
    return null;
  }

  return (
    <div className={clxs.container}>
      <img
        src={objPaymentStatus.paymentStatus == "success" ? GREEN_CHECKMARK_ICON : RED_EXCLAIMATION_ICON}
        alt="success"
        className={clxs.checkmark}
      />
      {description}
    </div>
  );
}

export default PaymentStatus;

const PATH = "/payment-status/:payment_id";

