import React, { useCallback, useMemo } from "react";
import { useLoginModal } from "../../hooks/use-login-modal";
import { useUser } from "../../hooks/use-session";
import Faq from "./Faq";
import Intro from "./Intro";
import PolicyBenefits from "./PolicyBenefits";
import withLoyaltyPointsTheme from "./Theme/with-loyalty-points-theme";
import useLoyaltyPointPolicy from "./use-loyalty-point-policy";
import clxs from "./loyalty-points.module.css";
import fwclxs from "../../styles/full-width.module.css";
import useClx from "../../hooks/use-clx";
import Modal, { useShowModal } from "../../hooks/use-modal";
import PointsHistory from "./PointsHistory";
import useGetLoyaltyPointsHistory from "../../hooks/use-get-loyalty-points-history";

const LoyaltyPoints = withLoyaltyPointsTheme(
  function LoyaltyPointsCore () {

    const showModal = useShowModal(POINTS_HISTORY_MODAL_ID)

    const showLoginFlowModal = useLoginModal();

    const handleLogin = useCallback(
      () => showLoginFlowModal({}),
      [showLoginFlowModal],
    );

    const getLoyaltyPointsHistory = useGetLoyaltyPointsHistory();

    const handleLoyaltyPointsHistory = useCallback(
      async () => {
        const pointsHistory = await getLoyaltyPointsHistory();

        if (!pointsHistory) {
          return;
        }
        await showModal({ pointsHistory: pointsHistory });
      },
      [getLoyaltyPointsHistory],
    );

    const policy = useLoyaltyPointPolicy();

    const user = useUser();

    const { tier, nextTier } = useMemo(
      () => {
        if (!user) {
          return {
            tier: undefined,
            nextTier: undefined,
          };
        }

        const tierValue = user?.loyalty_point_metadata.tier;

        const { tiers } = policy;

        const tierIndex = tiers.findIndex(each => each.value === tierValue);

        if (tierIndex === -1) {
          return {
            tier: {
              value: "no_tier",
              friendly_name: "No Tier",
              benefits: [],
              discount_percentage: 0,
              min_nights: 0,
              max_nights: tiers[0].min_nights,
            },
            nextTier: tiers[0],
          }
        }

        const tier = tiers[tierIndex];

        const nextTier = tierIndex > -1 && tierIndex < tiers.length - 1
          ? tiers[tierIndex + 1] : undefined;

        return {
          tier: tier,
          nextTier: nextTier,
        };
      },
      [user],
    );

    const icx = useClx(fwclxs.container, fwclxs.containerPadding);

    return (
      <>
        <Intro
          tier={tier}
          nextTier={nextTier}
          className={icx}
          nightsCompleted={user?.loyalty_point_metadata.total_nights_spent}
          onLogin={handleLogin}
          onShowLoyaltyPointsHistory={handleLoyaltyPointsHistory}
        />
        <PolicyBenefits
          titleClassName={clxs.benefitsTitle}
          tiers={policy.tiers}
          nightsCompleted={user?.loyalty_point_metadata.total_nights_spent}
          currentTier={tier?.value}
          className={clxs.section}
        />
        <Faq
          faq={policy.faq}
          titleClassName={clxs.title}
          className={clxs.section}
        />
        <Modal id={POINTS_HISTORY_MODAL_ID}>
          <PointsHistory />
        </Modal>
      </>
    );
  },
);

export default LoyaltyPoints;

const POINTS_HISTORY_MODAL_ID = "loyalty-points-history-modal";