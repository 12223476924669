import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import Img from "../../Img";
import clxs from "./intro.module.css";
import NoTier from "./NoTier";
import NotLoggedIn from "./NotLoggedIn";
import Tier from "./Tier";
import BANNER_DESKTOP from "../../../assets/loyalty-points/llp-banner-desktop.png";
import BANNER_MOBILE from "../../../assets/loyalty-points/llp-banner-mobile.png";

interface IntroProps {
  className?: string;
  tier?: LoyaltyPointsTier;
  nextTier?: LoyaltyPointsTier;
  nightsCompleted?: number;
  onLogin?: () => void;
  onShowLoyaltyPointsHistory?: () => void;
}

function Intro (props: IntroProps) {
  const { tier, className } = props;

  const safeTierValue = useMemo(
    () => tier?.value ?? "login",
    [tier],
  );

  const ccx = useClx(clxs.bannerContainer, className);

  return (
    <div className={ccx}>
      <Img
        src={BANNER_DESKTOP}
        mobileSrc={BANNER_MOBILE}
        desktopSrc={BANNER_DESKTOP}
        className={clxs.banner}
      />
      {(HERO_COMPONENT[safeTierValue] ?? HERO_COMPONENT.login)(props)}
    </div>
  );
}

export default Intro;

const TIER_HERO = (props: IntroProps) => (
  <Tier
    tier={props.tier}
    nextTier={props.nextTier}
    nightsCompleted={props.nightsCompleted}
    className={clxs.content}
    onShowLoyaltyPointsHistory={props.onShowLoyaltyPointsHistory}  
  />
);

const HERO_COMPONENT = {
  login: (props: IntroProps) => (
    <NotLoggedIn
      className={clxs.content}
      onLogin={props.onLogin}
    />
  ),
  no_tier: (props: IntroProps) => (
    <NoTier 
      className={clxs.container} 
      onShowLoyaltyPointsHistory={props.onShowLoyaltyPointsHistory}
    />
  ),
  silver: TIER_HERO,
  gold: TIER_HERO,
  platinum: TIER_HERO,
};

