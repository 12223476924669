import * as Yup from "yup";

const SEND_REQUEST_SCHEMA = Yup.object({
  full_name: Yup.string().trim().required("Please enter your name"),
  phone_ext: Yup.string().trim().required("Required"),
  phone_no: Yup.string()
    .trim()
    .matches(/^[0-9]{8,12}$/gi,"Invalid phone number")
    .required("Invalid phone number"),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Invalid email address"),
  interest: Yup.string().trim().required("Required"),
  location: Yup.string()
    .trim()
    .when("interest", {
      is: "rental",
      then: Yup.string().required("Required"),
    }),
  message: Yup.string().trim(),
});

const SEND_CORPORATE_REQUEST_SCHEMA = Yup.object({
  full_name: Yup.string().trim().required("Please enter your name"),
  phone_ext: Yup.string().trim().required("Required"),
  phone_no: Yup.string()
    .trim()
    .matches(/^[0-9]{8,12}$/gi,"Invalid phone number")
    .required("Invalid phone number"),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Invalid email address"),
  interest: Yup.string().trim().required("Required"),
  location: Yup.string()
    .trim()
    .when("interest", {
      is: "rental",
      then: Yup.string().required("Required"),
    }),
  message: Yup.string().trim(),
  guests: Yup.string()
    .trim()
    .required("Please select no of guests"),
});

export {SEND_REQUEST_SCHEMA,SEND_CORPORATE_REQUEST_SCHEMA};