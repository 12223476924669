import WEDDING_DESKTOP from "../../../assets/Collections-Wedding-Desktop.jpg";
import WEDDING_MOBILE from "../../../assets/Collections-weddings-Mobile.jpg";
import CELEBRATION_DESKTOP from "../../../assets/Collections-intimate-celebrations-Desktop.jpg";
import CELEBRATION_MOBILE from "../../../assets/Collections-intimate-celebration-Mobile.jpg";
import CORPORATE_DESKTOP from "../../../assets/Collections-Corporate-Desktop.jpg";
import CORPORATE_MOBILE from"../../../assets/Collections-Corporate-mobile.jpg";
import DINNING_EXPERIENCE_DESKTOP from "../../../assets/Collections-Dining-Experiences-Desktop.jpg";
import DINNING_EXPERIENCE_MOBILE from "../../../assets/Collections-FnB-experiences-Mobile.jpg";
import OUTDOOR_EXPERIENCE_DESKTOP from "../../../assets/Collections-Outdoor-Experiences-Desktop.jpg";
import OUTDOOR_EXPERIENCE_MOBILE from "../../../assets/Collection-outdoor-experiences-Mobile.jpg";
import IN_VILLA_EXPERIENCE_DESKTOP from "../../../assets/Collections-in-villa-experiences-Desktop.jpg";
import IN_VILLA_EXPERIENCE_MOBILE from "../../../assets/Collection-In-villa-experiences-Mobile.jpg";

const OCCASION_TEMPLATE : OccasionDetails[] =  [
  {
    title: "Dining Experiences",
    header: "Discover a world of culinary delights at Lohono where we bring your favourite cuisines to life.",
    subtitle: "Whether you crave Italian, Continental, or Chinese, our in-house menu offers an array of exquisite local specialties. For a truly personalised dining experience, we arrange a special chef  for a luxurious dine-in.",
    mobileThumbnail: DINNING_EXPERIENCE_DESKTOP,
    desktopThumbnail: DINNING_EXPERIENCE_MOBILE,
  },
  {
    title: "Celebrations",
    header: "Transform your dream celebration into reality at Lohono!",
    subtitle: "Our dedicated staff will handle everything, from stunning decor to flawless execution. Bring your mood board to life and create unforgettable memories for any birthday, anniversary, or special occasion.",
    mobileThumbnail: CELEBRATION_MOBILE,
    desktopThumbnail: CELEBRATION_DESKTOP,
  },
  {
    title: "Weddings",
    header: "If you’re going the destination wedding route, do it with Lohono!",
    subtitle: "Choose from our exquisite properties at breathtaking locations. Harmony - By Lohono Stays will manage all aspects, including F&B experiences, stay arrangements, venue decor, transportation, concierge service, and guest delights. Let us make your special day unforgettable.",
    mobileThumbnail: WEDDING_MOBILE,
    desktopThumbnail: WEDDING_DESKTOP,
  },
  {
    title: "Corporate Retreats",
    header: "Experience a refreshing shift from the office desk by brainstorming amidst nature.",
    subtitle: "Enjoy fast Wi-Fi, versatile meeting spaces for indoor and outdoor sessions, and dedicated staff catering to your needs with fresh in-house meals and housekeeping at Lohono Stays. With stunning vistas as your backdrop, this setting is perfect for a revitalising corporate retreat.",
    mobileThumbnail: CORPORATE_MOBILE,
    desktopThumbnail: CORPORATE_DESKTOP,
  },
  {
    title: "In-villa Experiences",
    header: "Stay in and savour our luxurious in-villa experiences",
    subtitle: "From gin tastings and BBQs to cheese making and wine sampling. Whether you're seeking a fun competition with friends or a unique date idea, our activities promise indulgence and entertainment right at your doorstep.",
    mobileThumbnail: IN_VILLA_EXPERIENCE_MOBILE,
    desktopThumbnail: IN_VILLA_EXPERIENCE_DESKTOP,
  },
  {
    title: "Outdoor Experiences",
    header: "Venture beyond the villa with Lohono's exciting outdoor experiences.",
    subtitle: "Enjoy activities like treks, paramotoring, surfing, kayaking, golfing, and horse riding. Discover a holiday filled with adventure and exploration.",
    mobileThumbnail: OUTDOOR_EXPERIENCE_MOBILE,
    desktopThumbnail: OUTDOOR_EXPERIENCE_DESKTOP,
  },
  
];


export default OCCASION_TEMPLATE;