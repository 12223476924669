import clx, {Argument} from "classnames";
import {useMemo} from "react";

function useClx(...classes: Argument[]) {
  const cx = useMemo(
    () => clx(...classes),
    classes,
  );

  return cx;
}

export default useClx;
