import GENERIC_BANNER from "../../../assets/listing-page-generic.jpg";
import ALIBAUG_BANNER from "../../../assets/listing-page-alibaug.jpg";
import BALI_BANNER from "../../../assets/listing-page-bali.jpg";
import COONOOR_BANNER from "../../../assets/listing-page-coonoor.jpg";
import GOA_BANNER from "../../../assets/listing-page-goa.jpg";
import KARJAT_BANNER from "../../../assets/listing-page-karjat.jpg";
// import OOTY_BANNER from "../../../assets/listing-page-ooty.jpg";
import RISHIKESH_BANNER from "../../../assets/listing-page-rishikesh.jpg";
import KOH_SAMUI_BANNER from "../../../assets/listing-page-koh-samui.jpg";
import LONAVALA_BANNER from "../../../assets/listing-page-lonavala.jpg";
import PHUKET_BANNER from "../../../assets/listing-page-phuket.jpg";
import MAHABALESHWAR_BANNER from "../../../assets/listing-page-mahabaleshwar.jpg";
import MUSSOORIE_BANNER from "../../../assets/listing-page-mussoorie.jpg";
import BHIMTAL_BANNER from "../../../assets/listing-page-bhimtal.jpg";
import SRINAGAR_BANNER from "../../../assets/listing-page-srinagar.jpg";
import JIM_CORBETT_BANNER from "../../../assets/listing-page-jim-corbett.jpg";
import JAIPUR_BANNER from "../../../assets/listing-page-jaipur.jpg";
import SHIMLA_BANNER from "../../../assets/listing-page-shimla.jpg";
import KASAULI_BANNER from "../../../assets/listing-page-kasauli.jpg";
// import SRI_LANKA_BANNER from "../../../assets/listing-page-sri-lanka.jpg";
import MALDIVES_BANNER from "../../../assets/listing-page-maldives.jpg";
import COORG_BANNER from "../../../assets/listing-page-coorg.jpg";

export const BANNER_IMAGE_MAP :bannerDetails = {
  "india-goa": GOA_BANNER,
  "india-lonavala": LONAVALA_BANNER,
  "india-alibaug": ALIBAUG_BANNER,
  "india-karjat": KARJAT_BANNER,
  "india-coonoor": COONOOR_BANNER,
  "india-coorg": COORG_BANNER,
  "india-mahabaleshwar": MAHABALESHWAR_BANNER,
  "india-mussoorie": MUSSOORIE_BANNER,
  "india-bhimtal": BHIMTAL_BANNER,
  "india-srinagar": SRINAGAR_BANNER,
  "india-kasauli": KASAULI_BANNER,
  // "india-ooty": OOTY_BANNER,
  "india-rishikesh": RISHIKESH_BANNER,
  "india-jimcorbett": JIM_CORBETT_BANNER,
  "india-jaipur": JAIPUR_BANNER,
  "india-shimla": SHIMLA_BANNER,
  "thailand-koh-samui": KOH_SAMUI_BANNER,
  "thailand-phuket": PHUKET_BANNER,
  "indonesia-bali": BALI_BANNER,
  // "srilanka-sri-lanka": SRI_LANKA_BANNER,
  "maldives-maldives": MALDIVES_BANNER,
  default: GENERIC_BANNER,
};

export const BANNER_TITLE_MAP : bannerDetails = {
  "india-goa": "Handpicked homes, intuitive hospitality, stellar service and modern amenities...",
  "india-lonavala": "One-of-a-kind experiences and homes combined with intuitive hospitality and modern amenities...",
  "india-alibaug": "Unique luxury homes that tell a story, the best amenities and thoughtful hospitality...",
  "india-karjat": "Scenic views, dense greenery and the most beautiful luxury villas...",
  "india-coonoor": "Stunning homes that exude charm and luxury in the Nilgiris",
  "india-coorg": "A Delightful Retreat Amidst Verdant Landscapes",
  "india-mahabaleshwar": "Scenic views, dense greenery and the most beautiful luxury villas...",
  "india-mussoorie": "Breathtaking luxury homes in The Queen of the Hills...",
  "india-bhimtal": "Blissful luxury homes in a picturesque lake town...",
  "india-srinagar": "Heritage and picturesque vistas coupled with the most beautiful homes...",
  "india-kasauli": "Heritage and picturesque vistas coupled with the most beautiful homes...",
  // "india-ooty": "Luxury homes amidst the verdant Blue Mountains",
  "india-rishikesh": "Rejuvenating luxury abodes on the banks of the holy Ganges",
  "india-shimla": "Luxury nestled amongst the Himalayan mountains with picturesque valleys and green forests",
  "india-jimcorbett": "Feel the natural richness hidden in country's best wildlife kingdom ...",
  "india-jaipur": "Experience the rich culture and history of Jaipur - the Pink City of India",
  "india-gurgaon": "Modern and picturesque landscape paired with the most beautiful homes...",
  "thailand-koh-samui": "Perfect beaches, crystal clear seas, night markets and temples...",
  "thailand-phuket": "White-sand beaches, romantic sunsets and exquisite hospitality...",
  "indonesia-bali": "Beachside escapes to hideaways in the hills...",
  // "srilanka-sri-lanka": "A diverse island with splendid beaches, forts and the best luxury villas...",
  "maldives-maldives": "Aquamarine lagoons, white sand beaches and luxury beachfront residences...",
  default: "Exclusive villas for rent in pristine\ndestinations!",
};