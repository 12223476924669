import React from "react";
import { useField } from "../../../../hooks/use-form";
import OtpInput from "../../../OtpInput";
import { OtpInputProps } from "../../../OtpInput/OtpInput";

interface OtpProps extends BaseOtpFieldProps {
  form: string;
  name: string;
  isImmediateAssistanceForm?:boolean;
}

function Otp (props: OtpProps) {
  const { form,isImmediateAssistanceForm, ...rest} = props,
    { name } = rest,
    { value, error, handleChange } = useField(form, name);

  return (
    <OtpInput
      {...rest}
      value={value}
      onChange={handleChange}
      isAssistanceForm={isImmediateAssistanceForm}
      error={error}
    />
  );
}

export default Otp;

type BaseOtpFieldProps = Omit<OtpInputProps, "value" | "onChange" | "onBlur" | "error">
