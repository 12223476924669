import * as Yup from "yup";
import SINGLE_WEEKEND_VALIDATION from "../../../../../../utils/single-weekend-validation";

const PRICE_CALCULATOR_SCHEMA = Yup.object({
  stay: Yup.object()
    .shape({
      start: Yup.date()
        .nullable(true)
        .required("Enter Checkin Date"),
      end: Yup.date()
        .nullable(true)
        .required("Enter Checkout Date"),
    }).test(SINGLE_WEEKEND_VALIDATION),
  occupancy: Yup.object({
    adult_count: Yup.number()
      .integer("adult count should be an integer")
      .moreThan(0, "adult count should be greater than 0")
      .required("adult count is required"),
    child_count: Yup.number()
      .integer("child count should be an integer")
      .moreThan(-1, "child count cannot be lower than 0"),
  }),
  pre_book_meals: Yup.boolean(),
});

export default PRICE_CALCULATOR_SCHEMA;
