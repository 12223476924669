import React from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./bespoke.module.css";
import BESPOKE_BANNER from "../../../../assets/lohono-black/bespoke.jpg";

interface BespokeProps {
  titleClassName?: string;
  subtitleClassName?: string;
  className?: string;
}

function Bespoke(props: BespokeProps) {
  const { className, titleClassName, subtitleClassName } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={titleClassName}>
        Bespoke Experience
      </div>
      <div className={subtitleClassName}>
        {CONTENT}
      </div>
      <img
        src={BESPOKE_BANNER}
        alt="banner"
        className={clxs.banner}
      />
    </div>
  );
}

export default Bespoke;

const CONTENT = `
Now, more than ever is the time to celebrate every life event and occasion, big or small. Whether you want to
throw your 8-year old the best birthday bash or pamper yourself with well-deserved wellness therapies, our
Events & Experiences Team designs unforgettable experiences. Be it a formal 5-course dinner, a fun karaoke
night, a high-tea soirée, a culinary class, or a whiskey masterclass, we can curate it all. All you need to do
is ask.
`;
