import React from "react";
import clxs from "./ineligible-criteria.module.css";
import CLOSE_ICON from "../../../assets/icons/close.svg";
import EXCLAMATION_RED_ICON from "../../../assets/icons/exclamation-red-icon.svg";
import Button from "../../Button";

interface IneligibleCriteriaProps {
  points: number;
  onSubmit?: () => void;
	criteria?: string[];
}

function IneligibleCriteria(props: IneligibleCriteriaProps) {
  const { points, onSubmit, criteria } = props;

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        <div className={clxs.heading}>
          <img
            src={EXCLAMATION_RED_ICON}
            alt="Ineligible Points Icon"
          />
          <div className={clxs.points}>
            {points}
            <div className={clxs.pointsText}>Points Redemption Unavailable</div>
          </div>
        </div>
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={onSubmit?.bind(null)}
        />
      </div>
      <div className={clxs.manualContainer}>
        <div className={clxs.criteriaHeading}>
          Referral Bonus points redemption requirement
        </div>
        <div className={clxs.criteriaContent}>
          <ul>
						{criteria?.map((each,key) => {
							return <li key={key}>{each}</li>	
						})}
            {/* <li>Stay for at least 2 nights.</li>
            <li>Select a villa with a minimum of 3 bedrooms (3BHK).</li> */}
          </ul>
        </div>
        <Button
          className={clxs.gotItButton}
          type="button"
          onClick={onSubmit?.bind(null)}
        >
          Got It
        </Button>
      </div>
    </div>
  );
}

export default IneligibleCriteria;
