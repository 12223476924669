import { useAtomValue } from "./use-atom";
import { FAQ_KEY } from "../../core/constants";

function useFaq() {
  const faq = useAtomValue<FAQ[]>(FAQ_KEY, []);

  return { faq: faq };
}

export default useFaq;
