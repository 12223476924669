import React, { ForwardedRef, forwardRef, ImgHTMLAttributes, useMemo } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./img.module.css";

export interface ImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  mobileSrc: string;
  desktopSrc: string;
  breakpoint?: string;
}

const Img = forwardRef(
  function ImgCore(
    props: ImgProps,
    ref: ForwardedRef<HTMLImageElement>,
  ) {
    const {
        className,
        mobileSrc,
        desktopSrc,
        breakpoint = DEFAULT_BREAKPOINT,
        ...rest
      } = props,
      { mobile, desktop } = useMemo(
        () => {
          const mobile=`(max-width: ${breakpoint})`,
            desktop = `(min-width: ${breakpoint})`;

          return {
            mobile: mobile,
            desktop: desktop,
          };
        },
        [breakpoint],
      ),
      pcx = useClx(clxs.picture, className),
      icx = useClx(clxs.image, "image");

    return (
      <picture className={pcx}>
        <source
          media={mobile}
          srcSet={mobileSrc}
        />
        <source
          media={desktop}
          srcSet={desktopSrc}
        />
        <img
          {...rest}
          ref={ref}
          className={icx}
        />
      </picture>
    );
  },
)

export default Img;

const DEFAULT_BREAKPOINT = "768px";