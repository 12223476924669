import React from "react";
import clxs from "./brands.module.css";
import BMW from "../../../assets/lohono-corporate/brands-hosted/bmw.svg";
import HINDUSTAN_UNILEVER from "../../../assets/lohono-corporate/brands-hosted/hul-logo.svg";
import AZB from "../../../assets/lohono-corporate/brands-hosted/azb.svg";
import MCKINSKEY from "../../../assets/lohono-corporate/brands-hosted/mckinskey.svg";
import CATHAY_PACIFIC from "../../../assets/lohono-corporate/brands-hosted/cathay-pacific.svg";
import PIRAMAL from "../../../assets/lohono-corporate/brands-hosted/piramal.svg";
import PAYTM from "../../../assets/lohono-corporate/brands-hosted/paytm.svg";
import CLUB_MAHINDRA from "../../../assets/lohono-corporate/brands-hosted/club-mahindra.svg"
import NEXT_BILLION_AI from "../../../assets/lohono-corporate/brands-hosted/ai.svg";
import RANDSTAD from "../../../assets/lohono-corporate/brands-hosted/randstad.svg";
import ANTLER from "../../../assets/lohono-corporate/brands-hosted/antler.svg";
import OLX from "../../../assets/lohono-corporate/brands-hosted/olx.svg";
import useClx from "../../../hooks/use-clx";
import fwclxs from "../../../styles/full-width.module.css";

function Brands() {
  const ccx = useClx(
    fwclxs.containerPadding,
    fwclxs.container,
    clxs.container,
  );
  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Brands Hosted
      </div>
      <div className={clxs.brands}>
        {TEMPLATE.map(
          (brand, key) => {
            return (
              <img
                src={brand}
                alt="close"
                className={clxs.brand}
                key={key}
              />
            );
          },
        )}
      </div>
    </div>
  );
}

export default Brands;

const TEMPLATE = [
  BMW,
  HINDUSTAN_UNILEVER,
  MCKINSKEY,
  AZB,
  CATHAY_PACIFIC,
  PIRAMAL,
  PAYTM,
  CLUB_MAHINDRA,
  NEXT_BILLION_AI,
  RANDSTAD,
  ANTLER,
  OLX,
];