import Fetch from "../../utils/fetch";

class OfferService implements IOfferService {
  private readonly fetch: Fetch<ApiResult>;

  constructor(fetch: Fetch<ApiResult>) {
    this.fetch = fetch;
  }

  getFiresaleOffers(): Promise<FetchResult<PropertyWithOffer[]>> {
    const url = new URL("/api/offers/firesale", window.location.origin);

    return this.fetch.GET(url.toString());
  }
}

export default OfferService;
