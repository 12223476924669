import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import CALL_PRIMARY_ICON from "../../../../assets/icons/call-primary.svg";
import CHEVRON_LEFT_ICON from "../../../../assets/icons/chevron-left-grey.svg";
import CLOSE_ICON from "../../../../assets/icons/close.svg";
import useClx from "../../../../hooks/use-clx";
import useContactUsPhoneNo from "../../../../hooks/use-contact-us-phone-no";
import { usePageValue } from "../../../../hooks/use-page";
import { Track } from "../../../AnalyticsManager";
import Button from "../../../Button";
import SendRequestForm from "../../index";
import clxs from "./modal-content.module.css";

interface ModalContent {
  defaultValue?: any;
  onSubmit?: (out: any) => void;
}

function ModalContent (props: ModalContent) {
  const { defaultValue, onSubmit } = props,
    { page } = usePageValue(),
    { pathname } = useLocation(),
    isLohonoBlack = useMemo(
      () => (
        page === "LB_ABOUT" ||
        pathname === "/villas/india/karjat/casa-riviere"
      ),
      [page, pathname],
    ),
    isCorporatePage = pathname?.includes("corporate-stays"),
    isEventsPage = pathname?.includes("experiences-and-events"),
    { friendly: contactUsPhoneNo, tel } = useContactUsPhoneNo((isLohonoBlack || isCorporatePage), isEventsPage),
    contactUsContent = useMemo(
      () => {
       return `Call us ${contactUsPhoneNo}`},
      [contactUsPhoneNo],
    ),
    sucx = useClx(clxs.submit, clxs.action),
    cucx = useClx(clxs.callUs, clxs.action);

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        <img
          src={CHEVRON_LEFT_ICON}
          alt="close"
          className={clxs.back}
          onClick={onSubmit?.bind(null, null)}
        />
        Send a request
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={onSubmit?.bind(null, null)}
        />
      </div>
      <SendRequestForm
        id={FORM_ID}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        className={clxs.form}
      >
        <Button
          className={sucx}
          type="submit"
        >
          Submit
        </Button>
        <div className={clxs.orContainer}>
          <span className={clxs.or}>
            OR
          </span>
        </div>
        <Track event="call_us_desktop_clicked">
          <a
            href={tel}
            className={cucx}
          >
            <img
              src={CALL_PRIMARY_ICON}
              alt="call"
              className={clxs.callIcon}
            />
            {contactUsContent}
          </a>
        </Track>
      </SendRequestForm>
      <Button
        className={clxs.submit2}
        form={FORM_ID}
        type="submit"
      >
        Submit
      </Button>
    </div>
  );
}

export default ModalContent;

const FORM_ID = "contact-us";
