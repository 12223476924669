const ABOUT_US_TEMPLATE = [
  {
    title: "We started Isprava in 2013",
    description:
      "and in just a few years, we have been fortunate to see tremendous success and have built an illustrious Isprava family. Over the last six years, Isprava has played a defining role in shaping what the uber-luxury traveller desires on a holiday. Whether it is offering truly world-class aesthetics and curated services or appreciating local discoveries. And while a few families have had the chance to own an Isprava home in Goa, Coonoor or Alibaug, there are hundreds and thousands of others who have been unable to experience the unique Isprava service.",
  },
  {
    title: "Lohono Stays by Isprava has been created",
    description:
      "to craft contemporary, luxury vacations for discerning travellers. Lohono is derived from the Hawaiian word “lono” which has associations of relaxation, music and peace. To us it means discovering the pleasures of an idyllic escape. We give a deeper meaning to luxury vacations through opulent homes and meaningful experiences.",
  },
  {
    title: "Most popular travel destinations",
    description:
      "in India and Asia. We curate and manage some of the most beautiful villas across Goa, Alibaug, Lonavala, Karjat, Mahabaleshwar, Mussoorie,  Coonoor, Phuket, Bali and Koh Samui. Some boast of abundant natural beauty like gurgling streams, indigenous flora or crashing waves. Others are close to cool watering holes or specialty restaurants by homegrown chefs.",
  },
  {
    title: "Come, Lohono with us",
    description:
      "because we believe in superior service which makes your holiday exceptional through its attention to detail. Our collection of impeccably-styled homes will transport you from Alibaug to The Algarve in a minute. Want to discover local gems? We have curated a local guide full of recommendations like family-owned fromageries and tucked-away art galleries.\nThe good life is waiting.",
  },
  {
    title: "Our version",
    description:
      "of the perfect holiday home ticks all the right boxes of being destination-specific and chic yet warm and welcoming. You’ll find a unique highlight in every individual home whether it’s a tempting gazebo by the pool or an age-old banyan tree with a charpoy laid underneath. And if you're in the mood to celebrate, our skilled concierge can offer exceptional gourmet and entertainment experiences for your loved ones.",
  },
];

export default ABOUT_US_TEMPLATE;
