import React, { ReactNode, useCallback, useState } from "react";
import useClx from "../../../hooks/use-clx";
import Collapse from "../../Collapse";
import clxs from "./read-more.module.css";
import CHEVRON_RIGHT_PRIMARY from "../../../assets/icons/chevron-right-primary.svg";

interface ReadMoreProps {
  className?: string;
  collapseClassName?: string;
  contentClassName?: string;
  readMoreIcon?: string;
  children?: ReactNode;
}

function ReadMore(props: ReadMoreProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false),
    handleToggle = useCallback(
      () => setCollapsed(collapsed => !collapsed),
      [setCollapsed],
    ),
    {
      className,
      collapseClassName,
      contentClassName,
      readMoreIcon = CHEVRON_RIGHT_PRIMARY,
      children,
    } = props,
    ccx = useClx(clxs.container, className),
    rmcx = useClx(clxs.readMore, "read-more");

  return (
    <div className={ccx}>
      <Collapse
        expanded={collapsed}
        outerClassName={collapseClassName}
        className={contentClassName}
      >
        {children}
      </Collapse>
      <div
        className={rmcx}
        onClick={handleToggle}
      >
        {collapsed ? "Read Less" : "Read more"}
        <img
          src={readMoreIcon}
          alt="view-all"
          className={clxs.readMoreIcon}
        />
      </div>
    </div>
  );
}

export default ReadMore;
