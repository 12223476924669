import React, { useCallback, useState } from "react";
import useAnalytics from "../../../hooks/use-analytics";
import usePhoneExts from "../../../hooks/use-phone-exts";
import { useSnackbar } from "../../../hooks/use-snackbar";
import useUserService from "../../../hooks/use-user-service";
import Form from "../../Form";
import clxs from "./create-account-otp.module.css";
import Otp from "../../Form/Fields/Otp";
import Button from "../../Button";
import Loader from "../../Loader";

interface CreateAccountOtpProps {
  phoneNo?: string;
  onSubmit: (value: any) => void;
}

function CreateAccountOtp (props: CreateAccountOtpProps) {
  const { track } = useAnalytics();

  const enqueueSnackbar = useSnackbar();

  const { service: userService } = useUserService();

  const phoneExts = usePhoneExts();

  const { onSubmit, phoneNo } = props;

  const [loader, setLoader] = useState(false);

  const handleGenerateOtp = useCallback(
    async (retry: boolean) => {
      if (!phoneNo) {
        return;
      }

      if (!phoneExts.length) {
        return;
      }

      const [phoneExt] = phoneNo?.split(" ");

      const pe = phoneExts.find(each => each.value === phoneExt);

      if (!pe) {
        enqueueSnackbar("Something went wrong", "error");

        return;
      }

      track("login_otp_generated", { retry: retry });

      setLoader(true);

      const { error } = await userService.generateOtp(pe.code, phoneNo);

      setLoader(false);
      if (!error) {
        return;
      }
      
      handleChange();
      enqueueSnackbar(error.message, "error");

      track("login_otp_generated_error", { error: error.message });

      return;
    },
    [
      enqueueSnackbar,
      userService,
      phoneExts,
      phoneNo,
    ],
  );

  const handleChange = () => {
    onSubmit("change");
  };

  return (
    <>
      <Form
        id={FORM_ID}
        defaultValue={DEFAULT_VALUE}
        className={clxs.formContainer}
        onSubmit={onSubmit}
      >
        <div className={clxs.title}>
          Enter OTP
        </div>
        <div className={clxs.subTitle}>
          {"Please enter the code we've sent via SMS to "}
          <br />
          {phoneNo}
          <span
            className={clxs.change}
            onClick={handleChange}
          >
            Change
          </span>
        </div>
        <Otp
          form={FORM_ID}
          name="otp"
          timeout={60}
          onGenerate={handleGenerateOtp}
        />
        <Button
          className={clxs.verifyButton}
          type="submit"
        >
          Verify Otp
        </Button>
        <Loader isLoading={loader}></Loader>
      </Form>
    </>
  );
}

export default CreateAccountOtp;

const FORM_ID = "create-account-otp";

const DEFAULT_VALUE = {
  otp: "",
};