import BEAUTIFUL_LOCATIONS from "../assets/experience-beautiful-locations.jpg";
import HOME_COMFORTS from "../assets/experience-home-comforts.jpg";
import INTUITIVE_SERVICE from "../assets/experience-intuitive-service.jpg";
import STRICT_HYGIENE from "../assets/experience-strict-hygiene.jpg";
import UNFORGETTABLE_EXPERIENCES from "../assets/experience-unforgettable-experiences.jpg";
import UNIQUE_HOMES from "../assets/experience-unique-homes.jpg";

const LOHONO_EXPERIENCE_TEMPLATE = [
  {
    title: "Unique homes",
    description: "The best homes in the world. From carefully restored heritage homes to stunning contemporary structures—all our luxury villas are thoughtfully handpicked. With dreamy private pools, verdant outdoor spaces and even picture-perfect gazebos, you can sit back and embrace the luxury of a private space.",
    href: "/villas/india/goa",
    thumbnail: UNIQUE_HOMES,
  },
  {
    title: "Beautiful locations",
    description: "A hilltop haven with breathtaking views or a beachside villa with world-class aesthetics—there’s an idyllic home, in exotic and popular destinations alike, for every discerning traveller. We can help you plan the perfect getaway for fun times with the family and some much-needed R & R.",
    href: "/villas/india/goa",
    thumbnail: BEAUTIFUL_LOCATIONS,
  },
  {
    title: "Strict hygiene standards",
    description: "Your safety and comfort, along with our staff’s well-being is paramount. Lohono homes comply with WHO guidelines and include measures such as temperature checks for staff and guests, regular disinfection of surfaces and provision of sanitizers in every space.",
    href: "/villas/india/goa",
    thumbnail: STRICT_HYGIENE,
  },
  {
    title: "Intuitive service",
    description: "Enjoy access to our stellar guest relations and concierge teams. From the turndown service to getting you a reservation at the trendiest restaurant, our ever-smiling staff works seamlessly to anticipate your every need and ensure you have an effortless and memorable stay.",
    href: "/villas/india/goa",
    thumbnail: INTUITIVE_SERVICE,
  },
  {
    title: "Unforgettable experiences",
    description: "Host an intimate celebration, savour a five-course gourmet meal or feel pampered with the best wellness therapies, all in the comfort of your Lohono villa. Enjoy a sunset bicycle ride with your loved ones or go on a culture trail through a local village—if it’s on your wish list, it’s on ours too!",
    href: "/villas/india/goa",
    thumbnail: UNFORGETTABLE_EXPERIENCES,
  },
  {
    title: "Home-like comforts",
    description: "Begin your day with a sumptuous complimentary breakfast spread, before you attend virtual meetings by the poolside or set up the kids’ online classes in the study. Take over the kitchen to cook up a storm and end the day curled up with your loved ones and furry companions.",
    href: "/villas/india/goa",
    thumbnail: HOME_COMFORTS,
  },
];

export default LOHONO_EXPERIENCE_TEMPLATE;
