import React, { MouseEvent, useMemo } from "react";
import useIsMobile from "../../hooks/use-is-mobile";
import { useQueryParamValue } from "../../hooks/use-query-param";
import { useScroll } from "../../hooks/use-scroll";
import Banner from "./Banner";
import useClx from "../../hooks/use-clx";
import Highlights from "./Highlights";
import Proposition from "./Proposition";
import Approach from "./Approach";
import Explore from "./Explore";
import useSendRequest from "../../hooks/use-send-request";
import useScrollToTop from "../../hooks/use-scroll-to-top";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./homeowner.module.css";

function Homeowner() {
  const isMobile = useIsMobile(),
    { scroll } = useScroll(),
    scrollVisible = useMemo(
      () => scroll > 100,
      [scroll],
    ),
    { showSendRequestModal } = useSendRequest(),
    isEmbedded = useQueryParamValue("boolean","embedded", false),
    bncx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.banner),
    pocx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.section),
    ctcx = useClx(fwclxs.container, clxs.section, clxs.ctaContainer),
    handlePartnerWithUs = async (e: MouseEvent<HTMLAnchorElement>) => {
      if (!isMobile) {
        return;
      }

      e.preventDefault();

      await showSendRequestModal({ interest: "home_acqui" });
    };

  useScrollToTop();

  return (
    <>
      <Banner className={bncx}/>
      <Highlights className={clxs.section}/>
      <Proposition className={pocx}/>
      <Approach className={clxs.section}/>
      {!isEmbedded && <Explore className={clxs.section} />}
      {scrollVisible && (
        <div className={ctcx}>
          <div className={clxs.ctaDescription}>
            Show your property to worldwide travellers
          </div>
          <a
            href="#contact"
            className={clxs.ctaAction}
            onClick={handlePartnerWithUs}
          >
            Come partner with us
          </a>
        </div>
      )}
    </>
  );
}

export default Homeowner;
