import POOLSIDE from "../../../assets/Picture-perfect-Web-Corporate-collage.jpg";
import TERRACE from "../../../assets/Picture-perfect-Web-In-villa-activities-04.jpg";
import BEACH from "../../../assets/Picture-perfect-Web-Weddings .jpg";
import GARDEN from "../../../assets/Picture-perfect-Web-In-villa-activities-03.jpg";
import LAKE from "../../../assets/Picture-perfect-Web-Outdoor-activity.jpg";
import INTERIOR from "../../../assets/Picture-perfect-Web-Intimate-celebrations.jpg";
import WEDDING from "../../../assets/Picture-perfect-Web-Wedding1.jpg";
import DINNING from "../../../assets/Picture-perfect-Web-Dining-Experiences.jpg";

const MOMENT_TEMPLATE : StaticDataCollection[] = [
  {
    id: 1,
    name: "Team bonding by the pool",
    description: "",
    active: "",
    slug: "",
    image: POOLSIDE,
    desktop_image: POOLSIDE,
    desktop_banner_image: POOLSIDE,
    mobile_banner_image: POOLSIDE,
  },
  {
    id: 2,
    name: "Exclusive match screenings",
    description: "",
    active: "",
    slug: "",
    image: TERRACE,
    desktop_image: TERRACE,
    desktop_banner_image: TERRACE,
    mobile_banner_image: TERRACE,
  },
  {
    id: 4,
    name: "Spa, wellness and more",
    description: "",
    active: "",
    slug: "",
    image: GARDEN,
    desktop_image: GARDEN,
    desktop_banner_image: GARDEN,
    mobile_banner_image: GARDEN,
  },
  {
    id: 5,
    name: "Rafting and other water sports",
    description: "",
    active: "",
    slug: "",
    image: LAKE,
    desktop_image: LAKE,
    desktop_banner_image: LAKE,
    mobile_banner_image: LAKE,
  },
  {
    id: 6,
    name: "Festive decor",
    description: "",
    active: "",
    slug: "",
    image: INTERIOR,
    desktop_image: INTERIOR,
    desktop_banner_image: INTERIOR,
    mobile_banner_image: INTERIOR,
  },
  {
    id: 7,
    name: "Celebrations",
    description: "",
    active: "",
    slug: "",
    image: WEDDING,
    desktop_image: WEDDING,
    desktop_banner_image: WEDDING,
    mobile_banner_image: WEDDING,
  },
  {
    id: 8,
    name: "Drinks by the barbecue",
    description: "",
    active: "",
    slug: "",
    image: DINNING,
    desktop_image: DINNING,
    desktop_banner_image: DINNING,
    mobile_banner_image: DINNING,
  },
  {
    id: 3,
    name: "Wedding festivities",
    description: "",
    active: "",
    slug: "",
    image: BEACH,
    desktop_image: BEACH,
    desktop_banner_image: BEACH,
    mobile_banner_image: BEACH,
  },
];

export default MOMENT_TEMPLATE;
