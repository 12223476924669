import React, { useMemo } from "react";
import clxs from "./membership-status.module.css";
import useClx from "../../../../hooks/use-clx";
import Img from "../../../Img";
import SILVER_DESKTOP from "../../../../assets/silver-member-desktop.svg";
import GOLDEN_DESKTOP from "../../../../assets/golden-member-desktop.svg";
import PLATINUM_DESKTOP from "../../../../assets/platinum-member-desktop.svg";
import SILVER_MOBILE from "../../../../assets/silver-member-mobile.svg";
import GOLDEN_MOBILE from "../../../../assets/golden-member-mobile.svg";



interface MembershipStatusProps {
  tier?: string;
  className?: string;
}

function MembershipStatus(props: MembershipStatusProps) {
  const { className, tier } = props;

  const ccx = useClx(clxs.container, className);

  const isHidden = useMemo(() => !tier , [tier]);

  const desktopImage = useMemo(()=>{

    if(tier == "silver"){
      return SILVER_DESKTOP;
    }

    if(tier == "gold"){
      return GOLDEN_DESKTOP;
    }

    if(tier == "platinum"){
      return PLATINUM_DESKTOP
    }

  },[tier]);

  const mobileImage = useMemo(()=>{

    if(tier == "silver"){
      return SILVER_MOBILE;
    }

    if(tier == "gold"){
      return GOLDEN_MOBILE;
    }

    if(tier == "platinum"){
      return PLATINUM_DESKTOP
    }

  },[tier]);

  if (isHidden) {
    return null;
  }

  return (
    <div 
      // data-type={tier} 
      className={ccx}
    >
        <Img
          src={mobileImage}
          mobileSrc={mobileImage}
          desktopSrc={desktopImage}
          alt="banner"
          className={clxs.tierImage}
        />
     {/* {tier?.toUpperCase()} MEMBER */}
    </div>
  );
}

export default MembershipStatus;
