import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { calculateTzAdjustment } from "../../../../../utils/date-tz";

function calculateStartOffset (
  monthStart: Date,
  monthEnd: Date,
  startDate: Date | null,
  endDate: Date | null,
) {
  if (!startDate) {
    return -1;
  }

  if (isAfter(startDate, monthEnd)) {
    return -1;
  }

  if (endDate && isBefore(endDate, monthStart)) {
    return -1;
  }

  if (isBefore(startDate, monthStart)) {
    if (!endDate) {
      return -1;
    }

    return 0;
  }

  return startDate.getDate() + calculateTzAdjustment(startDate);
}

function calculateEndOffset (
  monthStart: Date,
  monthEnd: Date,
  startDate: Date | null,
  endDate: Date | null,
) {
  if (!endDate) {
    return -1;
  }

  if (isBefore(endDate, monthStart)) {
    return -1;
  }

  if (startDate && isAfter(startDate, monthEnd)) {
    return -1;
  }

  if (isAfter(endDate, monthEnd)) {
    if (!startDate) {
      return -1;
    }

    return monthEnd.getDate() + calculateTzAdjustment(monthEnd);
  }

  return endDate.getDate() + calculateTzAdjustment(endDate);
}

function calculateMonthDayType (
  noOfDays: number,
  offset: number,
) {
  if (offset === 0) {
    return "start";
  }

  if (offset + 1 === noOfDays) {
    return "end";
  }

  return "none";
}

function calculateSelectionType (
  startOffset: number,
  endOffset: number,
  offset: number,
  isStartContinued: boolean,
  isEndContinued: boolean,
) {
  if (offset === endOffset && endOffset === 0) {
    return "end";
  }

  if (offset === startOffset && endOffset < 0) {
    return "start_only";
  }

  if (offset === endOffset && startOffset < 0) {
    return "end_only";
  }

  if (offset === startOffset) {
    if (isStartContinued) {
      return "true";
    }

    return "start";
  }

  if (offset === endOffset) {
    if (isEndContinued) {
      return "true";
    }

    return "end";
  }

  if (startOffset < 0 || endOffset < 0) {
    return "false";
  }

  const isBetween = offset > startOffset && offset < endOffset;

  return isBetween.toString();
}

export {
  calculateStartOffset,
  calculateEndOffset,
  calculateMonthDayType,
  calculateSelectionType,
};
