import { useEffect, useState } from "react";

function useIsClientMounted () {
  const [clientMounted, setClientMounted] = useState<boolean>(false);

  useEffect(
    () => setClientMounted(true),
    [setClientMounted],
  );

  return clientMounted;
}

export default useIsClientMounted;
