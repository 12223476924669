import enIN from "date-fns/locale/en-IN";
import React, { ComponentType, ReactElement, useCallback, useMemo } from "react";
import { BrowserRouterProps } from "react-router-dom";
import { StaticRouterProps } from "react-router-dom/server";
import { MutableSnapshot, RecoilRoot } from "recoil";
import mAtom from "../../../utils/m-atom";
import { ANALYTICS_SERVICE_KEY } from "../../hooks/use-analytics-service";
import useJssSsr from "../../hooks/use-jss-ssr";
import { OFFER_SERVICE_KEY } from "../../hooks/use-offer-service";
import { PAYMENT_SERVICE_KEY } from "../../hooks/use-payment-service";
import { PROPERTY_SERVICE_KEY } from "../../hooks/use-property-service";
import { RESERVATION_SERVICE_KEY } from "../../hooks/use-reservation-service";
import { SEND_REQUEST_SERVICE_KEY } from "../../hooks/use-send-request-service";
import { STATIC_SERVICE_KEY } from "../../hooks/use-static-content-service";
import { USER_SERVICE_KEY } from "../../hooks/use-user-service";
import { SEO_SERVICE_KEY } from "../../hooks/use-seo";

if (enIN?.options) {
  enIN.options.weekStartsOn = 0;
}

export interface StateProviderProps {
  children: ReactElement;
  Router: ComponentType<StaticRouterProps> | ComponentType<BrowserRouterProps>;
  routerProps: any;
  initialState?: { key: string; default: any; initial: any; }[];
  staticContentService: IStaticContentService,
  propertyService: IPropertyService;
  reservationService: IReservationService;
  sendRequestService: ISendRequestService;
  paymentService: IPaymentService;
  userService: IUserService;
  analyticsService: IAnalyticsService;
  offerService: IOfferService;
  seoService: ISeoService;
}

function StateProvider (props: StateProviderProps) {
  const {
      children,
      Router,
      routerProps,
      initialState: is = [],
      staticContentService,
      propertyService,
      reservationService,
      sendRequestService,
      paymentService,
      userService,
      analyticsService,
      offerService,
      seoService,
    } = props,
    initialState = useMemo(
      () => {
        return [
          ...is.map(
            (each: any) => ({
              key: each.key,
              initial: each.initial,
              default: each.default || each.initial,
            }),
          ),
          {
            key: STATIC_SERVICE_KEY,
            initial: staticContentService,
            default: staticContentService,
          },
          {
            key: PROPERTY_SERVICE_KEY,
            initial: propertyService,
            default: propertyService,
          },
          {
            key: RESERVATION_SERVICE_KEY,
            initial: reservationService,
            default: reservationService,
          },
          {
            key: SEND_REQUEST_SERVICE_KEY,
            initial: sendRequestService,
            default: sendRequestService,
          },
          {
            key: PAYMENT_SERVICE_KEY,
            initial: paymentService,
            default: paymentService,
          },
          {
            key: USER_SERVICE_KEY,
            initial: userService,
            default: userService,
          },
          {
            key: ANALYTICS_SERVICE_KEY,
            initial: analyticsService,
            default: analyticsService,
          },
          {
            key: OFFER_SERVICE_KEY,
            initial: offerService,
            default: offerService,
          },
          {
            key: SEO_SERVICE_KEY,
            initial: seoService,
            default: seoService,
          },
        ];
      },
      [
        is,
        staticContentService,
        propertyService,
        reservationService,
        sendRequestService,
        userService,
      ],
    ),
    initializeState = useCallback(
      (options: MutableSnapshot) => {
        if (!initialState) {
          return;
        }
        initialState.forEach((config) => {
          const { initial } = config,
            atom = mAtom(config);
          options.set(atom, initial);
        });
      },
      [initialState],
    );

  useJssSsr();

  return (
    <RecoilRoot initializeState={initializeState}>
      <Router {...(routerProps as any)}>
        {children}
      </Router>
    </RecoilRoot>
  );
}

export default StateProvider;
