import { useAtomState } from "./use-atom";
import { useCallback, useMemo } from "react";
import Cookies from "js-cookie";

function useStorageString (
  storage: Storage,
  key: string,
  init?: () => string,
) {
  const store = useMemo(
      () => {
        if (typeof window === "undefined") {
          return;
        }

        if (storage === "localStorage") {
          return window.localStorage;
        }

        if (storage === "sessionStorage") {
          return window.sessionStorage;
        }

        if (storage === "cookie") {
          return cookieStorage;
        }

        return null;
      },
      [storage],
    ),
    atomKey = useMemo(
      () => `${storage}_${key}`.toUpperCase(),
      [storage, key],
    ),
    [value, setValue] = useAtomState<string>(
      atomKey,
      () => {
        if (!store) {
          return "";
        }

        let value = store.getItem(key);

        if (!value && init) {
          value = init();

          if (value) {
            store.setItem(key, value);
          }
        }

        if (!value) {
          value = "";
        }

        return value;
      },
    ),
    handleSetValue = useCallback(
      (value: string) => {
        setValue(value);

        if (!store) {
          return;
        }

        store.setItem(key, value);
      },
      [store],
    );


  return {
    value: value,
    setValue: handleSetValue,
  };
}

function useStorageNumber (storage: Storage, key: string) {
  const { value: v, setValue } = useStorageString(storage, key),
    value = useMemo(() => Number.parseInt(v), [v]),
    handleSetValue = useCallback(
      (value: number) => {
        setValue(value.toString());
      },
      [setValue],
    );

  return {
    value: value,
    setValue: handleSetValue,
  };
}

function useStorageDate (storage: Storage, key: string) {
  const { value: v, setValue } = useStorageString(storage, key),
    value = useMemo(
      () => v ? new Date(v) : null,
      [v],
    ),
    handleSetValue = useCallback(
      (value: Date | null) => {
        if ((value?.toISOString() || "") === v) {
          return;
        }
        setValue(value?.toISOString() || "");
      },
      [setValue],
    );

  return {
    value: value,
    setValue: handleSetValue,
  };
}

function useStorageBoolean (storage: Storage, key: string) {
  const { value: v, setValue } = useStorageString(storage, key),
    value = useMemo(
      () => v ? v === "true" : false,
      [v],
    ),
    handleSetValue = useCallback(
      (value: boolean) => {
        setValue(value.toString());
      },
      [setValue],
    );

  return {
    value: value,
    setValue: handleSetValue,
  };
}

export {
  useStorageString,
  useStorageNumber,
  useStorageDate,
  useStorageBoolean,
};

const cookieStorage = {
  getItem: (key: string) => {
    return Cookies.get(key);
  },
  setItem: (key: string, value: string) => {
    return Cookies.set(key, value);
  },
};

type Storage = "localStorage" | "sessionStorage" | "cookie"
