import { useCallback, useState } from "react";

function useWithLoading (defaultLoading: boolean = false) {
  const [loading, setLoading] = useState<boolean>(defaultLoading);

  const withLoading = useCallback(
    async <A extends any[], T> (
      cb: (...args: A) => Promise<T>,
      ...args: A
    ) => {
      setLoading(true);

      const result = await cb(...args);

      setLoading(false);

      return result;
    },
    [setLoading],
  );

  return {
    loading: loading,
    withLoading: withLoading,
  };
}

export default useWithLoading;
