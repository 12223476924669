import { useCallback } from "react";
import { useAtomState } from "../use-atom";

function useSnackbar () {
  const { enqueueSnackbar } = useSnackbarState();

  return enqueueSnackbar;
}

export {
  useSnackbarState,
  useSnackbar,
};

function useSnackbarState () {
  const [state, setState] = useAtomState<SnackbarState>(
    SNACKBAR_STATE,
    DEFAULT_SNACKBAR_STATE,
  );

  const handleDismiss = useCallback(
    () => setState(
      prevState => {
        const { timeoutHandle } = prevState;

        if (timeoutHandle) {
          window.clearTimeout(timeoutHandle);
        }

        return DEFAULT_SNACKBAR_STATE;
      },
    ),
    [setState],
  );

  const enqueueSnackbar = useCallback(
    (
      message: string,
      severity: AlertSeverity,
    ) => {
      const dismissHandle = window.setTimeout(
        handleDismiss,
        SNACKBAR_TIMEOUT,
        DEFAULT_SNACKBAR_STATE,
      );

      setState({
        message: message,
        severity: severity,
        open: true,
        timeoutHandle: dismissHandle,
      });
    },
    [setState, handleDismiss],
  );

  return {
    state: state,
    enqueueSnackbar: enqueueSnackbar,
    handleDismiss: handleDismiss,
  };
}

const SNACKBAR_TIMEOUT = 10_000;

const DEFAULT_SNACKBAR_STATE: SnackbarState = {
  open: false,
  severity: "info",
  message: "",
  timeoutHandle: 0,
};

const SNACKBAR_STATE = "SNACKBAR_STATE";

type SnackbarState = {
  open: boolean;
  severity: AlertSeverity;
  message: string;
  timeoutHandle: number;
};


