function prepareMeta(meta: any) {
  const {
      source,
      medium,
      campaign,
      content,
      ad_group,
      term,
      ...rest
    } = meta,
    payload = {
      ...rest,
      utm_source: source,
      utm_medium: medium,
      utm_campaign: campaign,
      utm_content: content,
      utm_adgroup: ad_group,
      utm_term: term,
    };

  return payload;
}

export default prepareMeta;
