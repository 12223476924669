import { QueryType, QueryValue } from "./query-param";

function toQueryValue<T extends QueryType> (
  queryType: T,
  queryKey: string,
  values: string[],
  defaultValue?: QueryValue<T>,
): QueryValue<T> {
  if (queryType === "string") {
    if (values.length) {
      return values[0] as QueryValue<T>;
    }

    return defaultValue || "" as QueryValue<T>;
  }

  if (queryType === "string_array") {
    if (values.length) {
      return values as QueryValue<T>;
    }

    return defaultValue || [] as unknown as QueryValue<T>;
  }

  if (queryType === "integer") {
    if (values.length) {
      return Number.parseInt(values[0]) as QueryValue<T>;
    }

    return defaultValue || 0 as QueryValue<T>;
  }

  if (queryType === "integer_array") {
    if (values.length) {
      return values.map(v => Number.parseInt(v)) as QueryValue<T>;
    }

    return [] as unknown as QueryValue<T>;
  }

  if (queryType === "float") {
    if (values.length) {
      return Number.parseFloat(values[0]) as QueryValue<T>;
    }

    return defaultValue || 0.0 as QueryValue<T>;
  }

  if (queryType === "float_array") {
    if (values.length) {
      return values.map(v => Number.parseFloat(v)) as QueryValue<T>;
    }

    return defaultValue || [] as unknown as QueryValue<T>;
  }

  if (queryType === "date") {
    if (values.length) {
      return new Date(values[0]) as QueryValue<T>;
    }

    return defaultValue || null as QueryValue<T>;
  }

  if (queryType === "boolean") {
    if (values.length) {
      return (values[0] === "true") as QueryValue<T>;
    }

    return defaultValue || false as QueryValue<T>;
  }


  throw new Error("unimplemented type");
}

function fromQueryValue<T extends QueryType> (
  queryType: T,
  value: QueryValue<T>,
): string[] {
  if (queryType === "string") {
    return [value as string];
  }

  if (queryType === "string_array") {
    return value as string[];
  }

  if (queryType === "integer") {
    return [(value as number).toString()];
  }

  if (queryType === "integer_array") {
    return (value as number[]).map(
      each => each.toString(),
    );
  }

  if (queryType === "float") {
    return [(value as number).toString()];
  }

  if (queryType === "float_array") {
    return (value as number[]).map(
      each => each.toString(),
    );
  }

  if (queryType === "date") {
    return [(value as Date).toISOString()];
  }

  if (queryType === "boolean") {
    return [(value as boolean).toString()];
  }

  throw new Error("unimplemented type");
}

export {
  toQueryValue,
  fromQueryValue,
};
