import cached from "../../utils/cached";
import Fetch from "../../utils/fetch";

class SeoService implements ISeoService {
  private readonly fetch: Fetch<ApiResult>;

  constructor(fetch: Fetch<ApiResult>) {
    this.fetch = fetch;
  }

  getUtmMobileNumber(utmCampaign: string): Promise<FetchResult<UtmCampaignMobile>> {
    const url = new URL("/api/utmMobileNumber", window.location.origin);
    if(utmCampaign) {
      url.searchParams.set("utm_campaign", utmCampaign);
    }
    return this.fetch.GET(url.toString());
  }

  @cached()
  getSeo(type: SeoType, slug: string): Promise<FetchResult<Seo>> {
    const url = new URL(`/api/seo/${type}/${slug}`, window.location.origin);
    return this.fetch.GET(url.toString());
  }
}

export default SeoService;
