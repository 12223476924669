import React, {HTMLAttributes, useRef} from "react";
import {useCarouselObserver} from "../../../hooks/use-carousel";

export interface ItemProps extends Omit<HTMLAttributes<HTMLDivElement>, "ref"> {
  carouselId?: string;
}

function Item(props: ItemProps) {
  const ref = useRef<HTMLDivElement | null>(null),
    {carouselId = "", children, ...rest} = props;

  useCarouselObserver(carouselId, ref);

  return (
    <div
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  );
}

export default Item;
