import React, { FormHTMLAttributes, ForwardedRef, forwardRef, useCallback } from "react";
import {FormSubmitHandler, useFormState} from "../../hooks/use-form";
import {FormikValues} from "formik";
import {FormikHelpers} from "formik/dist/types";

export interface FormProps extends Omit<FormHTMLAttributes<HTMLFormElement>, "id" | "defaultValue" | "onSubmit"> {
  id: string;
  defaultValue: FormikValues,
  onSubmit?: FormSubmitHandler,
  validationSchema?: any | (() => any),
  enableReinitialize?: boolean,
  onChange?: (values: FormikValues) => void,
  isClearOnSubmit?: boolean,
  resetForm?: () => void,
}

const Form = forwardRef(
  function Form(
    props: FormProps,
    ref: ForwardedRef<HTMLFormElement>,
  ) {
    const {
        id,
        defaultValue,
        onSubmit,
        validationSchema,
        enableReinitialize,
        children,
        onChange,
        isClearOnSubmit,
        ...rest
      } = props,
      _handleSubmit = useCallback(
        async (
          values: FormikValues,
          formikHelpers: FormikHelpers<FormikValues>,
        ) => {
          formikHelpers.setSubmitting(true);

          if (!onSubmit) {
            return;
          }

          const formattedValues = validationSchema
            ? validationSchema.cast(values)
            : values;

          const result = await onSubmit(formattedValues, formikHelpers);

          formikHelpers.setSubmitting(true);

          isClearOnSubmit && formikHelpers.resetForm(defaultValue);

          return result;
        },
        [onSubmit],
      ),
      { handleSubmit } = useFormState(
        id,
        defaultValue,
        _handleSubmit,
        validationSchema,
        enableReinitialize,
        onChange,
      );

    return (
      <form
        {...rest}
        ref={ref}
        id={id}
        onSubmit={handleSubmit}
      >
        {children}
      </form>
    );
  },
);

export default Form;
