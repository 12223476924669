import React, { MouseEventHandler, useRef, useState } from "react";
import useClx from "../../../hooks/use-clx";
import Button from "../../Button";
import Img from "../../Img";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./banner.module.css";
import Carousel from "../../Carousel";
import Item from "../../Carousel/Item";
import ScrollCrumb from "../../Home/PropertyCarousel/ScrollCrumb";
import { DEFAULT_BANNERS } from "../../../core/services/event-banner";
import useCarouselAutoscroll from "../../../hooks/use-carousel-autoscroll";
import useDomObserver from "../../../hooks/use-dom-observer";

interface BannerProps {
  className?: string;
  onContactUs?: MouseEventHandler;
}

function Banner(props: BannerProps) {
  const { className, onContactUs} = props,
    ref = useRef<HTMLDivElement | null>(null),
    [domVisible, setDomVisible] = useState<boolean>(false), 
    ccx = useClx(fwclxs.container, clxs.container, className),
    cscx = useClx(clxs.carousel, "carousel"),
    handleDOMVisibleChange = (entry: IntersectionObserverEntry) => {
      const { intersectionRatio } = entry;
  
      if (intersectionRatio < 0.3) {
        setDomVisible(false);
  
        return;
      }
  
      if (intersectionRatio > 0.7) {
        setDomVisible(true);
  
        return;
      }
    };

    useCarouselAutoscroll(CAROUSEL_ID, 5000, domVisible);

    useDomObserver(ref, handleDOMVisibleChange, DOM_OBSERVER_THRESHOLD);
  
  return (
    <div className={ccx}>
      <div className={clxs.content}>
      <div className={clxs.actions}>
        <a
          className={clxs.viewMore}
          href="#intro"
        >
          View More
        </a>
        <Button
          className={clxs.contactUs}
          onClick={onContactUs}
        >
          Contact Us
        </Button>
      </div>
      {/* <div className={clxs.subtitle}>
        Lohono Stays X JADE at Villa Magnolia, Alibaug
      </div> */}
      </div>

      <Carousel
        id={CAROUSEL_ID}
        className={cscx}
        threshold={0.98}
        forwardRef={ref}
      >
        {[...DEFAULT_BANNERS.map(
            (each, index) => {
              const 
                title = each.title, 
                subtitle = each.subtitle,
                desktop_image_url = each.desktop_image_url, 
                mobile_image_url = each.mobile_image_url;

            return (
              <Item 
                key={index} 
                className={clxs.item}
                title={title}
              >
                  <>
                  <div className={clxs.subtitletitle}>
                    <h1 className={clxs.title}>
                      {title}
                    </h1>
                    <h3 className={clxs.subtitle}>
                      {subtitle}
                    </h3>
                  </div>
                    <Img
                      src={mobile_image_url}
                      mobileSrc={mobile_image_url}
                      desktopSrc={desktop_image_url}
                      className={clxs.img}
                      loading="lazy"
                    />
                  </>
              </Item>
            );
          }),
        ]}
      </Carousel>
      <ScrollCrumb
        carouselId={CAROUSEL_ID}
        count={DEFAULT_BANNERS.length}
        className={clxs.scrollCrumb}
      />
    </div>
  );
}

export default Banner;

const CAROUSEL_ID = "event-banners";

const DOM_OBSERVER_THRESHOLD = [0.3, 0.7];

