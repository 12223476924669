import * as Yup from "yup";

const PROPERTY_SEARCH_SCHEMA = Yup.object({
  destination: Yup.string()
    .trim()
    .required("Required"),
  stay: Yup.object()
      .shape({
        start: Yup.date().nullable(true),
        end: Yup.date().nullable(true),
      }),
  occupancy: Yup.object({
    adult_count: Yup.number()
      .integer("should be an integer")
      .moreThan(0, "should be greater than 0"),
    child_count: Yup.number()
      .integer("should be an integer")
      .moreThan(-1, "should be greater than -1"),
  }),
});

export default PROPERTY_SEARCH_SCHEMA;
