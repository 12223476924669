import React, { HTMLAttributes, MouseEvent, ReactNode, useCallback } from "react";
import useClx from "../../hooks/use-clx";
import useDisableScroll from "../../hooks/use-disable-scroll";
import clxs from "./drawer.module.css";

interface DrawerProps extends BaseDivProps {
  open: boolean;
  children?: ReactNode;
  backdropClassName?: string;
  onClose: () => void;
}

function Drawer (props: DrawerProps) {
  const {
    open,
    backdropClassName,
    children,
    className,
    onClose,
    ...rest
  } = props;

  const handleDismiss = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const { dismiss } = (e.target as HTMLDivElement).dataset;

      const dismissibleTarget = dismiss === "true";

      if (e.target !== e.currentTarget && !dismissibleTarget) {
        return;
      }

      onClose();
    },
    [onClose],
  );

  useDisableScroll(open);

  const bcx = useClx(clxs.backdrop, backdropClassName);

  const ccx = useClx(clxs.sidebar, className);

  return (
    <>
      <div
        className={bcx}
        data-open={open}
        onClick={handleDismiss}
      >
        <div
          {...rest}
          className={ccx}
        >
          {children}
        </div>
      </div>
    </>
  );
}

export default Drawer;

type BaseDivProps = Omit<HTMLAttributes<HTMLDivElement>, "onClick">;