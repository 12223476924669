import sanitizePayload from "../components/AnalyticsManager/sanitize-payload";
import useAnalyticsService from "./use-analytics-service";
import useHost from "./use-host";
import useIpGeoLocation from "./use-ip-geo-location";
import { useUser } from "./use-session";
import useUtm from "./use-utm";
import { usePageValue } from "./use-page";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

function useAnalytics() {
  const { service } = useAnalyticsService(),
    host = useHost(),
    ipGeoLocation = useIpGeoLocation(),
    { utm } = useUtm(),
    user = useUser(),
    { page: _page } = usePageValue(),
    utmSearch = useMemo(() => {
      const { source, medium, campaign, content, ad_group, term } = utm;

      return {
        utm_source: source,
        utm_medium: medium,
        utm_campaign: campaign,
        utm_content: content,
        utm_adgroup: ad_group,
        utm_term: term,
      };
    }, [utm]),
    { pathname, search, hash } = useLocation(),
    params = useParams(),
    handleTrack = (
      event: string,
      payload: any = {},
      skipKeys: string[] = [],
      page?: string,
    ) => {
      const safePage = page ?? _page ?? "";

      if ((!safePage || safePage === "ANY") && (event != "checkout_payment_failed")) {
        return;
      }

      //Check if the user profile has been updated if yes fetch the updated profile and sedn fro analytics.
      const isUserUpdated = payload && payload?.isUserUpdated;
      const updatedUserObj = isUserUpdated ? payload?.userObj : {};

      const qp = new URLSearchParams(search),
        // @ts-ignore
        keys = [...new Set<string>(qp.keys())];

      const s = keys.reduce((compiled, key) => {
        const value = qp.getAll(key);

        compiled[key] = value.length > 1 ? value : value[0];

        return compiled;
      }, {} as any);

      // const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const todaysDate = new Date();
      const timestamp = todaysDate.toISOString();
      const clientTimezone = todaysDate
        ? todaysDate
            .toString()
            .match(/\((.*)\)/)
            ?.pop()
        : "";

      // Set the logged_in flag to true
      // const sessionId = payload?.session_id;
      const sessionId = localStorage.getItem(SESSION_KEY);
      const isLoggedIn = sessionId && sessionId?.length > 1;
      const destinationCountry = params?.destination;
      const property_type = destinationCountry != "india" ? "International" : "Domestic";
      const isOfferApplied = s?.coupon_code?.length > 0 ? true : false;

      const sanitizedPayload = sanitizePayload(
        {
          ...s,
          ...params,
          ...utmSearch,
          ...payload,
          ...user,
          ...updatedUserObj,
          query: s,
          pathname: pathname,
          hash: hash,
          page: safePage,
          event: event,
          host: host,
          ipGeoLocation: ipGeoLocation,
          timestamp: timestamp,
          timezone: clientTimezone,
          logged_in: isLoggedIn,
          location_type: property_type,
          offerApplied: isOfferApplied,
        },
        skipKeys,
      );

      service.track(sanitizedPayload);
    };

  return {
    track: handleTrack,
  };
}

const SESSION_KEY = "SESSION";

export default useAnalytics;
