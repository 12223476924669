import React, { useEffect, useMemo, useState } from "react";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import useAnalytics from "../../hooks/use-analytics";
import usePathParams from "../../hooks/use-path-params";
import usePropertyListing from "../../hooks/use-property-listing";
import usePropertyLocations from "../../hooks/use-property-locations";
import { useQueryParam, useQueryParamValue } from "../../hooks/use-query-param";
import { useScroll } from "../../hooks/use-scroll";
import { Track } from "../AnalyticsManager";
import PropertyItem from "../PropertyItem";
import SecurePayment from "../SecurePayment";
import SendRequest from "../SendRequestNeedHelp";
import DiscountAlert from "../DiscountAlert";
import LoyaltyPointTicker from "../DiscountAlert/LoyaltyPointTicker";
import NearbyLocations from "./NearbyLocations";
import Outro from "./Outro";
import PropertySearch from "./PropertySearch";
import Sort from "./Sort/Desktop";
import PropertyMultiConfigItem from "../PropertyMultiConfigItem";
import clxs from "./property-listing.module.css";
import ReferralPoints from "../ReferralPoints";
import { useUser } from "../../hooks/use-session";
import useLoyaltyPointsBalance from "../../hooks/use-loyalty-points-balance";
import NotFound from "../NotFound";
// import LohonoExperience from "./LohonoExperience";

function PropertyListing() {
  const { track } = useAnalytics(),
    { propertyLocations } = usePropertyLocations(),
    location = usePathParams(PATH, "location"),
    destination = usePathParams(PATH, "destination"),
    checkin_date = useQueryParamValue("date", "checkin_date"),
    checkout_date = useQueryParamValue("date", "checkout_date"),
    adult_count = useQueryParamValue("integer", "adult_count"),
    child_count = useQueryParamValue("integer", "child_count"),
    [sort, setSort] = useQueryParam("string", "sort"),
    search = useQueryParamValue("string", "search"),
    user = useUser(),
    { points } = useLoyaltyPointsBalance(),
    location_slug = useMemo(
      () => `${destination}-${location}`,
      [destination, location],
    ),
    {
      properties,
      propertyCount,
      getNextPage,
      discount,
    } = usePropertyListing(
      location_slug,
      undefined,
      checkin_date?.toISOString(),
      checkout_date?.toISOString(),
      adult_count,
      child_count,
      search,
      sort,
    ),
    isDomestic = destination === "india",
    { ratio } = useScroll(),
    [lengthId, setLengthId] = useState<number>(0),
    destinationFriendly = useMemo(
      () => {
        const friendly = propertyLocations
          .find(each => each.slug === location_slug)?.name || "";

        return friendly;
      },
      [propertyLocations, location_slug],
    ),
    // title = useMemo(
    //   () => `Luxury villas in ${destinationFriendly}`,
    //   [destinationFriendly],
    // ),
    subtitle = useMemo(
      () => `Found ${propertyCount} luxury villas in ${destinationFriendly}`,
      [destinationFriendly, propertyCount],
    ),
    handleRatioChange = (ratio: number) => {
      if (ratio < 0.6) {
        return;
      }

      if (lengthId === properties.length) {
        return;
      }

      setLengthId(properties.length);
    },
    handleSortChange = (nextDirection: string) => {
      track(
        "property_listing_sort_clicked",
        { sort: nextDirection },
      );

      return setSort(nextDirection);
    };

  useEffect(
    () => handleRatioChange(ratio),
    [ratio],
  );

  useEffect(
    () => {
      if (!lengthId) {
        return;
      }

      getNextPage();
    },
    [lengthId],
  );

  // Check if the location_slug is valid, if not return NotFound
  const locationExists = propertyLocations.some(each => each.slug === location_slug);

  if (!locationExists) {
    return <NotFound/>; // Return NotFound if location is not listed
  }

  return (
    <>
      <div className={clxs.container}>
        <PropertySearch
          path={PATH}
          className={clxs.search}
          filterMobileClassName={clxs.search}
          filterDesktopClassName={clxs.search}
          propertyCount={propertyCount}
          sort={sort}
          onSortChange={handleSortChange}
        />
        {(isDomestic && user) && (
          <div className={clxs.spacer}>
            <ReferralPoints 
              isKnowMore={true} 
              points={points} 
              user={user}
            />
          </div>
        )}
        {/* {isDomestic && <LohonoExperience className={clxs.lohonoExperience}/>}  */}
        <div className={clxs.titleContainer}>
          <div className={clxs.title}>{subtitle}</div>
          {/* <div className={clxs.title}>{title}</div> */}
          <Sort
            className={clxs.sortDesktop}
            value={sort}
            onChange={handleSortChange}
          />
          <DiscountAlert
            message={discount.discount_message}
            method={discount.discount_method}
            className={clxs.discount}
            hideForCoupon={false}
          >
            <LoyaltyPointTicker
              className={clxs.llpTicker}
              method={discount.discount_method}
            />
          </DiscountAlert>
        </div>
        <div className={clxs.sendRequestContainer}>
          <SendRequest
            className={clxs.sendRequest}
            interest="rental"
          />
          <SecurePayment className={clxs.securePayment} />
        </div>
        <div className={clxs.properties}>
          {properties.map(
            (each, i) => {
              const decodedLocation = decodeLocationSlug(each.location_slug),
                { location, destination } = decodedLocation,
                propertySlug = each.property_slug,
                href = `/villas/${destination}/${location}/${propertySlug}`,
                payload: any = {
                  ...each,
                  ...decodedLocation,
                  href: href,
                  index: i,
                };

              return (
                <Track
                  key={i}
                  event="property_listing_property_clicked"
                  payload={payload}
                >
                  {each.view_layout === "multi_config" ? (
                    <PropertyMultiConfigItem property={each} />
                  ) : (
                    <PropertyItem property={each} />
                  )}
                </Track>
              );
            },
          )}
          <NearbyLocations
            locationSlug={location_slug}
            checkinDate={checkin_date}
            checkoutDate={checkout_date}
            adultCount={adult_count}
            childCount={child_count}
            className={clxs.nearbyLocations}
            title="Nearby Locations"
          />
          <Outro path={PATH} />
        </div>
      </div>
    </>
  );
}

export default PropertyListing;

const PATH = "/villas/:destination/:location";