import React, { useCallback } from "react";
import useClx from "../../../hooks/use-clx";
import useToggle from "../../../hooks/use-toggle";
import Drawer from "../../Drawer";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./header.module.css";
import CLOSE_ICON from "../../../assets/lohono-black/icons/close.svg";
import MENU_ICON from "../../../assets/lohono-black/icons/menu.svg";
import LOHONO_BLACK_LOGO from "../../../assets/lohono-black/logo/lohono-black-transparent.svg";

interface HeaderProps {
  className?: string;
}

function Header (props: HeaderProps) {
  const { toggle, setToggle } = useToggle(DRAWER_ID),
    { className } = props,
    ccx = useClx(
      fwclxs.container,
      fwclxs.containerPadding,
      clxs.container,
      className,
    ),
    handleToggle = useCallback(
      () => setToggle(toggle => !toggle),
      [setToggle],
    );

  return (
    <>
      <nav className={ccx}>
        <img
          src={MENU_ICON}
          alt="back"
          className={clxs.menu}
          onClick={handleToggle}
        />
        <a
          href="/lohono-black"
          className={clxs.logoContainer}
          title="Lohono Stays"
        >
          <img
            className={clxs.logo}
            src={LOHONO_BLACK_LOGO}
            alt="Lohono"
          />
        </a>
        <a
          href="/"
          title="Home"
          target="_self"
          className={clxs.header}
        >
          Home
        </a>
        <a
          href="/lohono-black"
          title="Lohono Black"
          target="_self"
          className={clxs.header}
        >
          Lohono Black
        </a>
        <a
          href="/contact-us"
          title="About Us"
          target="_self"
          className={clxs.header}
        >
          Contact Us
        </a>
      </nav>
      <Drawer
        open={toggle}
        onClose={handleToggle}
        className={clxs.drawer}
      >
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          data-dismiss={true}
        />
        <a
          href="/"
          title="Home"
          target="_self"
          className={clxs.drawerLink}
          data-dismiss={true}
        >
          Home
        </a>
        <a
          href="/lohono-black"
          title="Lohono Black"
          target="_self"
          className={clxs.drawerLink}
          data-dismiss={true}
        >
          Lohono Black
        </a>
        <a
          href="/contact-us"
          title="About Us"
          target="_self"
          className={clxs.drawerLink}
          data-dismiss={true}
        >
          Contact Us
        </a>
      </Drawer>
    </>
  );
}

export default Header;

const DRAWER_ID = "lohono-black-drawer";
