import { useEffect } from "react";
import { useAtomState } from "./use-atom";
import { PROPERTY_LOCATION_KEY } from "../../core/constants";
import usePropertyService from "./use-property-service";
// import usePrefetch from "./use-prefetch";

function usePropertyLocations (
  collection_slug?: string,
) {
  const { service } = usePropertyService(),
    // { hasPrefetchKey } = usePrefetch(),
    [
      propertyLocations,
      setPropertyLocations,
    ] = useAtomState<PropertyLocation[]>(PROPERTY_LOCATION_KEY, []),
    handleGetPropertyLocations = async (collection_slug?: string) => {
      // if (hasPrefetchKey(PROPERTY_LOCATION_KEY)) {
      //   return;
      // }

      const { error, response } = await service.getPropertyLocations(collection_slug);

      if (error || !response) {
        return;
      }

      setPropertyLocations(response);
    };

  useEffect(
    () => {
      handleGetPropertyLocations(collection_slug);
    },
    [collection_slug],
  );

  return { propertyLocations: propertyLocations };
}

export default usePropertyLocations;
