import React, { useMemo } from "react";
import NativePropertyConfigSelect, {
  PropertyConfigSelectProps as NativePropertyConfigSelectProps,
} from "../../../PropertyConfig/Select";
import { useField } from "../../../../hooks/use-form";

interface PropertyConfigSelectProps extends BasePropertyConfigSelectProps {
  form: string;
  name: string;
}

function PropertyConfigSelect(props: PropertyConfigSelectProps) {
  const {
      form,
      ...rest
    } = props,
    { name } = rest,
    {
      value,
      error: err,
      handleBlur,
      handleChange,
    } = useField(form, name),
    error = useMemo(
      () => {
        if (!err) {
          return "";
        }

        if (typeof err === "string") {
          return err;
        }

        const errs = Object.values(err).join(", ");

        return errs;
      },
      [err],
    );

  return (
    <NativePropertyConfigSelect
      {...rest}
      value={value}
      error={error as string}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}

export default PropertyConfigSelect;

type BasePropertyConfigSelectProps = Omit<NativePropertyConfigSelectProps, "value" | "onChange" | "onBlur" | "error">;
