import React, { useMemo } from "react";
import DateRangeInput, { DateRangeInputProps } from "../DateRangeInput";
import usePropertyCalendar from "./use-property-calendar";
import clxs from "./async-date-range-input.module.css";

export interface AsyncDateRangeInputProps extends DateRangeInputProps {
  propertySlug: string;
}

function AsyncDateRangeInput (props: AsyncDateRangeInputProps) {
  const {
    propertySlug,
    calendarDialogProps: _cdp = {},
    ...dateRangeInputProps
  } = props;

  const {
    handleMonthChange,
    handleDateDecorator,
    handleVerifySelection,
  } = usePropertyCalendar(propertySlug, _cdp.monthCount);

  const cdp = useMemo(
    () => {
      return {
        ..._cdp,
        onDateDecorate: handleDateDecorator,
        onMonthChange: handleMonthChange,
        className: clxs.calendarMonth,
      };
    },
    [
      _cdp,
      handleMonthChange,
      handleDateDecorator,
      clxs.calendarMonth,
    ],
  );

  return (
    <DateRangeInput
      {...dateRangeInputProps}
      onVerifySelection={handleVerifySelection}
      calendarDialogProps={cdp}
    />
  );
}

export default AsyncDateRangeInput;
