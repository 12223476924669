import format from "date-fns/format";
import React, { createElement, MouseEventHandler, ReactNode, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import useClx from "../../hooks/use-clx";
import NetworkImg from "../NetworkImg";
import LoyaltyPointShortTicker from "../LoyaltyPointShortTicker";
import clxs from "./property-item.module.css";
import CHEVRON_RIGHT_PRIMARY1 from "../../assets/icons/chevron-right-primary.svg";
import PET_FRIENDLY from "../../assets/icons/pet-friendly.svg";
import WELLNESS_ICON from "../../assets/icons/wellness.svg";
// import ISPRAVA_LOGO from "../../assets/logo/isprava-built.png";
import CHEVRON_RIGHT_PRIMARY2 from "../../assets/lohono-black/icons/chevron-right-primary.svg";
import LOHONO_BLACK_LOGO from "../../assets/lohono-black/logo/lohono-black.svg";
import PARTNER_HOME_ICON from "../../assets/partner-home-handshake.svg";
import RatingCounts from "../PropertyDetail/RatingCounts";
import useIsMobile from "../../hooks/use-is-mobile";

interface PropertyItemProps {
  property: PropertyItem;
  className?: string;
  routable?: boolean;
  subscript?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler;
}

function PropertyItem (props: PropertyItemProps) {
  const [search] = useSearchParams(),
    {
      property,
      routable = true,
      className,
      subscript = "/night",
      children,
      onClick,
    } = props,
    {
      location_slug,
      property_slug,
      thumbnail,
      name,
      address,
      accommodation,
      pool_count,
      bath_count,
      currency_symbol,
      rate,
      discounted_rate,
      sold_out,
      upcoming,
      status,
      brand,
      pet_friendly,
      configs,
      next_availability,
      builder,
      discount_method,
      loyalty_point_discount_percentage,
      average_ratings,
      ratings_count,
    } = property,
    guest_count = useMemo(()=>{
      const guest = accommodation.split(" ");
      const count = parseInt(guest[guest.length - 1], 10); 
      return count;    
    },[accommodation]),
    { isMobile } = useIsMobile(),
    specs = useMemo(
      () => {
        const specs: string[] = [
          `${accommodation} ${guest_count > 1 ? "Guests" : "Guset"}`,
        ];

        if (configs.length) {
          const partial = configs.map(each => each.bedrooms).join(", ");

          specs.push(`${partial} Bedrooms`);
        }

        if (bath_count) {
          specs.push(`${bath_count} ${bath_count > 1 ? "Bathrooms" : "Bathroom"}`);
        }

        if (pool_count) {
          specs.push(`${pool_count} ${pool_count > 1 ? "Pools" : "Pool"}`);
        }

        return specs;
      },
      [accommodation, bath_count, pool_count, configs],
    ),
    strikePrice = useMemo(
      () => {
        if (!discounted_rate) {
          return "";
        }
        if (discounted_rate == rate) {
          return "";
        }
        return `${currency_symbol} ${rate.toLocaleString("en-IN")}`;
      },
      [discounted_rate, rate, currency_symbol],
    ),
    finalPrice = useMemo(
      () => {
        const finalPrice = discounted_rate || rate;

        return `${currency_symbol} ${(finalPrice).toLocaleString("en-IN")}`;
      },
      [discounted_rate, rate, currency_symbol],
    ),
    { location, destination } = useMemo(
      () => decodeLocationSlug(location_slug),
      [location_slug],
    ),
    isUnavailable = useMemo(
      () => status === "unavailable" || upcoming,
      [status, upcoming],
    ),
    isBlack = useMemo(
      () => brand === "lohono_black",
      [brand],
    ),
    properties = ["ishavilas", "ishavilas-7", "villa-azul", "isp-villa-verde", "isp-villa-branco", "ishanya"],
    hasWellnessPromotion = useMemo(
      () => (properties.includes(property_slug)),
      [property_slug],
    ),
    ccx = useClx(
      clxs.container,
      isBlack ? clxs.black : null,
      className,
    ),
    rootElement = useMemo(
      () => {
        if (!routable) {
          return "div";
        }
        return "a";
      },
      [routable],
    ),
    nextAvailableDateNode = useMemo(
      () => {
        if (!sold_out) {
          return null;
        }

        const { checkin_date, checkout_date } = next_availability;

        if (!checkin_date || !checkout_date) {
          return null;
        }

        const checkin = format(new Date(checkin_date), "d LLL"),
          checkout = format(new Date(checkout_date), "d LLL");

        return (
          <div className={clxs.datesContainer}>
            {isMobile ? "- Next Available Dates" : "Next Available Dates"}
            <div className={clxs.dates}>
              {isMobile ? ` : ${checkin} - ${checkout}` : `${checkin} - ${checkout}`}
            </div>
          </div>
        );

      },
      [
        next_availability,
        sold_out,
        clxs.datesContainer,
        clxs.dates,
      ],
    ),
    tickerNode = useMemo(
      () => {
        if (upcoming) {
          return (
            <div className={clxs.upcoming}>
              Coming soon
            </div>
          );
        }

        if (sold_out) {
          return (
            <div className={clxs.soldOut}>
              Sold out
            </div>
          );
        }

        if (isUnavailable) {
          return (
            <div className={clxs.unavailable}>
              Unavailable
            </div>
          );
        }

        return null;
      },
      [upcoming, sold_out, isUnavailable],
    ),
    builderBadge = useMemo(
      () => {
        if (isBlack) {
          return (
            <img
              src={LOHONO_BLACK_LOGO}
              alt="lohono-black"
              className={clxs.badge}
            />
          );
        }

        // if (builder === "isprava") {
        //   return (
        //     <img
        //       src={ISPRAVA_LOGO}
        //       alt="isprava"
        //       className={clxs.badge}
        //     />
        //   );
        // }

        if (builder === "partner") {
          return (
            <div className={clxs.partnerHome}>
              <img
                alt="partner home"
                className={clxs.partnerHomeIcon}
                src={PARTNER_HOME_ICON}
              />
              Partner Home
            </div>
          );
        }

        return null;
      },
      [builder, isBlack],
    ),
    renderSoldOut = useMemo(() => {
      //If soldout or upcomming is true and nextAvailableDate is not present then
      const condition1 = (upcoming || sold_out) && !nextAvailableDateNode;

      //If sold_out ,upcoming ,isUnavailable is true
      const condition2 = sold_out || upcoming || isUnavailable;

      if (condition1) {
        return <div className={clxs.soloSoldOut}>{tickerNode}</div>;
      } else if (condition2) {
        return (
          <div className={clxs.soldOutContainer}>
            {tickerNode}
            {nextAvailableDateNode}
          </div>
        );
      } else {
        return null;
      }
    }, [sold_out, upcoming, nextAvailableDateNode, isUnavailable]),
    cProps = useMemo(
      () => {
        const baseProps = {
          className: ccx,
          onClick: onClick,
        };

        if (!routable) {
          return baseProps;
        }

        const updatedSearchParams = new URLSearchParams(search);

        if (!updatedSearchParams.has("adult_count")) {
          updatedSearchParams.set("adult_count", "2");
        }

        if (
          sold_out
          && next_availability.checkin_date
          && next_availability.checkin_date
        ) {
          updatedSearchParams.set(
            "checkin_date",
            new Date(next_availability.checkin_date).toISOString(),
          );

          updatedSearchParams.set(
            "checkout_date",
            new Date(next_availability.checkout_date).toISOString(),
          );
        }

        const safeSearch = updatedSearchParams.toString(),
          path = `/villas/${destination}/${location}/${property_slug}`,
          url = safeSearch ? `${path}?${safeSearch}` : path;

        return {
          ...baseProps,
          href: url,
          title: `View details of ${name}`,
        };
      },
      [
        routable,
        name,
        location,
        destination,
        property_slug,
        next_availability,
        sold_out,
        ccx,
        onClick,
        search,
      ],
    );

  return createElement(
    rootElement,
    cProps,
    <>
      <NetworkImg
        src={thumbnail.url}
        alt={thumbnail.alt}
        desktopQuality={50}
        mobileQuality={75}
        className={clxs.thumbnail}
        mobileDimensions={[800, 600]}
        desktopDimensions={[800, 600]}
      />
      <h3
        className={clxs.title}
        style={{ gridColumn: (((ratings_count && average_ratings) || sold_out) ? "span 2" : "span 3") }}
        title={name}
      >
        {name}
      </h3>
      {/* Check if both ratings_count and average_ratings */}
      {(ratings_count && average_ratings) ?
      <RatingCounts
        className={clxs.ratingCounts}
        average_ratings={average_ratings}
        ratings_count={ratings_count}
        theme="propertyItemRating"
      /> : null }
      <div className={clxs.subtitle}>
        {address}
      </div>
      <div className={clxs.specContainer}>
        {specs.map(
          (each, i) => (
            <div
              key={i}
              className={clxs.spec}
            >
              {each}
            </div>
          ),
        )}
      </div>
      {renderSoldOut}
      {isUnavailable ? (<div>&nbsp;</div>) : (
        <div className={clxs.pricingContainer}>
          <div className={clxs.startFrom}>
             Start from
            {strikePrice ? (
              <span className={clxs.strike}>
                {strikePrice}
              </span>
            ) : null}
          </div>
          <div
            className={clxs.view}
            data-sold-out={sold_out}
          >
            <span>
              View details
            </span>
          <img
            src={isBlack ? CHEVRON_RIGHT_PRIMARY2 : CHEVRON_RIGHT_PRIMARY1}
            alt="jump"
          />
          </div>
          <div 
            className={clxs.price}
            data-sold-out={sold_out}
          >
            <span className={clxs.amount}>
              {finalPrice}
            </span>
            {subscript}
            <sup>*</sup>
          </div>
          <div className={clxs.excTax}>
            <LoyaltyPointShortTicker
              discountPercentage={loyalty_point_discount_percentage}
              method={discount_method}
              className={clxs.llpTicker}
            />
            * Exclusive of taxes
          </div>
        </div>
      )}
      {builderBadge}
      {pet_friendly && (
        <div className={clxs.petFriendly}>
          <img
            src={PET_FRIENDLY}
            alt="icon"
          />
          <div>Pet friendly</div>
        </div>
      )}
      {hasWellnessPromotion && (
        <img
          src={WELLNESS_ICON}
          alt="icon"
          className={clxs.wellness}
        />
      )}
      {/* {tickerNode} */}
      {children}
    </>,
  );
}

export default PropertyItem;
