import { REFERRAL_PROGRAM_POLICY_KEY } from "../../core/constants";
import { useAtomState } from "./use-atom";

function useReferralProgramPolicy() {
  const [policy] = useAtomState<ReferralProgramPolicy>(
    REFERRAL_PROGRAM_POLICY_KEY,
    DEFAULT_POLICY,
  );

  return policy;
}

export default useReferralProgramPolicy;

const DEFAULT_POLICY: ReferralProgramPolicy = {
  faq: [],
};
