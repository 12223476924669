import { useCallback, useEffect, useRef } from "react";
import { useQueryParamValue } from "../../../hooks/use-query-param";

function useCalculatorFocus () {
  const ref = useRef<HTMLFormElement>(null);

  const focus = useQueryParamValue("string", "focus");

  const handleFocus = useCallback(
    (
      node: HTMLFormElement | null,
      focus: string,
    ) => {
      if (!node) {
        return;
      }

      if (focus !== "rate_calculator") {
        return;
      }

      node.scrollIntoView({ behavior: "smooth" });
    },
    [],
  );

  useEffect(
    () => handleFocus(ref.current, focus),
    [ref.current, focus],
  );

  return ref;
}

export default useCalculatorFocus;
