import React, { AnchorHTMLAttributes, ForwardedRef, forwardRef, ReactElement, useMemo } from "react";

interface AnchorProps extends BaseAnchorProps {
  className?: string;
  newTab?: boolean;
  title?: string;
  href: string;
  children?: ReactElement;
}

const Anchor = forwardRef(
  function AnchorBase(
    props: AnchorProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) {
    const {
      newTab,
      title,
      children,
      ...rest
    } = props;

    const { target, rel } = useMemo(
      () => ({
        target: newTab ? "_blank" : "_self",
        rel: newTab ? "noopener noreferrer" : undefined,
      }),
      [newTab],
    );

    return (
      <a
        {...rest}
        title={title}
        target={target}
        rel={rel}
        ref={ref}
      >
        {title}
        {children}
      </a>
    );
  },
);

export default Anchor;

type BaseAnchorProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "rel" | "target">;