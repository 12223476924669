import React, { useMemo } from "react";
import clx from "classnames";
import sortByAspectRatio from "./sort-by-aspect-ratio";
import clxs from "./gallery.module.css";
import CLOSE_ICON from "../../../assets/icons/close.svg";

interface GalleryProps {
  images?: PropertyImage[];
  onSubmit?: (value: any) => void;
}

function Gallery(props: GalleryProps) {
  const { images, onSubmit } = props,
    sorted = useMemo(
      () => sortByAspectRatio(images ?? []),
      [images],
    );

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={onSubmit?.bind(null, null)}
        />
      </div>
      <div className={clxs.imageContainer}>
        {sorted.map(
          (each, i) => {
            const { url, alt, caption, aspect_ratio } = each,
              icx = clx(clxs.image, aspect_ratio);

            return (
              <img
                key={i}
                src={url}
                alt={alt}
                className={icx}
                loading="lazy"
                title={caption}
              />
            );
          },
        )}
      </div>
      <span>&nbsp;</span>
    </div>
  );
}

export default Gallery;
