import format from "date-fns/format";

function toISODateString (date: Date): string {
 return format(date, "yyyy-MM-dd");
}

export default toISODateString;

/*
why this bullshit?

format with tz ->
 format(new Date("2022-01-31T00:00:00.000Z"), "yyyy-MM-dd") = '2022-01-31'
 format(new Date("2022-01-31T18:30:00.000Z"), "yyyy-MM-dd") = '2022-02-01'
 format(new Date("2022-02-01T00:00:00.000Z"), "yyyy-MM-dd") = '2022-02-01'

format without tz ->
 format(new Date("2022-01-31"), "yyyy-MM-dd") = '2022-01-31' (in mumbai)
 format(new Date("2022-02-01"), "yyyy-MM-dd") = '2022-02-01' (in mumbai)

 format(new Date("2022-01-31"), "yyyy-MM-dd") = '2022-01-30' (in san francisco)
 format(new Date("2022-02-01"), "yyyy-MM-dd") = '2022-01-31' (in san francisco)


1. 18:30.00.000Z at UTC is the start of a new day
1. all servers by convention are in UTC
2. if we don't share tz information, after parsing and formatting,
   we're not even talking about the same date anymore. to remedy this situation,
   always share tz information.
3. jumping tz should be handled in the topmost upstream server. this way it's
   guaranteed that we're at least talking about the same time regardless of tz
4. since for some reason topmost upstream server accepts date strings instead
   of iso string format, parsed date will mean different point in time depending
   on server's tz.
5. to remedy this issue, we now add ist (5.5 hrs or 5 hrs 30 min) offset to our
   server requests
*/
