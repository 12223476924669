import useUserService from "./use-user-service";
import { useEffect, useState } from "react";

function useReferral(referral_code: string) {
  const { service } = useUserService(),
    [referralDetails, setReferralDetails] = useState<ReferralDetails | null>(null),
    handleReferralDetails = async (referral_code: string) => {
      if (!referral_code) {
        return;
      }

      const { error, response } = await service.getReferralDetails(
        referral_code,
      );

      if (error || !response) {
        return;
      }

      setReferralDetails(response);
    };

  useEffect(() => {
    handleReferralDetails(referral_code);
  }, [referral_code]);

  return {
    referralDetails: referralDetails,
  };
}

export default useReferral;
