import React from "react";
import clxs from "./location-loader.module.css";

interface LocationLoaderProps {
  isLoading: boolean;
}

function LocationLoader(props: LocationLoaderProps) {
  const { isLoading } = props;

  return (
    isLoading ? (
      <div className={clxs.ldsRipple}>
        <div></div>
        <div></div>
      </div>
    ) : <></>
  );
}

export default LocationLoader;
