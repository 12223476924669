import React from "react";
import POINT_STAR from "../../../assets/loyalty-points/login-star.svg";
import useClx from "../../../hooks/use-clx";
import useLoyaltyPointsBalance from "../../../hooks/use-loyalty-points-balance";
import { Track } from "../../AnalyticsManager";
import clxs from "./point-tag.module.css";

interface PointTagProps {
  className?: string;
  onShowLoyaltyPointsHistory?: () => void;
}

function PointTag(props: PointTagProps) {
  const { className, onShowLoyaltyPointsHistory } = props;

  const { friendly: content } = useLoyaltyPointsBalance();

  const ccx = useClx(clxs.container, className);

  if (!content) {
    return null;
  }

  return (
    <Track event="loyalty_points_history_viewed">
      <div
        className={ccx}
        onClick={onShowLoyaltyPointsHistory}
      >
        <div className={clxs.tag}>
          <img
            src={POINT_STAR}
            alt="points"
            className={clxs.star}
          />
          {content}
        </div>
        <div className={clxs.available}>
          Available Points
        </div>
      </div>
    </Track>
  );
}

export default PointTag;
