import { useAtomState } from "./use-atom";

class _DefaultPaymentService implements IPaymentService {
  async request(): Promise<FetchResult<RazorpayResult>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }
}

const DefaultPaymentService = new _DefaultPaymentService();

export const PAYMENT_SERVICE_KEY = "PAYMENT_SERVICE";

function usePaymentService() {
  const [service, setService] =
    useAtomState<IPaymentService>(
      PAYMENT_SERVICE_KEY,
      DefaultPaymentService,
    );

  return {
    service: service,
    setService: setService,
  };
}

export default usePaymentService;
