import React, { ChangeEvent, InputHTMLAttributes, LabelHTMLAttributes, ReactElement } from "react";
import useClx from "../../../../hooks/use-clx";
import { useField } from "../../../../hooks/use-form";
import clxs from "./checkbox.module.css";

interface CheckboxProps extends BaseInputProps {
  labelProps?: BaseLabelProps;
  form: string;
  name: string;
  label: string | number | ReactElement;
  className?: string;
}

function Checkbox (props: CheckboxProps) {
  const {
      labelProps = {},
      form,
      name,
      className,
      label,
      ...rest
    } = props, {
      value = false,
      error,
      handleBlur,
      handleChange,
    } = useField(form, name),
    onChange = (
      e: ChangeEvent<HTMLInputElement>,
    ) => {
      const value = e.currentTarget.checked,
        target = { name: name, value: value };

      const event: any = {
        target: target,
        currentTarget: target,
      };

      handleChange(event);
    },
    ccx = useClx(clxs.container, className);

  return (
    <label
      {...labelProps}
      className={ccx}
    >
     <span
       className={clxs.checkmark}
       data-active={value}
     >
       {value ? "✓" : ""}
     </span>
      <input
        {...rest}
        type="checkbox"
        id={name}
        name={name}
        value={name}
        checked={value}
        onChange={onChange}
        onBlur={handleBlur}
        className={clxs.input}
        data-error={Boolean(error).valueOf()}
      />
      {label ?? <span>&nbsp;</span>}
      {error && <div className={clxs.error}>{error}</div>}
    </label>
  );
}

export default Checkbox;

type BaseLabelProps = Omit<LabelHTMLAttributes<HTMLLabelElement>, "className">;

type BaseInputProps = Omit<InputHTMLAttributes<HTMLInputElement>,
  | "id"
  | "name"
  | "type"
  | "value"
  | "onChange"
  | "onBlur">;
