import React, { useMemo } from "react";
import useClx from "../../../../../hooks/use-clx";
import clxs from "./pin.module.css";
import PIN_GENERIC from "../../../../../assets/icons/location-pin-generic.svg";
import PIN_HOTEL from "../../../../../assets/icons/pin-hotel.svg";
import PIN_RESTAURANT from "../../../../../assets/icons/pin-restaurant.svg";
import PIN_BEACH from "../../../../../assets/icons/pin-beach.svg";
import PIN_PROPERTY from "../../../../../assets/icons/pin-property.svg";

interface PinProps {
  lat: number;
  lng: number;
  name: string;
  category?: string;
  className?: string;
  distance_in_minutes?: number;
}

function Pin(props: PinProps) {
  const { category } = props,
    PIN_SRC = useMemo(
      () => {
        const c = (category || "").toUpperCase();

        let src = PIN_GENERIC;
        if (c === "HOTELS") {
          src = PIN_HOTEL;
        } else if (c === "RESTAURANTS") {
          src = PIN_RESTAURANT;
        } else if (c === "BEACHES") {
          src = PIN_BEACH;
        } else if (c === "PROPERTY") {
          src = PIN_PROPERTY;
        }

        return src;
      },
      [category],
    ),
    { className, name, distance_in_minutes } = props,
    subtitle = useMemo(
      () => distance_in_minutes ? `${distance_in_minutes} min` : null,
      [distance_in_minutes],
    ),
    ccx = useClx(clxs.container, className),
    pcx = useClx(clxs.pin, "pin"),
    tcx = useClx(clxs.title, "title"),
    scx = useClx(clxs.subtitle, "subtitle");

  return (
    <div className={ccx}>
      <img
        src={PIN_SRC}
        alt={name}
        title={name}
        className={pcx}
      />
      <div className={clxs.content}>
        <div className={tcx}>
          {name}
        </div>
        {subtitle && (
          <div className={scx}>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
}

export default Pin;
