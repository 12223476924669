import {
  createElement,
  MouseEvent,
  ReactElement,
  MouseEventHandler,
} from "react";
import useAnalytics from "../../hooks/use-analytics";

interface TrackProps {
  children: ReactElement;
  event?: string;
  payload?: any;
  skipKeys?: string[];
}

function Track (props: TrackProps) {
  const { track } = useAnalytics(),
    { event, payload = {}, children, skipKeys } = props,
    { type, props: childProps, key } = children,
    handleClick = (handler: MouseEventHandler | null, e: MouseEvent) => {
      if (event) {
        track(event, payload, skipKeys);
      }

      if (handler) {
        return handler(e);
      }
    };

  return createElement(
    type, {
      ...childProps,
      key: key,
      onClick: handleClick.bind(null, childProps.onClick ?? null),
    },
  );
}

export default Track;
