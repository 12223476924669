import React from "react";
import { useCarouselScroller } from "../../hooks/use-carousel";
import useClx from "../../hooks/use-clx";
import usePropertyLocations from "../../hooks/use-property-locations";
import fwclxs from "../../styles/full-width.module.css";
import { AnchorButton } from "../Button";
import Carousel from "../Carousel";
import Item from "../Carousel/Item";
import Scroller from "../Scroller";
import SendRequest from "../SendRequestNeedHelp";
import {
  PARTNER_BENEFITS_TEMPLATE,
  PARTNER_INCENTIVES_TEMPLATE,
  PARTNER_PROPERTY_TEMPLATE,
} from "../../core/partner";
import clxs from "./partner.module.css";
import AGENT_ILLUSTRATION from "../../assets/agent-send-request.svg";
import MAP_ILLUSTRATION from "../../assets/map-illustration.png";
import PARTNER_BANNER from "../../assets/partner-agents-banner.png";

function Partner() {
  const { propertyLocations } = usePropertyLocations(),
    { scrollLeft, scrollRight } = useCarouselScroller(PROPERTY_CAROUSEL_ID),
    bcx = useClx(fwclxs.container, clxs.section, clxs.banner),
    wscx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.section),
    itcx = useClx(wscx, clxs.intro),
    iccx = useClx(clxs.section, clxs.incentiveContainer),
    bncx = useClx(wscx, clxs.benefitContainer),
    lcx = useClx(clxs.section, clxs.locationContainer),
    fcx = useClx(wscx, clxs.featuresContainer),
    scx = useClx(clxs.section, clxs.sendRequestContainer);

  return (
    <>
      <div className={bcx}>
        <img
          src={PARTNER_BANNER}
          alt="partner"
          className={clxs.bannerImage}
          loading="lazy"
        />
        <h1 className={clxs.title}>
          Join the Lohono Premium Partner Program
        </h1>
        <h2 className={clxs.subtitle}>
          Partners would be provided the best possible rate at the time of enquiry
        </h2>
        <a
          color="inherit"
          href="#request"
          className={clxs.bannerCta}
        >
          Become a partner
        </a>
      </div>
      <div className={itcx}>
        <div className={clxs.introTitle}>
          Lohono Stays stands for unmatched holiday experiences and exquisite villas in great locations for discerning
          travellers
        </div>
        <img
          loading="lazy"
          src={MAP_ILLUSTRATION}
          alt="map-illustration"
          className={clxs.introIllustration}
        />
        <div className={clxs.introSubtitle}>
          The kind of traveller you interact with everyday
        </div>
      </div>
      <div className={iccx}>
        <div className={clxs.sectionTitle}>
          Why Become a Lohono Premium Partner?
        </div>
        <div className={clxs.sectionSubtitle}>
          Introducing a progressive <b>commission structure starting from 10%</b>
        </div>
        {PARTNER_INCENTIVES_TEMPLATE.map(
          (each, i) => (
            <div
              key={i}
              className={clxs.incentive}
            >
              <img
                src={each.icon}
                alt={each.title}
                className={clxs.incentiveIcon}
              />
              <div className={clxs.incentiveTitle}>
                {each.title}
              </div>
            </div>
          ),
        )}
        <AnchorButton
          className={clxs.sectionCta}
          href="#request"
        >
          Become a partner
        </AnchorButton>
      </div>
      <div className={bncx}>
        <div className={clxs.sectionTitle}>
          Benefits for you?
        </div>
        <div className={clxs.sectionSubtitle}>
          At Lohono Stays, you are not just an agent but a part of a family
        </div>
        <div className={clxs.benefitCarousel}>
          <span>&nbsp;</span>
          {PARTNER_BENEFITS_TEMPLATE.map(
            (each, i) => (
              <div
                key={i}
                className={clxs.benefit}
              >
                <div className="title">
                  {each.title}
                </div>
                <ul>
                  {each.points.map(
                    (each, i) => (
                      <li key={i}>{each}</li>
                    ),
                  )}
                </ul>
              </div>
            ),
          )}
          <span>&nbsp;</span>
        </div>
        <AnchorButton
          className={clxs.sectionCta}
          href="#request"
        >
          Become a partner
        </AnchorButton>
      </div>
      <div className={lcx}>
        <div className={clxs.sectionTitle}>
          Our Locations
        </div>
        <div className={clxs.sectionSubtitle}>
          Exclusive homes for rent spread across pristine destinations
        </div>
        <div className={clxs.locations}>
          {propertyLocations.map(
            (each, i) => {
              const {
                thumbnail,
                name,
                property_count,
              } = each;

              return (
                <div
                  key={i}
                  className={clxs.location}
                >
                  <img
                    loading="lazy"
                    src={thumbnail}
                    alt={name}
                    className={clxs.locationThumbnail}
                  />
                  <div className={clxs.locationTitle}>
                    {name}
                  </div>
                  <div className={clxs.locationSubtitle}>
                    {property_count ? `${property_count} Properties` : "Coming soon"}
                  </div>
                </div>
              );
            },
          )}
        </div>
      </div>
      <div className={fcx}>
        <div className={clxs.sectionTitle}>
          Villas Featured In
        </div>
        <div className={clxs.sectionSubtitle}>
          Our homes have been reviewed by a number of critics and publications. Check out our private villas
        </div>
        <Carousel
          id={PROPERTY_CAROUSEL_ID}
          className={clxs.propertyCarousel}
        >
          {PARTNER_PROPERTY_TEMPLATE.map(
            (each, i) => {
              const {
                thumbnail,
                title,
                subtitle,
                feature,
              } = each;

              return (
                <Item
                  key={i}
                  className={clxs.property}
                >
                  <img
                    loading="lazy"
                    src={thumbnail}
                    alt={title}
                    className={clxs.propertyThumbnail}
                  />
                  <div className={clxs.propertyTitle}>
                    {title}
                  </div>
                  <div className={clxs.propertySubtitle}>
                    {subtitle}
                  </div>
                  <span className={clxs.propertyFeature}>
                    <span className="subtitle">Featured in</span>
                    <span className="title">{feature}</span>
                  </span>
                </Item>
              );
            },
          )}
        </Carousel>
        <Scroller
          onScrollLeft={scrollLeft}
          onScrollRight={scrollRight}
          className={clxs.propertyScroller}
        />
      </div>
      <div className={scx}>
        <a
          id="request"
          href="#request"
          className={clxs.sendRequestAnchor}
        >
          &nbsp;
        </a>
        <div className={clxs.sectionTitle}>
          Know More To Become Partner
        </div>
        <div className={clxs.sectionSubtitle}>
          The good life is waiting. Come, Lohono with us!
        </div>
        <img
          loading="lazy"
          src={AGENT_ILLUSTRATION}
          alt="agent-contact"
          className={clxs.sendRequestIllustration}
        />
        <SendRequest
          className={clxs.sendRequest}
          interest="others"
        />
      </div>
    </>
  );
}

export default Partner;

const PROPERTY_CAROUSEL_ID = "partner-property-carousel";
