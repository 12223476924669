import React from "react";
import Modal from "../../../hooks/use-modal";
import useSendRequest from "../../../hooks/use-send-request";
import ModalContent from "./ModalContent";

function SendRequestModal () {
  const { modalId } = useSendRequest();

  return (
    <Modal
      id={modalId}
      scrollBlock={false}
    >
      <ModalContent/>
    </Modal>
  );
}

export default SendRequestModal;
