import React from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./luxury.module.css";
import BESPOKE_EVENTS from "../../../../assets/lohono-black/bespoke-events.jpg";
import BUTLER from "../../../../assets/lohono-black/butler.jpg";
import CAR_TRANSFER from "../../../../assets/lohono-black/car-transfer.jpg";
import CHEF_CURATED_MULTICUISINE from "../../../../assets/lohono-black/chef-curated-multicuisine.jpg";
import CONCIERGE from "../../../../assets/lohono-black/concierge.jpg";
import DEDICATED_WELLNESS from "../../../../assets/lohono-black/dedicated-wellness.jpg";
import ENHANCED_HYGIENE from "../../../../assets/lohono-black/enhanced-hygiene.jpg";
import HOTEL_QUALITY_BED from "../../../../assets/lohono-black/hotel-quality-bed.jpg";
import ONE_OF_KIND from "../../../../assets/lohono-black/one-of-a-kind.jpg";

interface LuxuryProps {
  titleClassName?: string;
  subtitleClassName?: string;
  className?: string;
}

function Luxury (props: LuxuryProps) {
  const { className, titleClassName, subtitleClassName } = props,
    ccx = useClx(clxs.container, className),
    tcx = useClx(clxs.title, titleClassName),
    scx = useClx(clxs.subtitle, subtitleClassName);

  return (
    <div className={ccx}>
      <div className={tcx}>
        Elevated Luxury
      </div>
      <div className={scx}>
        {CONTENT}
      </div>
      <div className={clxs.items}>
        {TEMPLATE.map(
          (each, key) => (
            <div
              className={clxs.item}
              key={key}
              title={each.title}
            >
              <img
                src={each.thumbnail}
                alt={each.title}
                className={clxs.itemThumbnail}
              />
              <div className={clxs.itemTitle}>
                {each.title}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default Luxury;

const CONTENT = "Exquisite design, fine craftsmanship, unmatched comfort, superior amenities and one-of-a-kind experiences.\nEvery estate has its own charm, its own adventures, its own secrets, and its own story";

const TEMPLATE = [
  {
    title: "One-of-a-kind estates",
    thumbnail: ONE_OF_KIND,
  },
  {
    title: "Butler service",
    thumbnail: BUTLER,
  },
  {
    title: "Chef-curated multi-cuisine meals",
    thumbnail: CHEF_CURATED_MULTICUISINE,
  },
  {
    title: "Dedicated wellness team",
    thumbnail: DEDICATED_WELLNESS,
  },
  {
    title: "Concierge service",
    thumbnail: CONCIERGE,
  },
  {
    title: "Car transfer service",
    thumbnail: CAR_TRANSFER,
  },
  {
    title: "Hotel-quality bed & bath linen",
    thumbnail: HOTEL_QUALITY_BED,
  },
  {
    title: "Bespoke events & experiences",
    thumbnail: BESPOKE_EVENTS,
  },
  {
    title: "Enhanced hygiene & safety measure",
    thumbnail: ENHANCED_HYGIENE,
  },
];
