import { useCallback, useEffect, useState } from "react";

function useOtpGenerate (
  onReset: () => void,
  timeout: number,
  onGenerate?: (resend: boolean) => void,
) {
  const [regenerated, setRegenerated] = useState<boolean>(false);

  const [timer, setTimer] = useState<number>(timeout);

  const handleGenerate = useCallback(
    async () => {
      if (!onGenerate) {
        setTimer(0);

        return;
      }

      await onGenerate(false);

      for await (const timer of asyncTimer(timeout)) {
        setTimer(timer);
      }
    },
    [setTimer, onGenerate, timeout],
  );

  const handleRegenerate = useCallback(
    async () => {
      if (regenerated) {
        return;
      }

      onReset();

      await handleGenerate();

      setRegenerated(true);
    },
    [
      handleGenerate,
      regenerated,
      setRegenerated,
      onReset,
    ],
  );

  useEffect(
    () => {
      handleGenerate();
    },
    [handleGenerate],
  );

  return {
    timer: timer,
    regenerated: regenerated,
    handleRegenerate: handleRegenerate,
  };
}

export default useOtpGenerate;

async function* asyncTimer (count: number) {
  for (let i = count - 1; i > -1; i -= 1) {
    const value = await new Promise<number>(
      resolve => setTimeout(resolve, 1000, i),
    );

    yield value;
  }

  yield 0;
}
