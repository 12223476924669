import React, { ForwardedRef, forwardRef, useMemo } from "react";
import createResponsiveSrc from "../../../utils/create-responsive-src";
import Img, { ImgProps } from "../Img";

interface NetworkImgProps extends Omit<ImgProps, "mobileSrc" | "desktopSrc"> {
  mobileQuality?: number;
  desktopQuality?: number;
  mobileDimensions?: [number, number];
  desktopDimensions?: [number, number];
  src: string;
}

const NetworkImg = forwardRef(
  function NetworkImgCore(
    props: NetworkImgProps,
    ref: ForwardedRef<HTMLImageElement>,
  ) {
    const { src } = props,
      {
        mobileQuality = 15,
        desktopQuality = 75,
        mobileDimensions,
        desktopDimensions,
        ...rest
      } = props,
      { desktop, mobile } = useMemo(
        () => src ?
          createResponsiveSrc(
            src,
            desktopQuality,
            mobileQuality,
            desktopDimensions,
            mobileDimensions,
          )
          : { desktop: "", mobile: "" },
        [
          src,
          mobileQuality,
          desktopQuality,
          desktopDimensions,
          mobileDimensions,
        ],
      );

    return (
      <Img
        {...rest}
        ref={ref}
        desktopSrc={desktop}
        mobileSrc={mobile}
      />
    );
  },
)

export default NetworkImg;
