import React from "react";
import useQueryParamValue from "../../../hooks/use-query-param/use-query-param-value";
import useReferral from "../../../hooks/use-referral";
// import useUserService from "../../../hooks/use-user-service";
import Button from "../../Button";
import ValidateReferrer from "../ValidateReferrer";
import clxs from "./referral-details.module.css";
import REFEREE_ICON from "../../../assets/icons/referee-icon.svg";

interface ReferralDetailsProps {
  onClick?: (value: any) => void;
  isValidateCode?: boolean;
  onCodeValidation?: () => void;
  session?: string;
}

function ReferralDetails(props: ReferralDetailsProps) {
  const { onClick, isValidateCode, session } = props,
    referralCode = useQueryParamValue("string", REFERRAL_CODE_QUERY_KEY),
    { referralDetails } = useReferral(referralCode);

  return (
    <>
      {!isValidateCode ? (
        referralDetails ? (
          <>
            <div className={clxs.referralContainer}>
              <div className={clxs.title}>Invite and Earn</div>
              <div>You have been invited by</div>
              <div className={clxs.referralDetails}>
                <img
                  src={REFEREE_ICON}
                  alt="referee_icon"
                  className={clxs.icon}
                />
                <div className={clxs.referralName}>
                  {referralDetails?.referralName}
                  <div className={clxs.tier}>
                    {referralDetails?.tier} Infinity Member
                  </div>
                </div>
              </div>
              <div>
                Login now to get your
                <span className={clxs.points}>
                  {" ₹" + referralDetails?.points.toLocaleString() + " rewards points. "}
                </span>
                <div className={clxs.availText}>Avail them on your first luxury vacation</div>
              </div>
            </div>
            <Button
              className={clxs.loginButton}
              type="button"
              onClick={onClick}
            >
              Login Now
            </Button>
          </>
        ) : (
          <>
            <div className={clxs.title}>Invalid Referral Code</div>
            <Button
              className={clxs.loginButton}
              type="button"
              onClick={onClick}
            >
              Login
            </Button>
          </>
        )
      ) : (
        <ValidateReferrer
          session={session as string}
          referralCode={referralCode}
        ></ValidateReferrer>
      )}
    </>
  );
}

export default ReferralDetails;

const REFERRAL_CODE_QUERY_KEY = "referral_code";
