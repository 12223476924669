import React from "react";
import clxs from "./no-villas-found.module.css";
import NO_VILLAS_FOUND_ICON from "../../../assets/icons/no-villas-found-icon.svg";

function NoVillasFound() {
  return (
    <>
      <div className={clxs.container}>
        <img
          src={NO_VILLAS_FOUND_ICON}
          alt="NO VILLAS FOUND ICON"
          className={clxs.icon}
        />
        <div className={clxs.header}>Oops!</div>
        <p>
          No villas found. Explore <span>other categories</span> or{" "}
          <span>try another location.</span>
        </p>
      </div>
    </>
  );
}

export default NoVillasFound;
