import React, {HTMLAttributes, LabelHTMLAttributes, TextareaHTMLAttributes} from "react";
import {useField} from "../../../../hooks/use-form";
import useClx from "../../../../hooks/use-clx";
import clxs from "./text-area.module.css";

interface TextAreaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "id" | "name" | "value" | "onChange" | "onBlur"> {
  form: string;
  name: string;
  containerProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
  label?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
}

function TextArea(props: TextAreaProps) {
  const {
      form,
      name,
      containerProps = {},
      label,
      labelProps = {},
      ...rest
    } = props,
    {
      value,
      error,
      handleBlur,
      handleChange,
    } = useField(form, name),
    {className: _ccx} = rest,
    {className: _lcx} = labelProps,
    ccx = useClx(clxs.container, _ccx),
    lcx = useClx(clxs.label, _lcx);

  if (value === undefined) {
    return null;
  }

  return (
    <div
      {...containerProps}
      className={ccx}
    >
      {label && (
        <label
          {...labelProps}
          htmlFor={name}
          className={lcx}
        >
          {label}
        </label>
      )}
      <textarea
        {...rest}
        id={name}
        name={name}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        className={clxs.input}
        data-error={Boolean(error).valueOf()}
        suppressHydrationWarning={true}
      />
      {error && <div className={clxs.error}>{error}</div>}
    </div>
  );
}

export default TextArea;
