import React from "react";
import useContactUsPhoneNo from "../../../hooks/use-contact-us-phone-no";
import useSendRequest from "../../../hooks/use-send-request";
import { Track } from "../../AnalyticsManager";
import CALL_ICON from "../../../assets/lohono-black/icons/call.svg";
import MAIL_ICON from "../../../assets/lohono-black/icons/enquire.svg";
import clxs from "./contact-us.module.css";

function ContactUs () {
  const { tel } = useContactUsPhoneNo(true),
    { showSendRequestModal } = useSendRequest(),
    handleContactUs = async () => {
      await showSendRequestModal({
        interest: "rental",
        location: "india-karjat",
        brand: 5,
      });
    };

  return (
    <div className={clxs.container}>
      <Track event="contact_us_mobile_clicked">
        <div
          className={clxs.action}
          onClick={handleContactUs}
        >
          <img
            src={MAIL_ICON}
            alt="enquire-now"
            className={clxs.icon}
          />
          Enquire Now
        </div>
      </Track>
      <Track event="call_now_mobile_clicked">
        <a
          href={tel}
          className={clxs.action}
        >
          <img
            src={CALL_ICON}
            alt="call-now"
            className={clxs.icon}
          />
          Call Now
        </a>
      </Track>
    </div>
  );
}

export default ContactUs;
