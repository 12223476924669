import { useAtomValue } from "./use-atom";
import { ENV_KEY } from "../../core/constants";

type Env = {
  GOOGLE_MAPS_API_KEY: string;
  RAZORPAY_API_KEY: string;
  REMOTE_PUBLIC_ASSET_FOLDER: string;
  APP_PLAY_STORE_URL: string;
  APP_APP_STORE_URL: string;
  IPREGISTRY_KEY: string;
};

const DefaultEnv: Env = {
  GOOGLE_MAPS_API_KEY: "",
  RAZORPAY_API_KEY: "",
  REMOTE_PUBLIC_ASSET_FOLDER: "",
  APP_PLAY_STORE_URL: "",
  APP_APP_STORE_URL: "",
  IPREGISTRY_KEY: "",
};

function useEnv() {
  return useAtomValue<Env>(ENV_KEY, DefaultEnv);
}

export default useEnv;
