import { STATIC_DATA_KEY } from "../../../../core/constants";
import { useAtomState } from "../../../hooks/use-atom";
import { useEffect } from "react";
import usePrefetch from "../../../hooks/use-prefetch";
import useStaticContentService from "../../../hooks/use-static-content-service";

function useStaticCollections () {

  const {service} = useStaticContentService(),
  {hasPrefetchKey} = usePrefetch(), [
    staticData,
    setStaticData,
  ] = useAtomState<StaticData>(STATIC_DATA_KEY, DEFAULT_DATA),
  handleGetStaticData = async () => {
    if (hasPrefetchKey(STATIC_DATA_KEY)) {
      return;
    }

    const { error, response } = await service.getStaticData();

    if (error || !response) {
      return;
    }

    setStaticData(response);
  };

  useEffect(
    () => {handleGetStaticData()},
    [],
  );

  return staticData.curated_list;
}


export default useStaticCollections;

const DEFAULT_DATA: StaticData = {curated_list: [],banners: []};