import { useAtomState } from "./use-atom";
import { useEffect } from "react";

const FIRST_TIME_KEY = "FIRST_TIME";

function useFirstTime() {
  const [firstTime, setFirstTime] =
    useAtomState<boolean>(FIRST_TIME_KEY, true);

  useEffect(
    () => {
      setFirstTime(false);
    },
    [],
  );

  return { firstTime: firstTime };
}

export default useFirstTime;
