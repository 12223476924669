import { useAtomValue } from "./use-atom";
import { TNC_KEY } from "../../core/constants";

function useTnc() {
  const tnc = useAtomValue<StaticContentSection[]>(
    TNC_KEY,
    [],
  );

  return { tnc: tnc };
}

export default useTnc;
