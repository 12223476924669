import { useAtomState } from "./use-atom";
import { useCallback, useMemo } from "react";

function usePrefetch(prefetch_keys: string[] = []) {
  const [keys, setKeys] = useAtomState<string[]>(PREFETCH_KEY, prefetch_keys);

  const set = useMemo(() => new Set(keys), [keys]);

  const hasPrefetchKey = useCallback(
    (key: string) => {
      return set.has(key);
    },
    [set],
  );

  const removePrefetch = useCallback(
    (key: string) => {
      setKeys(
        keys => {
          const updated = [...keys];

          const indexToRemove = updated.findIndex(each => each === key);

          if (indexToRemove < 0) {
            return keys;
          }

          updated.splice(indexToRemove, 1);

          return updated;
        },
      )
    },
    [setKeys],
  );

  return {
    hasPrefetchKey: hasPrefetchKey,
    removePrefetch: removePrefetch,
  };
}

export default usePrefetch;

export const PREFETCH_KEY = "PREFETCH";
