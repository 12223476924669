import React from "react";
import useClx from "../../../hooks/use-clx";
import Img from "../../Img";
import clxs from "./banner.module.css";
import EXPERIENCE_BACKGROUND_DESKTOP from "../../../assets/experience-background-desktop.jpg";
import EXPERIENCE_BACKGROUND_MOBILE from "../../../assets/experience-background-mobile.jpg";

interface BannerProps {
  className?: string;
}

function Banner(props: BannerProps) {
  const { className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <Img
        src={EXPERIENCE_BACKGROUND_DESKTOP}
        alt="background"
        className={clxs.background}
        mobileSrc={EXPERIENCE_BACKGROUND_MOBILE}
        desktopSrc={EXPERIENCE_BACKGROUND_DESKTOP}
      />
      <h1 className={clxs.title}>
        The Lohono Life
      </h1>
      <div className={clxs.description}>
        At Lohono, we always strive to embrace the highest benchmarks to deliver above and beyond your expectations. We
        offer you a curation of bespoke homes integrated with our exceptional hospitality team, to give you a luxurious
        and truly distinct experience. From taking care of your special dietary needs to planning an unforgettable
        celebration for your loved ones, we can do it all.
      </div>
    </div>
  );
}

export default Banner;
