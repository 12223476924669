import { useAtomValue } from "./use-atom";
import { SITEMAP_KEY } from "../../core/constants";

function useSitemap() {
  const sitemap = useAtomValue<SitemapPartial[]>(
    SITEMAP_KEY,
    [],
  );

  return { sitemap: sitemap };
}

export default useSitemap;
