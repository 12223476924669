import React, { useMemo } from "react";
import clxs from "./additional-details.module.css";
import Form from "../../Form";
import Button from "../../Button";
import TextField from "../../Form/Fields/TextField";
import ADDITIONAL_DETAILS_SCHEMA from "./additional-details-schema"

interface AdditionalDetailsProps {
  fullName?: string;
  email?: string;
  onSubmit: (value: any) => void;
}

function AdditionalDetails(props: AdditionalDetailsProps) {
  const { email, fullName, onSubmit } = props;

  const defaultValues = useMemo(
    () => ({
      full_name: fullName || "",
      email: email || "",
    }),
    [email, fullName],
  );

  return (
    <>
      <div className={clxs.title}>
        Finish signing up
        <div className={clxs.subTitle}>
          {"Let's get to know each other better"}
        </div>
      </div>
      <Form
        id={FORM_ID}
        defaultValue={defaultValues}
        onSubmit={onSubmit}
        className={clxs.formContainer}
        validationSchema={ADDITIONAL_DETAILS_SCHEMA}
      >
        <TextField
          form={FORM_ID}
          name="full_name"
          placeholder="Full Name*"
          className={clxs.fullName}
        />
        <TextField
          form={FORM_ID}
          name="email"
          className={clxs.email}
          placeholder="Email Address*"
        />
        <Button
          form={FORM_ID}
          className={clxs.continueButton}
          type="submit"
        >
          Continue
        </Button>
      </Form>
    </>
  );
}

export default AdditionalDetails;

const FORM_ID = "user-additional-details";
