import React, { useEffect, useMemo } from "react";
import format from "date-fns/format";
import usePathParams from "../../hooks/use-path-params";
import NotFound from "../NotFound";
import useReservation from "../../hooks/use-reservation";
import clxs from "./confirmation.module.css";
import GREEN_CHECKMARK_ICON from "../../assets/icons/green-checkmark.svg";
import { useStorageNumber } from "../../hooks/use-storage";
import useAnalytics from "../../hooks/use-analytics";

function Confirmation() {
  const reservationSlug = usePathParams(PATH, "reservation_slug"),
    { reservation } = useReservation(reservationSlug),
    { track } = useAnalytics(),
    {value: cid} = useStorageNumber("localStorage","customer_id"),
    thankYou = useMemo(
      () => {
        if (!reservation) {
          return "";
        }

        return `Thank you, ${reservation.name}`;
      },
      [reservation],
    ),
    description = useMemo(
      () => {
        if (!reservation) {
          return null;
        }

        const {
            slug,
            property_name,
            booked_at_date,
            paid_amount,
            currency_symbol,
          } = reservation,
          bookedDate = format(
            new Date(booked_at_date),
            "do MMM, yyyy hh:mm a",
          );

        return (
          <>
            {"Your payment of "}<b>{`${currency_symbol} ${paid_amount.toLocaleString("en-IN")}`}</b>{" for "}
            <b>{property_name}</b>{" at "}<b>{bookedDate}</b>{" was successful."}
            <br/>
            {"Your booking confirmation number is "}
            <b>{`#${slug}`}</b>.
          </>
        );
      },
      [reservation],
    ),
    handlePaymentSuccess = () => {
      track(
        "checkout_payment_successful",
        {
          cid,
          reservation_slug: reservationSlug,
        },
      );
    };

    useEffect(
      () => {
        handlePaymentSuccess()
      },
      [],
    );

  if (!reservationSlug) {
    return <NotFound/>;
  }

  if (!reservation) {
    return null;
  }

  return (
    <div className={clxs.container}>
      <img
        src={GREEN_CHECKMARK_ICON}
        alt="success"
        className={clxs.checkmark}
      />
      <div className={clxs.thankYou}>
        {thankYou}
      </div>
      <div className={clxs.message}>
        Your booking has been confirmed.
      </div>
      <p className={clxs.description}>
        {description}
      </p>
    </div>
  );
}

export default Confirmation;

const PATH = "/confirmation/:reservation_slug";

