import { useMemo } from "react";
import useIsMobile from "../../hooks/use-is-mobile";
import { useQueryParamValue } from "../../hooks/use-query-param";

function useIsHeaderHidden (
  page?: string,
  pathname?: string,
): boolean {
  const isEmbedded = useQueryParamValue("boolean","embedded", false);

  const { isMobile } = useIsMobile();

  const isHidden = useMemo(
    () => {
      if (isEmbedded) {
        return true;
      }

      const device = isMobile ? "mobile" : "desktop";

      const devicePathSubject = `${device}:${page ?? ""}`;

      const allPathSubject = `*:${page ?? ""}`;

      if (
        HIDDEN_CONFIG.has(devicePathSubject)
        || HIDDEN_CONFIG.has(allPathSubject)
      ) {
        return true;
      }

      const devicePathnameSubject = `${device}:${pathname ?? ""}`;

      const allPathnameSubject = `*:${pathname ?? ""}`;

      return (
        HIDDEN_CONFIG.has(devicePathnameSubject)
        || HIDDEN_CONFIG.has(allPathnameSubject)
      );
    },
    [isEmbedded, isMobile, page, pathname],
  );

  return isHidden;
}

export default useIsHeaderHidden;

const HIDDEN_CONFIG = new Set<string>([
  "*:CHECKOUT",
  "*:HOMEOWNER",
  "*:LB_ABOUT",
  "*:FEEDBACK",
  "*:SEND_REQUEST",
  "*:LOCATIONS",
  "mobile:PROPERTY_LISTING",
  "mobile:COLLECTION_LISTING",
  "*:/villas/india/karjat/casa-riviere",
  "*:DELETE_ACCOUNT_VERIFY_OTP",
  "*:DELETE_ACCOUNT",
  "*:DELETE_ACCOUNT_SUCCESS",
  "mobile:PROFILE",
]);
