import { useCallback, useEffect } from "react";

function useDisableScroll (
  disable: boolean,
) {
  const handleScroll = useCallback(
    (disable: boolean) => {
      if (!disable) {
        return;
      }

      const topScroll = document.documentElement.scrollTop,
        leftScroll = document.documentElement.scrollLeft;

        window.onscroll = () => window.scrollTo(leftScroll, topScroll);

        return () => {
          window.onscroll = null;
        };
    },
    [],
  );

  useEffect(
    () => handleScroll(disable),
    [handleScroll, disable],
  );
}

export default useDisableScroll;
