import { useAtomState } from "./use-atom";

class _DefaultSeoService implements ISeoService {
  async getSeo(): Promise<FetchResult<Seo>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getUtmMobileNumber(): Promise<FetchResult<UtmCampaignMobile>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }
}

const DefaultSeoService = new _DefaultSeoService();

export const SEO_SERVICE_KEY = "SEO_SERVICE";

function useSeoService() {
  const [service, setService] =
    useAtomState<ISeoService>(
      SEO_SERVICE_KEY,
      DefaultSeoService,
    );

  return {
    service: service,
    setService: setService,
  };
}

export default useSeoService;
