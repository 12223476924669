import React, {useMemo} from "react";
import { useLocation } from "react-router-dom";
import { PRESS_CATALOGUE_TEMPLATE } from "../../../core/press";
import useEnv from "../../hooks/use-env";
import clxs from "./press-detail.module.css";
import CALLIGRAPHY from "../../assets/lohono-calligraphy.svg";

function PressDetail() {
  const {REMOTE_PUBLIC_ASSET_FOLDER} = useEnv(),
    {pathname} = useLocation(),
    press = useMemo(
      () =>
        PRESS_CATALOGUE_TEMPLATE
          .find(each => each.href === pathname),
      [pathname],
    );

  if (!press) {
    return null;
  }

  const {title, assets} = press;

  return (
    <div className={clxs.container}>
      <img
        loading="lazy"
        src={CALLIGRAPHY}
        alt="lohono"
        className={clxs.calligraphy}
      />
      <div className={clxs.title}>
        {title}
        <div className={clxs.decorate}>&nbsp;</div>
      </div>
      {assets.map((each, i) => {
        const { file, type } = each,
          fileSrc = `${REMOTE_PUBLIC_ASSET_FOLDER}${file}`;

        if (type === "pdf") {
          return (
            <div key={i}>
              <iframe
                src={fileSrc}
                className={clxs.pdf}
              >
                <a href={file}>Link</a>
              </iframe>
            </div>
          );
        }

        return (
          <img
            loading="lazy"
            key={i}
            src={fileSrc}
            alt={title}
            className={clxs.image}
          />
        );
      })}
    </div>
  );
}

export default PressDetail;
