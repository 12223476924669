import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import CLOSE_ICON from "../../../../assets/icons/close-room-details.svg";
import Carousel from "../../../Carousel";
import Item from "../../../Carousel/Item";
import useClx from "../../../../hooks/use-clx";
import ScrollCrumb from "../../../Home/PropertyCarousel/ScrollCrumb";
import clxs from "./room-details.module.css";

interface RoomDetailsProps {
  open?: boolean;
  onSubmit?: (out: any) => void;
  roomDetails?: RoomDetails;
}

function RoomDetails(props: RoomDetailsProps) {
  const { onSubmit, roomDetails, open } = props,
    cscx = useClx(clxs.carousel, "carousel"),
    [previewImage, setPreviewImage] = useState<PropertyImage>(
      () => {
        if (!roomDetails?.image_urls.length) {
          return DEFAULT_PREVIEW_IMAGE;
        }

        return roomDetails.image_urls[0];
      },
    ),
    handlePreviewImage = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        if (!roomDetails) {
          return;
        }

        const { image_urls } = roomDetails;

        const target = e.target as HTMLElement;

        const index = target.getAttribute("data-key");

        if (!index) {
          return;
        }

        const parsedIndex = Number.parseInt(index);

        setPreviewImage(image_urls[parsedIndex]);
      },
      [roomDetails],
    ),
  handleDefaultPreviewImage = useCallback(
    (open?: boolean) => {
      if (!roomDetails || !open) {
        return;
      }

      setPreviewImage(roomDetails.image_urls[0] ?? DEFAULT_PREVIEW_IMAGE);
    },
    [setPreviewImage, roomDetails],
  );

  useEffect(
    () => handleDefaultPreviewImage(open),
    [handleDefaultPreviewImage, open],
  );

  if (!roomDetails) {
    return null;
  }

  const {
    name,
    bed_type,
    room_occupancy,
    mainAmenities,
    otherAmenities,
    image_urls,
    title,
    description,
  } = roomDetails,

  topAmenities = mainAmenities.slice(0, 3);

  return (
    <div className={clxs.container}>
      <img
        src={CLOSE_ICON}
        alt="close"
        className={clxs.close}
        onClick={onSubmit?.bind(null, null)}
      />
      <hr className={clxs.divider} />
      <img
        src={previewImage.url}
        alt={previewImage.alt}
        className={clxs.previewImage}
      />
      <div className={clxs.imageContainer}>
        {image_urls.map((each, key) => {
          const { url, alt } = each,
            isSelected = url === previewImage.url;

          return (
            <img
              key={key}
              onClick={handlePreviewImage}
              src={url}
              alt={alt}
              data-key={key}
              data-active={isSelected}
              className={clxs.thumbnail}
            />
          );
        })}
      </div>
      <div className={clxs.textContainer}>
        <div className={clxs.titleContainer}>
          <div className={clxs.title}>
            {name}
          </div>
          <div className={clxs.configSize}>
            {`Fits ${room_occupancy} (${bed_type})`}
          </div>
        </div>
        <div className={clxs.mainAmenitiesContainer}>
          {topAmenities.map((each, key) => {
            return (
              <div
                key={key}
                className={clxs.mainAmenities}
              >
                {each.name}
              </div>
            );
          })}
        </div>
        <div className={clxs.imageTitle}>
          Images
        </div>
        <div className={clxs.carouselContainer}>
          <Carousel
            className={cscx}
            id={CAROUSEL_ID}
            threshold={0.98}
          >
            {image_urls.map((each, key) => {
              const {
                url,
                alt,
              } = each;
              return (
                <Item
                  className={clxs.item}
                  key={key}
                >
                  <img
                    onClick={handlePreviewImage}
                    src={url}
                    alt={alt}
                    className={clxs.imgSelect}
                  />
                </Item>
              );
            })}
          </Carousel>
          <ScrollCrumb
            carouselId={CAROUSEL_ID}
            count={image_urls.length - 1}
            className={clxs.scrollCrumb}
          />
        </div>
        <div className={clxs.title}>
          {title}
        </div>
        <div className={clxs.bedType}>
          {bed_type}
        </div>
        <div className={clxs.description}>
          {description}
        </div>
        <div className={clxs.title}>
          Room Amenities
        </div>
        <div className={clxs.otherAmenitiesContainer}>
          {otherAmenities.map((each, key) => {
            return (
              <div
                key={key}
                className={clxs.otherAmenities}
              >
                <img
                  className={clxs.icon}
                  src={each.icon}
                  alt={each.name}
                />
                {each.name}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default RoomDetails;

const CAROUSEL_ID = "room-details-image";

const DEFAULT_PREVIEW_IMAGE = {
  alt: "",
  url: "",
  caption: "",
  aspect_ratio: "",
};
