import PEOPLE_ICON from "../assets/icons/agent-people.svg";
import HANDSHAKE_ICON from "../assets/icons/agent-handshake.svg";
import PRICE_TAG_ICON from "../assets/icons/agent-price-tag.svg";
import MEDALLION_ICON from "../assets/icons/agent-medallion.svg";

const PARTNER_INCENTIVES_TEMPLATE = [
  {
    icon: PEOPLE_ICON,
    title: "Personal Attention",
  },
  {
    icon: HANDSHAKE_ICON,
    title: "Hassle- free & respected partnership",
  },
  {
    icon: PRICE_TAG_ICON,
    title: "Exclusive discounts and offers",
  },
  {
    icon: MEDALLION_ICON,
    title: "Appreciation and rewards",
  },
];

const PARTNER_BENEFITS_TEMPLATE = [
  {
    title: "Preferential rates",
    points: [
      "Preferential rates: rates are much lower than offered to any direct customers",
      "Special offers for partners",
      "Regular updates of all the new offers and rate changes",
    ],
  },
  {
    title: "Planned Itinerary",
    points: [
      "Planned Itinerary for guests as per requirements",
      "One stop for domestic & international locations",
    ],
  },
  {
    title: "Single point of contact",
    points: [
      "Single point of contact for all your queries",
      "Transparent policies",
      "Reservation desk for all guest requirements",
      "Consistent service to enhance guest experience",
    ],
  },
];

const PARTNER_PROPERTY_TEMPLATE = [
  {
    thumbnail: "https://ik.imagekit.io/ispravahospitality/rental_property/isp-igreha-vaddo-villa-b/holding.jpg?tr=w-800,ar-16-9,c-at_least",
    title: "Igreha Vaddo - Villa B",
    subtitle: "Goa, India",
    feature: "Nat Geo Traveller",
  },
  {
    thumbnail: "https://ik.imagekit.io/ispravahospitality/rental_property/isp-villa-vivre/Villa-Vivre-1.jpg?tr=w-800,ar-16-9,c-at_least",
    title: "Villa Vivre",
    subtitle: "Assagaon, Goa, India",
    feature: "Brown Paper Bag",
  },
  {
    thumbnail: "https://ik.imagekit.io/ispravahospitality/rental_property/isp-villa-loto-bianco/balcony_2.jpg?tr=w-800,ar-16-9,c-at_least",
    title: "Villa Loto Bianco",
    subtitle: "Siolim, Goa, India",
    feature: "Brown Paper Bag",
  },
  {
    thumbnail: "https://ik.imagekit.io/ispravahospitality/rental_property/isp-villa-evora/holding.jpg?tr=w-800,ar-16-9,c-at_least",
    title: "Villa Evora",
    subtitle: "Assagaon, Goa, India",
    feature: "Conde Nast Traveler",
  },
];

export {
  PARTNER_INCENTIVES_TEMPLATE,
  PARTNER_BENEFITS_TEMPLATE,
  PARTNER_PROPERTY_TEMPLATE,
};
