import memoize from "lodash.memoize";

const createResponsiveSrc = memoize(
  (
    src: string,
    desktopQuality?: number,
    mobileQuality?: number,
    desktopDimensions?: [number, number],
    mobileDimensions?: [number, number],
  ) => {
    const { URL } = global,
      original = new URL(src),
      partials = original.pathname.split("/"),
      mobilePathname = "/" + [
        ...partials.slice(0, 1),
        mobileQuality !== undefined && `filters:quality(${mobileQuality})`,
        mobileDimensions !== undefined && mobileDimensions.join("x"),
        ...partials.slice(1),
      ].filter(Boolean).join("/"),
      desktopPathname = "/" + [
        ...partials.slice(0, 1),
        desktopQuality !== undefined && `filters:quality(${desktopQuality})`,
        desktopDimensions !== undefined && desktopDimensions.join("x"),
        ...partials.slice(1),
      ].filter(Boolean).join("/"),
      mobile = new URL(mobilePathname, original),
      desktop = new URL(desktopPathname, original);

    return {
      mobile: mobile.toString(),
      desktop: desktop.toString(),
    };
  },
  (src, dq, mq, dd, md) => [src, dq, mq, dd, md].filter(Boolean).join("#"),
);

export default createResponsiveSrc;
