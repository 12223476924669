import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

function useQuerySearch (): [QuerySearch, SetterQuerySearch] {
  const [search, setSearchParams] = useSearchParams();

  const shared = useMemo(
    () => {
      const s: QuerySearch = {};

      const qp = new URLSearchParams(search);

      for (const key of qp.keys()) {
        const values = qp.getAll(key);

        if (!values.length) {
          continue;
        }

        s[key] = values;
      }

      return s;
    },
    [search],
  );

  const setSearch: SetterQuerySearch = useCallback(
    (cb, options) => {
      const updated = cb(shared);

      const qp = new URLSearchParams();

      for (const key in updated) {
        const values = updated[key];

        if (Array.isArray(values)) {
          values.forEach(
            value => {
              if (value === null || value === undefined) {
                return;
              }

              qp.append(key, value);
            },
          );

          continue;
        }

        if (values === null || values === undefined) {
          continue;
        }

        qp.set(key, values);
      }

      setSearchParams(qp, options);
    },
    [setSearchParams, shared],
  );

  return [shared, setSearch];
}

export default useQuerySearch;

export type QuerySearch = Record<string, string[]>;

export type SetterQuerySearch = (
  cb: (search: QuerySearch) => QuerySearch,
  options?: NavigationOptions,
) => void;

type NavigationOptions = {
  replace?: boolean | undefined;
  state?: any;
};
