import React from "react";
import useClx from "../../hooks/use-clx";
import useLoyaltyPointsBalance from "../../hooks/use-loyalty-points-balance";
import clxs from "./point-tag.module.css";
import LLP_STAR from "../../assets/icons/loyalty-point-star-light.svg";

interface PointTagProps {
  className?: string;
}

function PointTag (props: PointTagProps) {
  const { className } = props;

  const ccx = useClx(clxs.container, className);

  const { friendly } = useLoyaltyPointsBalance();

  return (
    <div className={ccx}>
      <img
        src={LLP_STAR}
        alt="star"
      />
      {friendly}
    </div>
  );
}

export default PointTag;

