import React from "react";
import useClx from "../../../hooks/use-clx";
// import MomentItem from "./MomentItem";
import clxs from "./moment.module.css";
import Item from "../../Carousel/Item";
import MOMENT_TEMPLATE from "./moment-template";
import Scroller from "../../Scroller";
import Carousel from "../../Carousel";
import Img from "../../Img";
import { useCarouselScroller } from "../../../hooks/use-carousel";

interface MomentProps {
  className?: string;
}

function Moment(props: MomentProps) {
  const { className } = props,
  { scrollLeft, scrollRight } = useCarouselScroller(CAROUSEL_ID),
    ccx = useClx(clxs.container, className),
    p1cx = useClx(clxs.partial1, "partial1"),
    scx = useClx(clxs.section, className);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Capture Picture-Perfect Memories
      </div>
      <div className={clxs.subtitle}>
        {SUBTITLE}
      </div>
      {/* <div className={clxs.moments}>
        {MOMENT_TEMPLATE.map(
          (each, key) => (
            <MomentItem
              key={key}
              title={each.title}
              thumbnail={each.thumbnail}
              links={each.links}
            />
          ),
        )}
      </div> */}
      <div className={clxs.eventMoments}>
      { MOMENT_TEMPLATE.length == 3 ? null :
      <Scroller
        className={clxs.scroller}
        onScrollLeft={scrollLeft}
        onScrollRight={scrollRight}
      /> }
      <Carousel
        id={CAROUSEL_ID}
        className={scx}
        threshold={0.9}
        adjust={true}
      >
        {MOMENT_TEMPLATE.map(
          (each, i) => {
            const {
              name,
              desktop_banner_image,
              mobile_banner_image,
            } = each;

            return (
            <Item
              key={i}
              className={clxs.item}
            >
              <div className={clxs.momentContainer}>
                <div className={p1cx}>
                  <Img
                    alt={`Occasion ${name}`}
                    src={desktop_banner_image}
                    mobileSrc={mobile_banner_image}
                    desktopSrc={desktop_banner_image}
                    className={clxs.momentThumbnail}
                  />
                  <div className={clxs.partial1Title}>
                    {name}
                  </div> 
                </div>
              </div>
              </Item>
            );
          })
        }
      </Carousel>
      </div> 
    </div>
  );
}

export default Moment;

const CAROUSEL_ID = "event-moment";

const SUBTITLE = "From intimate gatherings to larger than life affairs, Lohono weaves an enchanting narrative that leaves lifelong memories.";
