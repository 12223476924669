import React from "react";
import { PRESS_CATALOGUE_TEMPLATE, PRESS_FEATURED_TEMPLATE } from "../../../core/press";
import { useCarouselScroller } from "../../hooks/use-carousel";
import useEnv from "../../hooks/use-env";
import Carousel from "../Carousel";
import Item from "../Carousel/Item";
import Scroller from "../Scroller";
import clxs from "./press-listing.module.css";

function PressListing () {
  const { REMOTE_PUBLIC_ASSET_FOLDER } = useEnv(),
    { scrollLeft, scrollRight } = useCarouselScroller(CAROUSEL_ID);

  return (
    <>
      <Carousel
        id={CAROUSEL_ID}
        className={clxs.carousel}
        threshold={0.9}
      >
        {PRESS_FEATURED_TEMPLATE.map(
          (each, i) => {
            const { title, banner, href, publication } = each,
              bannerSrc = `${REMOTE_PUBLIC_ASSET_FOLDER}${banner}`;

            return (
              <Item
                className={clxs.feature}
                key={i}
              >
                <img
                  loading="lazy"
                  src={bannerSrc}
                  alt={title}
                  className={clxs.featureImage}
                />
                <div className={clxs.featureGradient}>
                  &nbsp;
                </div>
                <div className={clxs.featureTitle}>
                  {title}
                </div>
                <a
                  href={href}
                  target="_blank"
                  rel="noreferrer noopener"
                  className={clxs.readMore}
                >
                  Read More
                </a>
                <div className={clxs.featureSubtitle}>
                  By<b>{publication}</b>
                </div>
              </Item>
            );
          },
        )}
      </Carousel>
      <Scroller
        onScrollLeft={scrollLeft}
        onScrollRight={scrollRight}
        className={clxs.scroller}
      />
      <h1 className={clxs.intro}>
        Our homes have been reviewed by a number of well-known and credible magazines and critics alike. We are proud to
        have been covered by some of the world’s leading luxury and travel publications.
      </h1>
      <div className={clxs.catalogue}>
        {PRESS_CATALOGUE_TEMPLATE.map(
          (each, i) => {
            const { title, banner, href, publication } = each,
              bannerSrc = `${REMOTE_PUBLIC_ASSET_FOLDER}${banner}`;

            return (
              <a
                key={i}
                href={href}
                target="_blank"
                rel="noreferrer noopener"
                className={clxs.publish}
              >
                <img
                  loading="lazy"
                  src={bannerSrc}
                  alt="banner"
                  className={clxs.thumbnail}
                />
                <div className={clxs.publisher}>
                  By <b>{publication}</b>
                </div>
                <div className={clxs.title}>{title}</div>
              </a>
            );
          },
        )}
      </div>
    </>
  );
}

export default PressListing;


const CAROUSEL_ID = "press-feature";