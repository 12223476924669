function urlSearchParamsFromAny (params: Any) {
  const qp = new URLSearchParams(
    Object.entries(params)
      .reduce(
        (compiled, entry) => {
          const [key, value] = entry;

          if (!value) {
            return compiled;
          }

          if (value instanceof Date) {
            compiled[key] = value.toISOString();

            return compiled;
          }

          compiled[key] = value.toString();

          return compiled;
        },
        {} as Record<string, string>,
      ),
  );

  return qp;
}

export default urlSearchParamsFromAny;

type SearchValue = string | Date | number | null;

type Any = Record<string, SearchValue>;
