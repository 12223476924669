import add from "date-fns/add";
import endOfMonth from "date-fns/endOfMonth";
import fmt from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import sub from "date-fns/sub";
import { utcEquivalent } from "../../../../../utils/date-tz";
import {
  calculateEndOffset,
  calculateMonthDayType,
  calculateSelectionType,
  calculateStartOffset,
} from "./calendar-month-helpers";

function calculateCalendarTemplate(
  monthDate: Date,
  startDate: Date | null,
  endDate: Date | null,
  onDateDecorate: DateDecorator = DEFAULT_DAY_DECORATOR,
) {
  const today = utcEquivalent(startOfDay(new Date())),
    // [isStartOnlyDateSelected, setStartOnlyDateSelected] = useState<boolean>(false),
    monthStart = utcEquivalent(startOfMonth(monthDate)),
    monthEnd = utcEquivalent(startOfDay(endOfMonth(monthDate))),
    noOfDays = monthEnd.getDate(),
    startOffset = calculateStartOffset(
      monthStart,
      monthEnd,
      startDate,
      endDate,
    ),
    endOffset = calculateEndOffset(
      monthStart, 
      monthEnd,
      startDate,
      endDate,
    ),
    isStartContinued = startDate ? isBefore(startDate, monthStart) : false,
    isEndContinued = endDate ? isAfter(endDate, monthEnd) : false,
    monthName = fmt(monthEnd, "MMMM yyyy"),
    gap = sub(monthEnd, { days: (noOfDays % 7) - 1 }).getDay(),
    isMonthInPast = isAfter(today, monthEnd),
    isMonthInFuture = isBefore(today, monthStart),
    createDate = (offset: number) => {
      const d = add(monthStart, { days: offset });

      return d.toISOString();
    },
    isSunday = (offset: number) => {
      return (offset + gap + 1) % 7 === 1;
    },
    isSaturday = (offset: number) => {
      return (offset + gap + 1) % 7 === 0;
    },
    calculateTense = (offset: number) => {
      if (isMonthInPast) {
        return "past";
      }

      if (isMonthInFuture) {
        return "future";
      }

      const day = today.getDate() - 1;

      if (offset < day) {
        return "past";
      }

      if (offset > day) {
        return "future";
      }

      return "present";
    };

  /* This code snippet is creating an array called `template` using `Array.from` method. It creates an
  array with a length of `noOfDays` (number of days in the month) and then maps over each element in
  the array to generate an object for each day in the month. */
  const template = Array.from(
    { length: noOfDays }, 
    (_, offset) => {
    const date = offset + 1,
      fullDate = createDate(offset),
      weekendType = isSunday(offset) ? "start"
        : isSaturday(offset) ? "end"
        : "none",
      monthDayType = calculateMonthDayType(noOfDays, offset),
      selection = calculateSelectionType(
        startOffset,
        endOffset,
        offset,
        isStartContinued,
        isEndContinued,
      ),
      tense = calculateTense(offset),
      customAttributes = onDateDecorate(monthStart, offset, startDate);

    return {
      date: date,
      fullDate: fullDate,
      weekendType: weekendType,
      monthDayType: monthDayType,
      selection: selection,
      tense: tense,
      customAttributes: customAttributes,
    };
  });

  //Moved the sandwich case handling to the onDateDecorate function. Hence this code is commented.
  /**
   * The function `handleSandwichCase` filters a template based on start and end offsets, then updates
   * certain custom attributes of the filtered object.
   * Adding a new attribute "data-only-checkout" as true for only checkout dates and making sold out as false to enable the date.
   */
  // const handleSandwichCase = () => {
  //   // This is to handle the sandwich case in the middle of the month.
  //   if (((startOffset >= 0) && (endOffset < 0)) || ((startOffset >= 0) && (endOffset >= 0))) {
  //     const templateToFilter = template.slice(startOffset);
  //     const onlyCheckoutObj = templateToFilter.find(oTemplate => oTemplate.customAttributes["data-sold-out"])
  //     if (onlyCheckoutObj) {
  //       onlyCheckoutObj.customAttributes["data-sold-out"] = false;
  //       onlyCheckoutObj.customAttributes["data-selectable"] = true;
  //       onlyCheckoutObj.customAttributes["data-only-checkout"] = true;
  //     }
  //   }
  //   // This is to handle the sandwich case in the end of the month. If previous month has last date as checkin and first day of this month is only sold out.
  //   if ((startDate && isStartContinued)) {
  //     const onlyCheckoutObj = template.find(oTemplate => oTemplate.customAttributes["data-sold-out"]);
  //     if (onlyCheckoutObj) {
  //       onlyCheckoutObj.customAttributes["data-sold-out"] = false;
  //       onlyCheckoutObj.customAttributes["data-selectable"] = true;
  //       onlyCheckoutObj.customAttributes["data-only-checkout"] = true;
  //     }
  //   }
  // };

  // handleSandwichCase();

  return {
    monthName: monthName,
    gap: gap,
    template: template,
  };
}

export default calculateCalendarTemplate;

const DEFAULT_DAY_DECORATOR: DateDecorator = () => ({});

export type DateDecorator = (
  monthStart: Date,
  dateOffset: number,
  startDate?: any,
) => Attributes;

type Attributes = Record<string, string | number | boolean>;
