import React, { useMemo, useState } from "react";
import useClx from "../../../hooks/use-clx";
import { Track } from "../../AnalyticsManager";
import ScrollDown from "../../ScrollDown";
import NetworkImg from "../../NetworkImg";
import clxs from "./property-banner.module.css";
// import ISPRAVA_LOGO from "../../../assets/logo/isprava-built.png";
import PARTNER_HOME_ICON from "../../../assets/partner-home-handshake.svg";
import useIsMobile from "../../../hooks/use-is-mobile";

interface PropertyBannerProps {
  className?: string;
  banner: PropertyImage;
  name: string;
  address?: string;
  builder?: string;
  onViewPhotos: () => void;
  videoAnchorId?: string;
  showVideoLink?: boolean;
  onShare: () => Promise<boolean>;
}

function PropertyBanner(props: PropertyBannerProps) {
  const [shareText, setShareText] = useState<string>("Share"),
    {
      // name,
      // address,
      banner,
      builder,
      className,
      onViewPhotos,
      onShare,
      showVideoLink,
      videoAnchorId = "#",
    } = props,
    { isMobile } = useIsMobile(),
    builderBadge = useMemo(
      () => {
        // if (builder === "isprava") {
        //   return (
        //     <img
        //       src={ISPRAVA_LOGO}
        //       alt="isprava"
        //       className={clxs.ispravaBuilt}
        //     />
        //   );
        // }

        if (builder === "partner") {
          return (
            <div className={clxs.partnerHome}>
              <img
                alt="partner home"
                className={clxs.partnerHomeIcon}
                src={PARTNER_HOME_ICON}
              />
              Partner Home
            </div>
          );
        }

        return null;
      },
      [builder],
    ),
    ccx = useClx(clxs.container, className),
    bncx = useClx(clxs.banner, "banner"),
    bdcx = useClx(clxs.backdrop, "backdrop"),
    vpcx = useClx(clxs.button, "view-photos"),
    shcx = useClx(clxs.button, "share"),
    vvcx = useClx(clxs.button, "view-video"),
    // tcx = useClx(clxs.title, "title"),
    // stcx = useClx(clxs.subtitle, "subtitle"),
    sdcx = useClx(clxs.scroll, "scroll-down"),
    btcx = useClx(((showVideoLink && isMobile) ? clxs.videoActions : clxs.actions), "button-container"),
    handleShare = async () => {
      const result = await onShare();

      if (result) {
        return;
      }

      setShareText("Copied!");

      await new Promise(resolve => setTimeout(resolve, 1500));

      setShareText("Share");
    };

  return (
    <div className={ccx}>
      <NetworkImg
        loading="lazy"
        src={banner.url}
        alt={banner.alt}
        className={bncx}
        title={banner.caption || undefined}
        mobileQuality={25}
        desktopQuality={75}
        onClick={onViewPhotos}
      />
      <div 
        className={bdcx}
        onClick={onViewPhotos}
      >
        &nbsp;
      </div>
      {builderBadge}
      <div className={btcx}>
      {showVideoLink && (
          <Track event="property_detail_view_video_clicked">
            <a
              className={vvcx}
              href={`#${videoAnchorId}`}
            >
              View video
            </a>
          </Track>
        ) }  
        <Track event="property_detail_share_clicked">
          <button
            className={shcx}
            onClick={handleShare}
          >
            {shareText}
          </button>
        </Track>
        <Track event="property_detail_view_photos_clicked">
          <button
            className={vpcx}
            onClick={onViewPhotos}
          >
            View photos
          </button>
        </Track>
      </div>
      <ScrollDown className={sdcx}/>
    </div>
  );
}

export default PropertyBanner;
