import clx from "classnames";
import React, { isValidElement, cloneElement, ReactElement, ReactNode, useMemo } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./discount-alert.module.css";
import OFFER_ICON from "../../assets/icons/offer-icon.svg";

interface DiscountAlertProps {
  message?: string;
  className?: string;
  method?: DiscountAlertType;
  children?: ReactElement;
  showChildRegardless?: boolean;
  hideForCoupon?: boolean;
}

function DiscountAlert (props: DiscountAlertProps) {
  const {
    method,
    message,
    children,
    className,
    showChildRegardless = false,
    hideForCoupon = true,
  } = props;

  const ccx = useClx(clxs.container, className);

  const isHidden = useMemo(
    () => !method || (hideForCoupon && method === "coupon") || !message,
    [method, message],
  );

  if (isHidden) {
    return showChildRegardless ? childWithClassName(children, "standalone") : null;
  }

  return (
    <div
      data-discount-type={method}
      data-single-child={!Boolean(children).valueOf()}
      className={ccx}
    >
      <img
        src={OFFER_ICON}
        alt="checkmark"
        className={clxs.offerIcon}
      />
      {message}
      {childWithClassName(children, "child")}
    </div>
  );
}

export default DiscountAlert;

type DiscountAlertType = "coupon" | "silver" | "gold" | "platinum" | string;

function childWithClassName (children: ReactNode, className: string) {
  if (!isValidElement(children)) {
    return null;
  }

  return cloneElement(
    children,
    {
      ...children.props,
      className: clx(children.props.className, className),
    },
  )
}
