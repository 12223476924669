import React, { useCallback, useMemo, useState, useRef } from "react";
import useClx from "../../../hooks/use-clx";
import Collapse from "../../Collapse";
import clxs from "./house-rule.module.css";
import READ_MORE from "../../../assets/read-more-chevron.svg";
import READ_LESS from "../../../assets/read-less-chevron.svg";

interface HouseRuleProps {
  title: string;
  houseRules: string[];
  maxVisible: number;
  className?: string;
  isSticky?: boolean;
}

function HouseRule(props: HouseRuleProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false),
    {
      className,
      title,
      houseRules,
      maxVisible,
      isSticky,
    } = props;

    const ref = useRef<HTMLUListElement>(null),
    primary = useMemo(
      () => houseRules.slice(0, maxVisible),
      [houseRules, maxVisible],
    ),
    secondary = useMemo(
      () => houseRules.slice(maxVisible),
      [houseRules, maxVisible],
    ),
    ccx = useClx(clxs.container, className),
    tcx = useClx(isSticky ? clxs.stickyContainer : clxs.titleContainer, "title"),
    rmcx = useClx(clxs.readMore, "read-more"),
    cccx = useClx(clxs.collapse, "content"),
    handleToggle = useCallback(
      () => {
        isSticky && ref.current?.scrollIntoView(),
        setCollapsed(collapsed => !collapsed);
      },
      [setCollapsed],
    );

  return (
    <ul 
      className={ccx} 
      ref={ref}
    >
      <div
        className={tcx}
        onClick={handleToggle}
      >
        {title}
        <img
          className={clxs.readMoreIcon}
          src={collapsed ? READ_LESS : READ_MORE}
          alt="read-more"
        />
      </div>
      {primary.map(
        (each, i) => (
          <li key={i}>
            {each}
          </li>
        ),
      )}
      {secondary.length ? (
        <>
          <Collapse
            expanded={collapsed}
            className={cccx}
            outerClassName="collapse-container"
          >
            {secondary.map(
              (each, i) => (
                <li key={i}>
                  {each}
                </li>
              ),
            )}
          </Collapse>
          <div
            className={rmcx}
            onClick={handleToggle}
          >
            {collapsed ? "Read Less" : "Read More"}
          </div>
        </>
      ) : null}
    </ul>
  );
}

export default HouseRule;
