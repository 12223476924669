import React from "react";
import useSendRequest from "../../hooks/use-send-request";
import Button from "../Button";
import SendRequestForm from "../SendRequestForm";
import clxs from "./send-request.module.css";

function SendRequest () {
  const { sendRequest } = useSendRequest();

  const defaultValue = {
    full_name: "",
    phone_ext: "+91",
    phone_no: "",
    email: "",
    interest: "rental",
    location: "goa",
    message: "",
  };

  return (
    <SendRequestForm
      id="send-request-page"
      defaultValue={defaultValue}
      isClearOnSubmit={true}
      onSubmit={sendRequest}
      className={clxs.container}
    >
      <Button
        className={clxs.submit}
        type="submit"
      >
        Submit
      </Button>
    </SendRequestForm>
  );
}

export default SendRequest;
