import { useAtomState } from "./use-atom";
import { useEffect } from "react";
import useReservationService from "./use-reservation-service";
import useWithLoading from "./use-with-loading";

const USER_RESERVATIONS_KEY = "USER_RESERVATIONS";

function useConfirmedReservations (
  session: string,
  reservationStatus: string,
) {
  const { loading, withLoading } = useWithLoading(true);

  const { service } = useReservationService(),
    [reservations, setReservations] =
      useAtomState<ConfirmedReservation[]>(USER_RESERVATIONS_KEY, []),
    handleGetUserReservations = async (
      session: string,
      reservationStatus: string,
    ) => {
      if (!session) {
        setReservations([]);
        return;
      }

      const { error, response } = await service.getConfirmedReservations(
        session,
        reservationStatus,
      );

      if (error || !response) {
        setReservations([]);

        return;
      }

      setReservations(response);
    };

  useEffect(
    () => {
      withLoading(handleGetUserReservations, session, reservationStatus);
    },
    [withLoading, session, reservationStatus],
  );

  return {
    loading: loading,
    reservations: reservations,
  };
}

export default useConfirmedReservations;
