import React, {useMemo} from "react";
import NativePropertyConfig, {
  PropertyConfigProps as NativePropertyConfigProps,
} from "../../../PropertyConfig";
import {useField} from "../../../../hooks/use-form";

interface PropertyConfigProps extends BasePropertyConfigProps {
  form: string;
  name: string;
}

function PropertyConfig(props: PropertyConfigProps) {
  const {
      form,
      ...rest
    } = props,
    { name } = rest,
    {
      value,
      error: err,
      setFieldValue,
    } = useField(form, name),
    error = useMemo(
      () => {
        if (!err) {
          return "";
        }

        if (typeof err === "string") {
          return err;
        }

        const errs = Object.values(err).join(", ");

        return errs;
      },
      [err],
    );

  return (
    <NativePropertyConfig
      {...rest}
      value={value}
      error={error}
      onChange={setFieldValue.bind(null, name)}
    />
  );
}

export default PropertyConfig;

type BasePropertyConfigProps = Omit<NativePropertyConfigProps, "value" | "onChange" | "onBlur" | "error">;
