import { QueryType, QueryValue } from "./query-param";
import useQueryParam from "./use-query-param";

function useSetQueryParam<T extends QueryType> (
  queryType: T,
  key: string,
  initial?: QueryValue<T>,
) {
  const [_, setQueryParam] = useQueryParam(queryType, key, initial);

  return setQueryParam;
}

export default useSetQueryParam;
