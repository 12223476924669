import { useMemo } from "react";
import usePhoneExts from "./use-phone-exts/use-phone-exts";

function useCountryOptions () {
  const phoneExts = usePhoneExts();

  const countryOptions = useMemo(
    () => phoneExts.map(
      each => ({
        label: each.label,
        value: each.code,
      }),
    ),
    [phoneExts],
  );

  return countryOptions;
}

export default useCountryOptions;
