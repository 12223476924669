import { useEffect, useState } from "react";

function usePortalElement (
  className?: string,
  style?: string,
) {
  const [element, setElement] = useState<HTMLDivElement | null>(null),
    handleCreateElement = () => {
      const root = document.body,
        element = document.createElement("div") as HTMLDivElement;

      if (!root) {
        return;
      }

      element.dataset.portal = "true";

      element.classList.toggle("portal");

      root.appendChild(element);

      setElement(element);

      return () => {
        root.removeChild(element);
      };
    },
    handleClassNameChange = (
      element: HTMLDivElement | null,
      className?: string,
    ) => {
      if (!element) {
        return;
      }

      if (!className) {
        return;
      }

      element.classList.toggle(className);

      return () => {
        element.classList.toggle(className);
      };
    },
    handleStyleChange = (
      element: HTMLDivElement | null,
      style?: string,
    ) => {
      if (!element) {
        return;
      }

      element.style.cssText = style ?? "";
    };

  useEffect(handleCreateElement, []);

  useEffect(
    () => handleClassNameChange(element, className),
    [element, className],
  );

  useEffect(
    () => handleStyleChange(element, style),
    [element, style],
  );

  return { element: element };
}

export default usePortalElement;
