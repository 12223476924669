import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import useClx from "../../../hooks/use-clx";
import usePropertyLocations from "../../../hooks/use-property-locations";
import { useStorageDate, useStorageNumber, useStorageString } from "../../../hooks/use-storage";
import Button from "../../Button";
import Form from "../../Form";
import { DateRange, Select, OccupancySelect } from "../../Form/Fields";
import PROPERTY_SEARCH_SCHEMA from "./property-search-schema";
import clxs from "./property-search.module.css";

interface PropertySearchProps {
  className?: string;
  onPropertySearch: (values: any) => void;
}

function PropertySearch(props: PropertySearchProps) {
  const { propertyLocations } = usePropertyLocations(),
    locationOptions = useMemo(
      () => propertyLocations.map(
        each => {
          const { name, slug } = each,
            option = {
              label: name,
              _value: slug,
              value: slug,
            };

          return option;
        },
      ),
      [propertyLocations],
    ), {
      value: destination,
      setValue: setDestination,
    } = useStorageString("sessionStorage", "destination"), {
      value: checkinDate,
      setValue: setCheckinDate,
    } = useStorageDate("sessionStorage", "checkin_date"), {
      value: checkoutDate,
      setValue: setCheckoutDate,
    } = useStorageDate("sessionStorage", "checkout_date"), {
      value: adultCount,
      setValue: setAdultCount,
    } = useStorageNumber("sessionStorage", "adult_count"), {
      value: childCount,
      setValue: setChildCount,
    } = useStorageNumber("sessionStorage", "child_count"),
    formRef = useRef<HTMLFormElement>(null),
    // { handleOpen } = useDialogToggle(dateRef),
    [showDateDialog, setShowDateDialog] = useState<boolean>(false),
    [showOccupancyDialog, setShowOccupancyDialog] = useState<boolean>(false),
    [showSelectDialog, setShowSelectDialog] = useState<boolean>(false),
    [startDate, setStartDate] = useState<Date>(),
    [endDate, setEndDate] = useState<Date>(),
    defaultValues = useMemo(
      () => {
        const defaultValue = {
          destination: destination || DEFAULT_VALUES.destination,
          stay: {
            start: checkinDate || DEFAULT_VALUES.stay.start,
            end: checkoutDate || DEFAULT_VALUES.stay.end,
          },
          occupancy: {
            adult_count: adultCount || DEFAULT_VALUES.occupancy.adult_count,
            child_count: childCount || DEFAULT_VALUES.occupancy.child_count,
          },
        };

        return defaultValue;
      },
      [],
    ),
    { className, onPropertySearch } = props,
    formId = "property-search",
    ccx = useClx(clxs.container, className),
    handleSubmit = (values: any) => {
      const {
        destination,
        stay: {
          start: checkin_date,
          end: checkout_date,
        },
        occupancy: {
          adult_count,
          child_count,
        },
      } = values;

      setDestination(destination);
      setCheckinDate(checkin_date);
      setCheckoutDate(checkout_date);
      setAdultCount(adult_count);
      setChildCount(child_count);

       // Check if both dates are either present or both are not present
       const validDates = (!checkin_date && !checkout_date) || (checkin_date && checkout_date);
      
       if (validDates) {
         return onPropertySearch(values);
       }else{
       setShowDateDialog(true);
       }
    },
    handleDestinationChangeSelect = (e: React.ChangeEvent<any>) => {

      const {target} = e;
      const {value} = target;

      if(value && value.length) {
        if(!startDate){
          setShowDateDialog(true);
        }
  
        if(!endDate){
          setShowDateDialog(true);
        }
      }
    },
    handleDateChangeSelect = (e: ChangeEvent<any>) => {
      const value = e.target.value;
      setStartDate(value?.start);
      setEndDate(value?.end);
      
      const startDate = value?.start;
      const endDate = value?.end;
      if(startDate && endDate) {
        setShowOccupancyDialog(true);
      }
    },
    handleAdultCountDoneClick = () => {
      setShowSelectDialog(true);
      formRef.current?.requestSubmit();
    };

  return (
    <Form
      id={formId}
      defaultValue={defaultValues}
      className={ccx}
      validationSchema={PROPERTY_SEARCH_SCHEMA}
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <div className={clxs.title}>
        Handpicked homes, <br/>
        paired with unparalleled hospitality
      </div>
      <Select
        form={formId}
        name="destination"
        label="Where to next"
        placeholder="Select your Destination"
        defaultValue=""
        options={locationOptions}
        searchable={true}
        isHomeDestination={true}
        className={clxs.destination}
        handlechangeparent={handleDestinationChangeSelect}
        isShowSelect={showSelectDialog}
      />
      <DateRange
        form={formId}
        isShowCalendarDialog={showDateDialog}
        name="stay"
        labels={["Check in", "Check out"]}
        placeholder={["Select Date", "Select Date"]}
        className={clxs.stay}
        onOpenDialog={()=>{
          setShowDateDialog(false);
        }}
        calendarDialogProps={{ monthCount: 2 }}
        handlechangeparent={handleDateChangeSelect}
      />
      <OccupancySelect
        form={formId}
        name="occupancy"
        label="Guests"
        className={clxs.destination}
        isShowOccupancyDialog={showOccupancyDialog}
        maxAdultCount={24}
        maxChildCount={8}
        occupancySuccessText="Apply & Search"
        handleDoneClick={handleAdultCountDoneClick}
      />
      <div className={clxs.searchContainer}>
        <Button
          type="submit"
          className={clxs.search}
        >
          Search
        </Button>
      </div>
    </Form>
  );
}

export default PropertySearch;

const DEFAULT_VALUES = {
  destination: "",
  stay: { start: null, end: null },
  occupancy: { adult_count: 2, child_count: 0 },
};
