import React, { ComponentType, useCallback, useEffect, useRef } from "react";
import useAccountIcon from "../client/hooks/use-account-icon";
import useLogo from "../client/hooks/use-logo";

function withThemeOverride (
  className: string,
  options?: ThemeOverrideOptions,
) {
  function withTheme<P> (
    C: ComponentType<P>,
  ): ComponentType<P> {
    return function ThemeWrapper (props: P) {
      const ref = useRef<any>(null);

      const handleTheme = useCallback(
        (ref: any) => {
          if (ref instanceof HTMLElement) {
            ref.classList.toggle(className);

            return () => {
              ref.classList.toggle(className);
            };
          }

          document.documentElement.classList.toggle(className);

          return () => {
            document.documentElement.classList.toggle(className);
          };
        },
        [],
      );

      const { logoSrc, accountIconSrc, lohonoInfinityLogoSrc } = options ?? {};

      useLogo("BRAND_LOGO",logoSrc);

      useLogo("INFINITY_LOGO", lohonoInfinityLogoSrc);

      useAccountIcon(accountIconSrc);

      useEffect(
        () => handleTheme(ref.current),
        [ref.current],
      );

      return (
        <C
          {...props}
          ref={ref}
        />
      );
    };
  }

  return withTheme;
}

export default withThemeOverride;

type ThemeOverrideOptions = {
  logoSrc?: string;
  accountIconSrc?: string;
  lohonoInfinityLogoSrc?: string;
};
