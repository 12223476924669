import React from "react";
import clxs from "./testimonials.module.css";
import fwclxs from "../../../styles/full-width.module.css";
import FIVE_STAR_RATINGS_ICON from "../../../assets/lohono-corporate/Testimonials/5_ratings_icon.svg";
import RATINGS_ICON from "../../../assets/lohono-corporate/Testimonials/4.8_ratings_icon.svg";
import useClx from "../../../hooks/use-clx";

function Testimonials() {
  const ccx = useClx(fwclxs.containerPadding, clxs.container, fwclxs.container);
  return (
    <div className={ccx}>
      <div className={clxs.title}>Testimonials</div>
      <div className={clxs.testimonials}>
        {TEMPLATE.map((each, key) => {
          const {
            description,
            companyName,
            rating,
            ratingIcon,
          } = each;
          return (
            <div
              className={clxs.testimonialItem}
              key={key}
            >
              <div className={clxs.description}>
                {description}
              </div>
              <hr className={clxs.divider}/>
              <div className={clxs.feedbackContainer}>
                <div className={clxs.companyName}>
                  {companyName}
                </div>
                <div className={clxs.ratingContainer}>
                  <div className={clxs.rating}>
                    {rating}
                  </div>
                  <img
                    src={ratingIcon}
                    alt="ratingIcon"
                    className={clxs.ratingIcon}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Testimonials;

const TEMPLATE = [
  {
    description: "We are so thrilled to share that we had a wonderful experience with Lohono. The staff was very much courteous while hosting us. The food was awesome. The experience of saying in a house boat was beyond our expectations. The requirements. Thank you once again for creating a memorable experience for us.",
    companyName: "OLX Group",
    rating: "5.0",
    ratingIcon: FIVE_STAR_RATINGS_ICON,
  },
  {
    description: "Riyuvan Estate is a very good property. The experience was good. The staff was very courteous, the food was good.",
    companyName: "Satvera India Private Limited ",
    rating: "4.8",
    ratingIcon: RATINGS_ICON,
  },
  {
    description: "Lohono is home for me. Always a great experience. Always a warm homely feeling engulfed by luxury.",
    companyName: "Insomnia Media And Content Services Llp",
    rating: "4.8",
    ratingIcon: RATINGS_ICON,
  },
];