import React from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./scroller.module.css";
import CHEVRON_LEFT_GREY from "../../assets/icons/chevron-left-grey.svg";
import CHEVRON_RIGHT_GREY from "../../assets/icons/chevron-right-grey.svg";

interface ScrollerProps {
  onScrollLeft: () => void;
  onScrollRight: () => void;
  className?: string;
}

function Scroller(props: ScrollerProps) {
  const { className, onScrollLeft, onScrollRight } = props,
    ccx = useClx(clxs.scroller, className);

  return (
    <div className={ccx}>
      <img
        src={CHEVRON_LEFT_GREY}
        alt="scroll-left"
        className={clxs.scrollIcon}
        onClick={onScrollLeft}
      />
      <img
        src={CHEVRON_RIGHT_GREY}
        alt="scroll-right"
        className={clxs.scrollIcon}
        onClick={onScrollRight}
      />
    </div>
  );
}

export default Scroller;
