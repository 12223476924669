import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./specification.module.css";

interface SpecificationProps {
  viewLayout: string;
  bedroomCount: number;
  accommodation: string;
  bathCount: number;
  poolCount: number;
  configs: PropertyConfig[];
  className?: string;
}

function Specification(props: SpecificationProps) {
  const {
      className,
      accommodation,
      bathCount,
      poolCount,
      configs,
      bedroomCount,
      viewLayout,
    } = props,
    bedroomContent = useMemo(
      () => {
        if (viewLayout === "multi_config") {
          return `${bedroomCount} ${bedroomCount > 1 ? "Bedrooms" : "Bedroom"}`;
        }

        const partial = configs.map(each => each.room_name?.split(" ")[0]).join(", ");

        if (partial === "") {
          return "";
        }

        return `${partial} Bedrooms`;
      },
      [bedroomCount, viewLayout, configs],
    ),
    guest_count = useMemo(()=>{
      const guest = accommodation.split(" ");
      const count = parseInt(guest[guest.length - 1], 10); 
      return count;    
    },[accommodation]),
    ccx = useClx(clxs.container, className),
    icx = useClx(clxs.item, "item");

  return (
    <div className={ccx}>
      {accommodation ? (
        <div className={icx}>
          {`${accommodation} ${guest_count > 1 ? "Guests" : "Guset"}`}
        </div>
      ) : null}
      {bedroomContent ? (
        <div className={icx}>
          {bedroomContent}
        </div>
      ) : null}
      {bathCount ? (
        <div className={icx}>
          {`${bathCount} ${bathCount > 1 ? "Bathrooms" : "Bathroom"}`}
        </div>
      ) : null}
      {poolCount ? (
        <div className={icx}>
          {`${poolCount} ${poolCount > 1 ? "Pools" : "Pool"}`}
        </div>
      ) : null}
    </div>
  );
}

export default Specification;
