import usePropertyService from "./use-property-service";
import { useEffect, useState } from "react";
import { DEFAULT_PAGINATION } from "../../utils/parse-pagination";

function usePropertyReviews(
  propertySlug: string,
) {
  const { service } = usePropertyService(),
    [guestReviews, setGuestReviews] = useState<Reviews[]>(DEFAULT_REVIEWS),
    [pagination, setPagination] = useState<Pagination>(DEFAULT_PAGINATION),
    [isLoading, setIsLoading] = useState<boolean>(false),

  handleGetGuestReviews = async (
    propertySlug: string,
    next?: boolean,
  ) => {

    setIsLoading(true);

    if (next && pagination.current_page === pagination.total_pages) {
      return;
    }

    const { error, response, paginate } = await service.getPropertyReviews(
      propertySlug,
      next ? pagination.current_page + 1 : 1,
    );

    setIsLoading(false);

    if (error) {
      console.error(error);
    }

    if (error || !response || !paginate) {
      return;
    }

    setGuestReviews(guestReviews => next ? [
      ...guestReviews,
      ...response,
    ] : response);

    setPagination(paginate);
  },

  getNextPage = async () => {
    return handleGetGuestReviews(
      propertySlug,
      true,
    )
  };

  useEffect(() => {
    handleGetGuestReviews(
      propertySlug,
    );
  }, [propertySlug]);

  return {
    reviews : guestReviews,
    reviewsCount: pagination?.total_records,
    isLoading: isLoading,
    getNextPage: getNextPage,
  };
}

export default usePropertyReviews;

const DEFAULT_REVIEWS: Reviews[] = [];