import React, { useMemo } from "react";
import clxs from "./points-history-item.module.css";
import CALENDAR_ICON from "../../../assets/loyalty-points/llp-calendar.svg";
import { format } from "date-fns";

interface PointHistoryProps {
  pointHistory: LoyaltyPointsHistory;
}

function PointHistoryItem(props: PointHistoryProps) {
  const { pointHistory } = props;

  const {
    calendarContent,
    propertyName,
    villaLocation,
    transactionAmount,
    comment,
    transactionType,
  } = useMemo(
    () => {
      const {
        check_in,
        nights,
        property_name,
        location,
        amount,
        comment,
        transaction_type,
        created_at,
      } = pointHistory;

      const formattedCheckin = format(new Date(check_in), "do LLL");

      const calendarContent = nights === null
        ? `${formattedCheckin}`
        : `${formattedCheckin} - ${nights} ${nights > 1 ? "nights" : "night"}`;

      const propertyName = property_name === null ? "" : property_name;

      const villaLocation = location === null ? "" : location;

      const transactionAmount = transaction_type === "credit"
        ? `+${amount.toLocaleString("en-in")}`
        : `-${amount.toLocaleString("en-in")}`;

      const createdAtFriendly = created_at ? format(new Date(created_at), "do LLL") : "";

      const createdAtContent = [
        transaction_type === "credit" ? "Added on" : "Redeemed on",
        createdAtFriendly,
      ].join(" ");

      return {
        calendarContent: calendarContent,
        propertyName: propertyName || comment,
        villaLocation: villaLocation,
        comment: createdAtContent,
        transactionAmount: transactionAmount,
        transactionType: transaction_type,
      };
    },
    [pointHistory],
  );

  return (
    <div className={clxs.history}>
      <div className={clxs.dateContainer}>
        <img
          src={CALENDAR_ICON}
          alt="calendar"
          className={clxs.calendarIcon}
        />
        {calendarContent}
      </div>
      <div className={clxs.propertyName}>{propertyName}</div>
      <div className={clxs.location}>{villaLocation}</div>
      <div
        className={clxs.amount}
        data-transaction={transactionType}
      >
        {transactionAmount}
      </div>
      <div
        className={clxs.comment}
        data-transaction={transactionType}
      >
        {comment}
      </div>
    </div>
  );
}

export default PointHistoryItem;