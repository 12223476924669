import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./policy-benefits.module.css";
import TierBenefit from "../TierBenefit";

interface PolicyBenefitsProps {
  className?: string;
  titleClassName?: string;
  tiers: LoyaltyPointsTier[];
  currentTier?: string;
  nightsCompleted?: number;
}

function PolicyBenefits (props: PolicyBenefitsProps) {
  const {
    className,
    titleClassName,
    tiers,
    currentTier,
    nightsCompleted,
  } = props;

  const currentTierIndex = useMemo(
    () => tiers.findIndex(each => each.value === currentTier) ?? -1,
    [currentTier],
  );

  const ccx = useClx(clxs.container, className);

  if (currentTierIndex + 1 === tiers.length) {
    return null;
  }

  return (
    <div className={ccx}>
      <div className={titleClassName}>
        Unlock Exclusive Benefits
      </div>
      <div className={clxs.benefitsContainer}>
        {tiers.map(
          (each, tierIndex) => {
            const tierStatus = getTierStatus(currentTierIndex, tierIndex);

            return (
              <TierBenefit
                tier={each}
                key={each.value}
                status={tierStatus}
                nightsCompleted={nightsCompleted}
                className={clxs.tier}
              />
            );
          },
        )}
      </div>
    </div>
  );
}

export default PolicyBenefits;

function getTierStatus (currentTierIndex: number, tierIndex: number) {
  if (tierIndex === currentTierIndex) {
    return "ongoing";
  }

  if (tierIndex < currentTierIndex) {
    return "completed";
  }

  return "pending";
}
