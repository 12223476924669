import usePropertyService from "./use-property-service";
import { useCallback } from "react";

function useRoomDetails() {
  const { service } = usePropertyService(),
    getRoomDetails = useCallback(
      async (
        propertySlug: string,
        configId: string,
      ) => {

        const { error, response } = await service.getRoomDetails(propertySlug, configId);

        if (error || !response) {
          return null;
        }

        return response;
      },
      [service],
    );

  return getRoomDetails;
}

export default useRoomDetails;
