import React, { useMemo } from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./youtube-video.module.css";

interface YoutubeVideoProps {
  youtubeVideoId: string;
  anchorId?: string;
}

function YoutubeVideo (props: YoutubeVideoProps) {
  const { youtubeVideoId, anchorId = "#" } = props,
    ccx = useClx(clxs.container),
    youtubeVideo = useMemo(
      () => {
        return `https://www.youtube.com/embed/${youtubeVideoId}?modestbranding=1&rel=0`;
      },
      [youtubeVideoId],
    );

  return (
    <div className={ccx}>
      <a
        id={anchorId}
        href={`#${anchorId}`}
        className={clxs.anchor}
      >
        &nbsp;
      </a>
      <iframe
        className={clxs.video}
        src={youtubeVideo}
        title="YouTube video player"
        frameBorder="0"
        allowFullScreen={true}
      />
    </div>
  );
}

export default YoutubeVideo;


