import { RefObject, useMemo } from "react";

function useAnchorPosition<T extends HTMLElement> (
  ref: RefObject<T>,
): AnchorPositionReturn {
  const boundingRect = useMemo(
    () => {
      if (typeof window === "undefined") {
        return null;
      }

      if (!ref.current) {
        return null;
      }

      const rect = ref.current.getBoundingClientRect();

      return rect;
    },
    [ref.current],
  );

  const anchorPosition = useMemo(
    () => {
      if (typeof window === "undefined") {
        return "left";
      }

      if (!boundingRect) {
        return "left";
      }

      const { innerWidth } = window,
        leftDelta = boundingRect.left,
        rightDelta = innerWidth - boundingRect.right;

      if (rightDelta < leftDelta) {
        return "right";
      }

      return "left";
    },
    [boundingRect],
  );

  return {
    boundingRect: boundingRect,
    anchorPosition: anchorPosition,
  };
}

export default useAnchorPosition;

type AnchorPositionReturn = {
  anchorPosition: "left" | "right";
  boundingRect: DOMRect | null;
}