const COLLECTIONS_LIST = [
  {
    id: 11,
    name: "Infinity Pool villas",
    description: "Pools blending into the most beautiful views",
    active: true,
    slug: "infinity-pool-villas",
    image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/infinity-pool-villas/Curated_Home_pages_Infinity_Pool.jpg",
    desktop_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/infinity-pool-villas/Curated_Home_pages_Infinity_Pool_Villas.jpg",
    desktop_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/infinity-pool-villas/Curated-isting-page_Infinity-Pool.jpg",
    mobile_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/infinity-pool-villas/Curated_isting_page_Infinity_Pool.jpg",
  },
  {
    id: 5,
    name: "Pet-friendly Villas",
    description: "Bring your furry friends along",
    active: true,
    slug: "pet-friendly-homes",
    image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/pet-friendly-homes/pet-friendly.png",
    desktop_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/pet-friendly-homes/pet-friendly.png",
    desktop_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/pet-friendly-homes/Curated-isting-page_Pet-friendly.jpg",
    mobile_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/pet-friendly-homes/Curated_isting_page_Pet_friendly.jpg",
  },
  {
    id: 14,
    name: "Off-beat Getaway Villas",
    description: "Unique. Undiscovered. Class apart.",
    active: true,
    slug: "off-beat-getaway-villas",
    image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/off-beat-getaway-villas/Curated_Home_pages_Off_beat_.jpg",
    desktop_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/off-beat-getaway-villas/Curated_Home_pages_Off_beat_.jpg",
    desktop_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/off-beat-getaway-villas/Curated-isting-page_Off-beat.jpg",
    mobile_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/off-beat-getaway-villas/Curated_isting_page_Off_beat.jpg",
  },
  {
    id: 15,
    name: "Event-friendly Villas",
    description: "Celebrate occasions, the Lohono way",
    active: true,
    slug: "event-friendly-villas",
    image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/event-friendly-villas/event-friendly.png",
    desktop_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/event-friendly-villas/event-friendly.png",
    desktop_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/event-friendly-villas/Curated-isting-page_Event-friendly.jpg",
    mobile_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/event-friendly-villas/Curated_isting_page_Event_friendly.jpg",
  },
  {
    id: 12,
    name: "Wellness Retreats",
    description: "Stays for your soul",
    active: true,
    slug: "wellness-experience",
    image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/wellness-experience/Curated_Home_pages_Wellness_Retreats.jpg",
    desktop_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/wellness-experience/Curated_Home_pages_Wellness_Retreats.jpg",
    desktop_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/wellness-experience/Curated-isting-page_Wellness-Retreats.jpg",
    mobile_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/wellness-experience/Curated_isting_page_Wellness_Retreats.jpg",
  },
  {
    id: 16,
    name: "Senior Citizen Friendly Villas",
    description: "Comfort and ease for everyone",
    active: true,
    slug: "senior-citizen-friendly-villas",
    image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/senior-citizen-friendly-villas/senior-citizen.png",
    desktop_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/senior-citizen-friendly-villas/senior-citizen.png",
    desktop_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/senior-citizen-friendly-villas/Curated-isting-page_Senior-Citizen.jpg",
    mobile_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/senior-citizen-friendly-villas/Curated_isting_page_Senior_Citizen.jpg",
  },
  {
    id: 17,
    name: "Heritage Homes",
    description: "Timeless elegance at its finest",
    active: true,
    slug: "heritage-homes",
    image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/heritage-homes/Curated_Home_pages_Heritage_Homes.jpg",
    desktop_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/heritage-homes/Curated_Home_pages_Heritage_Homes.jpg",
    desktop_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/heritage-homes/Curated-isting-page_Heritage-Homes.jpg",
    mobile_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/heritage-homes/Curated_isting_page_Heritage_Homes.jpg",
  },
  {
    id: 18,
    name: "1 Bedroom Offerings",
    description: "Don't need an entire villa? You can now book by the room.",
    active: true,
    slug: "1-bedroom-offerings",
    image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/1-bedroom-offerings/Curated_Home_pages_One_Bedroom.jpg",
    desktop_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/1-bedroom-offerings/Curated_Home_pages_One_Bedroom_Offerings.jpg",
    desktop_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/1-bedroom-offerings/Curated-isting-page_One-Bedroom.jpg",
    mobile_banner_image:
      "https://d3oo9u3p09egds.cloudfront.net//rental_highlight/1-bedroom-offerings/Curated_isting_page_One_Bedroom.jpg",
  },
];

export { COLLECTIONS_LIST };
