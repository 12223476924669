import React, { AnchorHTMLAttributes } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./button.module.css";

interface ButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
}

function Button (props: ButtonProps) {
  const { className, children, ...rest } = props;

  const cx = useClx(clxs.button, className);

  return (
    <a
      {...rest}
      className={cx}
    >
      {children}
    </a>
  );
}

export default Button;
