import React, { InputHTMLAttributes, ReactNode, useMemo, useRef } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./switch.module.css";

export interface SwitchProps extends BaseInputProps {
  children?: ReactNode;
}

function Switch (props: SwitchProps) {
  const { className, children, ...rest } = props;

  const ccx = useClx(clxs.label, className);

  const scx = useClx(clxs.switch, "switch");

  const tcx = useClx(clxs.toggle, "toggle");

  const ref = useRef<HTMLInputElement>(null);

  const checked = useMemo(
    () => ref.current?.checked ?? false,
    [ref.current?.checked],
  );

  return (
    <label
      className={ccx}
      data-checked={checked}
    >
      {children}
      <input
        {...rest}
        ref={ref}
        type="checkbox"
        className={clxs.input}
      />
      <div className={scx}>
        <div className={tcx}>
          {checked ? "✓" : "X"}
        </div>
      </div>
    </label>
  );
}

export default Switch;

type BaseInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type">;