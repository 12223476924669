class AnalyticsService implements IAnalyticsService {
  track(payload?: any): void {
    if (typeof window === "undefined") {
      return;
    }

    const { dataLayer } = window;

    if (!dataLayer) {
      return;
    }

    dataLayer.push(payload);
  }
}

export default AnalyticsService;
