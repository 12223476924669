import React from "react";
import useClx from "../../hooks/use-clx";
import Banner from "./Banner";
import Intro from "./Intro";
import Occasion from "./Occasion/Occasion";
import Moment from "./Moment";
import Recommendation from "../Recommendation";
import NeedHelp from "./NeedHelp";
import useSendRequest from "../../hooks/use-send-request";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./event.module.css";
import Modal, { useShowModal } from "../../hooks/use-modal";
import OccasionDetails from "./Occasion/OccasionDetails";
import usePropertyListing from "../../hooks/use-property-listing";

function Event() {
  const { showSendRequestModal } = useSendRequest(),
    showOccasionDetailsModal = useShowModal(OCCASION_DETAILS_MODAL_ID),
    location = "india-goa",
    oncx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.occasion),
    handleContactUs = async () => {
      return showSendRequestModal({ interest: "events_and_experience", brand: 1, location: "india-goa" });
    },
    handleViewDetails = async (selectedOccasion : OccasionDetails) => {
      return  showOccasionDetailsModal(selectedOccasion);
    },
    {
      properties,
    } = usePropertyListing( 
      location,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      //"pet-friendly-homes",
      "event-friendly-villas",
    );

  return (
    <>
      <Banner
        className={clxs.banner}
        onContactUs={handleContactUs}
      />
      <Intro className={clxs.intro} />
      <Occasion
        className={oncx}
        onContactUs={handleContactUs}
        onViewDetails={handleViewDetails}
      />
      <Moment />
      <NeedHelp onContactUs={handleContactUs} />
      <Recommendation
        title="Our Event-Friendly Villas"
        carouselId={TRENDING_CAROUSEL_ID}
        properties={properties} //Event-friendly Villas
        className={clxs.re}
        titleClassName={clxs.reTitle}
        tracking="event_trending_clicked"
      />
      <Modal id={OCCASION_DETAILS_MODAL_ID}>
        <OccasionDetails/>
      </Modal>
    </>
  );
}

export default Event;

const TRENDING_CAROUSEL_ID = "events-recommendation";

const OCCASION_DETAILS_MODAL_ID = "occasion-details";
