import format from "date-fns/format";
import React, { MouseEventHandler, useMemo } from "react";
import decodeLocationSlug from "../../../../../utils/decode-location-slug";
import CHEVRON_RIGHT_PRIMARY1 from "../../../../assets/icons/chevron-right-primary.svg";
import useClx from "../../../../hooks/use-clx";
import { Track } from "../../../AnalyticsManager";
import NetworkImg from "../../../NetworkImg";
import clxs from "./reservation-item.module.css";

interface ReservationItemProps {
  reservation: ConfirmedReservation;
  className?: string;
  onClick?: MouseEventHandler;
}

function ReservationItem (props: ReservationItemProps) {
  const { reservation, className, onClick } = props;

  const {
    name,
    address,
    checkin_date,
    checkout_date,
    adult_count,
    child_count,
    currency_symbol,
    booking_amount,
    unpaid_amount,
    thumbnail,
    lat, lng,
    location_slug,
    property_slug,
  } = reservation;

  const url = useMemo(
    () => {
      const { location, destination } = decodeLocationSlug(location_slug);

      const url: string = `/villas/${destination}/${location}/${property_slug}`;

      return url;
    },
    [location_slug, property_slug],
  );

  const viewOnMapUrl = useMemo(
    () => {
      const url = new URL("https://www.google.com/maps/search/?api=1");

      url.searchParams.set("query", `${lat},${lng}`);

      return url.toString();
    },
    [lat, lng],
  );

  const specs = useMemo(
    () => {
      const checkinDate = checkin_date
          ? format(new Date(checkin_date), "MMM d, yyyy")
          : "",
        checkoutDate = checkout_date
          ? format(new Date(checkout_date), "MMM d, yyyy")
          : "",
        stayFriendly = `${checkinDate} - ${checkoutDate}`,
        occupancyFriendly = [
          `${adult_count} Adults`,
          child_count && `${child_count} Children`,
        ].filter(Boolean).join(" - ");

      return [
        stayFriendly,
        occupancyFriendly,
      ];
    },
    [checkin_date, checkout_date, adult_count, child_count],
  );

  const finalPrice = useMemo(
    () => {
      return `${currency_symbol} ${booking_amount.toLocaleString("en-IN")}`;
    },
    [booking_amount, currency_symbol],
  );

  const paymentStatusContent = useMemo(
    () => unpaid_amount ? "Partially Paid" : "Fully Paid",
    [unpaid_amount],
  );

  const ccx = useClx(clxs.container, className);

  return (
    <a
      className={ccx}
      href={url}
      onClick={onClick}
      rel="noopener noreferrer"
      target="_blank"
    >
      <NetworkImg
        src={thumbnail.url}
        alt={thumbnail.alt}
        desktopQuality={50}
        mobileQuality={75}
        className={clxs.thumbnail}
        mobileDimensions={[800, 600]}
        desktopDimensions={[800, 600]}
      />
      <h3
        className={clxs.title}
        title={name}
      >
        {name}
      </h3>
      <Track event="view_on_map_clicked">
        <div className={clxs.subtitle}>
          {address}
          <a
            href={viewOnMapUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={clxs.viewOnMap}
          >
            View on map
          </a>
        </div>
      </Track>
      <div className={clxs.specContainer}>
        {specs.map(
          (each, i) => (
            <div
              key={i}
              className={clxs.spec}
            >
              {each}
            </div>
          ),
        )}
      </div>
      <div className={clxs.paymentStatus}>
        {paymentStatusContent}
      </div>
      <div className={clxs.pricingContainer}>
        <div className={clxs.view}>
          <span>
            View details
          </span>
          <img
            src={CHEVRON_RIGHT_PRIMARY1}
            alt="jump"
          />
        </div>
        <div className={clxs.price}>
          <div className={clxs.netAmount}>
            Net Amount
          </div>
          {finalPrice}
        </div>
      </div>
    </a>
  );
}

export default ReservationItem;
