import {useAtomState} from "./use-atom";

class _DefaultSendRequestService implements ISendRequestService {
  async save(): Promise<FetchResult<any>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: -1,
    };
  }
}

const DefaultSendRequestService = new _DefaultSendRequestService();

export const SEND_REQUEST_SERVICE_KEY = "SEND_REQUEST_SERVICE";

function useSendRequestService() {
  const [service, setService] =
    useAtomState<ISendRequestService>(
      SEND_REQUEST_SERVICE_KEY,
      DefaultSendRequestService,
    );

  return {
    service: service,
    setService: setService,
  }
}

export default useSendRequestService;
