import { usePage } from "../../hooks/use-page";

interface PageProps {
  page: string;
}

function Page(props: PageProps) {
  const {page} = props;

  usePage(page);

  return null;
}

export default Page;
