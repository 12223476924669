import React from "react";
import useSitemap from "../../hooks/use-sitemap";
import SitemapItem from "./SitemapItem";
import clxs from "./sitemap.module.css";

function Sitemap() {
  const { sitemap } = useSitemap();

  return (
    <>
      <h1 className={clxs.title}>
        Sitemap
      </h1>
      <div className={clxs.description}>
        Lohono Stays specializes in creating and curating luxury holiday homes for rent all over the world. Tied in with
        our signature hospitality, the endeavour is to offer the most luxurious and comfortable holiday experiences to
        our guests with our private villas in Goa, Alibaug and Coonoor.
      </div>
      <div className={clxs.container}>
        {sitemap.map(
          (each, key) => (
            <SitemapItem
              key={key}
              partial={each}
            />
          ),
        )}
      </div>
    </>
  );
}

export default Sitemap;
