import usePathParams from "../use-path-params";
import { useQueryParamValue } from "../use-query-param";
import { ReactElement } from "react";
import usePriceCalculator from "./use-price-calculator";
import { usePropertyConfigValues } from "../use-property-config";

interface PriceCalculatorContextProps {
  path: string;
  id: string;
  propertySlugKey: string;
  checkinDateKey: string;
  checkoutDateKey: string;
  adultCountKey: string;
  childCountKey: string;
  couponCodeKey: string;
  availLoyaltyPointsKey: string;
  configs: PropertyConfig[];
  children: ReactElement;
}

function PriceCalculatorContext(props: PriceCalculatorContextProps) {
  const {
      path,
      id,
      propertySlugKey,
      checkinDateKey,
      checkoutDateKey,
      adultCountKey,
      childCountKey,
      couponCodeKey,
      availLoyaltyPointsKey,
      configs,
      children,
    } = props,
    property_slug = usePathParams(path, propertySlugKey),
    checkin_date = useQueryParamValue("date", checkinDateKey),
    checkout_date = useQueryParamValue("date", checkoutDateKey),
    adult_count = useQueryParamValue("integer", adultCountKey),
    child_count = useQueryParamValue("integer", childCountKey),
    avail_loyalty_points = useQueryParamValue("boolean", availLoyaltyPointsKey),
    coupon_code = useQueryParamValue("string", couponCodeKey),
    { configArgs } = usePropertyConfigValues(property_slug, configs);

  usePriceCalculator(
    id,
    property_slug,
    checkin_date,
    checkout_date,
    adult_count,
    child_count,
    coupon_code,
    configArgs,
    avail_loyalty_points,
  );

  return children;
}

export default PriceCalculatorContext;
