import React from "react";
import clxs from "./get-enrolled.module.css";
import PLAY_STORE_ICON from "../../../assets/refer-n-earn/play-store.svg";
import APP_STORE_ICON from "../../../assets/refer-n-earn/app-store.svg";
import QR from "../../../assets/refer-n-earn/QR.png";
import useEnv from "../../../hooks/use-env";

interface GetEnrolledProps {
  title: string;
  containerClassName?: string;
  titleClassName?: string;
}

const GetEnrolled = (props: GetEnrolledProps) => {
  const {
    title,
    containerClassName,
    titleClassName,
  } = props,
  { APP_APP_STORE_URL, APP_PLAY_STORE_URL } = useEnv();

  return (
    <div className={containerClassName}>
      <div className={titleClassName}>
        {title}
      </div>
      <ul className={clxs.steps}>
        <hr className={clxs.verticalRuler}/>
        <li className={clxs.step}>
        {"Get enrolled in Lohono’s Loyalty Programme "}   
          <a
            className={clxs.link}
            href="./loyalty-points"
          >
            {"Lohono Infinity"}
          </a>
           {" by completing a stay at one of our villas."}
        </li>
        <li className={clxs.step}>
          <a
            href="/app-store"
            className={clxs.link}
          >
            {"Download Lohono app"}
          </a>
          {" to generate a unique referral link and share it with your connections."}
        </li>
        <li className={clxs.step}>
          Get Infinity points credited to your account when your connections complete their first stay.
        </li>
        <li className={clxs.step}>
          Redeem your Infinity points for next booking.
        </li>
      </ul>
      <hr className={clxs.divider} />
      <div className={clxs.subtitle}>
        Download the Lohono Stays Mobile App
      </div>
      <div className={clxs.app}>
        <div className={clxs.appLinks}>
        <a href={APP_PLAY_STORE_URL}>
            <img
              src={PLAY_STORE_ICON}
              alt="play store"
              className={clxs.playStore}
            />
        </a>
        <a href={APP_APP_STORE_URL}>
            <img
              src={APP_STORE_ICON}
              alt="play store"
              className={clxs.appStore}
            />
        </a>
        </div>
        <div className={clxs.verticalDivider}>
          <div className={clxs.separator}>OR</div>
        </div>
        <img
          src={QR}
          alt="QR"
          className={clxs.QR}
        />
      </div>
    </div>
  );
}

export default GetEnrolled;
