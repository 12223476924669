import { useMemo } from "react";
import useCountryCode from "./use-country-code";

function useSendRequestDefaultValues (
  defaultValue: any = {},
) {
  const { countryCode } = useCountryCode();

  const sanitized = useMemo(
    () => ({
      ...FULL_DEFAULT_VALUES,
      phone_ext: DEFAULT_PHONE_EXT_MAP[countryCode] ?? "",
      ...defaultValue,
    }),
    [countryCode, defaultValue],
  );

  return sanitized;
}

function useSendRequestMinimalDefaultValues (
  defaultValue: any = {},
) {
  const { countryCode } = useCountryCode();

  const sanitized = useMemo(
    () => ({
      ...MINIMAL_DEFAULT_VALUE,
      phone_ext: DEFAULT_PHONE_EXT_MAP[countryCode] ?? "",
      ...defaultValue,
    }),
    [countryCode],
  );

  return sanitized;
}

export {
  useSendRequestDefaultValues,
  useSendRequestMinimalDefaultValues,
};

const FULL_DEFAULT_VALUES = {
  full_name: "",
  phone_ext: "+91",
  phone_no: "",
  email: "",
  interest: "",
  location: "",
  message: "",
};

const MINIMAL_DEFAULT_VALUE = {
  full_name: "",
  phone_ext: "+91",
  phone_no: "",
  email: "",
  interest: "rental",
  location: "",
  message: "",
};


const DEFAULT_PHONE_EXT_MAP = {
  IN: "+91",
  GB: "+44",
  US: "+1",
};
