const INTEREST_OPTIONS = [
  { value: "rental", label: "Rent" },
  { value: "events_and_experience", label: "Experiences and Events" },
  { value: "development", label: "Own" },
  { value: "career", label: "Career" },
  { value: "home_acqui", label: "Partner as a Homeowner" },
  { value: "others", label: "Others" },
];

export default INTEREST_OPTIONS;
