import useReservationService from "../../hooks/use-reservation-service";
import { useCallback, useEffect, useState } from "react";

function useFeedbackArgs(feedbackSlug: string) {
  const { service } = useReservationService(),
    [feedbackArgs, setFeedbackArgs] = useState<FeedbackArgs | null>(null),
    [error, setError] = useState<string>(""),
    getFeedbackArgs = useCallback(
      async (feedbackSlug: string) => {
        const { response, error } = await service.getFeedbackArgs(feedbackSlug);

        if (error) {
          setError(error.message);

          return;
        }

        if (!response) {
          setError("something went wrong");

          return;
        }

        setFeedbackArgs(response);
      },
      [service],
    );

  useEffect(
    () => {
      getFeedbackArgs(feedbackSlug);
    },
    [getFeedbackArgs, feedbackSlug],
  );

  return {
    feedbackArgs: feedbackArgs,
    error: error,
  };
}

export default useFeedbackArgs;
