import React from "react";
import useClx from "../../../hooks/use-clx";
import { Track } from "../../AnalyticsManager";
import { AnchorButton } from "../../Button";
import Img from "../../Img";
import clxs from "./loyalty-point.module.css";
import BANNER_DESKTOP from "../../../assets/llp-home-banner-desktop.png";
import BANNER_MOBILE from "../../../assets/llp-home-banner-mobile.png";
import LOHONO_INFINITY_HOME_LG from "../../../assets/loyalty-points/lohono-infinity-home-image.svg";
import LOHONO_INFINITY_HOME_MD from "../../../assets/loyalty-points/lohono-infinity-home-image-md.svg";
import useIsMobile from "../../../hooks/use-is-mobile";

interface LoyaltyPointBannerProps {
  className?: string;
}

function LoyaltyPointBanner (props: LoyaltyPointBannerProps) {
  const { className } = props;

  const ccx = useClx(clxs.container, className);
  const { isMobile } = useIsMobile();
  const lohonoLogoSrc = isMobile ? LOHONO_INFINITY_HOME_MD : LOHONO_INFINITY_HOME_LG;

  return (
    <div className={ccx}>
      <Img
        src={BANNER_DESKTOP}
        mobileSrc={BANNER_MOBILE}
        desktopSrc={BANNER_DESKTOP}
        className={clxs.banner}
        loading="lazy"
        alt="Lohono"
      />
      <img
        src={lohonoLogoSrc}
        alt="Infinity Image Home"
        className={clxs.infinityImageHome}
      />
      <div className={clxs.header}>
        The Loyalty Program
      </div>
      <Track event="home_loyalty_points_know_more_clicked">
        <AnchorButton
          className={clxs.details}
          href="/loyalty-points"
          target="_blank"
          rel="noreferrer noopener"
        >
          Know More
        </AnchorButton>
      </Track>
      <div className={clxs.bottomtext}>
        {"Book your next dream villa to earn points and redeem exclusive deals on your next stay."}
      </div>
    </div>
  );
}

export default LoyaltyPointBanner;
