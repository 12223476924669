import React from "react";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./location-footer.module.css";
import LOHONO_LOGO_FOOTER from "../../../assets/logo/lohono-logo-footer.svg";
import useClx from "../../../hooks/use-clx";

interface LocationFooterProps {
  className?:string;
  footerSubtitle:string;
}

function LocationFooter(props : LocationFooterProps) {
  const {className, footerSubtitle} = props,
    ccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.container ,className);

  return (
    <>
      <footer
        suppressHydrationWarning={true}
        className={clxs.hideFooter}
      >
      <div className={ccx}>
      <div className={clxs.brand}>
          <img
            src={LOHONO_LOGO_FOOTER}
            alt="Lohono"
            className={clxs.logo}
            loading="lazy"
          />
          <div>
            {footerSubtitle}
          </div>
        </div>
      </div>
      </footer>
    </>
  );
}

export default LocationFooter;