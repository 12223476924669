import { useMemo } from "react";

function useIsMobile() {
  const isMobile = useMemo(
    () => {
      if (typeof window === "undefined") {
        return true;
      }

      const isMobile = window.innerHeight > window.innerWidth;

      return isMobile;
    },
    [],
  );

  return {isMobile: isMobile};
}

export default useIsMobile;
