import mAtom from "../../utils/m-atom";
import { useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

function useAtomState<T = any>(key: string, defaultValue: T | (() => T)) {
  const atom = useMemo(
    () =>
      mAtom<T>({
        key: key,
        default: defaultValue instanceof Function ?
          defaultValue() : defaultValue,
      }),
    [key],
    ),
    state = useRecoilState(atom);

  return state;
}

function useAtomValue<T = any>(key: string, defaultValue: T | (() => T)) {
  const atom = useMemo(
    () => mAtom<T>({
      key: key,
      default: defaultValue instanceof Function ?
        defaultValue() : defaultValue,
    }),
    [key, defaultValue],
    ),
    value = useRecoilValue(atom);

  return value;
}

function useAtomSet<T = any>(key: string, defaultValue: T | (() => T)) {
  const atom = useMemo(
    () => mAtom<T>({
      key: key,
      default: defaultValue instanceof Function ?
        defaultValue() : defaultValue,
    }),
    [key, defaultValue],
    ),
    set = useSetRecoilState(atom);

  return set;
}

export {
  useAtomState,
  useAtomValue,
  useAtomSet,
};
