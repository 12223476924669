import * as Yup from "yup";

const SEND_REQUEST_SCHEMA = Yup.object({
  full_name: Yup.string().trim().required("Please enter your name"),
  phone_ext: Yup.string().trim().required("Required"),
  phone_no: Yup.string()
    .trim()
    .matches(/^[0-9]{8,12}$/gi, "Invalid phone number")
    .required("Invalid phone number"),
  email: Yup.string().trim(),
  interest: Yup.string().trim().required("Required"),
  location: Yup.string()
    .trim()
    .when("interest", {
      is: "rental",
      then: Yup.string().required("Required"),
    }),
  message: Yup.string().trim(),
});

const MARKETING_PAGE_SCHEMA = Yup.object({
  full_name: Yup.string().trim().required("Please enter your name"),
  phone_ext: Yup.string().trim().required("Required"),
  phone_no: Yup.string()
    .trim()
    .matches(/^[0-9]{8,12}$/gi, "Invalid phone number")
    .required("Invalid phone number"),
  email: Yup.string().trim(),  
  budget: Yup.string().trim(),
});


export { SEND_REQUEST_SCHEMA, MARKETING_PAGE_SCHEMA};
