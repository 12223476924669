import React, { Fragment } from "react";
import decodeLocationSlug from "../../../../utils/decode-location-slug";
import { getPropertySearchUrl } from "../../../../utils/property-search";
import useClx from "../../../hooks/use-clx";
import useSendRequest from "../../../hooks/use-send-request";
import { Track } from "../../AnalyticsManager";
import clxs from "./property-location.module.css";
import LocationAnchor from "../../LocationAnchor";
import usePropertyLocationAnchorTemplate from "./use-property-location-anchor-template";

interface PropertyLocationProps {
  className: string;
  titleClassName: string;
  subtitleClassName: string;
  containerClassName: string;
}

function PropertyLocation (props: PropertyLocationProps) {
  const template = usePropertyLocationAnchorTemplate(),
    {
      className,
      titleClassName,
      subtitleClassName,
      containerClassName,
    } = props,
    ccx = useClx(clxs.container, containerClassName),
    { showSendRequestModal } = useSendRequest(),
    handleComingSoon = (location: PropertyLocation) => {
      if (!location.coming_soon) {
        return;
      }

      return showSendRequestModal(
        { interest: "rent", location: location.slug },
      );
    };

  return (
    <div className={className}>
      <a
        id="locations"
        className={clxs.anchor}
      >
      </a>
      <h2 className={titleClassName}>
        Explore Lohono
      </h2>
      <div className={subtitleClassName}>
        Exclusive homes for rent spread across pristine destinations, pick your nest of luxury
      </div>
      {template.map((each, key) => {
        const { title, locations } = each;

        return (
          <Fragment key={key}>
            <div className={clxs.propertyTitle}>
              {title}
            </div>
            <div className={ccx}>
              {locations.map((property, index) => {
                const {
                    name,
                    slug,
                    thumbnail,
                    property_count,
                    coming_soon,
                    country,
                  } = property,
                  url = getPropertySearchUrl(slug),
                  decodedLocation = decodeLocationSlug(slug);

                return (
                  <Track
                    event="home_location_clicked"
                    payload={{
                      ...each,
                      ...decodedLocation,
                      href: url,
                      index: index,
                    }}
                    key={slug}
                  >
                    <LocationAnchor
                      name={name}
                      slug={slug}
                      property_count={property_count}
                      coming_soon={coming_soon}
                      country={country}
                      thumbnail={thumbnail}
                      key={slug}
                      onClick={handleComingSoon.bind(null, property)}
                    />
                  </Track>
                );
              })}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

export default PropertyLocation;
