function cached (
  timeoutInMs?: number,
  prefix?: () => any[],
): MethodDecorator {
  return function(
    target: object,
    key: string | symbol,
    descriptor: PropertyDescriptor,
  ) {
    const original = descriptor.value;

    function memoized (...args: any) {
      const hostname = prefix?.() ?? "";

      const cache = memoized.cache;

      const key = cacheKeyCalculator(hostname, ...args);

      if (cache.has(key)) {
        return cache.get(key);
      }

      // @ts-ignore
      const result = original.apply(this, args);

      cache.set(key, result);

      if (timeoutInMs) {
        setTimeout(
          (key: string) => cache.delete(key),
          timeoutInMs,
          key,
        );
      }

      return result;
    }

    memoized.cache = new Map<string, any>();

    descriptor.value = memoized;

    return descriptor;
  };
}

export default cached;

function cacheKeyCalculator (...args: any[]): string {
  return args.map(
    each => {
      if (each === undefined || each === null) {
        return "null";
      }

      if (each instanceof Date) {
        return each.toISOString();
      }

      return each.toString();
    },
  ).join("#");
}
