import useReservationService from "./use-reservation-service";
import { useEffect, useState } from "react";

function useReservation(reservation_slug: string) {
  const { service } = useReservationService(),
    [reservation, setReservation] =
      useState<ReservationDetail | null>(null),
    handleGetReservation = async (reservation_slug: string) => {
      if (!reservation_slug) {
        return;
      }

      const { error, response } = await service.getReservation(reservation_slug);

      if (error || !response) {
        return;
      }

      setReservation(response);
    };

  useEffect(
    () => {
      handleGetReservation(reservation_slug);
    },
    [reservation_slug],
  );

  return {
    reservation: reservation,
  };
}

export default useReservation;
