import React, { useEffect } from "react";
import { LoginModal } from "../../hooks/use-login-modal";
import { PhoneExtsManager } from "../../hooks/use-phone-exts";
import { SessionManager } from "../../hooks/use-session";
import { SnackbarManager } from "../../hooks/use-snackbar";
import AppStoreModal from "../AppStoreModal";
import DomainManager from "../DomainManager";
import StateProvider, {StateProviderProps} from "../StateProvider";
import Routes from "../Routes";
import GlobalStyles from "../GlobalStyles";
import {Page} from "../../../../typings/page";
import Header from "../Header";
import ScrollSpy from "../ScrollSpy";
import Footer from "../Footer";
import ContactUsFloating from "../ContactUsFloating";
import SendRequestModal from "../SendRequestForm/Modal";
import { IpGeoLocationManager } from "../../hooks/use-ip-geo-location";

interface AppProps extends Omit<StateProviderProps, "children"> {
  pages: Page[];
  children?: never;
}

function App (props: AppProps) {
  const { pages, ...stateProviderProps } = props;

  useEffect(() => {
    // Remove the splash screen after the app has mounted
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
      // splashScreen.style.transition = "opacity 0.5s ease";
      splashScreen.style.opacity = "0";
      splashScreen.style.display = "none";

      // setTimeout(() => {
      //   splashScreen.style.display = "none";
      // }, 500); // Wait for the fade-out effect to finish
    }

    // Show the app root
    const root = document.getElementById("root");
    if (root) {
      // root.style.transition = "opacity 0.5s ease";
      root.style.opacity = "1";
    }
  },[]);

  return (
    <StateProvider {...stateProviderProps}>
      <>
        <SessionManager/>
        <GlobalStyles/>
        <Header/>
        <PhoneExtsManager/>
        <Routes pages={pages}/>
        <ScrollSpy/>
        <Footer pages={pages}/>
        <ContactUsFloating/>
        <SendRequestModal/>
        <AppStoreModal/>
        <IpGeoLocationManager/>
        <DomainManager/>
        <LoginModal/>
        <SnackbarManager/>
      </>
    </StateProvider>
  );
}

export default App;
