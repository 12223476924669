import React from "react";
import CONTACT_US_BANNER from "../../assets/contact-us-banner.svg";
import LOCATION_PIN from "../../assets/icons/location-pin.svg";
import useSendRequest from "../../hooks/use-send-request";
import { Track } from "../AnalyticsManager";
import Button from "../Button";
import SendRequestForm from "../SendRequestForm";
import clxs from "./contact-us.module.css";
import useContactUsTemplate from "./use-contact-us-template";

function ContactUs () {
  const { sendRequest } = useSendRequest();

  const template = useContactUsTemplate();

  const defaultValue = {
    full_name: "",
    phone_ext: "+91",
    phone_no: "",
    email: "",
    interest: "rental",
    location: "goa",
    message: "",
  };

  return (
    <div className={clxs.container}>
      <div className={clxs.titleContainer}>
        <h1 className={clxs.title}>
          Contact us
        </h1>
        <div className={clxs.subtitle}>
          The good life is waiting. Come,<br />
          Lohono with us!
        </div>
      </div>
      <img
        loading="lazy"
        src={CONTACT_US_BANNER}
        alt="contact-us"
        className={clxs.banner}
      />
      <div className={clxs.inquiriesContainer}>
        {template.map((template, key) => {
          const { title, contact, icon, track_event } = template;

          return (
            <Track
              event={track_event}
              key={key}
            >
              <div
                className={clxs.inquiry}
              >
                <img
                  src={icon}
                  alt={title}
                  className={clxs.inquiryIcon}
                />
                <h3 className={clxs.inquiryTitle}>{title}</h3>
                <div>Contact us on</div>
                <a
                  href={`tel:${contact}`}
                  className={clxs.inquiryContact}
                >
                  {contact}
                </a>
              </div>
            </Track>
          );
        })}
      </div>
      <SendRequestForm
        id="contact-us-embed"
        className={clxs.sendRequest}
        titleNode={(
          <div className={clxs.sendRequestTitle}>
            Send a request
          </div>
        )}
        defaultValue={defaultValue}
        isClearOnSubmit={true}
        onSubmit={sendRequest}
      >
        <Button
          className={clxs.submit}
          type="submit"
        >
          Submit
        </Button>
      </SendRequestForm>
      <div className={clxs.address}>
        <img
          src={LOCATION_PIN}
          alt="location"
          className={clxs.addressIcon}
        />
        <div className={clxs.addressTitle}>
          Mumbai office
        </div>
        <div>
          {ADDRESS}
        </div>
        <a
          href="https://g.page/IspravaVillas?share"
          target="_blank"
          rel="noopener noreferrer"
          className={clxs.addressLink}
        >
          View on map
        </a>
      </div>
    </div>
  );
}

export default ContactUs;

const ADDRESS = "42A, Impression House, G D Ambekar Marg, Kohinoor Mill, Wadala, Mumbai, Maharashtra – 400031";
