import React, { useCallback } from "react";
import { useQueryParamValue } from "../../hooks/use-query-param";
import { useSnackbar } from "../../hooks/use-snackbar";
import useUserService from "../../hooks/use-user-service";
import useWindowNavigate from "../../hooks/use-window-navigate";
import Form from "../Form";
import Otp from "../Form/Fields/Otp";
import clxs from "./delete-account-otp.module.css";
import CLOSE_ICON from "../../assets/icons/close.svg";
import LOHONO_APP_LOGO from "../../assets/logo/lohono-app-logo.svg";

function DeleteAccountOtp () {
  const push = useWindowNavigate();

  const enqueueSnackbar = useSnackbar()

  const { service: userService } = useUserService();

  const session = useQueryParamValue("string", "session");

  const accountType = useQueryParamValue("string", "account_type");

  const phoneNo = useQueryParamValue("string", "phone_no");

  const callback = useQueryParamValue("string", "callback");

  const handleCancel = () => {
    const search = new URLSearchParams();

    search.set("intent", "cancel");

    push({
      pathname: callback,
      search: search,
    });
  };

  const handleGenerateOtp = useCallback(
    async () => {
      const { error } = await userService.deleteAccountGenerateOtp(
        accountType,
        session,
      );

      if (error) {
        enqueueSnackbar(error.message, "error");

        return;
      }
    },
    [userService, accountType, session],
  );

  const handleSubmit = async (value: any) => {
    if (!value) {
      return;
    }

    const { otp } = value;

    const { error } = await userService.deleteAccountVerifyOtp(
      accountType,
      session,
      otp,
    );

    if (error) {
      enqueueSnackbar(error.message, "error");

      return;
    }

    const search = new URLSearchParams();

    search.set("session", session);

    search.set("callback", callback);

    push({
      pathname: callback,
      search: search,
    });
  };

  return (
    <Form
      id={FORM_ID}
      defaultValue={DEFAULT_VALUE}
      className={clxs.container}
      onSubmit={handleSubmit}
    >
      <div
        className={clxs.close}
        onClick={handleCancel}
      >
        <img
          src={CLOSE_ICON}
          alt="close"
        />
      </div>
      <img
        src={LOHONO_APP_LOGO}
        alt="Lohono logo"
        className={clxs.logo}
      />
      <div className={clxs.title}>
        OTP Verification
      </div>
      <div className={clxs.description}>
        {`We have sent verification code on\n${phoneNo}`}
      </div>
      <Otp
        form={FORM_ID}
        name="otp"
        timeout={60}
        onGenerate={handleGenerateOtp}
      />
      <button
        className={clxs.verify}
        type="submit"
        form={FORM_ID}
      >
        Verify OTP
      </button>
    </Form>
  );
}

export default DeleteAccountOtp;

const FORM_ID = "delete-user-verify-otp";

const DEFAULT_VALUE = {
  otp: "",
};