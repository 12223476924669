import React, { ReactElement, useMemo } from "react";
import {
  RouteProps as NativeRouteProps,
} from "react-router-dom";
import { useSession } from "../../../hooks/use-session";
import NotFound from "../../NotFound";

interface SecureRouteProps extends NativeRouteProps {
  isPrivate?: boolean;
  children: ReactElement;
}

function SecureRoute(props: SecureRouteProps) {
  const session = useSession(),
    ssr = useMemo(
      () => typeof window === "undefined",
      [],
    ),
    { isPrivate = false, children } = props;

  if (isPrivate && !session && !ssr) {
    return <NotFound transition={true} />;
  }

  return children;
}

export default SecureRoute;
