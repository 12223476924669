import React, { useState } from "react";
import clxs from "./faq-item.module.css";
import Collapse from "../../Collapse";
import EXPAND from "../../../assets/refer-n-earn/down-arrow.svg";
import COLLAPSE from "../../../assets/refer-n-earn/up-arrow.svg";

const FaqItem = (props: {faq:FAQ}) => {
  const [expanded, setExpanded] = useState<boolean>(false),
    { faq } = props,
    { question, answer } = faq;
  return (
    <div className={clxs.faqContainer}>
      <div
        className={clxs.question}
        onClick={setExpanded.bind(null, !expanded)}
      >
        {question}
        <div className={clxs.expandIcon}>
          {expanded ? (
            <img
              src={COLLAPSE}
              alt="collapse"
            />
          ) : (
            <img
              src={EXPAND}
              alt="collapse"
            />
          )
          }
        </div>
      </div>
      <Collapse
        expanded={expanded}
        className={clxs.answer}
      >
        {answer}
      </Collapse>
    </div>
  );
}

export default FaqItem;