import * as Yup from "yup";

function generateFeedbackValidationSchema(feedbackArgs: FeedbackArgs) {
  const propertyValidation = feedbackArgs.properties.length > 0
    ? Yup.string().trim().required("Required")
    : Yup.string().trim();

  const dynamicFields = feedbackArgs.questionnaire.reduce(
    (compiled, each) => {
      const { id, question_type, required } = each;

      if (question_type === "free_text") {
        return {
          ...compiled,
          [id]: ifRequired(Yup.string().trim(), required),
        };
      }

      if (question_type === "rating") {
        return {
          ...compiled,
          [id]: Yup.object()
            .shape({
              rating: ifRequired(
                Yup.number()
                  .integer("rating can only be between 1 and 5")
                  .min(0, "please select a rating")
                  .max(5, "rating can be at most 5"),
                required,
              ),
              description: Yup.string().trim(),
            }),
        };
      }

      if (question_type === "rating_large") {
        return {
          ...compiled,
          [id]: Yup.object()
            .shape({
              rating: ifRequired(
                Yup.number()
                  .integer("rating can only be between 1 and 10")
                  .min(1, "please select a rating")
                  .max(10, "rating can be at most 10"),
                required,
              ),
              description: Yup.string().trim(),
            }),
        };
      }

      if (question_type === "text_area") {
        return {
          ...compiled,
          [id]: ifRequired(Yup.string().trim(), required),
        };
      }

      return compiled;
    },
    {} as any,
  );

  const schema = Yup.object()
    .shape({
      property: propertyValidation,
      guest_name: Yup.string().trim().required("Required"),
      ...dynamicFields,
    });

  return schema;
}

export default generateFeedbackValidationSchema;

function ifRequired(schema: IfRequiredSchema, required: boolean) {
  if (required) {
    return schema.required("required");
  }

  return schema;
}

type IfRequiredSchema = Yup.StringSchema | Yup.NumberSchema;
