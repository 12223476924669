import React, { useMemo } from "react";
import clxs from "./rating-counts.module.css";
import useClx from "../../../hooks/use-clx";
import RATING_ICON from "../../../assets/icons/rating-icon.svg";

interface RatingCountsProps {
    className?: string;
    average_ratings?:number;
    ratings_count?:number;
    theme?:string;
  }

function RatingCounts(props:RatingCountsProps) {

    const {className,average_ratings,ratings_count,theme} = props;

    const ratingClassName =  useMemo(
      () => {
        if (theme == "recommendationRating") {
          return clxs.recommendationRating;
        } 
        if (theme === "propertyItemRating") {
          return clxs.propertyItemRating;
        } 
        if (theme === "collectionItemRating") {
          return clxs.collectionItemRating;
        } 
        if (theme === "propertyMultiConfigItemRating") {
          return clxs.propertyMultiConfigItemRating;
        }
        return clxs.container;
    },[theme]);

    const ccx = useClx(ratingClassName, className);

    return (
    <div className={ccx}>
        <img 
          src={RATING_ICON} 
          alt="icon"
          className={clxs.ratingIcon}
        />
         <span className={clxs.avgRating}>{average_ratings}</span>
         <span className={clxs.ratingCount}>
         {theme === "propertyItemRating"
            ?  `(${ratings_count} Reviews)`
            :  theme === "collectionItemRating"
            ?  `(${ratings_count} Reviews)`
            :  `(${ratings_count})`}
         </span>
    </div>  );
}

export default RatingCounts;