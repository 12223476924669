import usePropertyService from "./use-property-service";
import { useState, useEffect } from "react";

function useEligibilityPoints(
  checkin_date: Date | null,
  checkout_date: Date | null,
  property_slug: string,
  property_configs: PropertyConfigArg[],
  session: string,
) {
  const { service } = usePropertyService(),
    [eligibilityPoints, setEligibilityPoints] =
      useState<PointsEligibility | null>(null),
    handleGetReferralPoints = async (
      checkin_date: Date | null,
      checkout_date: Date | null,
      property_slug: string,
      property_configs: PropertyConfigArg[],
      session: string,
    ) => {
      if (!property_slug) {
        return;
      }

      const { error, response } = await service.getPointsEligibility(
        checkin_date,
        checkout_date,
        property_slug,
        property_configs,
        session,
      );

      if (error || !response) {
        return;
      }

      setEligibilityPoints(response);
    };

  useEffect(() => {
    handleGetReferralPoints(
      checkin_date,
      checkout_date,
      property_slug,
      property_configs,
      session,
    );
  }, [checkin_date]);

  return {
    eligibilityPoints: eligibilityPoints,
  };
}

export default useEligibilityPoints;
