import React from "react";
import useClx from "../../../hooks/use-clx";
import { Track } from "../../AnalyticsManager";
import Button from "../../Button";
import clxs from "./homeowner.module.css";
import ILLUSTRATION from "../../../assets/home-illustration.jpg";
import CALLIGRAPHY from "../../../assets/lohono-calligraphy.svg";

interface HomeownerProps {
  className?: string;
  onContactUs?: () => void;
}

function Homeowner(props: HomeownerProps) {
  const { className, onContactUs } = props,
    ccx = useClx(clxs.container, className);

  return(
    <div className={ccx}>
      <img
        src={ILLUSTRATION}
        alt="isprava-api"
        className={clxs.illustration}
        loading="lazy"
      />
      <img
        src={CALLIGRAPHY}
        alt="lohono-logo"
        className={clxs.calligraphy}
        loading="lazy"
      />
      <h2 className={clxs.title}>
        Partner as a Homeowner
      </h2>
      <div className={clxs.subtitle}>
        If you own a beautiful, one of a kind home that you feel exudes exclusivity and fits the Lohono style and vibe, register here to list with us!
      </div>
      <Track event="home_homeowner_clicked">
        <Button
          onClick={onContactUs}
          className={clxs.contact}
        >
          Contact us
        </Button>
      </Track>
    </div>
  );
}

export default Homeowner;
