import React, { useEffect, useState } from "react";
import clxs from "./not-found.module.css";
import NOT_FOUND_IMAGE from "../../assets/not-found.png";

interface NotFoundProps {
  transition?: boolean;
}

function NotFound(props: NotFoundProps) {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(
    () => {
      setTimeout(setVisible, 500, true);
    },
    [],
  );

  const { transition = false } = props;

  return (
    <div
      className={clxs.container}
      data-visible={!transition || visible}
    >
      <img
        src={NOT_FOUND_IMAGE}
        alt="not-found"
        className={clxs.banner}
      />
      <div className={clxs.title}>
        Oops! Page not found
      </div>
      <div className={clxs.subtitle}>
        {"We can't seem to find the page you're looking for."}
      </div>
      <a
        href="/"
        className={clxs.back}
      >
        Back to homepage
      </a>
    </div>
  );
}

export default NotFound;
