import React, { useCallback, useState, MouseEvent, useMemo } from "react";
import clxs from "./refer-history.module.css";
import { useSession } from "../../hooks/use-session";
import useReferHistory from "../../hooks/use-refer-history";
import CHEVRON_BACK from "../../assets/refer-n-earn/arrow_back_ios.svg";
import clx from "classnames";
import useEnv from "../../hooks/use-env";
import PLAY_STORE_ICON from "../../assets/refer-n-earn/play-store.svg";
import APP_STORE_ICON from "../../assets/refer-n-earn/app-store.svg";
import QR from "../../assets/refer-n-earn/QR.png";
import EXCLAMATION_RED from "../../assets/icons/exclamation-red-icon.svg";
import TIMER_ICON_YELLOW from "../../assets/refer-n-earn/hourglass_top_yellow.svg";
import TIMER_ICON_GREEN from "../../assets/refer-n-earn/hourglass_top_green.svg";
import REFEREE_ICON from "../../assets/icons/referee-icon.svg";
import DOWNLOAD_ICON from "../../assets/system_update.svg";
// import useClx from "../../hooks/use-clx";

function ReferHistory() {
  const session = useSession(),
    [tab, setTab] = useState<string>("pending"),
    { referHistories } = useReferHistory(session, tab, 0),
    { APP_APP_STORE_URL, APP_PLAY_STORE_URL } = useEnv(),
    pointIconSrc = useMemo(() => {
      if (tab == "failed") {
        return EXCLAMATION_RED;
      } else if (tab == "pending") {
        return TIMER_ICON_YELLOW;
      } else if (tab == "completed") {
        return "";
      }
    }, [tab]),
    handleTabChange = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        const target = e.currentTarget as HTMLElement;

        const value = target.dataset.value;

        if (!value) {
          return;
        }

        setTab(value);
      },
      [setTab],
    ),
    formatDate = (date: string) => {
      const dObj = new Date(date);
      return dObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    };

  return (
    <div className={clxs.container}>
      <div className={clxs.header}>
        <a
          href="/refer"
          className={clxs.backIcon}
        >
          <img
            src={CHEVRON_BACK}
            alt="back"
          />
        </a>
        <span>Refer History</span>
      </div>

      <div className={clxs.tabContainer}>
        <div className={clxs.tabs}>
          <div
            className={clxs.tab}
            data-value="pending"
            data-active={tab === "pending"}
            onClick={handleTabChange}
          >
            Pending
          </div>
          <div
            className={clxs.tab}
            data-value="completed"
            data-active={tab === "completed"}
            onClick={handleTabChange}
          >
            Completed
          </div>
          <div
            className={clxs.tab}
            data-value="failed"
            data-active={tab === "failed"}
            onClick={handleTabChange}
          >
            Failed
          </div>
        </div>

        <div className={clxs.historiesContainer}>
          {referHistories.map((each, key) => {
            const subStatus =
                each.sub_status == "pending_check_out"
                  ? "Checkout Pending"
                  : each.sub_status.split("_").reverse().join(" "),
              // status = each.status,
              isBookingConfirmed = subStatus.includes("Checkout Pending"),
              statusClass = clx(clxs.status),
              pointsClass = clx(clxs.points),
              reasonClass = clx(clxs.reason),
              getContentAndUI = function () {
                let statusContent = "";
                let iconSrc = "";
                let historyClass = "";
                const formattedDate = formatDate(each.date);
                if (tab == "failed") {
                  statusContent = formattedDate;
                  historyClass = clx(clxs.history, clxs.historyFailed);
                  iconSrc = EXCLAMATION_RED;
                } else if (tab == "completed") {
                  if (each.sub_status.includes("expired")) {
                    statusContent = "Expired on " + formattedDate;
                    historyClass = clx(clxs.history, clxs.historyPending);
                  } else {
                    statusContent = "Added on " + formattedDate;
                    historyClass = clx(clxs.history, clxs.historySuccess);
                  }
                } else if (tab == "pending") {
                  statusContent = subStatus;
                  if (isBookingConfirmed) {
                    historyClass = clx(clxs.history, clxs.historySuccess);
                  } else {
                    historyClass = clx(clxs.history, clxs.historyPending);
                  }
                  iconSrc = isBookingConfirmed
                    ? TIMER_ICON_GREEN
                    : pointIconSrc;
                }
                return { statusContent, iconSrc, historyClass };
              },
              { statusContent, iconSrc, historyClass } = getContentAndUI(),
              points = each.points_to_referee,
              pointsText =
                (tab == "completed" && subStatus != "expired")
                  ? "+" + points.toLocaleString()
                  : points.toLocaleString();
            return (
              <div
                className={historyClass}
                key={key}
              >
                <span className={clxs.profileContainer}>
                  <img
                    src={REFEREE_ICON}
                    alt="referee_icon"
                    className={clxs.profileIcon}
                  />
                  <span>
                    <span className={clxs.name}>{each.referee_name}</span>
                    <span className={statusClass}>{statusContent}</span>
                  </span>
                </span>

                <span className={pointsClass}>
                  {pointIconSrc ? (
                    <img
                      src={iconSrc}
                      alt="Ineligible Points"
                    />
                  ) : (
                    ""
                  )}
                  {pointsText}
                </span>
                {each.reason && (
                  <span className={reasonClass}>{each.reason}</span>
                )}
                <hr className={clxs.divider} />
              </div>
            );
          })}
        </div>
      </div>

      <div className={clxs.subtitle}>
        <img
          src={DOWNLOAD_ICON}
          alt="referee_icon"
          className={clxs.profileIcon}
        />
        Download the Lohono Stays Mobile App
      </div>

      <div className={clxs.app}>
        <div className={clxs.appLinks}>
          <a href={APP_PLAY_STORE_URL}>
            <img
              src={PLAY_STORE_ICON}
              alt="play store"
              className={clxs.playStore}
            />
          </a>
          <a href={APP_APP_STORE_URL}>
            <img
              src={APP_STORE_ICON}
              alt="play store"
              className={clxs.appStore}
            />
          </a>
        </div>
        <div className={clxs.verticalDivider}>
          <div className={clxs.separator}>OR</div>
        </div>
        <img
          src={QR}
          alt="QR"
          className={clxs.QR}
        />
      </div>
    </div>
  );
}

export default ReferHistory;
