import React, {ReactNode} from "react";
import clx from "classnames";
import CALLIGRAPHY_IMAGE from "../../assets/lohono-calligraphy.svg";
import COLLAGE_IMAGE from "../../assets/about-us-collage.png";
import ABOUT_US_TEMPLATE from "../../core/about-us";
import useClx from "../../hooks/use-clx";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./about-us.module.css";

function AboutUs() {
  const icx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.intro);

  return (
    <>
      <div className={icx}>
        <img
          loading="lazy"
          src={CALLIGRAPHY_IMAGE}
          alt="Lohono"
          className={clxs.calligraphy}
        />
        <h1 className={clxs.title}>
          The good life is waiting. Come, <br/> Lohono with us.
        </h1>
        <p className={clxs.description}>
          If you’re in the mood to celebrate, there are some exceptional gourmet
          and entertainment experiences curated by our resourceful concierge
          team.
        </p>
        <img
          loading="lazy"
          src={COLLAGE_IMAGE}
          alt="about-us"
          className={clxs.collage}
        />
      </div>
      <div className={clxs.points}>
        <div className={clxs.balloon}>
          About <br/>
          Lohono Stays
        </div>
        {ABOUT_US_TEMPLATE.reduce(
          (nodes, template, key) => {
            const {title, description} = template,
              alignment = key % 2 ? clxs.even : clxs.odd,
              tclx = clx(alignment, clxs.pointTitle),
              dclx = clx(alignment, clxs.pointDescription);

            return [
              ...nodes,
              <h2
                key={`title-${key}`}
                className={tclx}
              >
                {title}
              </h2>,
              <p
                key={`description-${key}`}
                className={dclx}
              >
                {description}
              </p>,
            ];
          },
          [] as ReactNode[],
        )}
      </div>
    </>
  );
}

export default AboutUs;
