import { DEFAULT_PAGINATION } from "../../utils/parse-pagination";
import usePropertyService from "./use-property-service";
import { useAtomState } from "./use-atom";
import {
  PROPERTY_LISTING_KEY,
  PROPERTY_LISTING_DISCOUNT_KEY,
  PROPERTY_LISTING_PAGINATION_KEY,
} from "../../core/constants";
import usePrefetch from "./use-prefetch";
import { useEffect, useState } from "react";
import { useSession } from "./use-session";

function usePropertyListing(
  location_slug: string,
  mood?: string,
  checkin_date?: string,
  checkout_date?: string,
  adult_count?: number,
  child_count?: number,
  search?: string,
  sort?: string,
  collection_slug?: string,
) {
  const { service } = usePropertyService(),
  [isLoading, setIsLoading] = useState<boolean>(false),
    session = useSession(),
    { hasPrefetchKey, removePrefetch } = usePrefetch(),
    [discount, setDiscount] = useAtomState<PropertiesListingDiscount>(
      PROPERTY_LISTING_DISCOUNT_KEY,
      () => ({ discount_message: "", discount_method: "" }),
    ),
    [pagination, setPagination] = useAtomState<Pagination>(
      PROPERTY_LISTING_PAGINATION_KEY,
      DEFAULT_PAGINATION,
    ),
    [properties, setProperties] = useAtomState<PropertyItem[]>(PROPERTY_LISTING_KEY, []),
    handleGetProperties = async (
      session: string,
      location_slug: string,
      mood?: string,
      checkin_date?: string,
      checkout_date?: string,
      adults?: number,
      children?: number,
      search?: string,
      sort?: string,
      next?: boolean,
      collection_slug?: string,
    ) => {
      const haveListingKey = hasPrefetchKey(PROPERTY_LISTING_KEY),
        haveListingPaginationKey = hasPrefetchKey(PROPERTY_LISTING_PAGINATION_KEY);

      if (haveListingKey && haveListingPaginationKey && !collection_slug) {
        removePrefetch(PROPERTY_LISTING_KEY);

        removePrefetch(PROPERTY_LISTING_PAGINATION_KEY);

        return;
      }

      if (next && pagination.current_page === pagination.total_pages) {
        return;
      }

      setIsLoading(true);

      const { error, response, paginate } = await service.getProperties(
        location_slug,
        mood,
        checkin_date,
        checkout_date,
        adults,
        children,
        search,
        sort,
        next ? pagination.current_page + 1 : 1,
        session,
        collection_slug,
      );

      setIsLoading(false);

      if (error) {
        console.error(error);
      }

      if (error || !response || !paginate) {
        return;
      }

      setDiscount(response.discount);

      setProperties(
        properties => next ? [
          ...properties,
          ...response.properties,
        ] : response.properties,
      );

      setPagination(paginate);
    },
    getNextPage = async () => {
      return handleGetProperties(
        session,
        location_slug,
        mood,
        checkin_date,
        checkout_date,
        adult_count,
        child_count,
        search,
        sort,
        true,
        collection_slug,
      );
    };

  useEffect(
    () => {
      handleGetProperties(
        session,
        location_slug,
        mood,
        checkin_date,
        checkout_date,
        adult_count,
        child_count,
        search,
        sort,
        false,
        collection_slug,
      );
    },
    [
      session,
      location_slug,
      mood,
      checkin_date,
      checkout_date,
      adult_count,
      child_count,
      search,
      sort,
      collection_slug,
    ],
  );

  return {
    propertyCount: pagination.total_records,
    properties: properties,
    discount: discount,
    getNextPage: getNextPage,
    isLoading: isLoading,
  };
}

export default usePropertyListing;
