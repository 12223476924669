class PaymentService implements IPaymentService {
  async request(
    api_key: string,
    description: string,
    name: string,
    email: string,
    contact: string,
    order_id: string,
    amount: number,
    currency: string,
  ): Promise<FetchResult<RazorpayResult>> {
    if (!window.Razorpay) {
      return {
        status: 404,
        error: new Error("razorpay not found"),
        response: null,
      };
    }

    try {
      const { Razorpay } = window,
        result: RazorpayResult = await new Promise(
          (resolve, reject) => {
            const options: RazorpayOptions = {
                key: api_key,
                order_id: order_id,
                amount: amount,
                currency: currency,
                name: "Lohono Stays",
                description: description,
                image: "https://lohono.s3.ap-south-1.amazonaws.com/images/lohono-transparent.png",
                prefill: {
                  name: name,
                  email: email,
                  contact: contact,
                },
                theme: {
                  color: "#b73735",
                },
                methods: {
                  netbanking: false,
                  emi: false,
                },
                handler: resolve,
                modal: {
                  ondismiss: reject.bind(null, new Error("payment cancelled")),
                },
              },
              instance = new Razorpay(options);

            instance.open();
          },
        );

      return {
        status: 200,
        error: null,
        response: result,
      };
    } catch (e: any) {
      return {
        status: 503,
        error: e,
        response: null,
      };
    }
  }
}

export default PaymentService;
