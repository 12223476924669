import { ChangeEvent, ChangeEventHandler, KeyboardEvent, useCallback, useState } from "react";

function useOtpInputState (
  name?: string,
  onChange?: ChangeEventHandler,
  defaultValue?: string,
  value?: string,
) {
  const [digits, setDigits] = useState<OtpInputDigits>(
      () => {
        const initial = (value ?? defaultValue);

        if (!initial) {
          return ["", "", "", ""];
        }

        return initial.split("").slice(0, 4) as OtpInputDigits;
      },
    ),
    handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        const index = Number.parseInt(target.dataset.index ?? "");

        if (Number.isNaN(index)) {
          return;
        }

        const value = target.value;

        const updated: OtpInputDigits = [...digits];

        updated[index] = value;

        setDigits(updated);

        if (!onChange) {
          return;
        }

        const otp = updated.join("").trim();

        const fakeTarget = { name: name, value: otp },
          payload: any = { target: fakeTarget, currentTarget: fakeTarget };

        onChange(payload);
      },
      [name, digits, setDigits, onChange],
    ),
    handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        const index = Number.parseInt(target.dataset.index ?? "");

        if (Number.isNaN(index)) {
          return;
        }

        const { key } = e,
          isBackspace = key === "Backspace",
          isTab = key === "Tab",
          parsedValue = Number.parseInt(key),
          value = target.value;

        if (isTab) {
          return;
        }

        if (!isBackspace && Number.isNaN(parsedValue)) {
          e.preventDefault();

          return;
        }

        if (isBackspace && value) {
          return;
        }

        if (!isBackspace) {
          return;
        }

        const previous = target.previousElementSibling as HTMLInputElement;

        if (!previous) {
          return;
        }

        previous.focus();
      },
      [],
    ),
    handleKeyUp = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;

        const index = Number.parseInt(target.dataset.index ?? "");

        if (Number.isNaN(index)) {
          return;
        }

        const value = target.value;

        if (!value) {
          return;
        }

        const next = target.nextElementSibling as HTMLInputElement;

        if (!next) {
          return;
        }

        next.focus();
      },
      [],
    ),
    handleReset = useCallback(
      () => setDigits(["", "", "", ""]),
      [setDigits],
    );

  return {
    digits: digits,
    handleReset: handleReset,
    handleChange: handleChange,
    handleKeyDown: handleKeyDown,
    handleKeyUp: handleKeyUp,
  };
}

export default useOtpInputState;

type OtpInputDigits = [string, string, string, string];
