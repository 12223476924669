import { useCallback } from "react";
import { useShowModal } from "../../hooks/use-modal";

function useAppStoreModal () {
  const showModal = useShowModal(APP_STORE_MODAL_ID);

  const handleShowAppStoreModal = useCallback(
    () => showModal({}),
    [],
  );

  return handleShowAppStoreModal;
}

export default useAppStoreModal;

export const APP_STORE_MODAL_ID = "app-store-modal";
