import React, { useMemo } from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./sort.module.css";
import ARROW_UP_ICON from "../../../../assets/icons/arrow-up.svg";

interface SortProps {
  className?: string,
  value: string;
  onChange: (nextDirection: string) => void;
}

function Sort (props: SortProps) {
  const { value, onChange, className } = props,
    text = useMemo(
      () => {
        if (value === "desc") {
          return "Price high to low";
        }

        if (value === "asc") {
          return "Price low to high";
        }

        return "Popularity";
      },
      [value],
    ),
    bcx = useClx(clxs.button, className),
    icx = useClx(clxs.icon, value !== "desc" && clxs.iconFlipped),
    nextDirection = useMemo(
      () => {
        if (value === "asc") {
          return "desc";
        }

        if (value === "desc") {
          return "popularity";
        }

        return "asc";
      },
      [value],
    ),
    handleToggle = () => {
      return onChange(nextDirection);
    };

  return (
    <button
      className={bcx}
      color="inherit"
      onClick={handleToggle}
      title="Sort by"
    >
      <img
        src={ARROW_UP_ICON}
        alt={text}
        className={icx}
      />
      {text}
    </button>
  );
}

export default Sort;
