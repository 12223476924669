import add from "date-fns/add";
import differenceInMonths from "date-fns/differenceInMonths";
import startOfMonth from "date-fns/startOfMonth";
import { useCallback, useEffect, useMemo, useState } from "react";

function useCalendarCursor (
  max: number = 12,
  min: number = 0,
  monthCount: number = 1,
  initialDate = TODAY_MONTH,
  onMonthChange?: MonthChangeHandler,
) {
  const [cursor, setCursor] = useState(
      () => {
        const initialCursor = differenceInMonths(initialDate, TODAY_MONTH);

        return initialCursor;
      },
    ),
    handleMonthChange = useCallback(
      (month: Date) => {
        if (!onMonthChange) {
          return;
        }

        onMonthChange(month);
      },
      [onMonthChange],
    ),
    handleScroll = useCallback(
      (calculateNextCursor: CalculateNextCursor) => {
        const nextCursor = calculateNextCursor(cursor, min, max);

        if (cursor === nextCursor) {
          return;
        }

        const monthScroll = Math.max(0, nextCursor);

        const withOffset = add(initialDate, { months: monthScroll });

        handleMonthChange(withOffset);

        setCursor(nextCursor);
      },
      [setCursor, handleMonthChange, min, max, cursor],
    ),
    handlePrevious = useCallback(
      () => handleScroll(
        (cursor, min) => Math.max(min, cursor - 1),
      ),
      [handleScroll],
    ),
    handleNext = useCallback(
      () => handleScroll(
        (cursor, min, max) => Math.min(max, cursor + 1),
      ),
      [handleScroll],
    ),
    handleToday = useCallback(
      () => {
        setCursor(0);

        handleMonthChange(initialDate);
      },
      [setCursor, initialDate, handleMonthChange],
    ),
    meta = useMemo(
      () => {
        const referenceMonth = TODAY_MONTH,
          months: Date[] = Array.from(
            { length: monthCount },
            (_, monthOffset) => add(
              referenceMonth,
              { months: monthOffset + cursor },
            ),
          );

        return {
          months: months,
        };
      },
      [monthCount, cursor],
    );

  useEffect(
    () => handleMonthChange(initialDate),
    [handleMonthChange],
  );

  return {
    ...meta,
    cursor: cursor,
    handlePrevious: handlePrevious,
    handleNext: handleNext,
    handleToday: handleToday,
  };
}

export default useCalendarCursor;

export type MonthChangeHandler = (month: Date) => void;

const TODAY_MONTH = startOfMonth(new Date());

type CalculateNextCursor = (
  cursor: number,
  min: number,
  max: number,
) => number;