import React from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./approach.module.css";
import APPROACH_DILIGENCE from "../../../assets/approach-diligence.svg";
import APPROACH_INCOME from "../../../assets/approach-income.svg";
import APPROACH_LISTING from "../../../assets/approach-listing.svg";
import APPROACH_MANAGING from "../../../assets/approach-managing.svg";
import APPROACH_RESTYLE from "../../../assets/approach-restyle.svg";
import APPROACH_MARKETING from "../../../assets/approach-marketing.svg";

interface ApproachProps {
  className?: string;
}

function Approach(props: ApproachProps) {
  const { className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        {"Lohono’s Approach "}
      </div>
      <div className={clxs.approaches}>
        {APPROACH_TEMPLATE.map(
          (each, key) => (
            <div
              className={clxs.approach}
              key={key}
            >
              <img
                src={each.thumbnail}
                alt={each.title}
                className={clxs.approachThumbnail}
              />
              <div className={clxs.approachTitle}>
                {each.title}
              </div>
              <div className={clxs.approachDescription}>
                {each.description}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default Approach;

const APPROACH_TEMPLATE = [
  {
    thumbnail: APPROACH_RESTYLE,
    title: "Re-styling/ Refurbishing/ Renovating your home",
    description: "Good design is in our DNA, and we will beautify your home for you and our guests.",
  },
  {
    thumbnail: APPROACH_LISTING,
    title: "Listing Your Home",
    description: "We know how important your home is to you so we leave no stone unturned when it comes to legislations and government regulations.",
  },
  {
    thumbnail: APPROACH_MANAGING,
    title: "Managing Your Home",
    description: "Our trained on-ground teams will work in collaboration with our partner’s staff. We can train your staff as required. You can also view/manage your home from anywhere via the Lohono Inner Circle app",
  },
  {
    thumbnail: APPROACH_INCOME,
    title: "Earning Rental Income For Your Home",
    description: "Our best-in-class technology systems ensure your home is listed across multiple channels for revenue maximization.",
  },
  {
    thumbnail: APPROACH_MARKETING,
    title: "Marketing Your Home",
    description: "We use professional photos and drone images/videos to boost the visibility of your home and run campaigns to acquire guests.",
  },
  {
    thumbnail: APPROACH_DILIGENCE,
    title: "Due Diligence",
    description: "We have years of experience in building and looking after the best homes while earning a generous rental income for our partners.",
  },
];
