import React, { MouseEvent, useState } from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./sort.module.css";
import CHEVRON_DOWN_LIGHT from "../../../../assets/icons/chevron-down-light.svg";

interface SortProps {
  className?: string,
  value: string;
  onChange: (nextDirection: string) => void;
}

function Sort (props: SortProps) {
  const [visible, setVisible] = useState<boolean>(false),
    { value, onChange, className } = props,
    bcx = useClx(clxs.button, className),
    handleToggle = () => setVisible(visible => !visible),
    handleValueChange = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      const target = e.target as HTMLElement;

      const { value } = target.dataset;

      if (!value) {
        return;
      }

      setVisible(false);

      onChange(value);
    };

  return (
    <button
      className={bcx}
      color="inherit"
      title="Sort by"
      onClick={handleToggle}
    >
      Sort By
      <img
        src={CHEVRON_DOWN_LIGHT}
        alt="sort by"
        className={clxs.icon}
      />
      <div
        className={clxs.menu}
        data-visible={visible}
        onClick={handleValueChange}
      >
        <div
          className={clxs.item}
          data-value="desc"
          data-selected={value === "desc"}
        >
          Price high to low
        </div>
        <div
          className={clxs.item}
          data-value="asc"
          data-selected={value === "asc"}
        >
          Price low to high
        </div>
        <div
          className={clxs.item}
          data-value="popularity"
          data-selected={value === "popularity"}
        >
          Popularity
        </div>
      </div>
    </button>
  );
}

export default Sort;
