import React, { Fragment } from "react";
import usePrivacy from "../../hooks/use-privacy";
import clxs from "./privacy-policy.module.css";

function PrivacyPolicy() {
  const { privacy } = usePrivacy();

  return (
    <>
      <h1>Privacy Policy</h1>
      {privacy.map(
        (each, key) => {
          const { title, content } = each;

          return (
            <Fragment key={key}>
              <div className={clxs.title}>
                {title}
              </div>
              {content.map(
                (each, key) => (
                  <p
                    key={key}
                    className={clxs.content}
                  >
                    {each}
                  </p>
                ),
              )}
            </Fragment>
          );
        },
      )}
    </>
  );
}

export default PrivacyPolicy;
