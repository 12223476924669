import React from "react";
import { useQueryParamValue } from "../../hooks/use-query-param";
import useClx from "../../hooks/use-clx";
import Banner from "./Banner";
import ExperienceItem from "./ExperienceItem";
import Recommendation from "../Recommendation";
import usePropertyTrending from "../../hooks/use-property-trending";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./experience.module.css";
import LOHONO_EXPERIENCE_TEMPLATE from "../../core/lohono-experience";

function Experience() {
  const { trendingProperties } = usePropertyTrending(),
    isEmbedded = useQueryParamValue("boolean", "embedded", false),
    bncx = useClx(fwclxs.container, fwclxs.containerPadding);

  return (
    <>
      <Banner className={bncx} />
      <div className={clxs.title}>
        Here is what you can look forward to with Lohono Stays:
      </div>
      <div className={clxs.itemContainer}>
        {LOHONO_EXPERIENCE_TEMPLATE.map(
          (each, key) => (
            <ExperienceItem
              key={key}
              title={each.title}
              description={each.description}
              href={each.href}
              thumbnail={each.thumbnail}
              hideJumpLink={isEmbedded}
            />
          ),
        )}
      </div>
      {!isEmbedded && (
        <Recommendation
          title="Trending this season"
          carouselId={TRENDING_CAROUSEL_ID}
          properties={trendingProperties}
          className={clxs.re}
          titleClassName={clxs.reTitle}
          tracking="experience_trending_clicked"
        />
      )}
    </>
  );
}

export default Experience;

const TRENDING_CAROUSEL_ID = "experience-recommendation";