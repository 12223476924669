import React, { ReactNode } from "react";
import useBreakdownTemplate from "../../hooks/use-breakdown-template";
import clxs from "./breakdown.module.css";
import CLOSE_ICON from "../../assets/icons/close.svg";

interface BreakdownProps {
  breakdown?: PriceBreakdown;
  currencySymbol: string;
  onSubmit?: (value: any) => void;
}

function Breakdown(props: BreakdownProps) {
  const { currencySymbol, breakdown, onSubmit } = props,
    { total, template } = useBreakdownTemplate(currencySymbol, breakdown);

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        Billing details
      </div>
      <div className={clxs.separator}>
        &nbsp;
      </div>
      {template.reduce(
        (compiled, each, i) => {
          const { label, value } = each;

          return [
            ...compiled,
            <div
              key={`label-${i}`}
              className={clxs.label}
            >
              {label}
            </div>,
            <div
              key={`value-${i}`}
              className={clxs.amount}
            >
              {value}
            </div>,
          ];
        },
        [] as ReactNode[],
      )}
      <div className={clxs.separator}>
        &nbsp;
      </div>
      <div>Total</div>
      <div className={clxs.amount}>
        {total}
      </div>
      <img
        src={CLOSE_ICON}
        alt="close"
        className={clxs.close}
        onClick={onSubmit?.bind(null, null)}
      />
    </div>
  );
}

export default Breakdown;
