const CORPORATE_VILLAS = [
  {
    "name": "Srinivas - The Royal Residence",
    "address": "Jaipur, Rajasthan, India",
    "starts_from": "15,000",
    "isprava_built": false,
    "location_slug": "/villas/india/jaipur",
    "property_slug": "srinivas-the-royal-residence",
    "rental_property_id": 388,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/srinivas-the-royal-residence/01_Facade.jpeg",
    "thumbnail_alt": "Srinivas - The Royal Residence - Facade",
    "guests": "Upto 12 guests",
    "bedroom_count": "6 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "9 Bathroom",
  },
  {
    "name": "Homestead",
    "address": "Jim Corbett National Park, Uttarakhand",
    "starts_from": "9,100",
    "isprava_built": false,
    "location_slug": "/villas/india/jimcorbett",
    "property_slug": "homestead",
    "rental_property_id": 382,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/homestead/Facade.jpeg",
    "thumbnail_alt": "The Homestead -  Facade",
    "guests": "Upto 33 guests",
    "bedroom_count": "13 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "12 Bathroom",
  },
  {
    "name": "Wakefield Estate",
    "address": "Barlow Ganj, Mussoorie, India",
    "starts_from": "77,000",
    "isprava_built": false,
    "location_slug": "/villas/india/mussoorie",
    "property_slug": "wakefield-estate",
    "rental_property_id": 91,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/wakefield-estate/facade.jpeg.jpg",
    "thumbnail_alt": "Wakefield Estate - Facade",
    "guests": "Upto 14 guests",
    "bedroom_count": "6 Bedroom",
    "pools_count": "",
    "baths_count": "6 Bathroom",
  },
  {
    "name": "Sacri Borod Hill 5",
    "address": "Mandrem, Goa, India",
    "starts_from": "84,000",
    "isprava_built": false,
    "location_slug": "/villas/india/goa",
    "property_slug": "sacri-borod-hill-5",
    "rental_property_id": 375,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/sacri-borod-hill-5/01_Facade__2_.jpeg",
    "thumbnail_alt": "Sacri Borod Hill 5 - Facade",
    "guests": "Upto 12 guests",
    "bedroom_count": "5 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "5 Bathroom",
  },
  {
    "name": "Estate de Frangipani",
    "address": "Assagaon, Goa, India",
    "starts_from": "94,000",
    "isprava_built": true,
    "location_slug": "/villas/india/goa",
    "property_slug": "estate-de-frangipani",
    "rental_property_id": 303,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/estate-de-frangipani/01_Facade__12_.jpeg",
    "thumbnail_alt": "Estate de Frangipani - Facade",
    "guests": "Upto 10 guests",
    "bedroom_count": "4 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "4 Bathroom",
  },
  {
    "name": "Casa Crystal",
    "address": "Coonoor, Tamil Nadu, India",
    "starts_from": "51,000",
    "isprava_built": false,
    "location_slug": "/villas/india/coonoor",
    "property_slug": "casa-crystal",
    "rental_property_id": 363,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/casa-crystal/01_Night_Facade.jpeg",
    "thumbnail_alt": "Casa Crystal - Night Facade",
    "guests": "Upto 10 guests",
    "bedroom_count": "4 Bedroom",
    "pools_count": "",
    "baths_count": "5 Bathroom",
  },
  {
    "name": "Sacri Borod Hill 9",
    "address": "Mandrem, Goa, India",
    "starts_from": "1,50,000",
    "isprava_built": false,
    "location_slug": "/villas/india/goa",
    "property_slug": "sacri-borod-hill-9",
    "rental_property_id": 95,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/sacri-borod-hill-9/_sea-view.jpeg",
    "thumbnail_alt": "Sacri Borod Hill 9 - Sea View",
    "guests": "Upto 12 guests",
    "bedroom_count": "5 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "5 Bathroom",
  },
  {
    "name": "Villa Alborz",
    "address": "Lonavala, Maharashtra, India",
    "starts_from": "53,000",
    "isprava_built": false,
    "location_slug": "/villas/india/alibaug",
    "property_slug": "villa-alborz",
    "rental_property_id": 59,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/villa-alborz/01_Facade.jpg",
    "thumbnail_alt": "Villa Alborz - Facade",
    "guests": "Upto 10 guests",
    "bedroom_count": "4 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "4 Bathroom",
  },
  {
    "name": "Estate de Escobar",
    "address": "Assagaon, Goa, India",
    "starts_from": "82,000",
    "isprava_built": true,
    "location_slug": "/villas/india/goa",
    "property_slug": "villa-escobar",
    "rental_property_id": 73,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/villa-escobar/villa-view.jpeg",
    "thumbnail_alt": "Estate De Escobar - Villa View",
    "guests": "Upto 10 guests",
    "bedroom_count": "5 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "5 Bathroom",
  },
  {
    "name": "Colina - Villa F",
    "address": "Assagaon, Goa, India",
    "starts_from": "36,000",
    "isprava_built": true,
    "location_slug": "/villas/india/goa",
    "property_slug": "colina-villa-f",
    "rental_property_id": 35,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/01t0K000005nIaiQAE/01_Facade__8_.jpeg",
    "thumbnail_alt": "Colina Villa F - Facade",
    "guests": "Upto 8 guests",
    "bedroom_count": "3 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "3 Bathroom",
  },
  {
    "name": "Sadhrana Bagh - Beri House",
    "address": "Gurgaon, NCR, India",
    "starts_from": "60,000",
    "isprava_built": false,
    "location_slug": "/villas/india/gurgaon",
    "property_slug": "beri-house",
    "rental_property_id": 386,
    "currency_symbol": "₹",
    "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/beri-house/01_Facade__3_.jpeg",
    "thumbnail_alt": "Beri House - Facade",
    "guests": "Upto 12 guests",
    "bedroom_count": "5 Bedroom",
    "pools_count": "1 Pool",
    "baths_count": "5 Bathroom",
  },
  // {
  //   "name": "Villa Verite",
  //   "address": "Zirad, Alibaug, Maharashtra, India",
  //   "starts_from": "63,000",
  //   "isprava_built": false,
  //   "location_slug": "/villas/india/alibaug",
  //   "property_slug": "isp-villa-verite",
  //   "rental_property_id": 15,
  //   "currency_symbol": "₹",
  //   "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/isp-villa-verite/Banner-Aerial_view_of_the_house.jpg",
  //   "thumbnail_alt": "Villa Verite - 5 bedroom luxury Villa in Alibaug",
  //   "guests": "Upto 12 guests",
  //   "bedroom_count": "5 Bedroom",
  //   "pools_count": "1 Pool",
  //   "baths_count": "6 Bathroom",
  // },
  // {
  //   "name": "Ludlow House",
  //   "address": "Kasauli, Himachal Pradesh, India",
  //   "starts_from": "57,000",
  //   "isprava_built": false,
  //   "location_slug": "/villas/india/kasauli",
  //   "property_slug": "ludlow-house",
  //   "rental_property_id": 377,
  //   "currency_symbol": "₹",
  //   "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/ludlow-house/01_Facade__3_.jpeg",
  //   "thumbnail_alt": "Ludlow House - Facade",
  //   "guests": "Upto 10 guests",
  //   "bedroom_count": "4 Bedroom",
  //   "pools_count": "",
  //   "baths_count": "4 Bathroom",
  // },
  // {
  //   "name": "Estate de Escobar",
  //   "address": "Assagaon, Goa, India",
  //   "starts_from": "82,000",
  //   "isprava_built": true,
  //   "location_slug": "/villas/india/goa",
  //   "property_slug": "villa-escobar",
  //   "rental_property_id": 73,
  //   "currency_symbol": "₹",
  //   "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/villa-escobar/villa-view.jpeg",
  //   "thumbnail_alt": "Estate De Escobar - Villa View",
  //   "guests": "Upto 10 guests",
  //   "bedroom_count": "5 Bedroom",
  //   "pools_count": "1 Pool",
  //   "baths_count": "5 Bathroom",
  // },
  // {
  //   "name": "Sadhrana Bagh - Beri House",
  //   "address": "Gurgaon, NCR, India",
  //   "starts_from": "53,000",
  //   "isprava_built": false,
  //   "location_slug": "/villas/india/gurgaon",
  //   "property_slug": "beri-house",
  //   "rental_property_id": 386,
  //   "currency_symbol": "₹",
  //   "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/beri-house/01_Facade__3_.jpeg",
  //   "thumbnail_alt": "Beri House - Facade",
  //   "guests": "Upto 12 guests",
  //   "bedroom_count": "5 Bedroom",
  //   "pools_count": "1 Pool",
  //   "baths_count": "5 Bathroom",
  // },
  // {
  //   "name": "The Kihim Courtyard",
  //   "address": "Kihim, Alibaug, Maharashtra, India",
  //   "starts_from": "38,000",
  //   "isprava_built": false,
  //   "location_slug": "/villas/india/alibaug",
  //   "property_slug": "kihim-courtyard",
  //   "rental_property_id": 351,
  //   "currency_symbol": "₹",
  //   "thumbnail_url": "https://d3oo9u3p09egds.cloudfront.net/rental_property/kihim-courtyard/01_Pool__3_.jpeg",
  //   "thumbnail_alt": "The Kihim Courtyard - Pool",
  //   "guests": "Upto 20 guests",
  //   "bedroom_count": "9 Bedroom",
  //   "pools_count": "1 Pool",
  //   "baths_count": "9 Bathroom",
  // },
];

export default CORPORATE_VILLAS;