import React, { useMemo } from "react";
import clxs from "./occasion-details.module.css";
import CLOSE_ICON from "../../../../assets/icons/close-occasion-details.svg";
import SendRequestForm from "../../../SendRequestForm";
import useSendRequest from "../../../../hooks/use-send-request";
import Button from "../../../Button";
import Img from "../../../Img";
import { Track } from "../../../AnalyticsManager";
import useContactUsPhoneNo from "../../../../hooks/use-contact-us-phone-no";
import CALL_PRIMARY_ICON from "../../../../assets/icons/call-primary.svg";
import useClx from "../../../../hooks/use-clx";

interface OccasionDetailsProps {
  onSubmit?: (out: any) => void;
  title?: string;
  header?: string;
  subtitle?: string;
  mobileThumbnail?: string;
  desktopThumbnail?: string;
}

function OccasionDetails(props: OccasionDetailsProps) {
  const { onSubmit, title, header, subtitle, mobileThumbnail, desktopThumbnail } = props;

    const defaultValue = useMemo(() => ({
      interest: "events_and_experience",
      message: `Interested in ${title} `,
    }), [title]),

    { friendly: contactUsPhoneNo, tel } = useContactUsPhoneNo(),
    contactUsContent = useMemo(
      () => `Call us ${contactUsPhoneNo}`,
      [contactUsPhoneNo],
    ),
    sucx = useClx(clxs.submit, clxs.action),
    cucx = useClx(clxs.callUs, clxs.action);

  const { sendRequest } = useSendRequest();

  return (
    <div className={clxs.container}>
      <img
        src={CLOSE_ICON}
        alt="close"
        className={clxs.close}
        onClick={onSubmit?.bind(null, null)}
      />
      <div className={clxs.contentContainer}>
        <div className={clxs.thumbnailBackground}>
          {mobileThumbnail && desktopThumbnail ?
          <Img
            alt="Occasion"
            src={desktopThumbnail}
            mobileSrc={mobileThumbnail}
            desktopSrc={desktopThumbnail}
            className={clxs.thumbnail}
          /> : null }
          <div className={clxs.backdrop}>&nbsp;</div>
        </div>
        <div className={clxs.content}>
          <div className={clxs.header}>{title}</div>
          <div className={clxs.title}>
            {header}
          </div>
          <div className={clxs.subtitle}>
            {subtitle}
          </div>
        </div>
      </div>
      <SendRequestForm
        id="contact-us-event"
        className={clxs.needHelp}
        titleNode={<div className={clxs.sendRequestTitle}>Send a request</div>}
        defaultValue={defaultValue}
        isClearOnSubmit={true}
        isOccasionForm={true}
        onSubmit={sendRequest}
      >
        <Button 
          className={sucx} 
          type="submit"
        >
          Submit
        </Button>
        <div className={clxs.orContainer}>
          <span className={clxs.or}>OR</span>
        </div>
        <Track event="call_us_desktop_clicked">
          <a 
            href={tel} 
            className={cucx}
          >
            <img 
              src={CALL_PRIMARY_ICON} 
              alt="call" 
              className={clxs.callIcon} 
            />
            {contactUsContent}
          </a>
        </Track>
      </SendRequestForm>
    </div>
  );
}

export default OccasionDetails;
