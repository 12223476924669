import React from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./elevated.module.css";
import ELEVATED_BACKGROUND from "../../../../assets/lohono-black/elevated-background.jpg";

interface ElevatedProps {
  className?: string;
}

function Elevated(props: ElevatedProps) {
  const{ className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <img
        src={ELEVATED_BACKGROUND}
        alt="elevated"
        className={clxs.background}
      />
      <div className={clxs.title}>
        Elevated Lohono
      </div>
      <div className={clxs.subtitle}>
        {"Elevated Living.  Elevated Luxury.  Elevated\nExperiences.  Elevated Estates."}
      </div>
      <a
        href="/lohono-black"
        className={clxs.discover}
      >
        Discover Lohono Black
      </a>
    </div>
  );
}

export default Elevated;
