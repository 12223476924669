import React from "react";
import useSendRequest from "../../../hooks/use-send-request";
import ContactUs from "../ContactUs";
import Header from "../Header";
import withLohonoBlackTheme from "../Theme";
import Banner from "./Banner";
import Bespoke from "./Bespoke";
import CasaRiviere from "./CasaRiviere";
import Elevated from "./Elevated";
import Intro from "./Intro";
import Luxury from "./Luxury";
import clxs from "./about.module.css";

const About = withLohonoBlackTheme(
  function AboutCore () {
    const { showSendRequestModal } = useSendRequest(),
      handleContactUs = async () => {
        await showSendRequestModal({
          interest: "rental",
          location: "india-karjat",
          brand: 5,
        });
      };

    return (
      <>
        <Header />
        <Banner onContactUs={handleContactUs} />
        <Intro
          className={clxs.section}
          titleClassName={clxs.title}
          subtitleClassName={clxs.subtitle}
        />
        <hr className={clxs.divider} />
        <Elevated
          className={clxs.section}
          titleClassName={clxs.title}
          subtitleClassName={clxs.subtitle}
        />
        <hr className={clxs.divider} />
        <Bespoke
          className={clxs.section}
          titleClassName={clxs.title}
          subtitleClassName={clxs.subtitle}
        />
        <hr className={clxs.divider} />
        <Luxury
          className={clxs.section}
          titleClassName={clxs.title}
          subtitleClassName={clxs.subtitle}
        />
        <hr className={clxs.divider} />
        <CasaRiviere
          className={clxs.section}
          subtitleClassName={clxs.subtitle}
          onContactUs={handleContactUs}
        />
        <ContactUs />
      </>
    );
  },
);

export default About;
