import React from "react";
import clxs from "./sitemap-item.module.css";

interface SitemapItemProps {
  partial: SitemapPartial;
}

function SitemapItem(props: SitemapItemProps) {
  const { partial: { title, links } } = props;

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        {title}
      </div>
      {links.map(
        (each, key) => (
          <a
            key={key}
            href={each.link}
            className={clxs.link}
          >
            {each.title}
          </a>
        ),
      )}
    </div>
  );
}

export default SitemapItem;
