import useUserService from "./use-user-service";
import { useEffect, useState } from "react";

function useReferHistory(session:string,refer_status:string,page:number) {
  const { service } = useUserService(),
    [referHistory, setReferHistory] = useState<ReferHistory[] | []>([]),
    getReferHistory = async (session:string, refer_status:string,page: number) => {
      if (!session) {
        return;
      }

      const { error, response } = await service.getReferHistory(
        session,
        refer_status,
        page,
      );

      if (error || !response) {
        return;
      }

      setReferHistory(response);
    };

  useEffect(() => {
    getReferHistory(session,refer_status,page);
  }, [session,refer_status,page]);

  return {
    referHistories: referHistory,
  };
}

export default useReferHistory;
