import React from "react";
import useClx from "../../../hooks/use-clx";
import Carousel from "../../Carousel";
import Item from "../../Carousel/Item";
import { useCarouselScroller } from "../../../hooks/use-carousel";
import clxs from "./proposition.module.css";
import LOHONO_STROKE_CLIPPED from "../../../assets/lohono-calligraphy-white.svg";
import CHEVRON_LEFT from "../../../assets/icons/chevron-left-grey.svg";
import CHEVRON_RIGHT from "../../../assets/icons/chevron-right-grey.svg";

interface PropositionProps {
  className?: string
}

function Proposition(props: PropositionProps) {
  const { className } = props,
    propositionCarouselId = "proposition-carousel",
    { scrollLeft, scrollRight } = useCarouselScroller(propositionCarouselId),
    ccx = useClx(clxs.container, className),
    lcx = useClx(clxs.scroller, clxs.scrollerLeft),
    rcx = useClx(clxs.scroller, clxs.scrollerRight);

  return (
    <div className={ccx}>
      <img
        src={LOHONO_STROKE_CLIPPED}
        alt="lohono"
        className={clxs.calligraphy}
      />
      <div className={clxs.title}>
        {"What’s in it for you?"}
      </div>
      <p className={clxs.description}>
        We understand the value of your holiday home. We also understand if managed well it can be a great source of
        income. Benefit from our pool of discerning guests, trained professionals, and efficient marketing tools to
        increase engagement.
      </p>
      <Carousel
        id={propositionCarouselId}
        className={clxs.carousel}
      >
        {PROPOSITION_TEMPLATE.map(
          (each, key) => (
            <Item
              key={key}
              className={clxs.proposition}
            >
              <div className={clxs.propositionTitle}>
                {each.title}
              </div>
              <div className={clxs.propositionDescription}>
                {each.description}
              </div>
            </Item>
          ),
        )}
      </Carousel>
      <div
        className={lcx}
        onClick={scrollLeft}
      >
        <img
          src={CHEVRON_LEFT}
          alt="left"
        />
      </div>
      <div
        className={rcx}
        onClick={scrollRight}
      >
        <img
          src={CHEVRON_RIGHT}
          alt="right"
        />
      </div>
    </div>
  );
}

export default Proposition;

const PROPOSITION_TEMPLATE = [
  {
    title: "Hassle-free upkeep of your home",
    description: "Our team of experts will ensure that your home is serviced to the minutest of details.",
  },
  {
    title: "Single window payment structure",
    description: "Your share will be deposited in your account every month, with no hidden costs.",
  },
  {
    title: "Truly enjoy your home",
    description: "Access to Lohono Stays’ premium hospitality when you visit your own home.",
  },
  {
    title: "Be part of an exclusive club",
    description: "Our guests are businessmen, celebrities and eminent personalities; a perfect platform to be amongst the world’s elite",
  },
  {
    title: "Access to the most luxurious homes",
    description: "Avail of your homeowner nights from the most luxurious holiday homes across the world.",
  },
];
