import useReservationService from "./use-reservation-service";
import { useEffect, useState } from "react";

function useCoupon(coupon_code: string) {
  const { service } = useReservationService(),
    [coupon, setCoupon] = useState<Coupon | null>(null),
    handleGetCoupon = async (coupon_code: string) => {
      if (!coupon_code) {
        setCoupon(null);
        return;
      }

      if (coupon_code === "NONE") {
        setCoupon(null);
        return;
      }

      if (coupon?.code === coupon_code) {
        return;
      }

      const { error, response } = await service.getCoupon(coupon_code);

      if (error || !response) {
        setCoupon(null);
        return;
      }

      setCoupon(response);
    };

  useEffect(
    () => {
      handleGetCoupon(coupon_code);
    },
    [coupon_code],
  );

  return { coupon: coupon };
}

function useCoupons(
  property_slug?: string,
  checkin_date?: string,
  checkout_date?: string,
  adult_count?: number,
  child_count?: number,
) {
  const { service } = useReservationService(),
    [coupons, setCoupons] = useState<Coupon[]>([]),
    handleGetCoupons = async (
      property_slug?: string,
      checkin_date?: string,
      checkout_date?: string,
      adult_count?: number,
      child_count?: number,
    ) => {
      if (!property_slug) {
        setCoupons([]);

        return;
      }

      const { error, response } = await service.getCoupons(
        property_slug,
        checkin_date,
        checkout_date,
        adult_count,
        child_count,
      );

      if (error || !response) {
        setCoupons([]);

        return;
      }

      setCoupons(response);
    };

  useEffect(
    () => {
      handleGetCoupons(
        property_slug,
        checkin_date,
        checkout_date,
        adult_count,
        child_count,
      );
    },
    [
      property_slug,
      checkin_date,
      checkout_date,
      adult_count,
      child_count,
    ],
  );

  return { coupons: coupons };
}

export {
  useCoupon,
  useCoupons,
};
