import usePrefetch from "../use-prefetch";
import {useAtomState} from "../use-atom";
import {PHONE_EXT_KEY} from "../../../core/constants";
import { useCallback, useEffect } from "react";
import useStaticContentService from "../use-static-content-service";

function usePhoneExts (): PhoneExt[] {
  const [phoneExts] = useAtomState<PhoneExt[]>(PHONE_EXT_KEY, []);

  return phoneExts;
}

function usePhoneExtsManager () {
  const {service} = useStaticContentService(),
    {hasPrefetchKey} = usePrefetch(),
    [_, setPhoneExts] = useAtomState<PhoneExt[]>(PHONE_EXT_KEY, []),
    handleGetPhoneExt = useCallback(
      async () => {
        if (hasPrefetchKey(PHONE_EXT_KEY)) {
          return;
        }

        const {error, response} = await service.getPhoneExt();

        if (error || !response) {
          return;
        }

        setPhoneExts(response);
      },
      [setPhoneExts],
    );

  useEffect(
    () => { handleGetPhoneExt(); },
    [handleGetPhoneExt],
  );
}

export default usePhoneExts;

export {
  usePhoneExtsManager,
}