import { useMemo } from "react";
import { useUser } from "./use-session";

function useLoyaltyPointsBalance () {
  const user = useUser();

  const points = useMemo(
    () => user?.loyalty_point_metadata.points_balance ?? 0,
    [user],
  );

  const friendly = useMemo(
    () => points?.toLocaleString("en-IN") ?? null,
    [points],
  );

  return {
    points: points,
    friendly: friendly,
  };
}

export default useLoyaltyPointsBalance;
