import React from "react";
import LoginFlow from "../../components/LoginFlow";
import Modal from "../use-modal";
import { LOGIN_MODAL_ID } from "./use-login-modal";

function LoginModal () {
  return (
    <Modal id={LOGIN_MODAL_ID}>
      <LoginFlow />
    </Modal>
  );
}

export default LoginModal;
