function sortByAspectRatio(
  images: PropertyImage[],
): PropertyImage[] {
  const sorted: (PropertyImage | undefined)[] = [];

  let landscapeIndex = -1,
    portraitIndex = -1,
    portraitCount = 0;

  for (let i = 0, iL = images.length; i < iL; i += 1) {
    const image = images[i],
      { aspect_ratio } = image;

    if (aspect_ratio === "portrait") {
      switch (portraitCount) {
        case 0: {
          portraitIndex = landscapeIndex + 1;

          landscapeIndex += 3;

          sorted.push(image, undefined, undefined);

          portraitCount += 1;

          break;
        }

        case 1: {
          portraitIndex += 1;

          portraitCount += 1;

          sorted[portraitIndex] = image;

          break;
        }

        case 2: {
          sorted[portraitIndex + 1] = image;

          portraitIndex = -1;

          portraitCount = 0;

          break;
        }
      }

      continue;
    }

    landscapeIndex += 1;

    sorted.push(image);
  }

  const filtered = sorted.filter(each => each !== undefined) as PropertyImage[];

  return filtered;
}

export default sortByAspectRatio;
