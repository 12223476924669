import React, { useState } from "react";
import Collapse from "../../Collapse";
import clxs from "./faq-item.module.css";

interface FaqItemProps {
  faq: FAQ;
  className?: string;
}

function FaqItem(props: FaqItemProps) {
  const [expanded, setExpanded] = useState<boolean>(false),
    { faq, className } = props,
    { question, answer } = faq;

  return (
    <div className={className}>
      <div
        className={clxs.question}
        onClick={setExpanded.bind(null, !expanded)}
      >
        {question}
        <div className={clxs.expandIcon}>
          {expanded ? "-" : "+"}
        </div>
      </div>
      <Collapse
        expanded={expanded}
        className={clxs.answer}
      >
        {answer}
      </Collapse>
    </div>
  );
}

export default FaqItem;
