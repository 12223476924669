import React from "react";
import useClx from "../../../hooks/use-clx";
import SendRequest from "../../SendRequestNeedHelp";
import clxs from "./banner.module.css";
import HOMEOWNER_BANNER from "../../../assets/homeowner-banner.jpg";

interface BannerProps {
  className?: string;
}

function Banner (props: BannerProps) {
  const { className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <a
        href="#contact"
        id="contact"
        className={clxs.anchor}
      >
        &nbsp;
      </a>
      <img
        src={HOMEOWNER_BANNER}
        alt="banner"
        className={clxs.background}
      />
      <div className={clxs.overlay}>&nbsp;</div>
      <h1 className={clxs.title}>
        Partner as a Homeowner
      </h1>
      <SendRequest
        title="Need help?"
        subtitle="Please share your name and number so our team can connect with you"
        className={clxs.sendRequest}
        interest="home_acqui"
      />
      <h4 className={clxs.subtitle}>
        Lohono Stays specializes in creating and curating luxury holiday homes all over the world.
      </h4>
    </div>
  );
}

export default Banner;
