import React, { MouseEvent, useMemo } from "react";
import clxs from "./verify-eligibility.module.css";

interface VerifyEligibilityProps {
  eligible?: boolean;
  onSubmit?: (action: string) => void;
}

function VerifyEligibility (props: VerifyEligibilityProps) {
  const { eligible = false, onSubmit } = props,
    negativeIntent = useMemo(
      () => {
        if (eligible) {
          return {
            intent: "cancel",
            content: "Cancel",
          };
        }

        return {
          intent: "raise_request",
          content: "Raise a request",
        };
      },
      [eligible],
    ),
    positiveIntent = useMemo(
      () => {
        if (eligible) {
          return {
            intent: "proceed",
            content: "Proceed",
          };
        }

        return {
          intent: "cancel",
          content: "Cancel",
        };
      },
      [eligible],
    ),
    handleSelection = (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      const target = e.target as HTMLButtonElement;

      const intent = target.dataset.intent ?? "";

      onSubmit?.(intent);
    };

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        We have checked your account
      </div>
      <div
        data-intent="dismiss"
        className={clxs.cancel}
        onClick={handleSelection}
      >
        ✕
      </div>
      {eligible ? (
        <div className={clxs.eligibilitySuccess}>
          <div className={clxs.check}>
            ✓
          </div>
          Your account meets the eligibility criteria for deletion
        </div>
      ) : (
        <div className={clxs.eligibilityFailure}>
          <div className={clxs.warning}>
            !
          </div>
          Your account does not meet the eligibility criteria for deletion.
        </div>
      )}
      <div className={clxs.content}>
        {eligible ? ELIGIBLE_CONTENT : INELIGIBLE_CONTENT}
      </div>
      <button
        data-intent={negativeIntent.intent}
        onClick={handleSelection}
        className={clxs.negativeIntent}
      >
        {negativeIntent.content}
      </button>
      <button
        data-intent={positiveIntent.intent}
        onClick={handleSelection}
        className={clxs.positiveIntent}
      >
        {positiveIntent.content}
      </button>
    </div>
  );
}

export default VerifyEligibility;

const ELIGIBLE_CONTENT = "Should you wish to proceed, we will ask you to verify your ownership through OTP.\n\nPlease note, you wont be able to revert the deletion hereafter.\n\nDo you wish to proceed?";

const INELIGIBLE_CONTENT = "You can come back once you fulfill all the conditions specified on previous page or raise a request to Lohono team";
