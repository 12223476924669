import Fetch from "../../utils/fetch";

class SendRequestService implements ISendRequestService {
  private readonly fetch: Fetch<ApiResult>;

  constructor(fetch: Fetch<ApiResult>) {
    this.fetch = fetch;
  }

  async save(
    brand: number,
    full_name: string,
    phone_ext: string,
    phone_no: string,
    email: string,
    interest: string,
    location: string,
    message: string,
    meta: any = {},
  ): Promise<FetchResult<any>> {
    const payload: any = {
        brand: brand,
        full_name: full_name,
        phone_ext: phone_ext,
        phone_no: phone_no,
        email: email,
        interest: interest,
        location: location,
        message: message,
        meta: meta,
      },
      url = new URL("/api/send-request", window.location.origin);

    return this.fetch.POST(url.toString(), payload);
  }
}

export default SendRequestService;
