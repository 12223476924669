import React from "react";
import clxs from "./points-history.module.css";
import CHEVRON_LEFT_ICON from "../../../assets/loyalty-points/arrow.svg";
import CLOSE_ICON from "../../../assets/icons/close.svg";
import PointsHistoryItem from "../PointsHistoryItem";

interface PointsHistoryProps {
  onSubmit?: (out: any) => void;
  pointsHistory?: LoyaltyPointsHistory[];
}

function PointsHistory(props: PointsHistoryProps) {
  const { onSubmit, pointsHistory } = props;
  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        <img
          src={CHEVRON_LEFT_ICON}
          alt="close"
          className={clxs.back}
          onClick={onSubmit?.bind(null, null)}
        />
        Points History
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={onSubmit?.bind(null, null)}
        />
      </div>
      <div>
        {(pointsHistory ?? []).map(
          (each, key) =>
            <PointsHistoryItem
              key={key}
              pointHistory={each}
            />,
        )}
      </div>
    </div>
  );
}

export default PointsHistory;