import { useCallback, useEffect } from "react";
import { useStorageString } from "./use-storage";

function useFingerprint (): string {
  const {
    value: fingerprint,
    setValue: setFingerprint,
  } = useStorageString("localStorage", "fingerprint");

  const handleDataChange = useCallback(
    async () => {
      if (fingerprint) {
        return;
      }

      // @ts-ignore
      const hash = window.crypto.randomUUID();

      setFingerprint(hash);
    },
    [setFingerprint, fingerprint],
  );

  useEffect(
    () => {
      handleDataChange();
    },
    [handleDataChange],
  );

  return fingerprint;
}

export default useFingerprint;
