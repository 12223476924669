import withThemeOverride from "../../../../utils/with-theme-override";
import clxs from "./loyalty-points-theme.module.css";
import LOHONO_LOGO_DARK from "../../../assets/logo/lohono-logo-dark.svg";
import ACCOUNT_ICON_DARK from "../../../assets/icons/account-primary-dark.svg";
import LOHONO_INFINITY_LOGO_DARK from "../../../assets/loyalty-points/lohono-infinity-logo-light.svg";

const withLoyaltyPointsTheme = withThemeOverride(
  clxs.theme,
  {
    logoSrc: LOHONO_LOGO_DARK,
    accountIconSrc: ACCOUNT_ICON_DARK,
    lohonoInfinityLogoSrc: LOHONO_INFINITY_LOGO_DARK,
  },
);

export default withLoyaltyPointsTheme;
