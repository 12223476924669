import { useAtomState } from "./use-atom";

class _DefaultOfferService implements IOfferService {
  async getFiresaleOffers(): Promise<FetchResult<PropertyWithOffer[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }
}

const DefaultOfferService = new _DefaultOfferService();

export const OFFER_SERVICE_KEY = "OFFER_SERVICE";

function useOfferService() {
  const [service, setService] =
    useAtomState<IOfferService>(
      OFFER_SERVICE_KEY,
      DefaultOfferService,
    );

  return {
    service: service,
    setService: setService,
  };
}

export default useOfferService;
