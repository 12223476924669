import React from "react";
import Img from "../../../Img";
import useClx from "../../../../hooks/use-clx";
import clxs from "./ocassion-item.module.css";

interface OccasionItemProps {
  title: string;
  thumbnailDesktop: string;
  thumbnailMobile: string;
  header?: string;
  subtitle?: string;
}

function OccasionItem(props: OccasionItemProps) {
  const p1cx = useClx(clxs.partial1, "partial1"),
    { title, thumbnailDesktop, thumbnailMobile, subtitle, header } = props;

  return (
    <div className={clxs.container}>
      <div className={p1cx}>
        <div className={clxs.thumbnailBackground}>
          <Img
            alt="Occasion"
            src={thumbnailDesktop}
            mobileSrc={thumbnailMobile}
            desktopSrc={thumbnailDesktop}
            className={clxs.thumbnail}
          />
          <div className={clxs.backdrop}>&nbsp;</div>
        </div>
        <div className={clxs.content}>
          <div className={clxs.header}>{title}</div>
          <div className={clxs.title}>
           {header}
          </div>
          <div className={clxs.subtitle}>
           {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OccasionItem;
