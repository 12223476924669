import React from "react";
import clxs from "./property-ratings.module.css";
import useClx from "../../../../hooks/use-clx";
import Stars from "../../GuestReviews/Stars";
// import RATING_ICON from "../../../../assets/icons/rating-icon.svg"

interface PropertyRatingsProps {
  className?: string;
  average_ratings:number;
  ratings_count:number;
  reviewRef?:React.RefObject<HTMLDivElement>;
}

function PropertyRatings(props: PropertyRatingsProps) {
  const { className ,average_ratings ,ratings_count ,reviewRef } = props;
  const ccx = useClx(clxs.container, className);

 
  // Function to scroll to the review section when the component is clicked
  const scrollIntoReview = () => {
    reviewRef?.current?.scrollIntoView({ behavior: "smooth",block: "start", inline: "start"});
  }

  return (
    <div className={ccx}>
      <div 
        className={clxs.reviewRatings} 
        onClick={scrollIntoReview}
      >
        <div className={clxs.avgRating}>
         {/* <img 
            src={RATING_ICON} 
            alt="icon" 
            className={clxs.ratingIcon} 
          /> */}
          {average_ratings}
          <Stars
            average_ratings={average_ratings}
            className={clxs.layoutStars}
            iconClassName={clxs.layoutStarsIcon}
            isShowEmptyStar={false}
          />
        </div>
        <div className={clxs.reviewCount}>
          {ratings_count}
          <div className={clxs.reviewText}> Reviews</div>
        </div>
      </div>
    </div>
  );
}

export default PropertyRatings;
