import React, { ChangeEvent, useEffect } from "react";
import clxs from "./pre-book-meals.module.css";
import { useQueryParam } from "../../../../../hooks/use-query-param";
import useClx from "../../../../../hooks/use-clx";
import { useSnackbar } from "../../../../../hooks/use-snackbar";


interface PreBookMealsProps {
  queryKey: string;
  className: string;
  theme?: string;
  form?: string;
  mealRef?:React.RefObject<HTMLDivElement>;
  handleMealChange?: (isChecked: boolean) => void;
}

function PreBookMeals(Props : PreBookMealsProps) {
  const {queryKey, className, theme, form, mealRef, handleMealChange} = Props,
  enqueueSnackbar = useSnackbar(),
  ccx = useClx(clxs.container, className),
  [checked, setChecked] = useQueryParam("boolean", queryKey),
  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      enqueueSnackbar(
        "Thank you! We've noted your interest in pre-booked meals",
        "success",
      );
    }
    handleMealChange && handleMealChange(isChecked);
  };

  const scrollIntoMealPlan = () => {
    mealRef?.current?.scrollIntoView({ behavior: "smooth",block: "start", inline: "start"});
  };

  useEffect(() => {
    setChecked(checked);
  }, [checked, setChecked]);

  return (
    <>
      <div className={ccx}>
        <label className={clxs.customcheckbox}>
        <div className={theme == "priceCalculator" ? clxs.priceboxbackground : clxs.boxbackground}>
          <input
            type="checkbox"
            id="pre_book_meals"
            name="pre_book_meals"
            checked={checked}
            onChange={handleChange}
            className={clxs.hiddencheckbox}
            form={form}
          />
          <span className={clxs.checkboxstyler}> </span>
        </div>
           <span className={clxs.checkboxcontent}>
           I’m interested to pre-book meals <sup></sup>
             <a
               href="#mealPlanOptions"
               onClick={scrollIntoMealPlan}
               rel="noreferrer noopener"
               className={theme == "priceCalculator" ? clxs.knowMore : clxs.hiddenKnowMore}
             >
               Know More
             </a>
           </span>
        </label>
      </div>
    </>
  );
}

export default PreBookMeals;
