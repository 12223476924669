import { DependencyList, useEffect, useState } from "react";

function useOnce (
  effect: () => boolean,
  deps?: DependencyList,
) {
 const [triggered, setTriggered] = useState<boolean>(false);

 useEffect(
   () => {
     if (triggered) {
       return;
     }

     const t = effect();

     setTriggered(t);
   },
   deps,
 );
}

export default useOnce;
