import {useCarouselScroller} from "./use-carousel";
import {useEffect, useState} from "react";

function useCarouselAutoscroll(carouselId: string, delay: number, enable: boolean = true) {
  const [iteration, setIteration] = useState<number>(0),
    [forward, setForward] = useState<boolean>(true),
    {leftCount, scrollLeft, rightCount, scrollRight} = useCarouselScroller(carouselId),
    handleScroll = () => {
      if (forward) {
        if (!rightCount) {
          setForward(false);
          scrollLeft();
          return;
        }

        scrollRight();
        return;
      } else {
        if (!leftCount) {
          setForward(true);
          scrollRight();
          return;
        }
        scrollLeft();
        return;
      }
    },
    handleSetInterval = (
      enable: boolean,
      delay: number,
      setIteration: (cb: (value: number) => number) => void,
    ) => {
      if (!enable) {
        return;
      }

      const descriptor = window.setInterval(
        () => setIteration(iteration => iteration + 1),
        delay,
      );

      return () => window.clearInterval(descriptor);
    };

  useEffect(
    () => handleSetInterval(enable, delay, setIteration),
    [enable, delay, setIteration],
  );

  useEffect(
    () => handleScroll(),
    [iteration],
  )
}

export default useCarouselAutoscroll;
