import React, { useCallback, useMemo } from "react";
import clxs from "./about.module.css";
import LOGO from "../../../assets/refer-n-earn/refer-logo.svg";
import ILLUSTRATION from "../../../assets/refer-n-earn/illustration.svg";
import { AnchorButton } from "../../Button";
import Button from "../../Button";
import useClx from "../../../hooks/use-clx";
import { useUser } from "../../../hooks/use-session";
import { useLoginModal } from "../../../hooks/use-login-modal";

interface AboutProps {
  title: string;
  containerClassName?: string;
  titleClassName?: string;
  infinityPoints?: number;
}

const About = (props: AboutProps) => {
  const { title, containerClassName, titleClassName } = props,
    showLoginFlowModal = useLoginModal(),
    handleLogin = useCallback(
      () => showLoginFlowModal({}),
      [showLoginFlowModal],
    ),
    user = useUser(),
    ccx = useClx(containerClassName, clxs.container),
    tcx = useClx(titleClassName, clxs.title),
    isHideCTA = useMemo(() => {
      const tier  =  user?.loyalty_point_metadata.tier;
      return ((user && user?.sign_up_referral_code?.length > 0) || (tier && tier != "no_tier"));
    }, [user]),
    anchorText = useMemo(() => {
      const text = isHideCTA ? "Find your Next stay" : "Explore Lohono";
      return text;
    }, [user]),
    referralBenefitPoints = useMemo(() => {
      const tier = user?.loyalty_point_metadata?.tier,
        isTierPresent = tier && tier != "no_tier",
        benefitPoints = isTierPresent ? user?.referral_benefits : "10,000";
      return benefitPoints;
    }, [user]);
  return (
    <div className={ccx}>
      <h1 className={tcx}>{title}</h1>
      <div className={clxs.content}>
        <img
          src={LOGO}
          alt="logo"
          className={clxs.logo}
        />
        <div>
          {"Earn "}
          <span className={clxs.highlight}>{"upto " + referralBenefitPoints.toLocaleString() +" Infinity points"}</span>
          {" when your connections \ncomplete their first stay with Lohono"}
        </div>
      </div>
      <img
        src={ILLUSTRATION}
        alt="illustration"
        className={clxs.illustration}
      />
      <div className={clxs.subContent}>
        {"* Exclusively available for members of "}
        <a
          href="../loyalty-points"
          className={clxs.link}
        >
          Lohono Infinity
        </a>
      </div>
      <div className={clxs.ctaContainer}>
        {!user ? (
          <Button
            className={clxs.cta}
            type="button"
            onClick={handleLogin}
          >
            Login / Create an Account
          </Button>
        ) : (
          <AnchorButton
            className={clxs.ctaLoggedIn}
            href="/#locations"
            rel="noreferrer noopener"
            data-referral-code={isHideCTA}
          >
            {anchorText}
          </AnchorButton>
        )}
      </div>
    </div>
  );
};

export default About;
