import React from "react";
import Modal from "../../hooks/use-modal";
import AppStore from "./AppStore";
import { APP_STORE_MODAL_ID } from "./use-app-store-modal";

function AppStoreModal () {
  return (
    <Modal id={APP_STORE_MODAL_ID}>
      <AppStore/>
    </Modal>
  );
}

export default AppStoreModal;
