import React, { useCallback, useEffect } from "react";
import useCountryCode, { CountryCodeMeta } from "../../hooks/use-country-code";
import useIpGeoLocation, { IPGeoLocation } from "../../hooks/use-ip-geo-location";
import Modal, { useShowModal } from "../../hooks/use-modal";
import { useStorageBoolean } from "../../hooks/use-storage";
import DomainPrompt from "./DomainPrompt";

function DomainManager () {
  const showModal = useShowModal(DOMAIN_PROMPT_KEY);

  const { value, setValue } = useStorageBoolean("localStorage", DOMAIN_PROMPT_KEY);

  const ipGeoLocation = useIpGeoLocation();

  const countryCodeMeta = useCountryCode();

  const handleDomainPrompt = useCallback(
    async (
      ipGeoLocation: IPGeoLocation,
      countryCodeMeta: CountryCodeMeta,
      value: boolean,
    ) => {
      const promptParameters = inferPromptParameters(countryCodeMeta, ipGeoLocation);

      if (!promptParameters) {
        return;
      }

      if (value) {
        return;
      }

      const payload = await showModal(promptParameters);

      setValue(true);

      if (!payload) {
        return;
      }

      return window.location.replace(payload.href);
    },
    [setValue],
  );

  useEffect(
    () => {
      handleDomainPrompt(ipGeoLocation, countryCodeMeta, value);
    },
    [ipGeoLocation, countryCodeMeta, value],
  );

  return (
    <>
      <Modal id={DOMAIN_PROMPT_KEY}>
        <DomainPrompt />
      </Modal>
    </>
  );
}

export default DomainManager;

const DOMAIN_PROMPT_KEY = "domain-prompt";

function inferPromptParameters (
  countryCodeMeta: CountryCodeMeta,
  ipGeoLocation: IPGeoLocation,
) {
  if (!countryCodeMeta.countryCode || !ipGeoLocation.countryCode) {
    return null;
  }

  if (ipGeoLocation.countryCode === countryCodeMeta.countryCode) {
    return null;
  }

  const partial = window.location.pathname + window.location.search + window.location.hash;

  const params = NAVIGATION_PARAMS[ipGeoLocation.countryCode] ?? NAVIGATION_PARAMS["GB"];

  return {
    ...params,
    href: `${params.href}${partial}`.trim(),
  };
}

const NAVIGATION_PARAMS = {
  "IN": {
    description: "You are currently viewing our international website. Do you want to be redirected to Lohono's Indian website?",
    href: "https://www.lohono.com",
  },
  "GB": {
    description: "You are currently viewing our India website. Do you want to be redirected to Lohono's international website?",
    href: "https://www.lohono.co.uk",
  },
}
