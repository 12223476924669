import React, { HTMLAttributes, memo, useMemo } from "react";
import useClx from "../../../../hooks/use-clx";
import calculateCalendarTemplate, { DateDecorator } from "./calculate-calendar-template";
import clxs from "./calendar-month.module.css";

interface CalendarMonthProps extends HTMLAttributes<HTMLDivElement> {
  monthDate: Date;
  startDate: Date | null;
  endDate: Date | null;
  className?: string;
  onDateDecorate?: DateDecorator;
}

const CalendarMonth = memo(
  function CalendarMonthCore (props: CalendarMonthProps) {
    const {
        className,
        monthDate,
        startDate,
        endDate,
        onDateDecorate,
        ...divProps
      } = props,
      ccx = useClx(clxs.container, className),
      dcx = useClx(clxs.date, "date"),
      { monthName, gap, template } = useMemo(
        () => calculateCalendarTemplate(
          monthDate,
          startDate,
          endDate,
          onDateDecorate,
        ),
        [monthDate, startDate, endDate, onDateDecorate],
      );

    return (
      <div
        {...divProps}
        data-component="month"
        className={ccx}
      >
        <div className={clxs.monthName}>
          {monthName}
        </div>
        <div className={clxs.dayName}>Sun</div>
        <div className={clxs.dayName}>Mon</div>
        <div className={clxs.dayName}>Tue</div>
        <div className={clxs.dayName}>Wed</div>
        <div className={clxs.dayName}>Thu</div>
        <div className={clxs.dayName}>Fri</div>
        <div className={clxs.dayName}>Sat</div>
        <div
          className={clxs.offset}
          data-offset={gap}
        >
          &nbsp;
        </div>
        {template.map(
          (each) => {
            const {
              date,
              fullDate,
              weekendType,
              monthDayType,
              selection,
              tense,
              customAttributes,
            } = each;

            return (
              <button
                {...customAttributes}
                key={fullDate}
                type="button"
                className={dcx}
                data-date={fullDate}
                data-weekend={weekendType}
                data-selection={selection}
                data-month-day={monthDayType}
                data-tense={tense}
              >
                <span className="background">
                  {date}
                </span>
              </button>
            );
          },
        )}
      </div>
    );
  },
);

export default CalendarMonth;
