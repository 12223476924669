import React from "react";
import clxs from "./location-header.module.css";
import useClx from "../../../hooks/use-clx";
import fwclxs from "../../../styles/full-width.module.css";

interface LocationHeaderProps {
  banner?: string;
  className?: string;
  filterDesktopClassName?: string;
  filterMobileClassName?: string;
  bannerTitle?: string;
  subtitle?: string;
}

function LocationHeader(props: LocationHeaderProps) {
  const { className, banner, bannerTitle, subtitle } = props,
    bcx = useClx(fwclxs.container, clxs.container, className);

  return (
    <div className={bcx}>
      <img
        loading="lazy"
        src={banner}
        alt="banner"
        className={clxs.background}
      />
      <div className={clxs.overlay}>&nbsp;</div>
      <div className={clxs.title}>{bannerTitle}</div>
      <h1 className={clxs.subtitle}>{subtitle}</h1>
    </div>
  );
}

export default LocationHeader;
