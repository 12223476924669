import React, { HTMLAttributes, MouseEvent, useCallback, useMemo, useState } from "react";
import CHEVRON_LEFT from "../../../assets/icons/chevron-left.svg";
import useAnalytics from "../../../hooks/use-analytics";
import useClx from "../../../hooks/use-clx";
import usePhoneExts from "../../../hooks/use-phone-exts";
import { useSession, useUser } from "../../../hooks/use-session";
import { useSnackbar } from "../../../hooks/use-snackbar";
import useUserService from "../../../hooks/use-user-service";
import { Track } from "../../AnalyticsManager";
import Form from "../../Form";
import { Select, TextField } from "../../Form/Fields";
import EDIT_SCHEMA from "./edit-schema";
import clxs from "./edit.module.css";

interface EditProps extends HTMLAttributes<HTMLDivElement> {
}

function Edit (props: EditProps) {
  const enqueueSnackbar = useSnackbar()

  const { track } = useAnalytics();

  const phoneExts = usePhoneExts();

  const session = useSession();

  const user = useUser();

  const { service: userService } = useUserService();

  const [editing, setEditing] = useState<boolean>(false);

  const handleUpdateUser = useCallback(
    async (values: any) => {
      if (!values) {
        return;
      }

      const { full_name = "", email = "" } = values;

      const { error } = await userService.updateUser(session, full_name, email);

      if (error) {
        enqueueSnackbar(error.message, "error");

        track("profile_edit_error", { error: error.message });

        return;
      }

      track("profile_edit_submitted");

      enqueueSnackbar("Your details have been updated", "success");

      setEditing(false);
    },
    [setEditing, userService, session],
  );

  const defaultValue = useMemo(
    () => {
      const [phoneExt, phoneNo] = (user?.phone_no ?? " ").split(" ");
      return {
        full_name: user?.full_name ?? "",
        email: user?.email ?? "",
        phone_ext: phoneExt ?? "",
        phone_no: phoneNo ?? "",
      };
    },
    [user],
  );

  const handleEdit = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const target = e.currentTarget as HTMLElement;

      const value = target.dataset.value === "true";

      setEditing(value);
    },
    [setEditing],
  );

  const { className, ...rest } = props;

  const ccx = useClx(clxs.container, className);

  return (
    <div
      {...rest}
      className={ccx}
    >
      <div className={clxs.header}>
        <a
          href="/profile"
          className={clxs.backIcon}
        >
          <img
            src={CHEVRON_LEFT}
            alt="back"
          />
        </a>
        Profile
        <Track event={editing ? "profile_edit_cancel_clicked" : "profile_edit_button_clicked"}>
          <button
            className={clxs.edit}
            data-value={!editing}
            onClick={handleEdit}
          >
            {editing ? "Cancel" : "Edit"}
          </button>
        </Track>
      </div>
      <div className={clxs.headerBorder}>
        &nbsp;
      </div>
      <Form
        key={user?.cid}
        id={FORM_ID}
        defaultValue={defaultValue}
        className={clxs.editForm}
        validationSchema={EDIT_SCHEMA}
        onSubmit={handleUpdateUser}
      >
        <TextField
          form={FORM_ID}
          name="full_name"
          label="Full name"
          className={clxs.twoColumn}
          disabled={!editing}
        />
        <TextField
          form={FORM_ID}
          name="email"
          label="Email"
          className={clxs.twoColumn}
          disabled={!editing}
        />
        <Select
          form={FORM_ID}
          name="phone_ext"
          options={phoneExts}
          label="Phone code"
          disabled={true}
        />
        <TextField
          form={FORM_ID}
          name="phone_no"
          label="Phone number"
          disabled={true}
        />
        {editing && (
          <button
            type="submit"
            className={clxs.save}
          >
            Save
          </button>
        )}
      </Form>
    </div>
  );
}

export default Edit;

const FORM_ID = "profile-edit";