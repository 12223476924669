import React, { HTMLAttributes, MouseEvent, useCallback } from "react";
import decodeLocationSlug from "../../../../utils/decode-location-slug";
import useClx from "../../../hooks/use-clx";
import useConfirmedReservations from "../../../hooks/use-confirmed-reservations";
import { useQueryParam } from "../../../hooks/use-query-param";
import { useSession } from "../../../hooks/use-session";
import { Track } from "../../AnalyticsManager";
import { AnchorButton } from "../../Button";
import ReservationItem from "./ReservationItem";
import clxs from "./trips.module.css";
import CHEVRON_LEFT from "../../../assets/icons/chevron-left.svg";
import NO_TRIPS_ILLUSTRATION from "../../../assets/no-trips-illustration.svg";

interface TripsProps extends HTMLAttributes<HTMLDivElement> {
}

function Trips (props: TripsProps) {
  const session = useSession();

  const [tab, setTab] = useQueryParam("string", "reservation_type", "confirmed");

  const { loading, reservations } = useConfirmedReservations(session, tab);

  const handleTabChange = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      const target = e.currentTarget as HTMLElement;

      const value = target.dataset.value;

      if (!value) {
        return;
      }

      setTab(value);
    },
    [setTab],
  );

  const { className, ...rest } = props;

  const ccx = useClx(clxs.container, className);

  return (
    <div
      {...rest}
      className={ccx}
    >
      <div className={clxs.header}>
        <a
          href="/profile"
          className={clxs.backIcon}
        >
          <img
            src={CHEVRON_LEFT}
            alt="back"
          />
        </a>
        My Trips
      </div>
      <div className={clxs.tabs}>
        <Track event="profile_my_trips_upcoming_clicked">
          <div
            className={clxs.tab}
            data-value="confirmed"
            data-active={tab === "confirmed"}
            onClick={handleTabChange}
          >
            Upcoming
          </div>
        </Track>
        <Track event="profile_my_trips_completed_clicked">
          <div
            className={clxs.tab}
            data-value="past"
            data-active={tab === "past"}
            onClick={handleTabChange}
          >
            Completed
          </div>
        </Track>
      </div>
      <div className={clxs.trips}>
        {loading ? null : reservations.length ? (
          reservations.map(
            (reservation, key) => {
              const decodedLocation = decodeLocationSlug(reservation.location_slug),
                { location, destination } = decodedLocation,
                propertySlug = reservation.property_slug,
                href = `/villas/${destination}/${location}/${propertySlug}`,
                payload: any = {
                  ...reservation,
                  ...decodedLocation,
                  href: href,
                  index: key,
                };

              return (
                <Track
                  key={key}
                  event="profile_trip_clicked"
                  payload={payload}
                >
                  <ReservationItem reservation={reservation} />
                </Track>
              );
            },
          )
        ) : (
          <div className={clxs.noTrips}>
            <img
              src={NO_TRIPS_ILLUSTRATION}
              alt="no trips"
            />
            {(
              tab === "confirmed"
                ? "No upcoming trips yet"
                : tab === "past"
                  ? "No Completed trips yet"
                  : "No trips found"
            )}
            <AnchorButton
              href="/villas/india/goa"
              className={clxs.noTripsCta}
            >
              Book Now
            </AnchorButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default Trips;
