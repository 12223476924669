const SANITATION_TEMPLATE = [
  "Sanitizer bottles are placed in all rooms and masks are provided at check-in",
  "We currently use only non-personal-contact greeting methods",
  "All surface areas are cleaned using WHO-approved disinfectants",
  "All luggage including handbags are wiped with WHO-approved disinfectants before placing them in the room",
  "We sanitize our villas for at least 24 hours before every check-in (have changed verbatim)",
  "Contactless temperature check is done for all guests during check-in (have changed verbatim)",
  "Our villa attendants wear gloves and masks at all times while on shift",
  "Our villa attendants disinfect surfaces like door knobs, tables and desks at regular intervals",
  "All kitchen equipment, appliances and utensils are sanitized before arrival and then every morning and evening during the stay",
  "All vegetables and are soaked in certified, non chemical cleaning agents",
  "Disposable crockery and cutlery is available on request",
  "Pool is cleaned and disinfected everyday (have changed verbatim)",
  "Temperature check is mandatory for all villa attendants everyday. Any attendant with temperature over 99.1°F is not allowed to enter the villa",
  "We allow increased ventilation by opening up windows and we adjust air conditioning throughout",
  "Stringent requirements ensure that our vendors and suppliers provide products and services that meet accredited hygiene standards",
];

const SANITATION_QUICK_LINK_TEMPLATE = [
  {
    title: "Goa",
    href: "/villas/india/goa",
    src:
      "https://isprava-oi-development.s3.amazonaws.com/rental_location/goa/Goa.jpg?tr=w-400,c-at_least",
  },
  {
    title: "Alibaug",
    href: "/villas/india/alibaug",
    src:
      "https://isprava-oi-development.s3.amazonaws.com/rental_location/alibaug/Alibaug.jpg?tr=w-400,c-at_least",
  },
  {
    title: "Lonavla",
    href: "/villas/india/lonavala",
    src:
      "https://isprava-oi-development.s3.amazonaws.com/rental_location/lonavala/Lonavala.jpeg?tr=w-400,c-at_least",
  },
];

export { SANITATION_TEMPLATE, SANITATION_QUICK_LINK_TEMPLATE };
