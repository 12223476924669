import { generatePropertyConfigKey } from "../../../../hooks/use-property-config";

function searchParamsFromFormValues(
  propertySlug: string,
  viewLayout: string,
  configs: PropertyConfig[],
  values: any,
): Record<string, [string]> {
  const {
      stay = { start: null, end: null },
      occupancy = { adult_count: 0, child_count: 0 },
      config_args = {},
      package_slug = "",
      pre_book_meals = false,
    } = values,
    { start: checkin_date, end: checkout_date } = stay,
    { adult_count, child_count } = occupancy,
    configArgs = viewLayout !== "package" ? 
      Object.entries(config_args as Record<string, number>)
      .reduce(
        (compiled, [key, value]) => {
          if (!value) {
            return compiled;
          }

          return {
            ...compiled,
            [key]: [value.toString()],
          };
        },
        {} as Record<string, string[]>,
      ) : configs.reduce(
        (compiled, config) => {
          const key = generatePropertyConfigKey(propertySlug, config);

          const value = package_slug === key ? "1" : "0"

          return {
            ...compiled,
            [key]: [value.toString()],
          };
        },
        {} as Record<string, string[]>,
      ),
    payload: Record<string, [string]> = {
      checkin_date: [checkin_date?.toISOString()],
      checkout_date: [checkout_date?.toISOString()],
      adult_count: [adult_count],
      child_count: [child_count],
      pre_book_meals: [pre_book_meals],
      ...configArgs,
    };

  return payload;
}

export default searchParamsFromFormValues;
