import React from "react";
import useClx from "../../../../hooks/use-clx";
import { Track } from "../../../AnalyticsManager";
import { AnchorButton } from "../../../Button";
import PointTag from "../../PointTag";
import clxs from "./no-tier.module.css";

interface NoTierPros {
  className?: string;
  onShowLoyaltyPointsHistory?: () => void;
}

function NoTier (props: NoTierPros) {
  const { className, onShowLoyaltyPointsHistory} = props;

  const ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <PointTag 
        className={clxs.points} 
        onShowLoyaltyPointsHistory={onShowLoyaltyPointsHistory}
      />
      <div className={clxs.title}>
        {"Plan a stay with us and level up to a "}
        <span className={clxs.silver}>
          Silver Membership
        </span>
      </div>
      <Track event="loyalty_points_book_now_clicked">
        <AnchorButton
          className={clxs.book}
          href="/#locations"
        >
          Book Now
        </AnchorButton>
      </Track>
    </div>
  );
}

export default NoTier;
