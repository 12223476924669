import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo } from "react";

function usePropertyConfigValues(
  propertySlug: string,
  configs: PropertyConfig[],
) {
  const [search, setSearch] = useSearchParams();

  const values = useMemo(
    () => {
      const hasOnlyOneConfig = configs.length === 1;

      const values: Record<string, number> = {};

      const configValueMap: Record<string, number> = {};

      const configArgs: PropertyConfigArg[] = [];

      const configMap: Record<number, PropertyConfig> = {};

      const configMaxCount: Record<string, number> = {};

      let configsSelectedCount = 0;

      for (const config of configs) {
        const key = generatePropertyConfigKey(propertySlug, config);

        const value = Number.parseInt(search.get(key) || "0");

        const safeValue = Number.isNaN(value) ? 0 : value;

        values[key] = safeValue;

        configValueMap[config.config_id] = safeValue;

        configMap[config.config_id] = config;

        configMaxCount[key] = config.available_inventory || 0;

        const requiredRooms = hasOnlyOneConfig ? 1 : safeValue;

        if (requiredRooms) {
          configsSelectedCount += 1;
        }

        configArgs.push({
          property_config_id: config.config_id,
          required_rooms: requiredRooms,
        });
      }

      return {
        values: values,
        configValueMap: configValueMap,
        configMap: configMap,
        configArgs: configArgs,
        configMaxCount: configMaxCount,
        configsSelectedCount: configsSelectedCount,
      };
    },
    [propertySlug, configs, search],
  );

  const setPropertyConfigValue = useCallback(
    (configId: string | PropertyConfig, value: string) => {
      const key = generatePropertyConfigKey(propertySlug, configId);

      const updatedSearch = new URLSearchParams(search);

      const _value = Number.parseInt(value || "0");

      const safeValue = Number.isNaN(_value) ? 0 : _value;

      if (!safeValue) {
        updatedSearch.delete(key);
      } else {
        updatedSearch.set(key, safeValue.toString());
      }

      setSearch(updatedSearch);
    },
    [setSearch, propertySlug, search],
  );

  return {
    ...values,
    setPropertyConfigValue: setPropertyConfigValue,
  };
}

export {
  generatePropertyConfigKey,
  usePropertyConfigValues,
};

function generatePropertyConfigKey(
  propertySlug: string,
  configIdOrConfig: PropertyConfig | string,
): string {
  const configId = (configIdOrConfig as PropertyConfig)?.config_id ?? configIdOrConfig;

  const key = `${propertySlug}_${configId}`;

  return key;
}
