import { LOYALTY_POINTS_POLICY_KEY } from "../../../core/constants";
import { useAtomState } from "../../hooks/use-atom";

function useLoyaltyPointPolicy () {
  const [policy] = useAtomState<LoyaltyPointsPolicy>(LOYALTY_POINTS_POLICY_KEY, DEFAULT_POLICY);

  return policy;
}

export default useLoyaltyPointPolicy;

const DEFAULT_POLICY: LoyaltyPointsPolicy = {
  faq: [],
  tiers: [],
};
