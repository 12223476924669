import React, { useMemo } from "react";
import { useCarouselScroller } from "../../../../hooks/use-carousel";
import useClx from "../../../../hooks/use-clx";
import Carousel from "../../../Carousel";
import Item from "../../../Carousel/Item";
import Scroller from "../../../Scroller";
import clxs from "./room-layout.module.css";
import BEDROOM_ICON from "../../../../assets/icons/bedroom-small.svg";

interface RoomLayoutProps {
  carouselId: string;
  bedrooms: Bedroom[];
  title: string;
  className?: string;
  containerClassName?: string;
  titleClassName?: string;
}

function RoomLayout(props: RoomLayoutProps) {
  const { 
    carouselId,
    title,
    titleClassName,
    className,
    bedrooms,
    containerClassName,
  } = props,
  { scrollLeft, scrollRight } = useCarouselScroller(carouselId),
  ccx = useClx(clxs.container, className),
  tcx = useClx(titleClassName, clxs.placeholder),
  cacx = useClx(clxs.carousel, containerClassName);

  return (
    <div className={ccx}>
      <div className={tcx}>
        {title}
      </div>
      <Carousel 
        id={carouselId} 
        className={cacx}
        threshold={0.9}
        adjust={true}
      >
        {bedrooms.map(
          (each, key) => {
            const {
              amenities,
              bed_type,
              floor_name,
              image,
            } = each,
            {
              url,
              alt,
              caption,
              aspect_ratio,
            } = image[0] ?? {
              url: "",
              alt: "",
              caption: "",
              aspect_ratio: "",
            },
            topAmenities = useMemo(
              () => amenities.slice(0, 3),
              [amenities],
            ),
            icx = useClx(clxs.bedroomImage, aspect_ratio),
            itemcx = url ? clxs.item : clxs.itemNoImageBedroom,
            bedroomImageSrc = url ? url : BEDROOM_ICON;

            return(
              <Item
                key={key}
                className={itemcx}
              >
                <img
                  loading="lazy"
                  src={bedroomImageSrc}
                  alt={alt}
                  title={caption}
                  className={url ? icx : clxs.thumbnail}
                />
                <div className={clxs.bedroomTitle}>
                  {bed_type}
                </div>
                <div className={clxs.floor}>
                  {floor_name}
                </div>
                {amenities.length ? (
                  <ul className={clxs.amenities}>
                  {topAmenities.map(
                    (each, key) => (
                      <li key={key}>
                        {each}
                      </li>
                    ),
                  )}
                </ul>
                ) : null}
              </Item>
            );
          },
        )}
      </Carousel>
      <Scroller
        className={clxs.scroller}
        onScrollLeft={scrollLeft}
        onScrollRight={scrollRight}
      />
    </div>
  )
}

export default RoomLayout;
