import React, { ForwardedRef, forwardRef, MouseEventHandler } from "react";
import { getPropertySearchUrl } from "../../../utils/property-search";
import useClx from "../../hooks/use-clx";
import NetworkImg from "../NetworkImg";
import clxs from "./location-anchor.module.css";

interface LocationAnchorProps {
  name: string,
  slug: string,
  property_count: number,
  coming_soon: boolean,
  country: string,
  className?:string,
  thumbnail: string,
  url?: string;
  onClick?: MouseEventHandler;
}

const LocationAnchor = forwardRef(
  function LocationAnchorCore(
    props: LocationAnchorProps,
    ref: ForwardedRef<HTMLAnchorElement | HTMLDivElement>,
  ) {
    const {
        name,
        slug,
        thumbnail,
        coming_soon,
        className,
        url,
        onClick,
      } = props,
      title = `Luxury villas for rent in ${name}`,
      safeUrl = url ?? getPropertySearchUrl(slug),
      ccx = useClx(clxs.location, className),
      tcx = useClx(clxs.title, "title");

    if (coming_soon) {
      return (
        <div
          title={title}
          className={ccx}
          onClick={onClick}
          ref={ref as ForwardedRef<HTMLDivElement>}
        >
          <NetworkImg
            src={thumbnail}
            alt={"properties in " + name}
            className={clxs.image}
            loading="lazy"
            mobileDimensions={[300, 300]}
            desktopDimensions={[600, 600]}
          />
          <div className={tcx}>
            {name}
          </div>
        </div>
      );
    }

    return (
      <a
        className={ccx}
        href={safeUrl}
        onClick={onClick}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
      >
        <NetworkImg
          src={thumbnail}
          alt={name}
          className={clxs.image}
          loading="lazy"
          mobileDimensions={[300, 300]}
          desktopDimensions={[600, 600]}
        />
        <div className={tcx}>
          {name}
        </div>
      </a>
    );
  },
);

export default LocationAnchor;

