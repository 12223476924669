import { useCallback, useMemo } from "react";
import { fromQueryValue, toQueryValue } from "./marshal";
import { QueryType, QueryValue, QueryValueSetter } from "./query-param";
import useQuerySearch, { QuerySearch } from "./use-query-search";

function useQueryParam<T extends QueryType> (
  queryType: T,
  queryKey: string,
  defaultValue?: QueryValue<T>,
): [QueryValue<T>, QueryValueSetter<T>] {
  const [search, setSearch] = useQuerySearch();

  const value = useMemo(
    () => {
      const searchValue: string[] = search[queryKey] ?? [];

      const value = toQueryValue(
        queryType,
        queryKey,
        searchValue,
        defaultValue,
      );

      return value;
    },
    [
      queryType,
      queryKey,
      search,
    ],
  );

  const handleSetValue: QueryValueSetter<T> = useCallback(
    (value, options) => {
      const searchValue = fromQueryValue(queryType, value);

      setSearch(
        search => {
          const updated = unsetQueryParams(search, options?.reset ?? false);

          if (searchValue.length) {
            updated[queryKey] = searchValue;
          } else {
            delete updated[queryKey];
          }

          return updated;
        },
        options,
      );
    },
    [setSearch, queryKey, queryType],
  );

  return [value, handleSetValue];
}

export default useQueryParam;

function unsetQueryParams (
  search: QuerySearch,
  paramsToUnset: boolean | string[],
) {
  if (paramsToUnset === true) {
    return {};
  }

  if (Array.isArray(paramsToUnset) && paramsToUnset.length) {
    const updated = { ...search };

    for (const key of paramsToUnset) {
      delete updated[key];
    }

    return updated;
  }

  return { ...search };
}
