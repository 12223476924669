import React, { HTMLAttributes, ReactNode, useMemo, useRef } from "react";
import useAnchorPosition from "../../hooks/use-anchor-position";
import useClx from "../../hooks/use-clx";
import useDialogToggle from "../../hooks/use-dialog-toggle";
import Portal from "../Portal";
import clxs from "./context-menu.module.css";
import EXPAND_LESS_ICON from "../../assets/icons/expand-less-grey.svg";
import EXPAND_MORE_ICON from "../../assets/icons/expand-more-grey.svg";

interface ContextMenuProps extends BaseDivProps {
  children: ReactNode;
  menu: ReactNode;
  menuClassName?: string;
  portalClassName?: string;
  expandIcon?: string;
  collapseIcon?: string;
}

function ContextMenu (props: ContextMenuProps) {
  const {
    children,
    menu,
    menuClassName,
    portalClassName,
    className,
    expandIcon = EXPAND_MORE_ICON,
    collapseIcon = EXPAND_LESS_ICON,
    ...rest
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const { open, handleToggle } = useDialogToggle(ref, false);

  const { boundingRect, anchorPosition } = useAnchorPosition(ref);

  const positionStyles = useMemo(
    () => {
      const { left = 0, right = 0, bottom = 0 } = boundingRect ?? {};

      const styles: any = [
        `--menu-bottom: ${bottom}px;`,
        `--menu-left: ${left}px;`,
        `--menu-right: ${right}px`,
      ].join(";");

      return styles;
    },
    [boundingRect],
  );

  const mcx = useClx(clxs.menu, menuClassName);

  const pcx = useClx(clxs.portal, portalClassName);

  return (
    <>
      <div
        {...rest}
        className={className}
        ref={ref}
        onClick={handleToggle}
      >
        {children}
        <img
          src={open ? collapseIcon : expandIcon}
          alt="expand"
          className={clxs.expand}
        />
      </div>
      <Portal
        className={pcx}
        style={positionStyles}
        scrollBlock={open}
      >
        <div
          className={mcx}
          data-position={anchorPosition}
          data-open={open}
          data-dismiss={true}
        >
          {menu}
        </div>
      </Portal>
    </>
  );
}

export default ContextMenu;

type BaseDivProps = Omit<HTMLAttributes<HTMLDivElement>, "onClick">;
