import * as Yup from "yup";

const CHECKOUT_SCHEMA = Yup.object({
  full_name: Yup.string().trim().required("Required"),
  phone_ext: Yup.string().trim().required("Required"),
  phone_no: Yup.string()
    .trim()
    .matches(/^[0-9]{8,12}$/gi, "Invalid phone no")
    .required("Required"),
  message: Yup.string().trim(),
  tnc: Yup.boolean()
    .oneOf([true], "Required")
    .required("Required"),
});

export default CHECKOUT_SCHEMA;
