import React, { MouseEventHandler } from "react";
import CR_BANNER from "../../../../assets/lohono-black/casa-riviere-banner.jpg";
import useClx from "../../../../hooks/use-clx";
import Button, { AnchorButton } from "../../../Button";
import clxs from "./casa-reviere.module.css";

interface CasaRiviereProps {
  subtitleClassName?: string;
  className?: string;
  onContactUs?: MouseEventHandler<HTMLButtonElement>;
}

function CasaRiviere (props: CasaRiviereProps) {
  const { className, subtitleClassName, onContactUs } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div>
        <div className={clxs.title}>
          Casa Riviere
        </div>
        <div className={subtitleClassName}>
          Karjat, Maharashtra, India
        </div>
      </div>
      <img
        src={CR_BANNER}
        alt="banner"
        className={clxs.banner}
      />
      <div className={subtitleClassName}>
        A private 12-acre property, a striking house perched on the edge of a lake, and a dramatic landscape that will
        take your breath away.
        The need to escape the bustling city, embrace nature and feel a sense of peace is what inspired the homeowners
        to create this once-in-a-lifetime
        estate.
      </div>
      <div className={clxs.action}>
        <AnchorButton
          href="/villas/india/karjat/casa-riviere"
          className={clxs.viewDetails}
        >
          View Details
        </AnchorButton>
        <Button
          className={clxs.contactUs}
          onClick={onContactUs}
        >
          Contact Us
        </Button>
      </div>
    </div>
  );
}

export default CasaRiviere;
