import usePropertyService from "./use-property-service";
import { useEffect, useState } from "react";
import useQueryParamValue from "./use-query-param/use-query-param-value";
import { useSession } from "./use-session";

function usePropertySimilar(
  propertySlug: string,
  isEH: boolean = false,
  adultCountKey: string,
  childCountKey: string,
  checkinDateKey?: string,
  checkoutDateKey?: string,
) {
  const { service } = usePropertyService(),
    checkinDate = useQueryParamValue("date", checkinDateKey || ""),
    checkoutDate = useQueryParamValue("date", checkoutDateKey || ""),
    adultCount = useQueryParamValue("integer", adultCountKey),
    childCount = useQueryParamValue("integer", childCountKey),
    [similarProperties, setSimilarProperties] = useState<PropertyItem[]>([]),
    session = useSession(),

    handleGetSimilarProperties = async (
      propertySlug: string,
      isEH: boolean,
      adultCount: number,
      childCount: number,
      checkinDate?: Date | null,
      checkoutDate?: Date | null,
    ) => {

      const { error, response } = await service.getSimilarProperties(
        propertySlug,
        isEH,
        adultCount,
        childCount,
        checkinDate?.toISOString(),
        checkoutDate?.toISOString(),
        session,
      );

      if (error || !response) {
        return;
      }

      setSimilarProperties(response);
    };

  useEffect(() => {
    handleGetSimilarProperties(
      propertySlug,
      isEH,
      adultCount,
      childCount,
      checkinDate,
      checkoutDate,
    );
  }, [propertySlug, isEH, adultCount, childCount,checkinDate, checkoutDate]);

  return { similarProperties: similarProperties };
}

export default usePropertySimilar;
