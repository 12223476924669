import React, { useEffect } from "react";
import useCountryOptions from "../../hooks/use-country-options";
import Modal, { useShowModal } from "../../hooks/use-modal";
import { useQueryParamValue } from "../../hooks/use-query-param";
import { useSnackbar } from "../../hooks/use-snackbar";
import useUserService from "../../hooks/use-user-service";
import useWindowNavigate from "../../hooks/use-window-navigate";
import Button from "../Button";
import Form from "../Form/Form";
import Select from "../Form/Fields/Select";
import TextArea from "../Form/Fields/TextArea";
import DELETE_ACCOUNT_SCHEMA from "./delete-account-schema";
import VerifyEligibility from "./VerifyEligibility";
import clxs from "./delete-account.module.css";
import CHEVRON_LEFT from "../../assets/icons/chevron-left.svg";
import { useLoginModal } from "../../hooks/use-login-modal";
import { useStorageString } from "../../hooks/use-storage";
import { useUser } from "../../hooks/use-session";

function DeleteAccount () {
  const push = useWindowNavigate();

  const enqueueSnackbar = useSnackbar();

  const { service: userService } = useUserService();

  const user = useUser();

  const countryOptions = useCountryOptions();

  const showLoginFlowModal = useLoginModal();

  const showEligibilityModal = useShowModal(ELIGIBILITY_MODAL);

  const {value:sessionId} = useStorageString("localStorage",SESSION_KEY);

  const sessionEsId = useQueryParamValue("string", "session");

  const accountType = useQueryParamValue("string", "account_type");

  const callback = useQueryParamValue("string", "callback");

  const platform = useQueryParamValue("string", "platform");

  const existingEsId = user?.session_esid ? user?.session_esid : "";

  const accountTypeWeb = "guest";

  const callbackWeb = "/delete-account/result";


  const handleCancel = () => {
    const search = new URLSearchParams();

    search.set("intent", "cancel");

    push({
      pathname: callback,
      search: search,
    });
  };

  const handleRaiseRequest = async (values: any) => {
    const { error } = await userService.deleteAccountRaiseRequest(
      platform == "web" ? accountTypeWeb : accountType ,
      platform == "web" ? existingEsId : sessionEsId,
      values.country_code,
      values.reason,
    );

    if (error) {
      enqueueSnackbar(error.message, "error");

      return;
    }

    const search = new URLSearchParams();

    enqueueSnackbar(RAISE_REQUEST_SUCCESS, "success");

    setTimeout(
      () => {
        search.set("intent", "cancel");

        push({
          pathname: callback,
          search: search,
        });
      },
      5000,
    );
  };

  const handleProceed = async (
    phoneNo: string,
    values: any,
  ) => {
    const { error } = await userService.deleteAccount(
      platform == "web" ? accountTypeWeb : accountType ,
      platform == "web" ? existingEsId : sessionEsId,
      values.country_code,
      values.reason,
      false,
    );

    if (error) {
      enqueueSnackbar(error.message, "error");

      return;
    }

    const search = new URLSearchParams();

    search.set("phone_no", phoneNo);

    search.set("account_type",  platform == "web" ? accountTypeWeb : accountType );

    search.set("session", platform == "web" ? existingEsId : sessionEsId);

    search.set("callback", platform == "web" ? callbackWeb : callback );

    push({
      pathname: "/delete-account/verify-otp",
      search: search,
    });
  };

  const handleDeleteAccount = async (values: any) => {
    if (!values) {
      return;
    }

    if (!sessionId?.length && !sessionEsId?.length) {
      handleUserLogin();
      return;
    }

    const { error, response } = await userService.deleteAccount(
      platform == "web" ? accountTypeWeb : accountType ,
      platform == "web" ? existingEsId : sessionEsId,
      values.country_code,
      values.reason,
      true,
    );


    if (error) {
      enqueueSnackbar(error.message, "error");

      return;
    }

    if (!response) {
      enqueueSnackbar("something went wrong", "error");

      return;
    }

    const intent = await showEligibilityModal({ eligible: response.eligible });

    if (!intent) {
      return;
    }

    if (intent === "cancel") {
      return handleCancel();
    }

    if (intent === "raise_request") {
      return handleRaiseRequest(values);
    }

    if (intent === "proceed") {
      return handleProceed(response.phone_no, values);
    }
  };

  const handleUserLogin = () => {
    if(!sessionId?.length && !sessionEsId?.length){ 
        showLoginFlowModal({});   
    }
  }

  useEffect(
    () => {
      handleUserLogin();
    },[],
  )

  return (
    <>
      <div className={clxs.container}>
        <div className={clxs.titleContainer}>
          <img
            loading="lazy"
            src={CHEVRON_LEFT}
            alt="chevron-left"
            onClick={handleCancel}
          />
          Delete My Account
        </div>
        <div className={clxs.warningContainer}>
          <div className={clxs.title}>
            What happens when you delete your account?
          </div>
          <ul className={clxs.notes}>
            <li className={clxs.note}>
              Once your request is processed, your personal data will be deleted (except for certain information that we
              are legally required or permitted to retain, as outlined in our Privacy Policy).
            </li>
            <li className={clxs.note}>
              If you try to login in future, neither you nor Lohono would be able to retrieve past information in any
              way and you will be assigned a new user identity post login.
            </li>
          </ul>
        </div>
        <div className={clxs.requirementsContainer}>
          <div className={clxs.title}>What is required to delete my account?</div>
          <div className={clxs.subTitle}>Your account needs to meet the following criteria to proceed with deletion -
          </div>
          <ul className={clxs.notes}>
            <li className={clxs.note}>
              All the trips should be completed before deletion. If there is an ongoing trip, you can request for
              deletion after the completion of the trip.
            </li>
            <li className={clxs.note}>
              If there is an upcoming trip, you will have to wait till the trip is complete. Alternatively, you can
              cancel the trip as per the cancellation policy.
            </li>
            <li className={clxs.note}>
              There should not be any outstanding settlements for the previous trips such as security deposit refunds or
              outstanding payments for additional purchases.
            </li>
            <li className={clxs.note}>
              {"There shouldn't be any unused credits in the account."}
            </li>
          </ul>
        </div>
        <Form
          id={FORM_ID}
          defaultValue={DEFAULT_VALUES}
          onSubmit={handleDeleteAccount}
          validationSchema={DELETE_ACCOUNT_SCHEMA}
        >
          <Select
            form={FORM_ID}
            name="country_code"
            options={countryOptions}
            label="Where do you reside? *"
            className={clxs.selectMenu}
          />
          <TextArea
            form={FORM_ID}
            name="reason"
            label="Reason for deletion *"
            className={clxs.reason}
            rows={4}          
          />
          <Button
            className={clxs.continue}
            type="submit"
            form={FORM_ID}
          >
            Continue
          </Button>
        </Form>
      </div>
      <Modal id={ELIGIBILITY_MODAL}>
        <VerifyEligibility />
      </Modal>
    </>
  );
}

export default DeleteAccount;

const RAISE_REQUEST_SUCCESS = "Your request has been successfully captured, you'll be taken back shortly";

const FORM_ID = "delete-account";

const DEFAULT_VALUES = {
  reason: "",
  country_name: "+91",
  country_code: "IN",
};

const ELIGIBILITY_MODAL = "eligibility-modal";

const SESSION_KEY = "SESSION";