import * as Yup from "yup";

const EDIT_SCHEMA = Yup.object({
  full_name: Yup.string().trim().required("Required"),
  email: Yup.string()
    .trim()
    .email("Invalid email address"),
});

export default EDIT_SCHEMA;
