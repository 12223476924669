import { useCallback, useEffect } from "react";

function useScrollBlock (block?: boolean) {
  const handleBlock = useCallback(
    (block?: boolean) => {
      if (typeof document === "undefined") {
        return;
      }

      if (!block) {
        return;
      }

      document.body.dataset.scrollable = "false";

      return () => {
        document.body.dataset.scrollable = "true";
      };
    },
    [],
  );

  useEffect(
    () => handleBlock(block),
    [handleBlock, block],
  );
}

export default useScrollBlock;
