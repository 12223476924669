const FINANCIAL_EXPRESS_WEBSITE_FEATURE: Press = {
  title: "Luxury Rental Home in Goa",
  banner: "/press/financial-express-feature-website-small.jpg",
  href: "/press/financial-express-feature-website",
  publication: "Financial Express",
  assets: [
    {
      file: "/press/financial-express-feature-website.jpg",
      type: "image",
    },
  ],
  seo_title: "Finally 'Goa ka plan' Materializing: Bookings Surge - Press Release - Lohono Stays",
  seo_description: "The travel industry seems to be getting back on track as the booking for luxury rental homes in Goa surge to almost 100 percent occupancy! Check out what insights our Co-Founder & CEO, Nibhrant Shah had to share on this!",
  seo_canonical:
    "https://www.lohono.com/press/financial-express-feature-website",
};

const BRANCO_ARCHITECTURAL_DIGEST: Press = {
  title: "Exclusive feature on Villa Branco, Goa",
  banner: "/press/ad-branco-1-small.jpg",
  href: "/press/architectural-digest-branco-you-are-beautiful",
  publication: "Architectural Digest",
  assets: [
    {
      file: "/press/ad-branco-1.jpg",
      type: "image",
    },
    {
      file: "/press/ad-branco-2.png",
      type: "image",
    },
  ],
  seo_title: "Architectural Digest - Lohono Stays Press",
  seo_description: "Architectural Digest reviews villa Branco by Isprava",
  seo_canonical:
    "https://www.lohono.com/press/architectural-digest-branco-you-are-beautiful",
};

const VERDE_BUSINESS_TRAVELLER: Press = {
  title: "Villa Verde",
  banner: "/press/business-traveller-india-1-small.jpg",
  href: "/press/business-traveller-villa-verde",
  publication: "Business Traveller India",
  assets: [
    {
      file: "/press/business-traveller-india-1.jpg",
      type: "image",
    },
  ],
  seo_title: "Business Traveller India - Lohono Stays Press",
  seo_description: "Read about Isprava's journey with Business Traveller",
  seo_canonical: "https://www.lohono.com/press/business-traveller-villa-verde",
};

const REDEFINE_LUXURY_BUSINESS_TRAVELLER: Press = {
  title: "Redefining Luxury Living",
  banner: "/press/business-traveller-india-2-small.jpg",
  href: "/press/business-traveller-redefining-luxury-living",
  publication: "Business Traveller India",
  assets: [
    {
      file: "/press/business-traveller-india-2.jpg",
      type: "image",
    },
  ],
  seo_title: "Business Traveller India - Lohono Stays Press",
  seo_description:
    "Business Traveller descibes Isprava as one of India's leading luxury home developers and how Isprava is increasing its footprint in the country's leisure destination",
  seo_canonical:
    "https://www.lohono.com/press/business-traveller-redefining-luxury-living",
};

const ALBANY_CASA_VOGUE: Press = {
  title: "Exclusive feature of Albany Cottage, Coonoor and Villa Verde, Goa",
  banner: "/press/casa-vogue-1-small.jpg",
  href: "/press/casa-vogue-albany-cottage-and-villa-verde",
  publication: "Casa Vogue",
  assets: [
    {
      file: "/press/casa-vogue-1.jpg",
      type: "image",
    },
    {
      file: "/press/casa-vogue-2.jpg",
      type: "image",
    },
  ],
  seo_title: "Casa Vogue - Lohono Stays Press",
  seo_description: "Casa Vogue - Lohono Stays Press",
  seo_canonical:
    "https://www.lohono.com/press/casa-vogue-albany-cottage-and-villa-verde",
};

const EVORA_NAST_TRAVELLER: Press = {
  title:
    "Isprava's Villa Evora in Goa is one of the 10 beautiful villas to rent this monsoon",
  banner: "/press/conde-nast-traveler-2018-small.jpg",
  href: "/press/conde-nast-traveller-isprava-villa-evora-in-goa",
  publication: "Condé Nast Traveler",
  assets: [
    {
      file: "/press/conde-nast-traveler-2018.jpeg",
      type: "image",
    },
  ],
  seo_title: "Conde Nest Traveller - Lohono Stays Press",
  seo_description:
    "Conde Nest reviews Villa Evora and rates the villa as one of the 10 beautiful villas to rent this monsoon",
  seo_canonical:
    "https://www.lohono.com/press/conde-nast-traveller-isprava-villa-evora-in-goa",
};

const CRAFTING_LUXURY_ELLE_DECORE: Press = {
  title: "Crafting your legacy of luxury",
  banner: "/press/elle-decor-2018-1-small.jpg",
  href: "/press/elle-decor-crafting-your-legacy-of-luxury",
  publication: "Elle Decor",
  assets: [
    {
      file: "/press/elle-decor-2018-1.jpeg",
      type: "image",
    },
    {
      file: "/press/elle-decor-2018-2.jpeg",
      type: "image",
    },
  ],
  seo_title: "Elle Decor - Lohono Stays Press",
  seo_description:
    "Elle Decor describes Isprava's journey and how Isprava builds homes that go beyond the ordinary",
  seo_canonical:
    "https://www.lohono.com/press/elle-decor-crafting-your-legacy-of-luxury",
};

const HOLIDAY_HOMES_ET_LUXURY: Press = {
  title: "A new wave in the world of holiday homes",
  banner: "/press/et-luxury-secondary-homes-small.jpg",
  href: "/press/et-luxury-a-new-wave-in-the-world-of-holiday-homes",
  publication: "ET Luxury",
  assets: [
    {
      file: "/press/et-luxury-secondary-homes.jpg",
      type: "image",
    },
  ],
  seo_title: "ET Luxury - Lohono Stays Press",
  seo_description:
    "ET Luxury describes a new wave in the world of holiday homes with Isprava",
  seo_canonical:
    "https://www.lohono.com/press/et-luxury-a-new-wave-in-the-world-of-holiday-homes",
};

const LUXURY_ADDRESS_ET_PANACHE: Press = {
  title: "Luxury gets a new address",
  banner: "/press/et-panache-luxury-address-small.jpg",
  href: "/press/et-panache-luxury-gets-a-new-address",
  publication: "ET Panache",
  assets: [
    {
      file: "/press/et-panache-luxury-address.jpg",
      type: "image",
    },
  ],
  seo_title: "ET Panache - Lohono Stays Press",
  seo_description:
    "ET Panache review on Isprava homes. The properties developed by Isprava give people an oppurtunity to leave the hustle and bustle of the city and escape to the solitude of Goa",
  seo_canonical:
    "https://www.lohono.com/press/et-panache-luxury-gets-a-new-address",
};

const CONTEMPORARY_FORBES_INDIA: Press = {
  title: "Contemporary tradition",
  banner: "/press/isprava-forbes-2014-small.jpg",
  href: "/press/forbes-india-mmarquee-contemporary-tradition",
  publication: "Forbes India Marquee",
  assets: [
    {
      file: "/press/isprava-forbes-2014.pdf",
      type: "pdf",
    },
  ],
  seo_title: "Forbes India Marquee - Lohono Stays Press",
  seo_description:
    "Forbes India Marquee reviews Isprava homes highlighting contemporary tradition",
  seo_canonical:
    "https://www.lohono.com/press/forbes-india-mmarquee-contemporary-tradition",
};

const SANDY_SHORES_GOOD_HOMES: Press = {
  title: "The Sandy Shores of Home",
  banner: "/press/isprava-good-homes-2014-small.jpg",
  href: "/press/good-homes-the-sandy-shores-of-homes",
  publication: "Good Homes",
  assets: [
    {
      file: "/press/isprava-good-homes-2014.pdf",
      type: "pdf",
    },
  ],
  seo_title: "Good homes - Lohono Stays Press",
  seo_description:
    "Good homes reviews Villa Branco. Highlighting Sandy shores of homes with Isprava luxury villas",
  seo_canonical:
    "https://www.lohono.com/press/good-homes-the-sandy-shores-of-homes",
};

const GOA_ROBB_INDIA: Press = {
  title: "Casa Goa",
  banner: "/press/isprava-robb-2014-small.jpg",
  href: "/press/robb-report-india-casa-goa",
  publication: "Robb Report India",
  assets: [
    {
      file: "/press/isprava-robb-2014.pdf",
      type: "pdf",
    },
  ],
  seo_title: "Robb Report by India - Lohono Stays Press",
  seo_description:
    "Robb Report highlights Isprava homes specially Villa Branco. Villa Branco showcases a harmonious blend of Goan-Portuguese and European architectural designs",
  seo_canonical: "https://www.lohono.com/press/robb-report-india-casa-goa",
};

const ESCAPE_NAT_GEO: Press = {
  title: "A Luxurious Escape With Isprava",
  banner: "/press/escape-nat-geo-small.jpg",
  href: "/press/nat-geo-traveller-a-luxurious-escape-with-isprava",
  publication: "Nat Geo Traveller",
  assets: [
    {
      file: "/press/escape-nat-geo.jpeg",
      type: "image",
    },
  ],
  seo_title: "National Geographic Traveller India - Lohono Stays Press",
  seo_description:
    "Read National Geographic Traveller post on how Isprava holiday homes are the perfect combination of luxury and comfort",
  seo_canonical:
    "https://www.lohono.com/press/nat-geo-traveller-a-luxurious-escape-with-isprava",
};

const PERSONALIZE_PARSIANA: Press = {
  title: "Personalizing real estate",
  banner: "/press/parsiana-magazine-small.jpg",
  href: "/press/parsiana-personalizing-real-estate",
  publication: "Parsiana",
  assets: [
    {
      file: "/press/parsiana-magazine.jpg",
      type: "image",
    },
  ],
  seo_title: "Parsiana - Personalizing real estate - Lohono Stays Press",
  seo_description:
    "Read Nibhrant's view on personalising real estate with Indo-European homes. Luxury villas that celebrate rustic charms",
  seo_canonical:
    "https://www.lohono.com/press/parsiana-personalizing-real-estate",
};

const CRAFTING_LUXURY_ROSE_BOWL: Press = {
  title: "Crafting your legacy of luxury",
  banner: "/press/rose-bowl-1-small.jpg",
  href: "/press/the-rose-bowl-crafting-your-legacy-of-luxury",
  publication: "The Rose Bowl",
  assets: [
    {
      file: "/press/rose-bowl-1.jpeg",
      type: "image",
    },
    {
      file: "/press/rose-bowl-2.jpeg",
      type: "image",
    },
  ],
  seo_title:
    "The Rose bowl - Crafting your legacy of luxury - Lohono Stays Press",
  seo_description:
    "Lohono Stays press coverage on The Rose Bowl: Read more about Isprava on the Rose Bowl",
  seo_canonical:
    "https://www.lohono.com/press/the-rose-bowl-crafting-your-legacy-of-luxury",
};

const LUXEBOOK_FEATURE: Press = {
  title: "Decoding 'Workation': A New Tourism Trend - Press Release - Lohono Stays",
  banner: "/press/luxebook-feature.jpg",
  href: "/press/luxebook-feature",
  publication: "",
  assets: [
    {
      file: "/press/luxebook-feature.jpg",
      type: "image",
    },
  ],
  seo_title: "Decoding 'Workation': A New Tourism Trend - Press Release - Lohono Stays",
  seo_description: "The ongoing global scenario has forced a lot of people to work from home and avoid travelling. Workation is a kind of holiday where you visit one of our private villas located in serene spots with excellent WiFi and work while enjoying the luxurious stay and amenities.",
  seo_canonical: "/press/luxebook-feature",
};

const CONDA_NASTE_TRAVELLER: Press = {
  title: "9 Lohono Stays villas feature in Condé Nast Traveller's 50 Top Villas in India",
  banner: "/press/conde-nast-traveller.jpg",
  href: "/press/conde-nast-traveller-top-50-villas-in-india",
  publication: "Conde Nast Traveller India",
  assets: [
    {
      file: "/press/conde-nast-traveller.pdf",
      type: "pdf",
    },
  ],
  seo_title: "9 Lohono Stays villas feature in Condé Nast Traveller's 50 Top Villas in India",
  seo_description: "Lohono Stays villas feature in in the 50 Top villas in India in the Conde Nast Traveller India. Conde Nast Traveller highlights 9 Lohono Stays villas under Top 50 villas in India. Lohono Stays specializes in creating and curating luxury holiday homes for rent all over the world. Tied in with our signature hospitality, the endeavour is to offer",
  seo_canonical: "/press/conde-nast-traveller-top-50-villas-in-india",
};

const PRESS_FEATURED_TEMPLATE: Press[] = [
  CONDA_NASTE_TRAVELLER,
  FINANCIAL_EXPRESS_WEBSITE_FEATURE,
  BRANCO_ARCHITECTURAL_DIGEST,
];

const PRESS_CATALOGUE_TEMPLATE: Press[] = [
  CONDA_NASTE_TRAVELLER,
  FINANCIAL_EXPRESS_WEBSITE_FEATURE,
  EVORA_NAST_TRAVELLER,
  REDEFINE_LUXURY_BUSINESS_TRAVELLER,
  ALBANY_CASA_VOGUE,
  BRANCO_ARCHITECTURAL_DIGEST,
  ESCAPE_NAT_GEO,
  VERDE_BUSINESS_TRAVELLER,
  CRAFTING_LUXURY_ELLE_DECORE,
  HOLIDAY_HOMES_ET_LUXURY,
  LUXURY_ADDRESS_ET_PANACHE,
  CONTEMPORARY_FORBES_INDIA,
  SANDY_SHORES_GOOD_HOMES,
  GOA_ROBB_INDIA,
  PERSONALIZE_PARSIANA,
  CRAFTING_LUXURY_ROSE_BOWL,
  LUXEBOOK_FEATURE,
];

export { PRESS_FEATURED_TEMPLATE, PRESS_CATALOGUE_TEMPLATE };
