import React from "react";
import clxs from "./collection-property-item.module.css";
import Skeleton from "../../Skeleton";


function CollectionPropertyItemSkeleton() {
    return ( 
    <div className={clxs.container}>
        <div className={`${clxs.thumbnailContainer} ${clxs.thumbnailSkeleton}`}> <Skeleton /></div>
        <div className={clxs.title}><Skeleton /></div>
        <div className={clxs.subtitle}><Skeleton /></div>
        <div 
          className={clxs.specContainer} 
          style={{ height: 16 }} 
        ><Skeleton /></div>
        {/* <div className={clxs.soldout}><Skeleton /></div> */}
        <div 
          className={clxs.pricingSkeleton}
          style={{ height: 62 }}
        ><Skeleton /></div>
    </div> );
}

export default CollectionPropertyItemSkeleton;