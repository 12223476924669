import React, { useMemo } from "react";
import NIGHT_ICON from "../../../../../assets/loyalty-points/night-icon.svg";
import PLATINUM_TROPHY from "../../../../../assets/loyalty-points/platinum-trophy.png";
import useClx from "../../../../../hooks/use-clx";
import clxs from "./gimmick.module.css";

interface GimmickProps {
  tier: LoyaltyPointsTier;
  nextTier?: LoyaltyPointsTier;
  nightsCompleted?: number;
  className?: string;
}

function Gimmick (props: GimmickProps) {
  const { tier, nextTier, nightsCompleted = 0, className } = props;

  const ccx = useClx(clxs.gimmick, className);

  const {
    nextTierName,
    unlockContent,
    progressAttribute,
    requiredNights,
    pmX,
    pmY,
  } = useMemo(
    () => {
      const totalLength = 660;

      if (!tier) {
        return {
          unlockContent: null,
          nextTierName: "",
          requiredNights: 0,
          progressAttribute: `${0} ${totalLength}`,
          pmX: -10,
          pmY: -10,
        };
      }

      const nextTierName = nextTier?.friendly_name ?? tier?.friendly_name;

      const pendingNights = tier.max_nights - nightsCompleted + 1;

      const unlockContent = pendingNights < 2
        ? "1 more night to unlock"
        : `${pendingNights} more nights to unlock`;

      const pbTotalNights = tier.max_nights;

      const ratio = nightsCompleted / pbTotalNights;

      const progressLength = Math.ceil(ratio * totalLength);

      const progressAttribute = `${progressLength} ${totalLength}`;

      const pmX = 250 - 210 * Math.cos(ratio * Math.PI);

      const pmY = 240 - 210 * Math.sin(ratio * Math.PI);

      return {
        unlockContent: unlockContent,
        nextTierName: `${nextTierName} Member`,
        requiredNights: tier.max_nights + 1,
        progressAttribute: progressAttribute,
        pmX: pmX,
        pmY: pmY,
      };
    },
    [tier, nextTier, nightsCompleted],
  );

  return (
    <div
      className={ccx}
      data-tier={tier.value}
    >
      <svg
        width={VIEWPORT_WIDTH}
        height={VIEWPORT_HEIGHT}
        viewBox={`0 0 ${VIEWPORT_WIDTH} ${VIEWPORT_HEIGHT}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clxs.speedometer}
      >
        <defs>
          <linearGradient
            id="trophy-border-gradient"
            gradientTransform="rotate(90)"
          >
            <stop
              offset="0%"
              stopColor="rgba(109, 102, 102, 0)"
            />
            <stop
              offset="50%"
              stopColor="#6D6666"
            />
            <stop
              offset="100%"
              stopColor="rgba(109, 102, 102, 0)"
            />
          </linearGradient>
        </defs>
        <path
          d="M 80 240 A 1 1 0 0 1 420 240"
          stroke="var(--llp-gimmick-inner-circle-color)"
          strokeWidth="10"
          strokeLinecap="round"
          strokeDasharray="36 36"
          pathLength="800"
        />
        <path
          d="M 40 240 A 1 1 0 0 1 460 240"
          stroke="var(--llp-gimmick-progress-background-color)"
          strokeWidth="10"
          strokeLinecap="round"
        />
        <path
          d="M 40 240 A 1 1 0 0 1 460 240"
          stroke="var(--llp-gimmick-progress-foreground-color)"
          strokeWidth="10"
          strokeLinecap="round"
          strokeDasharray={progressAttribute}
          className={clxs.gimmickProgressForeground}
        />
        <circle
          r="12"
          fill="var(--llp-gimmick-progress-background-color)"
          cx="460"
          cy="240"
          className={clxs.gimmickProgressMarker}
        />
        <text
          fill="var(--llp-gimmick-progress-background-text-color)"
          textAnchor="middle"
          x="460"
          y="240"
          dy="4"
          fontSize="12px"
          className={clxs.gimmickProgressMarkerText}
        >
          {requiredNights}
        </text>
        <circle
          r="12"
          fill="var(--llp-gimmick-progress-foreground-color)"
          cx={pmX}
          cy={pmY}
          className={clxs.gimmickProgressMarker}
        />
        <text
          fill="var(--llp-gimmick-progress-foreground-text-color)"
          textAnchor="middle"
          x={pmX}
          y={pmY}
          dy="4"
          fontSize="12px"
          className={clxs.gimmickProgressMarkerText}
        >
          {nightsCompleted}
        </text>
        <circle
          r="30"
          fill="#211e1e"
          cx={VIEWPORT_WIDTH / 2}
          cy="30"
          className={clxs.trophyBackground}
          stroke="url(#trophy-border-gradient)"
        />
        <image
          href={PLATINUM_TROPHY}
          width="35"
          x="232"
          y="15"
          className={clxs.trophy}
        />
      </svg>
      <img
        src={NIGHT_ICON}
        alt="nights"
        className={clxs.nightIcon}
      />
      <div className={clxs.nightsCompletedPre}>
        {"Total Number of\nnights spent"}
      </div>
      <div className={clxs.unlock}>
        {unlockContent}
      </div>
      <div className={clxs.nightsCompleted}>
        {nightsCompleted}
      </div>
      <div className={clxs.nextTier}>
        {nextTierName}
      </div>
    </div>
  );
}

export default Gimmick;

const VIEWPORT_WIDTH = 500;

const VIEWPORT_HEIGHT = 270;
