import React from "react";
import useClx from "../../../../hooks/use-clx";
import { Track } from "../../../AnalyticsManager";
import Button from "../../../Button";
import clxs from "./not-logged-in.module.css";
import LOGIN_STAR from "../../../../assets/loyalty-points/login-star.svg";
import LOHONO_LOGO from "../../../../assets/loyalty-points/lohono-infinity-logo-light.svg";
import LOHONO_LOGO_LARGE from "../../../../assets/loyalty-points/lohono-infinity-logo-light-lg.svg";
import useIsMobile from "../../../../hooks/use-is-mobile";

interface NotLoggedInProps {
  className?: string;
  onLogin?: () => void;
}

function NotLoggedIn (props: NotLoggedInProps) {
  const { className, onLogin } = props;
  const { isMobile } = useIsMobile();
  const lohonoLogoSrc = isMobile ? LOHONO_LOGO : LOHONO_LOGO_LARGE;

  const ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <img
        src={lohonoLogoSrc}
        alt="Infinity Logo"
        className={clxs.infinityLogo}
      />
      <h1 className={clxs.title}>
        Lohono Loyalty Points
      </h1>
      <h2 className={clxs.subtitle}>
        Earn loyalty points every time you stay with us
      </h2>
      <div className={clxs.loginContainer}>
        <img
          src={LOGIN_STAR}
          alt="login"
          className={clxs.star}
        />
        
        <span>Unlock your welcome gift of <b>1,000 Loyalty Points</b> and redeem them on your first booking</span>
        
        <Track event="loyalty_points_login_clicked">
          <Button
            className={clxs.login}
            onClick={onLogin}
          >
            Login / Create an account
          </Button>
        </Track>
      </div>
    </div>
  );
}

export default NotLoggedIn;
