import React, { useMemo, useState } from "react";
import GoogleMapReact, { MapOptions } from "google-map-react";
import useClx from "../../../../hooks/use-clx";
import useIsMobile from "../../../../hooks/use-is-mobile";
import useEnv from "../../../../hooks/use-env";
import Pin from "./Pin";
import clxs from "./things-to-do.module.css";
import MAP_FEATURES from "./map-fetaures";
import EXPAND_MORE_GREY from "../../../../assets/icons/expand-more-grey.svg";
import EXPAND_LESS_GREY from "../../../../assets/icons/expand-less-grey.svg";
import BEACH_ORANGE from "../../../../assets/icons/beach-orange.svg";
import RESTAURANT_ORANGE from "../../../../assets/icons/restaurant-orange.svg";
import HOTEL_ORANGE from "../../../../assets/icons/hotel-orange.svg";

interface ThingsToDoProps {
  name: string;
  nearbyPlaces: NearbyPlaceCategory[];
  lat: number;
  lng: number;
  className?: string;
}

function ThingsToDo(props: ThingsToDoProps) {

  const { GOOGLE_MAPS_API_KEY } = useEnv(),
    { isMobile } = useIsMobile(),
    { className, nearbyPlaces } = props,
    nearbyPlacesMap = useMemo(
      () => {
        return nearbyPlaces.reduce(
          (compiled, each) => {
            const { category } = each;

            compiled[category] = each;

            return compiled;
          },
          {} as { [K: string]: NearbyPlaceCategory },
        );
      },
      [nearbyPlaces],
    ),
    [category, setCategory] = useState<string>(() => nearbyPlaces[0].category),
    [collapsed, setCollapsed] = useState<boolean>(true),
    { lat, lng, name } = props,
    mapProps = useMemo(
      () => {
        return {
          key: isMobile ? "mobile" : "desktop",
          bootstrapURLKeys: { key: GOOGLE_MAPS_API_KEY },
          defaultCenter: {
            lat: lat,
            lng: lng - (isMobile ? 0 : 0.075),
          },
          defaultZoom: 11,
          options: () => {
            const options: MapOptions = {
              styles: MAP_FEATURES,
            };
            return options;
          },
        };
      },
      [GOOGLE_MAPS_API_KEY, isMobile],
    ),
    placeCategory = useMemo(
      () => nearbyPlacesMap[category],
      [nearbyPlacesMap, category],
    ),
    ccx = useClx(clxs.container, className),
    pcx = useClx(clxs.places, isMobile && collapsed && clxs.placesCollapsed);

  if (!placeCategory) {
    return null;
  }

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Things to do nearby
      </div>
      <div className={clxs.tabs}>
        {nearbyPlaces.map(
          (each, i) => {
            const { category } = each,
              icon = TAB_ICON_MAP[category.toLowerCase()] || TAB_ICON_MAP.default;

            return (
              <button
                key={i}
                color="inherit"
                onClick={setCategory.bind(null, category)}
                className={clxs.tab}
              >
                <div className={clxs.tabIconTitle}>
                  <img
                    src={icon}
                    alt={category}
                    className={clxs.tabIcon}
                  />
                </div>
                {category}
              </button>
            );
          },
        )}
      </div>
      <div className={clxs.map}>
        <GoogleMapReact {...mapProps} >
          {placeCategory.places.map(
            (each, i) => {
              const { name, lat, lng, distance_in_minutes } = each;

              return (
                <Pin
                  key={i}
                  lat={lat}
                  lng={lng}
                  name={name}
                  category={category}
                  distance_in_minutes={distance_in_minutes}
                />
              );
            },
          )}
          <Pin
            lat={lat}
            lng={lng}
            name={name}
            className={clxs.pin}
            category="property"
          />
        </GoogleMapReact>
      </div>
      <div className={pcx}>
        <div
          className={clxs.placesTitle}
          onClick={setCollapsed.bind(null, isMobile && !collapsed)}
        >
          {placeCategory.category}
          <img
            src={collapsed ? EXPAND_LESS_GREY : EXPAND_MORE_GREY}
            alt="expand"
            className={clxs.expandIcon}
          />
        </div>
        {placeCategory.places.map(
          (each, i) => {
            const { name, distance_in_minutes } = each;

            return (
              <div
                key={i}
                className={clxs.place}
              >
                {name}
                <div className={clxs.distance}>
                  {`${distance_in_minutes} min`}
                </div>
              </div>
            );
          },
        )}
        <div>&nbsp;</div>
      </div>
    </div>
  );
}

export default ThingsToDo;

const TAB_ICON_MAP = {
  default: BEACH_ORANGE,
  beaches: BEACH_ORANGE,
  hotels: HOTEL_ORANGE,
  restaurants: RESTAURANT_ORANGE,
};
