import React, { MouseEventHandler } from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./occasion.module.css";
import OCCASION_TEMPLATE from "./occassion-template";
import Carousel from "../../Carousel";
import Scroller from "../../Scroller";
import { useCarouselScroller } from "../../../hooks/use-carousel";
import Img from "../../Img";
import Item from "../../Carousel/Item";
import useIsMobile from "../../../hooks/use-is-mobile";
import OccasionItem from "./OccasionItem";

interface OccasionProps {
  className?: string;
  onContactUs?: MouseEventHandler;
  onViewDetails: (selectedOccasion : OccasionDetails)=>void;
}

function Occasion(props: OccasionProps) {
  const { className,onViewDetails } = props,
  p1cx = useClx(clxs.partial1, "partial1"),
  // p2cx = useClx(clxs.partial2, "partial2"),
  { scrollLeft, scrollRight } = useCarouselScroller(CAROUSEL_ID),
  { isMobile } = useIsMobile(),
    scx = useClx(clxs.section, className),
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Experiences For You
      </div>
      <div className={clxs.subtitle}>
        {CONTENT}
      </div>
      {isMobile ? 
      <div className={clxs.occasions}>
         {OCCASION_TEMPLATE.map(
          (each, key) => (
            <OccasionItem
              key={key}
              title={each.title}
              thumbnailDesktop={each.desktopThumbnail}
              thumbnailMobile={each.mobileThumbnail}
              header={each.header}
              subtitle={each.subtitle}
            />
          ),
        )}
      </div> 
      :
      <div className={clxs.occasions}>
      { OCCASION_TEMPLATE.length == 4 ? null :
      <Scroller
        className={clxs.scroller}
        onScrollLeft={scrollLeft}
        onScrollRight={scrollRight}
      /> }
      <Carousel
        id={CAROUSEL_ID}
        className={scx}
        threshold={0.9}
        adjust={true}
      >
        {OCCASION_TEMPLATE.map(
          (each, i) => {
            const {
              title,
              desktopThumbnail,
              mobileThumbnail,
            } = each;

            return (
            <Item
              key={i}
              className={clxs.item}
            >
              <div className={clxs.occasionContainer}>
                <div className={p1cx}>
                  <Img
                    alt={`Occasion ${title}`}
                    src={desktopThumbnail}
                    mobileSrc={mobileThumbnail}
                    desktopSrc={desktopThumbnail}
                    className={clxs.partial1Thumbnail}
                  />
                  <div className={clxs.partial1Title}>
                    {title}
                  </div>
                  <div
                    className={clxs.viewAction}
                    onClick={()=>onViewDetails(each)}
                  >
                    View More
                  </div>
                  <div 
                    className={clxs.backdrop}
                  >
                    &nbsp;
                  </div>  
                </div>
              </div>
              </Item>
            );
          })
        }
      </Carousel>
      </div> }
    </div>
  );
}

export default Occasion;

const CONTENT = "Lohono's luxury villas are perfect for any event, from corporate retreats and weddings to birthday parties and baby showers. Our Experiences team will help you plan unforgettable affairs, taking care of everything from the ideal villa to decor, food, and entertainment.";

const CAROUSEL_ID = "event-occasion";
