import DeleteAccountOtp from "../components/DeleteAccountOtp";
import DeleteAccountSuccess from "../components/DeleteAccountSuccess";
import Home from "../components/Home";
import NotFound from "../components/NotFound";
import Profile from "../components/Profile";
import SendRequest from "../components/SendRequest";
import TNC from "../components/TNC";
import PrivacyPolicy from "../components/PrivacyPolicy";
import AboutUs from "../components/AboutUs";
import Experience from "../components/Experience";
import PressListing from "../components/PressListing";
import PressDetail from "../components/PressDetail";
import ContactUs from "../components/ContactUs";
import Sanitation from "../components/Sanitation";
import PropertyListing from "../components/PropertyListing";
import PropertyDetail from "../components/PropertyDetail";
import Checkout from "../components/Checkout";
import Confirmation from "../components/Confirmation";
import Partner from "../components/Partner";
import InvestorReport from "../components/InvestorReport";
import FAQ from "../components/FAQ";
import Sitemap from "../components/Sitemap";
import Homeowner from "../components/Homeowner";
import Event from "../components/Event";
import LBAbout from "../components/LohonoBlack/About";
import Feedback from "../components/Feedback";
import DeleteAccount from "../components/DeleteAccount";
import YogaExperience from "../components/YogaExperience";
import FitnessRetreat from "../components/FitnessRetreat";
import LoyaltyPoints from "../components/LoyaltyPoints";
import LohonoCorporate from "../components/LohonoCorporate";
import ReferralProgramme from "../components/ReferralProgramme";
import ReferHistory from "../components/ReferHistory";
import PaymentStatus from "../components/PaymentStatus";
import CollectionListing from "../components/CollectionListing";
import Locations from "../components/Locations";

function buildPageTemplate () {
  return [
    {
      path: "/",
      isPrivate: false,
      header: "Home",
      footer: null,
      Component: Home,
      new_tab: false,
      page: "HOME",
      title: "Lohono Stays",
    },
    {
      path: "/villas/:destination/:location",
      isPrivate: false,
      header: "Villas on rent",
      footer: null,
      Component: PropertyListing,
      new_tab: false,
      page: "PROPERTY_LISTING",
      title: "Luxury villas for rent",
    },
    {
      path: "/villas/:destination/:location/:property",
      isPrivate: false,
      header: null,
      footer: null,
      Component: PropertyDetail,
      new_tab: false,
      page: "PROPERTY_DETAIL",
      title: "Luxury villa for rent",
    },
    {
      path: "/villas/collections/:collection_slug",
      isPrivate: false,
      header: "Villas on rent",
      footer: null,
      Component: CollectionListing,
      new_tab: false,
      page: "COLLECTION_LISTING",
      title: "Luxury villas for rent",
    },
    {
      path: "/villas/:destination/:location/:property/checkout",
      isPrivate: false,
      header: null,
      footer: null,
      Component: Checkout,
      new_tab: false,
      page: "CHECKOUT",
      title: "Luxury villa for rent",
    },
    {
      path: "/confirmation/:reservation_slug",
      isPrivate: false,
      header: null,
      footer: null,
      Component: Confirmation,
      new_tab: false,
      page: "CONFIRMATION",
      title: "Luxury villa for rent",
    },
    {
      path: "/payment-status/:paymentId",
      isPrivate: false,
      header: null,
      footer: null,
      Component: PaymentStatus,
      new_tab: false,
      page: "PAYMENT_STATUS",
      title: "Luxury villa for rent",
    },
    {
      path: "/corporate-stays",
      isPrivate: false,
      header: "Corporate Retreats",
      footer: "Corporate Retreats",
      Component: LohonoCorporate,
      new_tab: true,
      page: "LOHONO_CORPORATE",
      title: "Lohono Corporate",
      childRoutes: [],
    },
    {
      path: "/locations/:location_slug",
      isPrivate: false,
      header: "Villas on rent",
      footer: null,
      Component: Locations,
      new_tab: false,
      page: "LOCATIONS",
      title: "Luxury villas for rent",
    },
    {
      path: "/contact-us",
      isPrivate: false,
      header: "Contact us",
      footer: "Contact us",
      Component: ContactUs,
      new_tab: false,
      page: "CONTACT_US",
      title: "Contact us",
    },
    {
      path: "https://blog.lohono.com",
      isPrivate: false,
      header: "Blog",
      footer: "Blog",
      Component: null,
      new_tab: true,
      title: "Lohono Blog",
    },
    {
      path: "/about-us",
      isPrivate: false,
      header: "About us",
      footer: "About us",
      Component: AboutUs,
      new_tab: false,
      page: "ABOUT_US",
      title: "About Lohono",
    },
    {
      path: "/lohono-experience",
      isPrivate: false,
      header: "Lohono Experience",
      footer: "Lohono Experience",
      Component: Experience,
      new_tab: false,
      page: "EXPERIENCE",
      title: "Lohono Experience",
    },
    {
      path: "/loyalty-points",
      isPrivate: false,
      header: "Loyalty Points",
      footer: "Loyalty Points",
      Component: LoyaltyPoints,
      new_tab: false,
      page: "LOYALTY_POINTS",
      title: "Loyalty Points",
    },
    {
      path: "/refer",
      isPrivate: false,
      header: "Referral Programme",
      footer: "Referral Programme",
      Component: ReferralProgramme,
      new_tab: false,
      page: "REFFERAL_PROGRAMME",
      title: "Referral Programme",
    },
    {
      path: "/refer-history",
      isPrivate: false,
      header: "Refer History",
      footer: null,
      Component: ReferHistory,
      new_tab: false,
      page: "REFER_HISTORY",
      title: "Refer History",
    },
    // {
    //   path: "https://www.isprava.com/our-homes/",
    //   isPrivate: false,
    //   header: "Home for sale",
    //   footer: "Home for sale",
    //   Component: null,
    //   new_tab: true,
    //   title: "Home for sale",
    // },
    {
      path: "/press",
      isPrivate: false,
      header: null,
      footer: "Press",
      Component: PressListing,
      new_tab: false,
      page: "PRESS_LISTING",
      title: "Press releases",
    },
    {
      path: "/press/:press_slug",
      isPrivate: false,
      header: null,
      footer: null,
      Component: PressDetail,
      new_tab: false,
      page: "PRESS_DETAIL",
      title: "Press releases",
    },
    {
      path: "/terms-and-conditions",
      isPrivate: false,
      header: null,
      footer: null,
      Component: TNC,
      new_tab: true,
      page: "TNC",
      title: "Lohono - Terms and conditions",
    },
    {
      path: "/privacy-policy",
      isPrivate: false,
      header: null,
      footer: null,
      Component: PrivacyPolicy,
      new_tab: true,
      page: "PRIVACY_POLICY",
      title: "Lohono - Privacy policy",
    },
    {
      path: "/faq",
      isPrivate: false,
      header: null,
      footer: "FAQ",
      Component: FAQ,
      new_tab: true,
      page: "FAQ",
      title: "Lohono - FAQ",
    },
    {
      path: "/sitemap",
      isPrivate: false,
      header: null,
      footer: "Sitemap",
      Component: Sitemap,
      new_tab: true,
      page: "SITEMAP",
      title: "Sitemap",
    },
    {
      path: "/sanitisation-at-lohono",
      isPrivate: false,
      header: null,
      footer: null,
      Component: Sanitation,
      new_tab: true,
      page: "SANITATION",
      title: "Lohono - Sanitization",
    },
    {
      path: "/investor-reports",
      isPrivate: false,
      header: null,
      footer: null,
      Component: InvestorReport,
      new_tab: true,
      page: "INVESTOR_REPORT",
      title: "Investor report",
    },
    {
      path: "/partner",
      isPrivate: false,
      header: null,
      footer: null,
      Component: Partner,
      new_tab: false,
      page: "PARTNER",
      title: "Partner",
    },
    {
      path: "/lohono-black",
      isPrivate: false,
      header: null,
      footer: null,
      Component: LBAbout,
      new_tab: false,
      page: "LB_ABOUT",
      title: "Lohono Black",
    },
    {
      path: "/experiences-and-events",
      isPrivate: false,
      header: "Experiences and Events",
      footer: "Experiences and Events",
      Component: Event,
      new_tab: true,
      page: "EXPERIENCES_AND_EVENTS",
      title: "Experiences and Events",
    },
    {
      path: "/experiences/yoga-retreat",
      isPrivate: false,
      header: null,
      footer: null,
      Component: YogaExperience,
      new_tab: true,
      page: "YOGA_RETREAT",
      title: "Yoga Retreat with Ira Trivedi",
    },
    {
      path: "/homeowner",
      isPrivate: false,
      header: "List your property",
      footer: "List your property",
      Component: Homeowner,
      new_tab: true,
      page: "HOMEOWNER",
      title: "Homeowner",
    },
    {
      path: "/experiences/lohono-wellness",
      isPrivate: false,
      header: null,
      footer: null,
      Component: FitnessRetreat,
      new_tab: true,
      page: "WELLNESS_EXPERIENCE",
      title: "Lohono Stays | Wellness Experience",
      childRoutes: [],
    },
    {
      path: "/feedback/:feedback_slug",
      isPrivate: false,
      header: null,
      footer: null,
      Component: Feedback,
      new_tab: false,
      page: "FEEDBACK",
      title: "Feedback",
    },
    {
      path: "/send-request",
      isPrivate: false,
      header: null,
      footer: null,
      Component: SendRequest,
      new_tab: true,
      page: "SEND_REQUEST",
      title: "Send request",
    },
    {
      path: "/delete-account",
      isPrivate: false,
      header: null,
      footer: null,
      Component: DeleteAccount,
      new_tab: true,
      page: "DELETE_ACCOUNT",
      title: "Delete Account",
    },
    {
      path: "/delete-account/verify-otp",
      isPrivate: false,
      header: null,
      footer: null,
      Component: DeleteAccountOtp,
      new_tab: true,
      page: "DELETE_ACCOUNT_VERIFY_OTP",
      title: "Delete Account - Verify OTP",
    },
    {
      path: "/delete-account/result",
      isPrivate: false,
      header: null,
      footer: null,
      Component: DeleteAccountSuccess,
      new_tab: true,
      page: "DELETE_ACCOUNT_SUCCESS",
      title: "Delete Account",
    },
    {
      path: "/profile/*",
      isPrivate: true,
      header: null,
      footer: null,
      Component: Profile,
      new_tab: true,
      page: "PROFILE",
      title: "Profile",
    },
    {
      path: "*",
      isPrivate: false,
      header: null,
      footer: null,
      Component: NotFound,
      new_tab: false,
      page: "NOT_FOUND",
      title: "Not found",
    },
  ];
}

export default buildPageTemplate;
