function decodeLocationSlug (locationSlug: string) {
  const [destination, ...rest] = locationSlug.split("-"),
    location = rest.join("-");

  return {
    location: location,
    destination: destination,
  };
}

export default decodeLocationSlug;
