import React, { useEffect, useState } from "react";
// import getSessionFromContext from "../../../../server/utils/get-session-from-context";
import { useSnackbar } from "../../../hooks/use-snackbar";
import useUserService from "../../../hooks/use-user-service";
import clxs from "./validate-referrer.module.css";
import NOT_ELIGIBLE from "../../../assets/already-member.svg";
import INVALID_LINK from "../../../assets/invalid-link.svg";
import GREEN_CHECKMARK from "../../../assets/icons/green-checkmark.svg";
// import { useUser } from "../../../hooks/use-session";

interface ValidateReferrerProps {
  referralCode: string;
  session: string;
}

function ValidateReferrer(props: ValidateReferrerProps) {
  const { referralCode, session } = props,
    { service } = useUserService(),
    enqueueSnackbar = useSnackbar(),
    [validateFlowState, setValidateFlowState] = useState<string>(""),
    [headerText, setHeaderText] = useState<string>(""),
    [bodyText, setBodyText] = useState<string>(""),
    [footerText, setFooterText] = useState<string>(""),
    handleValidateCode = async (referralCode: string) => {
      if (!referralCode) {
        return;
      }

      const { error, response } = await service.validateReferralCode(
        referralCode,
        session,
      );

      if (error) {
        enqueueSnackbar(error.message, "error");
        return;
      }

      //If success is false meaning there was a problem in adding points due to a reson.
      if (response) {
        // const errorMessage = response?.body ? response?.body : "";
        const { header, body, footer } = response;
        setHeaderText(header);
        setBodyText(body);
        setFooterText(footer);
        const status = response?.sub_status || "invalid";
        const success = response?.success || false;

        if (
          status == "already_infinity_member" ||
          status == "already_tier_member"
        ) {
          setValidateFlowState("notEligible");
        } else if (status == "pending_booking" && success) {
          setValidateFlowState("success");
        } else if (status == "invalid") {
          setValidateFlowState("invalidLink");
        } else if (
          status == "expired" ||
          status == "referee_blacklisted" ||
          status == "referrer_blacklisted"
        ) {
          setValidateFlowState("expiredLink");
        }

        return;
      }
    };

  useEffect(() => {
    handleValidateCode(referralCode);
  }, [referralCode]);

  return (
    <>
      {validateFlowState == "success" ? (
        <div className={clxs.container}>
          <div className={clxs.text}>{headerText}</div>
          <img
            src={GREEN_CHECKMARK}
            alt="already a member"
            className={clxs.iconImage}
          />
          <span>
            {/* <b>₹5,000 infinity points</b> are added to your balance. Redeem it
            on your first booking. */}
            {bodyText}
          </span>
        </div>
      ) : validateFlowState == "notEligible" ? (
        <div className={clxs.container}>
          <div className={clxs.text}>{headerText}</div>
          <img
            src={NOT_ELIGIBLE}
            alt="already a member"
            className={clxs.bigImage}
          />
          <span>{bodyText}</span>
        </div>
      ) : validateFlowState == "invalidLink" ? (
        <div className={clxs.container}>
          <img
            src={INVALID_LINK}
            alt="Invalid Link Image"
            className={clxs.iconImage}
          />
          <div className={clxs.text}>{bodyText}</div>
          <span>{footerText}</span>
        </div>
      ) : validateFlowState == "expiredLink" ? (
        <div className={clxs.container}>
          <img
            src={INVALID_LINK}
            alt="Expired Link Image"
            className={clxs.iconImage}
          />
          <div className={clxs.text}>
            {/* The link you were invited with has expired. */}
            {bodyText}
          </div>
          <span>{footerText}</span>
        </div>
      ) : null}
    </>
  );
}

export default ValidateReferrer;
