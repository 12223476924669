import { useCallback } from "react";
import { useAtomState } from "../use-atom";
import { useSnackbar } from "../use-snackbar";
import { useStorageString } from "../use-storage";
import useUserService from "../use-user-service";

function useUserFromSession () {
  const { service: userService } = useUserService();

  const { session } = useSessionManager();

  const [_, setUser] = useAtomState<User | null>(USER_KEY, null);

  const handleGetUserFromSession = useCallback(
    async () => {
      if (!session) {
        setUser(null);

        return null;
      }

      const { response, error } = await userService.getUser(session);

      if (error) {
        return error;
      }

      if (!response) {
        return new Error("user login unknown error");
      }

      setUser(response);

      return response;
    },
    [userService, session],
  );

  return handleGetUserFromSession;
}

function useSession (): string {
  const [session] = useAtomState<string>(SESSION_KEY, "");

  return session;
}

function useLogin () {
  const { service: userService } = useUserService();

  const enqueueSnackbar = useSnackbar();

  const { setSession } = useSessionManager();

  const [_, setUser] = useAtomState<User | null>(USER_KEY, null);

  const handleLogin = useCallback(
    async (
      phone_no: string,
      otp: string,
    ) => {
      const { error: sessionError, response: session } = await userService.login(phone_no, otp);

      if (sessionError) {
        enqueueSnackbar(sessionError.message, "error");

        return sessionError;
      }

      if (!session) {
        return new Error("session not generated");
      }

      setSession(session);

      const { error: userError, response: user } = await userService.getUser(session);

      if (userError) {
        enqueueSnackbar(userError.message, "error");

        return userError;
      }

      setUser(user);

      return user;
    },
    [
      userService,
      enqueueSnackbar,
      setSession,
      setUser,
    ],
  );

  return handleLogin;
}

function useUser () {
  const [user] = useAtomState<User | null>(USER_KEY, null);

  return user;
}

function useLogout () {
  const { service: userService } = useUserService();

  const enqueueSnackbar = useSnackbar();

  const { setValue: setStorageSession } = useStorageString("localStorage", SESSION_KEY);

  const [session, setSession] = useAtomState<string>(SESSION_KEY, "");

  const [_, setUser] = useAtomState<User | null>(USER_KEY, null);

  const handleLogout = useCallback(
    async () => {
      const { error } = await userService.logout(session);

      if (error) {
        return error;
      }

      setSession("");

      setStorageSession("");

      setUser(null);

      return null;
    },
    [
      enqueueSnackbar,
      userService,
      setSession,
      setStorageSession,
      setUser,
      session,
    ],
  );

  return handleLogout;
}

function useSessionManager () {
  const {
    value: lsSession,
    setValue: setLsSession,
  } = useStorageString("localStorage", SESSION_KEY);

  const { setValue: setCSession } = useStorageString("cookie", SESSION_KEY, () => lsSession);

  const [session, setSession] = useAtomState<string>(SESSION_KEY, lsSession);

  const handleSetSession = useCallback(
    (session: string) => {
      setLsSession(session);

      setCSession(session);

      setSession(session);
    },
    [setLsSession, setCSession, setSession],
  );

  return {
    session: session,
    setSession: handleSetSession,
  };
}

const SESSION_KEY = "SESSION";

const USER_KEY = "USER";

export {
  useUserFromSession,
  useSession,
  useLogin,
  useUser,
  useLogout,
  SESSION_KEY,
  USER_KEY,
};
