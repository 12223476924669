import {MutableRefObject, useEffect} from "react";

function useDomObserver<T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  cb: (entry: IntersectionObserverEntry) => void,
  threshold?: number | number[],
) {
  const handleCreateObserver = (
    dom: T | null,
    threshold?: number | number[],
  ) => {
    if (!dom) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        let entry: IntersectionObserverEntry | null = null;

        for (let i = 0, iL = entries.length; i < iL; i += 1) {
          const e = entries[0],
            {target} = e;

          if (target === dom) {
            entry = e;
            break;
          }
        }

        if (!entry) {
          return;
        }

        cb(entry);
      }, {
        root: null,
        threshold: threshold,
      },
    );

    observer.observe(dom);

    return () => observer.disconnect();
  };

  useEffect(
    () => handleCreateObserver(ref.current || null, threshold),
    [ref.current],
  );
}

export default useDomObserver;
