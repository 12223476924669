import React from "react";
import stl from "./domain-prompt.module.css";

interface DomainPromptProps {
  description?: string;
  href?: string;
  onSubmit?: (params: any) => void;
}

function DomainPrompt (props: DomainPromptProps) {
  const { description, href, onSubmit } = props;

  const handleDismiss = () => onSubmit?.(null);

  const handleSubmit = () => onSubmit?.(
    { description: description, href: href },
  );

  return (
    <div className={stl.container}>
      <div className={stl.description}>
        {description}
      </div>
      <button
        type="button"
        className={stl.no}
        onClick={handleDismiss}
      >
        No
      </button>
      <button
        className={stl.yes}
        onClick={handleSubmit}
      >
        Yes
      </button>
    </div>
  );
}

export default DomainPrompt;
