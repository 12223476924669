import React from "react";
import useClx from "../../../../hooks/use-clx";
import Img from "../../../Img";
import clxs from "./elevated.module.css";
import ELEVATED1 from "../../../../assets/lohono-black/elevated1.jpg";
import ELEVATED2_DESKTOP from "../../../../assets/lohono-black/elevated2-desktop.jpg";
import ELEVATED2_MOBILE from "../../../../assets/lohono-black/elevated2-mobile.jpg";
import ELEVATED3_DESKTOP from "../../../../assets/lohono-black/elevated3-desktop.jpg";
import ELEVATED3_MOBILE from "../../../../assets/lohono-black/elevated3-mobile.jpg";

interface ElevatedProps {
  titleClassName?: string;
  subtitleClassName?: string;
  className?: string;
}

function Elevated (props: ElevatedProps) {
  const { className, titleClassName, subtitleClassName } = props,
    ccx = useClx(clxs.container, className),
    tcx = useClx(clxs.title, titleClassName),
    scx = useClx(clxs.elevatedContainer, subtitleClassName);

  return (
    <div className={ccx}>
      <div className={tcx}>
        Elevated Lohono
      </div>
      <div className={scx}>
        <div className={clxs.elevatedPartial}>
          Elevated Living.
        </div>
        <div className={clxs.elevatedPartial}>
          Elevated Luxury.
        </div>
        <div className={clxs.elevatedPartial}>
          Elevated Experiences.
        </div>
        <div className={clxs.elevatedPartial}>
          Elevated Estates.
        </div>
      </div>
      <img
        src={ELEVATED1}
        alt="Swimming pool"
        className={clxs.first}
      />
      <Img
        alt="Bathtub"
        src={ELEVATED2_DESKTOP}
        mobileSrc={ELEVATED2_MOBILE}
        desktopSrc={ELEVATED2_DESKTOP}
        className={clxs.rest}
      />
      <Img
        alt="Playing area"
        src={ELEVATED3_DESKTOP}
        mobileSrc={ELEVATED3_MOBILE}
        desktopSrc={ELEVATED3_DESKTOP}
        className={clxs.rest}
      />
    </div>
  );
}

export default Elevated;
