import usePropertyService from "./use-property-service";
import usePrefetch from "./use-prefetch";
import {useAtomState} from "./use-atom";
import {PROPERTY_TRENDING_KEY} from "../../core/constants";
import {useEffect} from "react";
import { useSession } from "./use-session";

function usePropertyTrending() {
  const {service} = usePropertyService(),
    {hasPrefetchKey} = usePrefetch(), [
      trendingProperties,
      setTrendingProperties,
    ] = useAtomState<PropertyItem[]>(PROPERTY_TRENDING_KEY, []),
    session = useSession(),
    handleGetTrendingProperties = async () => {
      if (hasPrefetchKey(PROPERTY_TRENDING_KEY)) {
        return;
      }

      const {error, response} = await service.getTrendingProperties(session);

      if (error || !response) {
        return;
      }

      setTrendingProperties(response);
    };

  useEffect(
    () => {handleGetTrendingProperties()},
    [],
  );

  return {trendingProperties: trendingProperties};
}

export default usePropertyTrending;
