import React, { useMemo } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./villa-item.module.css";
import usePropertyDetail from "../../hooks/use-property-detail";
import { useShowModal } from "../../hooks/use-modal";
import Img from "../Img";

interface VillaItemProps {
  property: PropertyItem;
  // checkinDateKey?: string;    doubt
  // checkoutDateKey?: string;
  // adultCountKey?: string;
  // childCountKey?: string;
  className?: string;
}

function VillaItem(props: VillaItemProps) {
  const {
      property,
      className,
    } = props,
    scx = useClx(clxs.section, className),
    {
      thumbnail,
      name,
      address,
      property_slug,
      rate,
      currency_symbol,
      discounted_rate,
      accommodation,
      view_layout,
      bedroom_count,
      configs,
      bath_count,
      pool_count,
    } = property,
    isShowDiscountedPrice =
      discounted_rate > 0 && rate > 0 && discounted_rate < rate,
    finalPrice = discounted_rate || rate,
    specs = useMemo(() => {
      const specs: string[] = [`${accommodation} Guests`];

      if (view_layout === "multi_config") {
        specs.push(
          `${bedroom_count} ${bedroom_count > 1 ? "Bedrooms" : "Bedroom"}`,
        );
      } else if (configs.length) {
        // const partial = configs.map((each) => each.bedrooms).join(", ");

        specs.push(`${bedroom_count} ${bedroom_count == 1 ? "Bedroom" : "Bedrooms"}`);
      }

      if (bath_count) {
        specs.push(
          `${bath_count} ${bath_count > 1 ? "Bathrooms" : "Bathroom"}`,
        );
      }

      if (pool_count) {
        specs.push(`${pool_count} ${pool_count > 1 ? "Pools" : "Pool"}`);
      }

      return specs;
    }, [accommodation, bath_count, pool_count, configs]),
    showGalleryModal = useShowModal(GALLERY_MODAL_ID),
    { propertyDetail } = usePropertyDetail(property_slug),
    handleShowGallery = () => showGalleryModal({ images: propertyDetail?.images });

  return (
    <div className={scx}>
      <div 
        className={clxs.property} 
        title={name}
      >
        <Img
          src={thumbnail.url}
          mobileSrc={thumbnail.url}
          desktopSrc={thumbnail.url}
          alt={thumbnail.alt}
          onClick={handleShowGallery}
          className={clxs.thumbnail}
        />
        <div className={clxs.title}>{name}</div>
        <div className={clxs.subtitle}>{address}</div>
        <div className={clxs.specContainer}>
          {specs.map((each, i) => (
            <div 
              key={i} 
              className={clxs.spec}
            >
              {each}
            </div>
          ))}
        </div>
        <div className={clxs.list}>
          <div className={clxs.startFrom}>
            Start from
            {isShowDiscountedPrice && (
              <span className={clxs.strike}>
                {`${currency_symbol} ${rate.toLocaleString("en-IN")}`}
              </span>
            )}
          </div>
          <span className={clxs.rate}>
            {`${currency_symbol} ${finalPrice.toLocaleString("en-IN")}`}
          </span>
          {"/night*"} <br/>
          <span className={clxs.exc}>{"*Exclusive of taxes"}</span>
        </div>
      </div>
    </div>
  );
}


export default VillaItem;

const GALLERY_MODAL_ID = "villa-gallery-mobile";
