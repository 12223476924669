import { QueryType, QueryValue } from "./query-param";
import useQueryParam from "./use-query-param";

function useQueryParamValue<T extends QueryType> (
  queryType: T,
  key: string,
  initial?: QueryValue<T>,
): QueryValue<T> {
  const [value] = useQueryParam(queryType, key, initial);

  return value;
}

export default useQueryParamValue;
