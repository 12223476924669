import React, { MouseEventHandler } from "react";
import useClx from "../../../hooks/use-clx";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./banner.module.css";
import Img from "../../Img";
import BANNER_DESKTOP from "../../../assets/lohono-corporate/banner-desktop.png";
import BANNER_MOBILE from "../../../assets/lohono-corporate/banner-mobile.png"
import Button from "../../Button";

interface BannerProps {
  className?: string;
  onEnquireNow?: MouseEventHandler<HTMLButtonElement>;
}

function Banner(props: BannerProps) {
  const {className, onEnquireNow } = props,
    ccx = useClx(
      fwclxs.container, 
      fwclxs.containerPadding, 
      clxs.container, className,
    ),
    bgcx = useClx(fwclxs.container, clxs.background),
    bdcx = useClx(fwclxs.container, clxs.backdrop);
  return (
    <div className={ccx}>
      <Img
        alt="lohono-corporate"
        src={BANNER_DESKTOP}
        desktopSrc={BANNER_DESKTOP}
        mobileSrc={BANNER_MOBILE}
        className={bgcx}
      />
      <div className={bdcx}>&nbsp;</div>
      <h1 className={clxs.title}>
        {"Welcome to Lohono Corporate\nRetreats"}
      </h1>
      <div className={clxs.subtitle}>
       {"Bond with your colleagues outside of work with Lohono Stays\nCorporate Packages"}
      </div>
      <Button 
        className={clxs.enquireNow}
        onClick={onEnquireNow}
      >
        Enquire Now
      </Button>
    </div>
  )
}

export default Banner;