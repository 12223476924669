import { useStorageString } from "../../hooks/use-storage";

/**
 * Custom hook for checkout page to store basic form details in localstorage on attempting booking.
 * Updating localstorage details on change.
 * 
 * @param formInputs 
 */
function usePersistedCheckoutFormValue (
    formInputs: CheckoutFormInputs,
) {
    //Using custom storage string hook to store values in localStorage.
    const {value: name,setValue: setName} = useStorageString("localStorage",formInputs.name);
    const {value: phone_ext,setValue: setPhoneExt} = useStorageString("localStorage",formInputs.phone_ext);
    const {value: phone,setValue: setPhone} = useStorageString("localStorage",formInputs.phone);
    const {value: message,setValue: setMessage} = useStorageString("localStorage",formInputs.message);

    //Sets the value in localstorage, called everytime booking is attempted.
    function handleSetCheckoutFormValues(value: any) {
        setName(value.full_name);
        setPhoneExt(value.phone_ext);
        setPhone(value.phone_no);
        setMessage(value.message);
    }

    return {
        handleSetCheckoutFormValues: handleSetCheckoutFormValues,
        inputFormValues: {name,phone_ext,phone,message},
    }
}

export{
    usePersistedCheckoutFormValue,
};

export interface CheckoutFormInputs {
    name: string,
    phone_ext: string,
    phone: string,
    message: string
 }