import React from "react";
import useClx from "../../../../hooks/use-clx";
import clxs from "./amenities.module.css";
import SWIMMING_POOL from "../../../../assets/lohono-black/icons/amenity-swimming-pool.svg";
import WIFI from "../../../../assets/lohono-black/icons/amenity-wifi.svg";
import AC from "../../../../assets/lohono-black/icons/amenity-ac.svg";
import TV from "../../../../assets/lohono-black/icons/amenity-tv.svg";
import SPEAKER from "../../../../assets/lohono-black/icons/amenity-speaker.svg";
import HAIR_DRYER from "../../../../assets/lohono-black/icons/amenity-hair-dresser.svg";
import TOILETRIES from "../../../../assets/lohono-black/icons/amenity-toiletries.svg";
import LAUNDRY from "../../../../assets/lohono-black/icons/amenity-laundary.svg";
import COURT from "../../../../assets/lohono-black/icons/amenity-court.svg";
import GAMES_ROOM from "../../../../assets/lohono-black/icons/amenity-games-room.svg";
import KIDS_ZONE from "../../../../assets/lohono-black/icons/amenity-kids-zone.svg";
import POPCORN_CART from "../../../../assets/lohono-black/icons/amenity-popcorn.svg";

interface AmenitiesProps {
  className?: string;
}

function Amenities(props: AmenitiesProps) {
  const { className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Amenities
      </div>
      <div className={clxs.amenities}>
        {TEMPLATE.map(
          (each, key) => (
            <div
              key={key}
              className={clxs.amenity}
            >
              <img
                src={each.icon}
                alt={each.title}
                className={clxs.amenityIcon}
              />
              <div className={clxs.amenityTitle}>
                {each.title}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default Amenities;

const TEMPLATE = [
  {
    title: "Swimming pool",
    icon: SWIMMING_POOL,
  },
  {
    title: "WiFi",
    icon: WIFI,
  },
  {
    title: "Air conditioning",
    icon: AC,
  },
  {
    title: "TV with projector",
    icon: TV,
  },
  {
    title: "Bluetooth speaker",
    icon: SPEAKER,
  },
  {
    title: "Hair dryer",
    icon: HAIR_DRYER,
  },
  {
    title: "Toiletries & bath linen",
    icon: TOILETRIES,
  },
  {
    title: "Laundry service",
    icon: LAUNDRY,
  },
  {
    title: "Multipurpose court",
    icon: COURT,
  },
  {
    title: "Games room",
    icon: GAMES_ROOM,
  },
  {
    title: "Kid's play zone",
    icon: KIDS_ZONE,
  },
  {
    title: "Ice cream & popcorn cart",
    icon: POPCORN_CART,
  },
];
