import { useShowModal } from "../use-modal";

function useLoginModal () {
  return useShowModal(LOGIN_MODAL_ID);
}

const LOGIN_MODAL_ID = "login-flow-modal";

export {
  useLoginModal,
  LOGIN_MODAL_ID,
};
