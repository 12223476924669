import { useAtomValue } from "./use-atom";
import { PRIVACY_KEY } from "../../core/constants";

function usePrivacy() {
  const privacy = useAtomValue<StaticContentSection[]>(
    PRIVACY_KEY,
    [],
  );

  return { privacy: privacy };
}

export default usePrivacy;
