import React, { useMemo } from "react";
import useClx from "../../hooks/use-clx";
import Modal, { useShowModal } from "../../hooks/use-modal";
import CouponSuggestion from "../CouponSuggestion";
import { useCoupon } from "../../hooks/use-coupons";
import clxs from "./coupon-code.module.css";

interface CouponCodeProps {
  value: string;
  propertySlug: string,
  checkinDate?: string,
  checkoutDate?: string,
  adultCount?: number,
  childCount?: number,
  className?: string;
  onChange: (couponCode: string) => void;
}

function CouponCode(props: CouponCodeProps) {
  const showCouponCodeSuggestions = useShowModal(COUPON_CODE_MODAL_ID),
    { value } = props,
    { coupon } = useCoupon(value),
    { onChange, className, ...suggestionProps } = props,
    { title, applied, actionText } = useMemo(
      () => {
        const applied = coupon !== null;

        return {
          applied: applied,
          title: applied ?
            "Offer applied on the bill" :
            "Have a discount coupon?",
          actionText: applied ? "Remove" : "View Offers",
        }
      },
      [coupon],
    ),
    ccx = useClx(clxs.container, className),
    acx = applied ? clxs.remove : clxs.viewOffer,
    handleViewOffer = async () => {
      const couponCode = await showCouponCodeSuggestions(suggestionProps as any);

      if (!couponCode) {
        return;
      }

      onChange(couponCode || "");
    },
    handler = applied ? onChange.bind(null, "NONE") : handleViewOffer;

  return (
    <>
      <div className={ccx}>
        <div className={clxs.title}>
          {coupon && (
            <div className={clxs.success}>
              {`${coupon.name} discount is Applied`}
            </div>
          )}
          {title}
        </div>
        <button
          type="button"
          className={acx}
          onClick={handler}
        >
          {actionText}
        </button>
      </div>
      <Modal id={COUPON_CODE_MODAL_ID}>
        <CouponSuggestion />
      </Modal>
    </>
  );
}

export default CouponCode;

const COUPON_CODE_MODAL_ID = "coupon-suggestions";