import React from "react";
import useClx from "../../../../hooks/use-clx";
import Img from "../../../Img";
import clxs from "./intro.module.css";
import INTRO_BANNER_DESKTOP from "../../../../assets/lohono-black/intro-desktop.jpg";
import INTRO_BANNER_MOBILE from "../../../../assets/lohono-black/intro-mobile.jpg";

interface IntroProps {
  titleClassName?: string;
  subtitleClassName?: string;
  className?: string;
}

function Intro (props: IntroProps) {
  const { className, titleClassName, subtitleClassName } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <h1 className={titleClassName}>
        Lohono Black
      </h1>
      <Img
        alt="Breakfast"
        src={INTRO_BANNER_DESKTOP}
        mobileSrc={INTRO_BANNER_MOBILE}
        desktopSrc={INTRO_BANNER_DESKTOP}
        className={clxs.image}
      />
      <div className={subtitleClassName}>
        Luxury to some means beautifully designed intimate spaces, while to others, it means seven-star hospitality. At
        Lohono Black, we offer you the best of both worlds—the privacy and comfort of your own space combined with the
        exemplary Lohono service and amenities. More importantly, we offer you peace of mind, so you can focus on what’s
        most important—creating memories for a lifetime.
      </div>
    </div>
  );
}

export default Intro;
