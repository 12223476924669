import React, { useMemo } from "react";
import clxs from "./location-contact-us-footer.module.css";
import MAIL_ICON from "../../../../assets/icons/mail-outline-white.svg";
import useIsMobile from "../../../../hooks/use-is-mobile";
import { useScroll } from "../../../../hooks/use-scroll";
import useClx from "../../../../hooks/use-clx";
import { Track } from "../../../AnalyticsManager";

interface LocationContactUsFooterProps {
  formRef:React.RefObject<HTMLDivElement>;
}

function LocationContactUsFooter(props : LocationContactUsFooterProps) {
  const {formRef} = props,
    { isMobile } = useIsMobile(),
    { scroll } = useScroll(),
    scrollVisible = useMemo(
      () => scroll > 100,
      [scroll],
    ),
    ncx = useClx(
      clxs.navBar,
      isMobile && scrollVisible ? clxs.navVisible : clxs.navInvisible,
    );
  

    const scrollImmidiateAssistanceForm = () => {
      formRef?.current?.scrollIntoView({ behavior: "smooth",block: "start", inline: "start"});
    };

  return (
    <>
      <div className={ncx}>
        <Track event="contact_us_mobile_clicked">
          <a
            className={clxs.navBarButton}
            onClick={scrollImmidiateAssistanceForm}
          >
            <img
              src={MAIL_ICON}
              alt="enquire-now"
              className={clxs.icon}
            />
            Enquire Now
          </a>
        </Track>
      </div>
    </>
  );

}

export default LocationContactUsFooter;