import React, { CSSProperties, HTMLAttributes, useMemo, useRef } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./collapse.module.css";

interface CollapseProps extends BaseDivProps {
  expanded: boolean;
  outerClassName?: string;
}

function Collapse (props: CollapseProps) {
  const {
    outerClassName,
    className,
    children,
    expanded,
    ...rest
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const style = useMemo(
    () => {
      const style: CSSProperties = {
        ["--content-height" as any]: ref.current
          ? `${ref.current.clientHeight}px`
          : "100%",
      };

      return style;
    },
    [ref.current],
  );

  const ccx = useClx(clxs.container, outerClassName);

  const cocx = useClx(clxs.content, className);

  return (
    <div
      className={ccx}
      style={style}
    >
      <div
        {...rest}
        ref={ref}
        className={cocx}
        data-expanded={expanded}
      >
        {children}
      </div>
    </div>
  );
}

export default Collapse;

type BaseDivProps = HTMLAttributes<HTMLDivElement>;
