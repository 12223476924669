import { useAtomState, useAtomValue } from "./use-atom";
import { useEffect } from "react";

const PAGE_KEY = "PAGE";

const DEFAULT_PAGE = "ANY";

function usePage(page: string = DEFAULT_PAGE) {
  const [p, setPage] = useAtomState<string>(PAGE_KEY, page),
    handlePageChange = (page: string) => {
      if (p === page) {
        return;
      }

      setPage(page);
    };

  useEffect(() => handlePageChange(page), [page]);

  return { page: p };
}

function usePageValue() {
  const page = useAtomValue<string>(PAGE_KEY, DEFAULT_PAGE);

  return { page };
}

export {
  usePage,
  usePageValue,
};
