import fetch from "cross-fetch";
import { useCallback, useEffect } from "react";
import { useAtomState } from "./use-atom";
import useEnv from "./use-env";

function useIpGeoLocation () {
  const { value } = useIpGeoLocationState();

  return value;
}

export default useIpGeoLocation;

function IpGeoLocationManager () {
  useIpGeoLocationManager();

  return null;
}

export {
  IpGeoLocationManager,
};

function useIpGeoLocationManager () {
  const { IPREGISTRY_KEY } = useEnv();

  const { setValue } = useIpGeoLocationState();

  const getIpGeoLocation = useCallback(
    async () => {
      const ipGeoLocationSession = getIpGeoLocationFromSessionStorage();

      if (ipGeoLocationSession) {
        setValue(ipGeoLocationSession);

        return;
      }

      if (!IPREGISTRY_KEY) {
        return;
      }

      const ipGeoLocationApi = await getIpGeoLocationFromApi(IPREGISTRY_KEY);

      if (!ipGeoLocationApi) {
        return;
      }

      setValue(ipGeoLocationApi);

      setIpGeoLocationToSessionStorage(ipGeoLocationApi);
    },
    [setValue, IPREGISTRY_KEY],
  );

  useEffect(
    () => {
      getIpGeoLocation();
    },
    [getIpGeoLocation],
  );
}

function useIpGeoLocationState () {
  const [value, setValue] = useAtomState<IPGeoLocation>(
    IP_GEO_LOCATION_KEY,
    DEFAULT_IP_GEO_LOCATION,
  );

  return {
    value: value,
    setValue: setValue,
  };
}

async function getIpGeoLocationFromApi (
  apiKey: string,
): Promise<IPGeoLocation | null> {
  if (!apiKey) {
    return null;
  }

  const url = `https://api.ipregistry.co/?key=${apiKey}`;

  try {
    const response = await fetch(url);

    const result: any = await response.json();

    if (!result?.location?.country?.name) {
      return null;
    }

    const ipGeoLocation: IPGeoLocation = {
      city: result?.location?.city ?? "",
      country: result?.location?.country?.name ?? "",
      countryCode: result?.location?.country?.code ?? "",
      pincode: result?.location?.postal ?? "",
      region: result?.location?.region?.name ?? "",
    };

    return ipGeoLocation;
  } catch (e) {
    console.error("unable to detect location!");
  }

  return null;
}

function getIpGeoLocationFromSessionStorage (): IPGeoLocation | null {
  if (typeof window === "undefined" || !window.sessionStorage) {
    return null;
  }

  const result = window.sessionStorage.getItem(IP_GEO_LOCATION_KEY);

  if (!result) {
    return null;
  }

  const ipGeoLocation = JSON.parse(result);

  return ipGeoLocation;
}

function setIpGeoLocationToSessionStorage (payload: IPGeoLocation) {
  if (typeof window === "undefined" || !window.sessionStorage) {
    return;
  }

  const marshalled = JSON.stringify(payload);

  window.sessionStorage.setItem(IP_GEO_LOCATION_KEY, marshalled);
}

const IP_GEO_LOCATION_KEY = "IP_GEO_LOCATION";

const DEFAULT_IP_GEO_LOCATION: IPGeoLocation = {
  city: "",
  country: "",
  countryCode: "",
  pincode: "",
  region: "",
};

export type IPGeoLocation = {
  country: string;
  countryCode: string;
  city: string;
  region: string;
  pincode: string;
};
