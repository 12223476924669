import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import useContactUsPhoneNo from "../../../hooks/use-contact-us-phone-no";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./header.module.css";
import CALL_GRAY from "../../../assets/icons/call-gray.svg";
import CALL_ORANGE from "../../../assets/icons/call-orange.svg";
import LOHONO_LOGO from "../../../assets/logo/lohono-logo.svg";

interface HeaderProps {
  className?: string;
}

function Header (props: HeaderProps) {
  const { className } = props,
    ccx = useClx(
      fwclxs.container,
      fwclxs.containerPadding,
      clxs.container,
      className,
    ),
    { friendly: contactUsPhoneNo, tel } = useContactUsPhoneNo(),
    contactUsContent = useMemo(
      () => `Need Help? ${contactUsPhoneNo}`,
      [contactUsPhoneNo],
    );

  return (
    <nav className={ccx}>
      <a
        href="/"
        className={clxs.logoContainer}
        title="Lohono Stays"
      >
        <img
          className={clxs.logo}
          src={LOHONO_LOGO}
          alt="Lohono"
        />
      </a>
      <div>
        <a
          href={tel}
          className={clxs.callDesktop}
          title="Call us"
        >
          <img
            src={CALL_GRAY}
            alt="call"
          />
          {contactUsContent}
        </a>
        <a
          href={tel}
          className={clxs.callMobile}
          title="Call us"
        >
          <img
            src={CALL_ORANGE}
            alt="call"
          />
        </a>
      </div>
    </nav>
  );
}

export default Header;
