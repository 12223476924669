import { MouseEvent as ME, RefObject, useCallback, useEffect, useState } from "react";

function useDialogToggle<T extends HTMLElement> (
  ref: RefObject<T>,
  disabled?: boolean,
  onOpenDialog?:()=>void,
) {
  const [open, setOpen] = useState<boolean>(false),
    handleOpen = useCallback(
      (e?: ME<HTMLDivElement>) => {

        if(e) {
          e.stopPropagation();
        }

        if (disabled) {
          return;
        }

        const {scrollY} = window;

        if(scrollY < 100) {
          window.scrollTo({top: 150, behavior: "smooth"});
        }

        setOpen(true);
      },
      [setOpen, disabled],
    ),
    handleClose = useCallback(
      () => {
        setOpen(false);
      },
      [setOpen],
    ),
    handleToggle = useCallback(
      () => {
        setOpen(value => !value);
      },
      [setOpen],
    ),
    configureAutoDismiss = useCallback(
      () => {
        if (!document) {
          return;
        }

        if (!ref.current) {
          return;
        }

        const handler = (e: MouseEvent) => {
          const target = e.target as HTMLElement;

          const { dismiss } = target.dataset;

          if (dismiss) {
            setOpen(false);

            return;
          }

          const isChild = ref.current?.contains(target) ?? false;

          if (isChild) {
            return;
          }

          const isInPortal = target.closest("[data-portal='true']") !== null;

          if (isInPortal) {
            return;
          }


          onOpenDialog && onOpenDialog();

          setOpen(false);
        };

        document.addEventListener("click", handler);

        return () => {
          document.removeEventListener("click", handler);
        };
      },
      [setOpen, ref.current],
    );

  useEffect(configureAutoDismiss, [configureAutoDismiss]);

  return {
    open: open,
    handleOpen: handleOpen,
    handleClose: handleClose,
    handleToggle: handleToggle,
  };
}

export default useDialogToggle;
