import {useCallback, useEffect} from "react";
import {useScrollSet} from "../../hooks/use-scroll";

function ScrollSpy() {
  const {handleSetScroll} = useScrollSet(),
    handleScroll = useCallback(
      () => {
        const {scrollY} = window;

        handleSetScroll(scrollY);
      },
      [],
    ),
    handleAddScrollListener = () => {
      document.addEventListener("scroll", handleScroll, {passive: true});
    },
    handleRemoveScrollListener = () => {
      document.removeEventListener("scroll", handleScroll);
    };

  useEffect(
    () => {
      handleAddScrollListener();

      handleScroll();

      return handleRemoveScrollListener;
    },
    [],
  );

  return null;
}

export default ScrollSpy;
