import React, { MouseEventHandler } from "react";
import useClx from "../../../../hooks/use-clx";
import Button from "../../../Button";
import Img from "../../../Img";
import ScrollDown from "../../../ScrollDown";
import fwclxs from "../../../../styles/full-width.module.css";
import clxs from "./banner.module.css";
import BANNER_DESKTOP from "../../../../assets/lohono-black/banner-desktop.jpg";
import BANNER_MOBILE from "../../../../assets/lohono-black/banner-mobile.jpg";

interface BannerProps {
  className?: string;
  onContactUs?: MouseEventHandler<HTMLButtonElement>;
}

function Banner (props: BannerProps) {
  const { className, onContactUs } = props,
    ccx = useClx(
      fwclxs.container,
      fwclxs.containerPadding,
      clxs.container,
      className,
    ),
    bgcx = useClx(fwclxs.container, clxs.background),
    bdcx = useClx(fwclxs.container, clxs.backdrop);

  return (
    <div className={ccx}>
      <Img
        alt="Sea view"
        src={BANNER_DESKTOP}
        desktopSrc={BANNER_DESKTOP}
        mobileSrc={BANNER_MOBILE}
        className={bgcx}
      />
      <div className={bdcx}>&nbsp;</div>
      <div className={clxs.title}>
        {"A selection of curated,\ninvite-only estates for\nthe discerning traveller"}
      </div>
      <Button
        className={clxs.contactUs}
        onClick={onContactUs}
      >
        Contact Us
      </Button>
      <ScrollDown className={clxs.scrollDown} />
    </div>
  );
}

export default Banner;
