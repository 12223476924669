import React from "react";
import useClx from "../../../hooks/use-clx";
import usePropertyTrending from "../../../hooks/use-property-trending";
import Carousel from "../../Carousel";
import { getPropertySearchUrl } from "../../../../utils/property-search";
import { useCarouselScroller } from "../../../hooks/use-carousel";
import Item from "../../Carousel/Item";
import clxs from "./explore.module.css";
import CHEVRON_LEFT from "../../../assets/icons/chevron-left-grey.svg";
import CHEVRON_RIGHT from "../../../assets/icons/chevron-right-grey.svg";

interface ExploreProps {
  className?: string;
}

function Explore(props: ExploreProps) {
  const { trendingProperties } = usePropertyTrending(),
    { className } = props,
    exploreCarouselId = "explore-carousel",
    { scrollLeft, scrollRight } = useCarouselScroller(exploreCarouselId),
    ccx = useClx(clxs.container, className),
    lcx = useClx(clxs.scroller, clxs.scrollerLeft),
    rcx = useClx(clxs.scroller, clxs.scrollerRight);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Explore Lohono
      </div>
      <div className={clxs.description}>
        Exclusive homes for rent spread across pristine destinations, pick your nest of luxury
      </div>
      <Carousel
        id={exploreCarouselId}
        className={clxs.carousel}
      >
        {trendingProperties.map(
          (property, key) => {
            const {
                thumbnail,
                name,
                address,
                location_slug,
                property_slug,
              } = property,
              locationUrl = getPropertySearchUrl(location_slug),
              url = `${locationUrl}/${property_slug}`;

            return (
              <Item key={key}>
                <a
                  className={clxs.property}
                  href={url}
                >
                  <img
                    src={thumbnail.url}
                    alt={thumbnail.alt}
                    className={clxs.propertyThumbnail}
                  />
                  <div className={clxs.propertyTitle}>
                    {name}
                  </div>
                  <div className={clxs.propertySubtitle}>
                    {address}
                  </div>
                </a>
              </Item>
            );
          },
        )}
      </Carousel>
      <div
        className={lcx}
        onClick={scrollLeft}
      >
        <img
          src={CHEVRON_LEFT}
          alt="left"
        />
      </div>
      <div
        className={rcx}
        onClick={scrollRight}
      >
        <img
          src={CHEVRON_RIGHT}
          alt="right"
        />
      </div>
    </div>
  );
}

export default Explore;
