import React from "react";
import BULLET_ICON from "../../assets/icons/lohono-bullet.svg";
import CHECKLIST_ICON from "../../assets/icons/who-checklist.svg";
import Button from "../Button";
import SendRequestForm from "../SendRequestForm";
import clxs from "./sanitation.module.css";
import { SANITATION_QUICK_LINK_TEMPLATE, SANITATION_TEMPLATE } from "../../core/sanitation";
import useSendRequest from "../../hooks/use-send-request";

function Sanitation () {
  const { sendRequest } = useSendRequest();

  const defaultValue = {
    full_name: "",
    phone_ext: "+91",
    phone_no: "",
    email: "",
    interest: "rental",
    location: "goa",
    message: "",
  };

  return (
    <div className={clxs.container}>
      <div className={clxs.titleContainer}>
        <img
          src={CHECKLIST_ICON}
          alt="sanitization-icon"
          className={clxs.titleIcon}
        />
        <h1 className={clxs.title}>
          We follow all the regulations provided by WHO to keep you safe.
        </h1>
      </div>
      <div className={clxs.sendRequestContainer}>
        <SendRequestForm
          id="contact-us-embed"
          className={clxs.sendRequest}
          defaultValue={defaultValue}
          isClearOnSubmit={true}
          onSubmit={sendRequest}
          titleNode={(
            <div className={clxs.sendRequestTitle}>
              Send a request
            </div>
          )}
        >
          <Button
            className={clxs.submit}
            type="submit"
          >
            Submit
          </Button>
        </SendRequestForm>
        <div className={clxs.quickLinks}>
          <div className={clxs.quickLinksTitle}>
            Book your stay
          </div>
          <div className={clxs.quickLinksSubtitle}>
            Select your destination
          </div>
          {SANITATION_QUICK_LINK_TEMPLATE.map(
            (each, i) => (
              <a
                key={i}
                href={each.href}
                className={clxs.quickLink}
              >
                <img
                  loading="lazy"
                  src={each.src}
                  alt={each.title}
                  className={clxs.quickLinkThumbnail}
                />
                <div className={clxs.quickLinkTitle}>
                  {each.title}
                </div>
              </a>
            ),
          )}
        </div>
      </div>
      <ul className={clxs.checklist}>
        <div className={clxs.checklistTitle}>
          Provisions
        </div>
        {SANITATION_TEMPLATE.map(
          (bullet, i) => (
            <li
              key={i}
              className={clxs.bullet}
            >
              <img
                src={BULLET_ICON}
                alt="bullet"
                className={clxs.bulletDecoration}
              />
              {bullet}
            </li>
          ),
        )}
      </ul>
    </div>
  );
}

export default Sanitation;
