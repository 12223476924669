import React, { useCallback, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import useAnalytics from "../../hooks/use-analytics";
import useClx from "../../hooks/use-clx";
import usePathParam from "../../hooks/use-path-params";
import { useLogout } from "../../hooks/use-session";
import { useSnackbar } from "../../hooks/use-snackbar";
import useUserWithInitials from "../../hooks/use-user-with-initials";
import useWindowNavigate from "../../hooks/use-window-navigate";
import PointTag from "../PointTag";
import Edit from "./Edit";
import Trips from "./Trips";
import { Track } from "../AnalyticsManager";
import fwclxs from "../../styles/full-width.module.css";
import clxs from "./profile.module.css";
import CHEVRON_LEFT from "../../assets/arrow_back.svg";
import CHEVRON_RIGHT from "../../assets/icons/chevron-right-grey.svg";
import CHEVRON_RIGHT_ACTIVE from "../../assets/icons/chevron-right-primary.svg";
import PROFILE_ICON from "../../assets/icons/profile.svg";
import PROFILE_ACTIVE_ICON from "../../assets/icons/profile-active.svg";
import TRIPS_ICON from "../../assets/icons/trips.svg";
import TRIPS_ACTIVE_ICON from "../../assets/icons/trips-active.svg";
import LOGOUT_ICON from "../../assets/icons/logout.svg";
import REWARDS_ICON from "../../assets/icons/rewards.svg";
import REDEEM_ICON from "../../assets/refer-n-earn/redeem.svg";

function Profile () {
  const push = useWindowNavigate();

  const enqueueSnackbar = useSnackbar();

  const { track } = useAnalytics();

  const action = usePathParam(PATH, "action");

  const inChildRoute = useMemo(
    () => action !== "",
    [action],
  );

  const { initials, fullName } = useUserWithInitials();

  const logout = useLogout();

  const handleLogout = useCallback(
    async () => {
      if (!confirm("Are you sure?")) {
        return;
      }

      const err = await logout();

      if (err) {
        track("logout_unsuccessful", { error: err.message });

        enqueueSnackbar(err.message, "error");

        return;
      }

      track("logout_successful");

      push({ pathname: "/" }, { replace: true });
    },
    [logout, push],
  );

  const hcx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.headerContainer);

  return (
    <>
      <div
        className={hcx}
        data-in-child-route={inChildRoute}
      >
        <a
          href="/"
          className={clxs.backIcon}
        >
          <img
            src={CHEVRON_LEFT}
            alt="left"
          />
        </a>
        Profile
      </div>
      <div className={clxs.container}>
        <div
          className={clxs.profileContainer}
          data-in-child-route={inChildRoute}
        >
          <div className={clxs.avatarContainer}>
            <div className={clxs.avatar}>
              {initials}
            </div>
            {fullName}
          </div>
          <div className={clxs.navLinks}>
            <Track
              event="profile_edit_clicked"
              payload={{
                title: "Edit",
                header: "Edit",
                href: "/profile/edit",
              }}
            >
              <a
                href="/profile/edit"
                className={clxs.navLink}
                data-active={action === "edit"}
              >
                <img
                  src={action === "edit" ? PROFILE_ACTIVE_ICON : PROFILE_ICON}
                  alt="profileIcon"
                  className={clxs.icon}
                />
                My Profile
                <img
                  src={action === "edit" ? CHEVRON_RIGHT_ACTIVE : CHEVRON_RIGHT}
                  alt="jump"
                  className={clxs.jump}
                />
              </a>
            </Track>
            <Track
              event="profile_my_trips_clicked"
              payload={{
                title: "My Trips",
                header: "My Trips",
                href: "/profile/trips",
              }}
            >
              <a
                href="/profile/trips"
                className={clxs.navLink}
                data-active={action === "trips"}
              >
                <img
                  src={action === "trips" ? TRIPS_ACTIVE_ICON : TRIPS_ICON}
                  alt="myTripsIcon"
                  className={clxs.icon}
                />
                My Trips
                <img
                  src={action === "trips" ? CHEVRON_RIGHT_ACTIVE : CHEVRON_RIGHT}
                  alt="jump"
                  className={clxs.jump}
                />
              </a>
            </Track>
            <Track
              event="profile_loyalty_points_clicked"
              payload={{
                title: "Rewards",
                header: "Rewards",
                href: "/loyalty-points",
              }}
            >
              <a
                href="/loyalty-points"
                className={clxs.rewards}
              >
                <img
                  src={REWARDS_ICON}
                  alt="rewards"
                  className={clxs.icon}
                />
                Rewards
                <PointTag className={clxs.rewardPoints} />
                <img
                  src={CHEVRON_RIGHT}
                  alt="jump"
                  className={clxs.jump}
                />
              </a>
            </Track>
            <Track
              event="profile_invite_and_earn_clicked"
              payload={{
                title: "Invite and Earn",
                header: "Invite and Earn",
                href: "./refer",
              }}
            >
              <a
                href="../../../refer"
                className={clxs.navLink}
              >
                <img
                  src={REDEEM_ICON}
                  alt="redeem"
                  className={clxs.icon}
                />
                Invite and Earn
                <img
                  src={CHEVRON_RIGHT}
                  alt="jump"
                  className={clxs.jump}
                />
              </a>
            </Track>
            <Track event="profile_logout_clicked">
              <div
                className={clxs.navLink}
                onClick={handleLogout}
              >
                <img
                  src={LOGOUT_ICON}
                  alt="LogoutIcon"
                  className={clxs.icon}
                />
                Logout
              </div>
            </Track>
          </div>
        </div>
        <Routes>
          <Route
            path="/edit"
            element={(
              <Edit
                className={clxs.action}
                data-in-child-route={inChildRoute}
              />
            )}
          />
          <Route
            path="/trips"
            element={(
              <Trips
                className={clxs.action}
                data-in-child-route={inChildRoute}
              />
            )}
          />
        </Routes>
      </div>
    </>
  );
}

const PATH = "/profile/:action";

export default Profile;