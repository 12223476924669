import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import useUtm from "../../hooks/use-utm";
import useAnalytics from "../../hooks/use-analytics";
import useFirstTime from "../../hooks/use-first-time";
import { useStorageString } from "../../hooks/use-storage";

interface AnalyticsManagerProps {
  page?: string;
}

function AnalyticsManager(props: AnalyticsManagerProps) {
  const { track } = useAnalytics(),
    { firstTime } = useFirstTime(),
    { utm } = useUtm(),
    { page } = props,
    { pathname, search, hash } = useLocation(),
    { value: session } = useStorageString("sessionStorage", "session_id", uuid),
    handleOnLoad = () => {
      setTimeout(track, 500, "page_loaded", { session_id: session }, [], page);
    },
    handleRouteChange = () => {
      if (page === "ANY") {
        return;
      }

      if (firstTime) {
        setTimeout(track, 500, "location_changed", undefined, [], page);
      } else {
        track("location_changed", undefined, undefined, page);
      }
    };

  useEffect(() => handleOnLoad(), [page]);

  useEffect(
    () => handleRouteChange(),
    [pathname, search, hash, utm, page],
  );

  useUtm();

  return null;
}

export default AnalyticsManager;
