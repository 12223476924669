import React from "react";
import useEnv from "../../hooks/use-env";
import { AnchorButton } from "../Button";
import clxs from "./investor-report.module.css";
import DOWNLOAD_ICON from "../../assets/icons/download.svg";
import INVESTOR_REPORT_BACKGROUND from "../../assets/investor-report-background.png";
import INVESTOR_REPORTS from "../../core/investor-reports";

function InvestorReport () {
  const { REMOTE_PUBLIC_ASSET_FOLDER } = useEnv();

  return (
    <>
      <img
        src={INVESTOR_REPORT_BACKGROUND}
        alt="investor-report-background"
        className={clxs.background}
        loading="lazy"
      />
      <div className={clxs.container}>
        <div className={clxs.title}>
          Downloads
        </div>
        <div className={clxs.reports}>
          {INVESTOR_REPORTS.map(
            (each, key) => {
              const href = `${REMOTE_PUBLIC_ASSET_FOLDER}${each.href}`;

              return (
                <div
                  className={clxs.item}
                  key={key}
                >
                  <div>{each.title}</div>
                  <AnchorButton
                    className={clxs.download}
                    href={href}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src={DOWNLOAD_ICON}
                      alt="download"
                      className={clxs.downloadIcon}
                    />
                    Download
                  </AnchorButton>
                </div>
              );
            },
          )}
        </div>
      </div>
    </>
  );
}

export default InvestorReport;
