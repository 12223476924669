function parsePagination (from: any): Pagination {
  const paginate = from.paginate ?? from.paginate_body ?? DEFAULT_PAGINATION;

  return {
    ...DEFAULT_PAGINATION,
    ...paginate,
    current_page: Number.parseInt(paginate.current_page),
  };
}

export default parsePagination;

const DEFAULT_PAGINATION: Pagination = {
  total_pages: 0,
  current_page: 0,
  total_records: 0,
  start_offset: 0,
  end_offset: 0,
};

export {
  DEFAULT_PAGINATION,
};