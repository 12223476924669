import { useMemo } from "react";
import PARTNER_ICON from "../../assets/icons/partner-with-us.svg";
import RENTAL_ENQUIRY_ICON from "../../assets/icons/rental-enquiries.svg";
import CORPORATE_ENQUIRY_ICON from "../../assets/icons/corporate-enquiries.svg";
import useContactUsPhoneNo from "../../hooks/use-contact-us-phone-no";
import useCountryCode from "../../hooks/use-country-code";

function useContactUsTemplate () {
  const { countryCode } = useCountryCode();

  const { friendly: contactUsPhoneNo } = useContactUsPhoneNo();

  const template = useMemo(
    () => {
      const rentalEnquiry = {
          title: "Rentals Enquiries",
          contact: contactUsPhoneNo,
          icon: RENTAL_ENQUIRY_ICON,
          className: "",
          track_event: "contact_us_rental_enquiries_call_click",
        },
        partnerEnquiry = {
          title: "Partner as a Homeowner",
          contact: "+91 96190 98948",
          icon: PARTNER_ICON,
          className: "",
          track_event: "contact_us_partner_homeowner_call_click",
        },
        corporateEnquiry = {
          title: "Travel Partners and Corporate Enquiries",
          contact: "+91 75388 80808",
          icon: CORPORATE_ENQUIRY_ICON,
          className: "",
          track_event: "contact_us_corporate_enquiry__call_click",
        };

      if (countryCode !== "IN") {
        return [rentalEnquiry];
      }

      return [rentalEnquiry, partnerEnquiry, corporateEnquiry];
    },
    [countryCode, contactUsPhoneNo],
  );

  return template;
}

export default useContactUsTemplate;
