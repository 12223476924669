import { useCallback, useEffect } from "react";
// import LOHONO_LOGO from "../assets/logo/lohono-logo.svg";
import { useAtomState } from "./use-atom";

function useLogo (logoKey: string, logoSrc?: any) {

  const [brandLogo, setBrandLogo] = useAtomState<string>(logoKey, logoSrc);

  const handleSetBrandLogo = useCallback(
    (logoSrc?: string) => {
      if (!logoSrc) {
        return;
      }

      setBrandLogo(logoSrc);
    },
    [setBrandLogo],
  );

  useEffect(
    () => handleSetBrandLogo(logoSrc),
    [handleSetBrandLogo, logoSrc],
  );

  return brandLogo;
}

export default useLogo;
