import React from "react";
import { Select, TextField } from "../../Form/Fields";
import CREATE_ACCOUNT_SCHEMA from "./create-account-schema";
import clxs from "./create-account.module.css";
import Form from "../../Form";
import Button from "../../Button";
import usePhoneExts from "../../../hooks/use-phone-exts";
import useClx from "../../../hooks/use-clx";

interface CreateAccountProps {
  onSubmit: (value: any) => void;
}

function CreateAccount (props: CreateAccountProps) {
  const phoneExts = usePhoneExts(),
  pcx = useClx(clxs.phoneNo , "phone-no"),
    { onSubmit } = props;

  return (
    <>
      <div className={clxs.title}>
       Login / Create Account
        <div className={clxs.subTitle}>
          Enter your phone number to continue
        </div>
      </div>
      <Form
        id={FORM_ID}
        defaultValue={DEFAULT_VALUE}
        onSubmit={onSubmit}
        className={clxs.formContainer}
        validationSchema={CREATE_ACCOUNT_SCHEMA}
      >
        <div className={clxs.phoneContainer}>
          <Select
            form={FORM_ID}
            name="phone_ext"
            options={phoneExts}
            // label="Phone code *"
          />
          <TextField
            form={FORM_ID}
            name="phone_no"
            className={pcx}
            // label="Phone number *"
            placeholder="Phone number *"
          />
        </div>
        <Button
          form={FORM_ID}
          type="submit"
          className={clxs.sendOtpButton}
        >
          Send Otp
        </Button>
      </Form>
    </>
  );
}

export default CreateAccount;

const FORM_ID = "create-account";

const DEFAULT_VALUE = {
  phone_ext: "+91",
  phone_no: "",
};

