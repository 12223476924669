import React, { HTMLAttributes, MouseEventHandler } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./alert.module.css";

interface AlertProps extends DivBaseProps {
  severity: AlertSeverity;
  onClose?: MouseEventHandler;
}

function Alert (props: AlertProps) {
  const {
      children,
      severity,
      className,
      onClose,
      ...rest
    } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div
      {...rest}
      className={ccx}
      data-severity={severity}
    >
      {children}
      {onClose ? (
        <div
          className={clxs.clear}
          onClick={onClose}
        >
          ×
        </div>
      ) : (
        <span>&nbsp;</span>
      )}
    </div>
  );
}

export default Alert;

type DivBaseProps = HTMLAttributes<HTMLDivElement>;
