import { ReactNode } from "react";
import usePortalElement from "../../hooks/use-portal-element";
import { createPortal } from "react-dom";
import useScrollBlock from "./use-scroll-block";

interface PortalProps {
  children: ReactNode;
  className?: string;
  style?: string;
  scrollBlock?: boolean;
}

function Portal(props: PortalProps) {
  const { className, style, scrollBlock, children } = props;

  const { element } = usePortalElement(className, style);

  useScrollBlock(scrollBlock);

  if (!element) {
    return null;
  }

  return createPortal(children, element);
}

export default Portal;
