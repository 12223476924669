import Fetch from "../../utils/fetch";
import cached from "../../utils/cached";

class ReservationService implements IReservationService {
  private readonly fetch: Fetch<ApiResult>;

  constructor(fetch: Fetch<ApiResult>) {
    this.fetch = fetch;
  }

  getTentativePrice(
    args: TentativePriceArgs,
    session?: string,
  ): Promise<FetchResult<TentativePrice>> {
    const url = new URL("/api/reservation/tentative", window.location.origin);

    return this.fetch.POST(
      url.toString(),
      { args: args },
      { headers: { session: session ?? "" } },
    );
  }

  addCouponLoyaltyPoints(
    args: BookingPricingArgs,
    session?: string,
  ): Promise<FetchResult<TentativePrice>> {
    const url = new URL("/api/reservation/pricing/couponLoyaltyPoints", window.location.origin);

    return this.fetch.PATCH(
      url.toString(),
      { args: args },
      { headers: { session: session ?? "" } },
    );
  }

  deleteCoupon(
    args: BookingPricingArgs,
    session?: string,
  ): Promise<FetchResult<TentativePrice>> {
    const url = new URL("/api/reservation/pricing/deleteCoupon", window.location.origin);

    return this.fetch.DELETE(
      url.toString(),
      { args: args },
      { headers: { session: session ?? "" } },
    );
  }

  addReservation(
    args: ReservationArgs,
    session?: string,
  ): Promise<FetchResult<Reservation>> {
    const url = new URL("/api/reservation", window.location.origin);

    return this.fetch.POST(
      url.toString(),
      { args: args },
      { headers: { session: session ?? "" } },
    );
  }

  confirmReservation(
    order_id: string,
    payment_id: string,
    signature: string,
    session?: string,
  ): Promise<FetchResult<string | string[]>> {
    const payload = {
      payment_id: payment_id,
      signature: signature,
    };

    const url = new URL(`/api/reservation/confirm/${order_id}`, window.location.origin);

    return this.fetch.POST(
      url.toString(),
      payload,
      { headers: { session: session ?? "" } },
    );
  }

  getReservation(slug: string): Promise<FetchResult<ReservationDetail>> {
    const url = new URL(`/api/reservation/${slug}`, window.location.origin);

    return this.fetch.GET(url.toString());
  }

  @cached()
  getCoupons(
    property_slug: string,
    checkin_date?: string,
    checkout_date?: string,
    adult_count?: number,
    child_count?: number,
  ): Promise<FetchResult<Coupon[]>> {
    const url = new URL(`/api/property/${property_slug}/coupon`, window.location.origin);

    if (checkin_date) {
      url.searchParams.set("checkin_date", checkin_date);
    }

    if (checkout_date) {
      url.searchParams.set("checkout_date", checkout_date);
    }

    if (adult_count) {
      url.searchParams.set("adult_count", adult_count.toString());
    }

    if (child_count) {
      url.searchParams.set("child_count", child_count.toString());
    }

    return this.fetch.GET(url.toString());
  }

  @cached()
  getCoupon(coupon_code: string): Promise<FetchResult<Coupon>> {
    const url = new URL(`/api/coupon/${coupon_code}`, window.location.origin);

    return this.fetch.GET(url.toString());
  }

  getConfirmedReservations(
    session: string,
    reservation_status: string,
  ): Promise<FetchResult<ConfirmedReservation[]>> {
    const url = new URL("/api/user/reservations", window.location.origin);

    url.searchParams.set("reservation_status", reservation_status);

    return this.fetch.GET(
      url.toString(), {
        headers: { session: session },
      },
    );
  }

  getBookingDetail(booking_slug: string): Promise<FetchResult<Property>> {
    const url = new URL(`/api/user/reservations/${booking_slug}`, window.location.origin);

    return this.fetch.GET(url.toString());
  }

  getFeedbackArgs(feedback_slug: string): Promise<FetchResult<FeedbackArgs>> {
    const url = new URL(`/api/feedback/${feedback_slug}`, window.location.origin);

    return this.fetch.GET(url.toString());
  }

  saveFeedback(
    feedback_slug: string,
    property_slug: string,
    guest_name: string,
    questionnaire: any[],
    meta: any,
  ): Promise<FetchResult<void>> {
    const payload = {
      property_slug: property_slug,
      guest_name: guest_name,
      questionnaire: questionnaire,
      meta: meta,
    };

    const url = new URL(`/api/feedback/${feedback_slug}`, window.location.origin);

    return this.fetch.POST(url.toString(), payload);
  }
  
  getPaymentStatus(payment_id: string): Promise<FetchResult<PaymentStatus>> {
    const url = new URL(`/api/reservation/payment-status/${payment_id}`, window.location.origin);

    return this.fetch.GET(url.toString());
  }

  createBooking(
    args: BookingPostArgs, 
    session: string,
  ): Promise<FetchResult<BookingResponse>> {
    const url = new URL("/api/reservation/booking", window.location.origin);

    return this.fetch.POST(
      url.toString(),
      { args: args },
      { headers: { session: session ?? "" } },
    );
  }

  updateBooking(
    args: BookingUpdateArgs, 
    session: string,
  ): Promise<FetchResult<BookingResponse>> {
    const url = new URL("/api/reservation/booking/update", window.location.origin);

    return this.fetch.PATCH(
      url.toString(),
      { args: args },
      { headers: { session: session ?? "" } },
    );
  }

  bookingPayment(
    args: BookingPaymentArgs, 
    session: string,
  ): Promise<FetchResult<BookingPaymentResponse>> {
    const url = new URL("/api/reservation/booking/payment", window.location.origin);

    return this.fetch.POST(
      url.toString(),
      { args: args },
      { headers: { session: session ?? "" } },
    );
  }
}

export default ReservationService;
