import React, { ChangeEventHandler, useEffect } from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./otp-input.module.css";
import useOtpGenerate from "./use-otp-generate";
import useOtpInputState from "./use-otp-input-state";
import TIMER_ICON from "../../assets/icons/hourglass_full.svg";

export interface OtpInputProps {
  defaultValue?: string;
  value?: string;
  name?: string;
  className?: string;
  onGenerate?: (resend: boolean) => void;
  onChange?: ChangeEventHandler;
  timeout: number;
  error?: string;
  disabled?: boolean;
  isAssistanceForm?:boolean;
}

function OtpInput (props: OtpInputProps) {
  const {
      name,
      className,
      onGenerate,
      onChange,
      timeout,
      value,
      defaultValue,
      error,
      isAssistanceForm,
      disabled = false,
    } = props,
    {
      digits,
      handleReset,
      handleChange,
      handleKeyDown,
      handleKeyUp,
    } = useOtpInputState(name, onChange, defaultValue, value),
    {
      timer,
      regenerated,
      handleRegenerate,
    } = useOtpGenerate(handleReset, timeout, onGenerate),
    countingDown = timer > 0,
    ccx = useClx(clxs.container, className),
    dcx = useClx(clxs.digitsContainer, "digits"),
    icx = useClx(clxs.digit, "digit");

    useEffect(() => {
      handleReset();
    }, []);

  return (
    <div className={ccx}>
      <div className={dcx}>
        {digits.map(
          (value, index) => (
            <input
              disabled={disabled}
              key={index}
              type="tel"
              data-index={index}
              maxLength={1}
              minLength={1}
              className={isAssistanceForm ? clxs.smallDigit : icx}
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              tabIndex={0}
            />
          ),
        )}
      </div>
      {(!isAssistanceForm) ? (
      <div
        className={clxs.timer}
        data-visible={countingDown}
      >
        <img
          src={TIMER_ICON}
          alt="timer"
          className={clxs.timerIcon}
        />
        {timer}
      </div>) : (<div></div>)}
      {(!regenerated && !isAssistanceForm) ? (
        <div className={clxs.resendContent}>
          {"Haven’t received a code?"}
          <button
            className={clxs.resend}
            disabled={countingDown && !regenerated}
            onClick={handleRegenerate}
            type="button"
          >
            Resend Now
          </button>
        </div>
      ) : (<div></div>)}
      {error && <span className={clxs.error}>{error}</span>}
    </div>
  );
}

export default OtpInput;

