import React from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./secure-payment.module.css";
import SECURE_SHIELD from "../../assets/icons/secure-shield.svg";

interface SecurePaymentProps {
  className?: string;
}

function SecurePayment (props: SecurePaymentProps) {
  const { className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <img
        src={SECURE_SHIELD}
        alt="icon"
        className={clxs.icon}
      />
      <div className={clxs.title}>100% Secure payment</div>
      <div className={clxs.subtitle}>40,000+ guests hosted</div>
    </div>
  );
}

export default SecurePayment;
