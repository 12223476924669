import React, { ReactNode, useEffect, useRef, useState } from "react";
// import useAnalytics from "../../../hooks/use-analytics";
import Carousel from "../../Carousel";
import Item from "../../Carousel/Item";
import ScrollCrumb from "./ScrollCrumb";
import Img from "../../Img";
// import AppBanner from "./AppBanner";
import { Track } from "../../AnalyticsManager";
import { useCarouselScroller } from "../../../hooks/use-carousel";
import useCarouselAutoscroll from "../../../hooks/use-carousel-autoscroll";
import useClx from "../../../hooks/use-clx";
import useDomObserver from "../../../hooks/use-dom-observer";
import usePropertyBannerTemplate from "./use-property-banner-template";
import fwclxs from "../../../styles/full-width.module.css";
import clxs from "./property-carousel.module.css";
// import TWOONE_BANNER_WEB from "../../../assets/twoone_banner_web_april.jpg";
// import TWOONE_BANNER_MOBILE from "../../../assets/twoone_banner_responsive_april.jpg";
// import REFERRAL_BANNER_WEB from "../../../assets/referral_banner_web.jpg";
// import REFERRAL_BANNER_MOBILE from "../../../assets/referral_banner_responsive.jpg";

interface PropertyCarouselProps {
  className?: string;
  children?: ReactNode;
}

function PropertyCarousel(props: PropertyCarouselProps) {
  // const { track } = useAnalytics(),
  const ref = useRef<HTMLDivElement | null>(null),
  { visibleSet } = useCarouselScroller(CAROUSEL_ID),
  [domVisible, setDomVisible] = useState<boolean>(false),
  [visible, setVisible] = useState<BannerData | null>(null),
  propertyBannerTemplate = usePropertyBannerTemplate(),
  { className, children } = props,
  ccx = useClx(fwclxs.container, clxs.container, className),
  cscx = useClx(clxs.carousel, "carousel"),
  handleVisibleChange = (visibleSet: Set<number>) => {
    const visible = [...visibleSet];

    if (!visible.length) {
      return;
    }

    if(!propertyBannerTemplate?.length) {
      return;
    }

    setVisible(propertyBannerTemplate[visible[0]]);
  },
  handleDOMVisibleChange = (entry: IntersectionObserverEntry) => {
    const { intersectionRatio } = entry;

    if (intersectionRatio < 0.3) {
      setDomVisible(false);

      return;
    }

    if (intersectionRatio > 0.7) {
      setDomVisible(true);

      return;
    }
  };

  useCarouselAutoscroll(CAROUSEL_ID, 7500, domVisible);

  const validateUrl = (url:string) => {
    try {
      const urlObj = new URL(url);
      return urlObj.pathname;
    } catch (err) {
      return url;
    }
  }

  useEffect(() => {
    handleVisibleChange(visibleSet);
  }, [visibleSet]);

  useDomObserver(ref, handleDOMVisibleChange, DOM_OBSERVER_THRESHOLD);

  return (
    <div className={ccx}>
      <Carousel
        id={CAROUSEL_ID}
        className={cscx}
        threshold={0.98}
        forwardRef={ref}
      >
        {[...propertyBannerTemplate.map(
            (each, index) => {
              const 
                name = each.title, 
                desktop_image_url = each.desktop_url, 
                mobile_image_url = each.image_url, 
                path = validateUrl(each.redirect_url),
                altImage = each.alt,
              
              title = `Luxury villa for rent - ${name}`;

            return (
              <Item 
                key={index} 
                className={clxs.item}
                title={title}
              >
                <Track
                  event="home_banner_clicked"
                  payload={{
                    location: each.subtitle,
                    property: each.title,
                    url: each.redirect_url,
                    index: index,
                    alt: altImage,
                  }}
                >
                  <a href={path}>
                    <Img
                      src={mobile_image_url}
                      mobileSrc={mobile_image_url}
                      desktopSrc={desktop_image_url}
                      className={clxs.img}
                      loading="lazy"
                      alt={altImage ? altImage : name}
                    />
                  </a>
                </Track>
              </Item>
            );
          }),
        ]}
      </Carousel>
      <ScrollCrumb
        carouselId={CAROUSEL_ID}
        count={propertyBannerTemplate.length}
        className={clxs.scrollCrumb}
      />
      {(visible?.redirect_url && visible?.title) && (
        <Track
          event="home_banner_clicked"
          payload={{
            location: visible.subtitle,
            destination: visible.subtitle,
            property: visible.title,
          }}
        >
          <a 
            href={visible.redirect_url} 
            className={clxs.jump}
          >
            <div className={clxs.title}>{visible.title}</div>
            <div className={clxs.subtitle}>{visible.subtitle}</div>
          </a>
        </Track>
      )}
      {children}
    </div>
  );
}

export default PropertyCarousel;

const DOM_OBSERVER_THRESHOLD = [0.3, 0.7];

const CAROUSEL_ID = "property-banners";
