import React from "react";
import clxs from "./skeleton.module.css";

interface SkeletonProps {
  width?: string | number;
  height?: string | number;
  variant?: string;
}

function Skeleton({ width, height, variant }: SkeletonProps) {
  const style = {
    width,
    height,
  };

  return (
    <span
      className={`${clxs.skeleton} ${variant ? clxs[variant] : " "}`}
      style={style}
    ></span>
  );
}

export default Skeleton;
