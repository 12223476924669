import differenceInDays from "date-fns/differenceInDays";
import getDay from "date-fns/getDay";
import { TestConfig } from "yup";

const SINGLE_WEEKEND_VALIDATION: TestConfig = {
  name: "weekend-validation",
  message: "minimum 2-night booking is required for weekend stays",
  test: (value) => {
    const {
      start: checkin,
      end: checkout,
    } = value as { start: Date | null, end: Date | null };

    if (!checkin || !checkout) {
      return true;
    }

    const periodInDays = differenceInDays(checkout, checkin);

    if (periodInDays !== 1) {
      return true;
    }

    const checkinDay = getDay(checkin),
      checkoutDay = getDay(checkout);

    return !(checkinDay === 0 || checkinDay === 6
      || checkoutDay === 0 || checkoutDay === 6);
  },
};

export default SINGLE_WEEKEND_VALIDATION;
