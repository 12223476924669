import { useCallback, useEffect, useState } from "react";
import { useAtomState } from "../use-atom";
import { useQueryParam } from "../use-query-param";

function useModal<P extends SubmitProps<V>, V = any> (
  id: string,
): [SubmitReqProps<P, V> | null, ShowModal<P, V>] {
  const [childProps, setChildProps] = useAtomState<SubmitReqProps<P, V> | null>(id, null);

  const setModalId = useModalQueryParamSync(id, childProps?.onSubmit);

  const handleShowModal: ShowModal<P, V> = useCallback(
    async (props) => {
      setModalId(id);

      const result = await new Promise<V | null>(
        resolve => {
          const childProps: SubmitReqProps<P, V> = {
            ...props,
            onSubmit: resolve,
          } as any;

          setChildProps(childProps);
        },
      );

      setChildProps(null);

      setModalId("", { replace: true });

      return result;
    },
    [setModalId, setChildProps, id],
  );

  return [childProps, handleShowModal];
}

function useShowModal<P extends SubmitProps<V> = any, V = any> (
  id: string,
): ShowModal<P, V> {
  const [_, handleShowModal] = useModal<P, V>(id);

  return handleShowModal;
}

export {
  useModal,
  useShowModal,
};

function useModalQueryParamSync (
  id: string,
  onSubmit?: (out: any) => void,
) {
  const [modalId, setModalId] = useQueryParam("string", "modal_id");

  const [lastModalId, setLastModalId] = useState<string>(modalId);

  useEffect(
    () => setLastModalId(modalId),
    [setLastModalId, modalId],
  );

  useEffect(
    () => {
      if (modalId || lastModalId !== id || !onSubmit) {
        return;
      }

      onSubmit(null);
    },
    [id, onSubmit, modalId, lastModalId],
  );

  return setModalId;
}

type SubmitReqProps<P extends SubmitProps<V>, V = any> = P & {
  onSubmit: (value: V | null) => void;
};

type ShowModal<P extends SubmitProps<V>, V = any> = (
  props: P
) => Promise<V | null>;

export type SubmitProps<T> = {
  className?: string;
  onSubmit?: ((value: T) => void) | ((value: null) => void);
};