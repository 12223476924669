import Base from "../../utils/fetch";
import fetch from "cross-fetch";

class Fetch extends Base<ApiResult> {
  protected async base<T>(url: string, data?: any, options?: RequestInit): Promise<ApiResult<T>> {
    const headers: any = options?.headers || {},
      method = options?.method || "GET";

    headers["Accept"] = "application/json";

    const _options: RequestInit = {
      ...options,
      method,
      headers,
    };

    if (data) {
      if (data instanceof FormData) {
        _options.body = data;
      } else {
        _options.body = JSON.stringify(data);
        headers["Content-Type"] = "application/json";
      }
    }

    try {
      const response = await fetch(url, _options),
        { status } = response,
        unmarshalled = (await response.json()) as any;

      if (status < 200 || status > 399) {
        return {
          status: status,
          error: new Error(unmarshalled.error || JSON.stringify(unmarshalled)),
          response: unmarshalled.response as T,
        };
      }

      return {
        status: status,
        response: unmarshalled.response as T,
        paginate: unmarshalled.paginate || undefined,
        error: null,
      };
    } catch (e: any) {
      return {
        status: 503,
        response: null,
        error: e,
      };
    }
  }
}

export default Fetch;
