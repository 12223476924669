import React, { useCallback, useMemo, useState } from "react";
import useClx from "../../../hooks/use-clx";
import usePathParams from "../../../hooks/use-path-params";
import clxs from "./outro.module.css";
//import OUTRO_TEMPLATE from "../../../core/outro";
import Collapse from "../../Collapse";
import OUTRO_TEMPLATE_NEW from "../../../core/outro";

interface OutroProps {
  path: string;
  className?: string;
}

function Outro(props: OutroProps) {
  const { path, className } = props,
    destination = usePathParams(path, "destination"),
    location = usePathParams(path, "location"),
    /**
     * Memoizes the location_slug string created by combining destination and location.
     *
     * @returns {string} A combined string like 'india-goa'.
     */
    location_slug = useMemo(
      () => `${destination}-${location}`,
      [destination, location],
    ),
    ccx = useClx(clxs.container, className),
    /**
     * Memoizes the content fetched from the OUTRO_TEMPLATE based on location_slug.
     *
     * @returns {string | null} The outro content for the given location, or null if not found.
     */
    content = useMemo(
      () => OUTRO_TEMPLATE_NEW[location_slug] || null,
      [location_slug],
    );

  /**
   * Splits the given text content into primary and secondary sections.
   * If the text contains paragraph breaks (`\n\n`), the first paragraph is considered primary,
   * and the rest is considered secondary. If there are no paragraph breaks, the text is split
   * into two parts: the first two-thirds as primary and the remaining one-third as secondary.
   *
   * @param {string} text - The content to be split.
   * @returns {{ primary: string, secondary: string }} An object containing the primary and secondary parts of the text.
   */
  const splitContent = (text: OutroContent) => {
    // If content is null or undefined, return empty strings for both primary and secondary content
    if (!text) {
      return {
        primary: "",
        secondary: "",
      };
    }

    const header = text.header;
    const description = text.description;
    const sections = text.sections.map(({ title, content }) => `${title} ${content}`).join("\n");

    // If content includes paragraphs, split by double newline
    if (description.includes("\n\n")) {
      const [primary,...rest] = description.split("\n\n");
      return {
        primary: `${header} ${primary}` ,
        secondary: `${rest.join("\n\n").trim()} ${sections}`,
      };
    } else {
      const [primary,...rest] = description.split("\n");
      return {
        primary:`${header} \n ${primary}`,
        secondary: rest.join("\n\n").trim() + sections,
      };
    }
  };

  const { primary, secondary } = splitContent(content);

  const [collapsed, setCollapsed] = useState<boolean>(false);

  /**
   * Toggles the collapsed state for showing or hiding the secondary content.
   *
   * @returns {void}
   */
  const handleToggle = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  const rmcx = useClx(clxs.readMore, "read-more");

  // If no content exists, return null to avoid rendering the component
  if (!content) {
    return null;
  }

  return (
    <div className={ccx}>
      <div className={clxs.overlay}>&nbsp;</div>
      <div className={clxs.content}>
          <div
            className={clxs.primaryContent}
            dangerouslySetInnerHTML={{ __html: primary }}
          />
          {collapsed && (
            <Collapse
              className={`${clxs.contentCollapse}`}
              expanded={collapsed}
            >
              <div
                className={clxs.secondaryContent}
                dangerouslySetInnerHTML={{ __html: secondary }}
              />
            </Collapse>
          )}
        <div
          className={rmcx} 
          onClick={handleToggle}
        >
          {collapsed ? "Read Less" : "Read More"}
        </div>
        </div>
       
    </div>
  );
}

export default Outro;
