function getAnalyticsVillaLayout(layout: string) {
    if(!layout) {
      return "NA";
    }

    if(layout == "package") {
      return "multiBedroom"
    }

    if(layout == "standard") {
      return "villa"
    }

    if(layout == "multi_config") {
      return "resort"
    }

    return "NA";
  }

  export {
    getAnalyticsVillaLayout,
  }