import throttle from "lodash.throttle";
import {useAtomSet, useAtomValue} from "./use-atom";
import {useCallback, useMemo} from "react";
import useIsMobile from "./use-is-mobile";

function useScroll() {
  const {isMobile} = useIsMobile(),
    scroll = useAtomValue<number>(SCROLL_LISTENER_KEY, 0),
    ratio = useMemo(
      () => {
        if (typeof window === "undefined") {
          return 0;
        }
        const {clientHeight} = document.body,
          {innerHeight} = window,
          scrolled = innerHeight + scroll,
          ratio = scrolled / clientHeight;

        return ratio;
      },
      [isMobile, scroll],
    );

  return {
    scroll: scroll,
    ratio: ratio,
  };
}

function useScrollSet() {
  const setScroll = useAtomSet<number>(SCROLL_LISTENER_KEY, 0),
    handleSetScroll = useCallback(
      (value: number) => _tSetScroll(setScroll, value),
      [setScroll],
    );

  return {handleSetScroll: handleSetScroll};
}

export {
  useScroll,
  useScrollSet,
};

const SCROLL_LISTENER_KEY = "SCROLL_LISTENER";

const _tSetScroll = throttle(
  (cb: (value: number) => void, value: number) => cb(value),
  100,
);
