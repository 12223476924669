import add from "date-fns/add";
import differenceInDays from "date-fns/differenceInDays";
import endOfMonth from "date-fns/endOfMonth";
import startOfDay from "date-fns/startOfDay";
import memoize from "lodash.memoize";
import { useCallback, useMemo, useState } from "react";
import toISODateString from "../../../../utils/to-iso-date-string";
import usePropertyService from "../../../hooks/use-property-service";

function usePropertyCalendar (
  propertySlug: string,
  monthCount?: number,
) {
  const { service } = usePropertyService();

  const getPropertyCalendar = useMemo(
    () => memoize(
      async (
        propertySlug: string,
        startMonth: string,
        monthCount?: number,
      ) => {
        const { error, response } = await service.getPropertyCalendar(
          propertySlug,
          startMonth,
          monthCount,
        );

        if (error || !response) {
          return {
            calendar: [] as PropertyCalendar,
            calendarMap: {} as Record<string, boolean>,
          };
        }

        const calendarMap = response.reduce(
          (compiled, each) => {
            const { date, sold_out } = each;

            compiled[date] = sold_out;

            return compiled;
          },
          {} as Record<string, boolean>,
        );

        return {
          calendar: response,
          calendarMap: calendarMap,
        };
      },
      (propertySlug, startMonth, monthCount) =>
        [propertySlug, startMonth, monthCount].join("#"),
    ),
    [service],
  );

  const [calendarMeta, setCalendarMeta] = useState<CalendarMeta>(DEFAULT_CALENDAR_META);

  const handleMonthChange = useCallback(
    async (month: Date) => {
      const calendarMeta = await getPropertyCalendar(
        propertySlug,
        startOfDay(endOfMonth(month)).toISOString(),
        monthCount,
      );

      setCalendarMeta({ ...calendarMeta });
    },
    [
      setCalendarMeta,
      getPropertyCalendar,
    ],
  );

  const handleDateDecorator = useCallback(
    (monthStart: Date, dateOffset: number, startDate: Date) => {
      const cursor = add(monthStart, { days: dateOffset }),
        key = cursor.toISOString(),
        soldOut = calendarMeta.calendarMap[key] ?? false;

        // If startDate is selected by the user, enabling the next availabe date only for checkout purposes. This also handles the sandwich case issue.
        if (startDate) {
          const onlySoldOutKey = getOnlySoldOutDateKey(startDate);
          if (onlySoldOutKey && (key == onlySoldOutKey)) {
            return {
              "data-sold-out": false,
              "data-selectable": true,
            }
          }
        }

      return {
        "data-sold-out": soldOut,
        "data-selectable": !soldOut,
      };
    },
    [calendarMeta],
  );

  /**
   * This method finds the next date available only for checkout after selection of the start date.
   * 
   * @param startDate 
   * @returns datestring so that a particular date can be made available only for checkout.
   */
  const getOnlySoldOutDateKey = (startDate: Date) => {
    if (!startDate) {
      return null;
    }

    const startDateString = startDate.toISOString();

    const {calendar} = calendarMeta;

    const nextSoldOutDateObj = calendar.find(
      (oCalendar) => oCalendar.date > startDateString && oCalendar.sold_out,
    );

    return nextSoldOutDateObj?.date;
  };

  const handleVerifySelection = useCallback(
    (
      start: Date | null,
      end: Date | null,
      nextStart: Date | null,
      nextEnd: Date | null,
    ) => {
      const { calendar, calendarMap } = calendarMeta;

      if (!calendar.length) {
        return {
          start: nextStart,
          end: nextEnd,
        };
      }

      if (!nextStart || !nextEnd) {
        return {
          start: nextStart,
          end: nextEnd,
        };
      }

      const nextStartKey = toISODateString(nextStart),
        nextEndKey = toISODateString(nextEnd);

      if (calendarMap[nextStartKey]) {
        return {
          start: start,
          end: nextEnd,
        };
      }

      if (calendarMap[nextEndKey]) {
        return {
          start: nextStart,
          end: end,
        };
      }

      const startOffset = differenceInDays(
          nextStart,
          startOfDay(new Date(calendar[0].date)),
        ),
        endOffset = differenceInDays(
          nextEnd,
          startOfDay(new Date(calendar[0].date)),
        );

      if (startOffset > calendar.length) {
        return {
          start: nextStart,
          end: nextEnd,
        };
      }

      const isSoldOut = calendar.some(
        (each, index) => {
          if (index < startOffset) {
            return false;
          }

          if (index > endOffset) {
            return false;
          }

          // Allowing selection of the endOffset since it could be a checkout only date.
          if (index == endOffset) {
            return false;
          }

          return each.sold_out;
        },
      );

      if (isSoldOut) {
        return {
          start: start,
          end: end,
        };
      }

      return {
        start: nextStart,
        end: nextEnd,
      };
    },
    [calendarMeta],
  );

  return {
    handleMonthChange: handleMonthChange,
    handleDateDecorator: handleDateDecorator,
    handleVerifySelection: handleVerifySelection,
  };
}

export default usePropertyCalendar;

const DEFAULT_CALENDAR_META: CalendarMeta = {
  calendar: [],
  calendarMap: {},
};

type CalendarMeta = {
  calendar: PropertyCalendar;
  calendarMap: Record<string, boolean>;
};