import { useCallback, useEffect } from "react";
import useAnalytics from "../use-analytics";
import { useUserFromSession } from "./use-session";

function SessionManager () {
  useAutoLoginManager();

  return null;
}

export default SessionManager;

function useAutoLoginManager () {
  const { track } = useAnalytics();

  const getUserFromSession = useUserFromSession();

  const handleUserFromSession = useCallback(
    async () => {
      const user = await getUserFromSession();

      if (!user) {
        return;
      }

      if (user instanceof Error) {
        track("login_error", { error: user.message });

        return;
      }

      track("login_successful", { isUserUpdated: true, userObj: user });
    },
    [getUserFromSession],
  );

  useEffect(
    () => {
      handleUserFromSession();
    },
    [handleUserFromSession],
  );
}
