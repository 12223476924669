import React, { useCallback, useState } from "react";
import useClx from "../../../../hooks/use-clx";
import Collapse from "../../../Collapse";
import clxs from "./description.module.css";

interface DescriptionProps {
  description: string;
  className?: string;
}

function Description(props: DescriptionProps) {
  const { description, className } = props;

  const [collapsed, setCollapsed] = useState<boolean>(false),
    handleToggle = useCallback(
      () => setCollapsed(collapsed => !collapsed),
      [setCollapsed],
    ),
    rmcx = useClx(clxs.readMore, "read-more");

  return (
    <div className={className}>
      <Collapse
        className={clxs.description}
        expanded={collapsed}
      >
        {description}
      </Collapse>
      <div
        className={rmcx}
        onClick={handleToggle}
      >
        {collapsed ? "Read Less" : "Read more"}
      </div>
    </div>
  );
}

export default Description;