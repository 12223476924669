import { MutableRefObject, useEffect, useMemo } from "react";
import { useAtomSet, useAtomState, useAtomValue } from "./use-atom";

function useCarouselIds(carouselId: string) {
  const saneId = useMemo(
      () => `${carouselId.toUpperCase().replace(/-/g, "_")}`,
      [carouselId],
    ),
    observerId = useMemo(
      () => `${saneId}_${CAROUSEL_OBSERVER_KEY}`,
      [saneId],
    ),
    visibleSetId = useMemo(
      () => `${carouselId}_${CAROUSEL_VISIBLE_KEY}`,
      [carouselId],
    ),
    scrollerId = useMemo(
      () => `${saneId}_${CAROUSEL_SCROLLER_KEY}`,
      [saneId],
    );
  return {
    saneId: saneId,
    observerId: observerId,
    visibleSetId: visibleSetId,
    scrollerId: scrollerId,
  };
}

function useCarouselScrollState(carouselId: string) {
  const { visibleSetId } = useCarouselIds(carouselId),
    [visibleSets, setVisibleSets] = useAtomState<VisibleSet>(
      visibleSetId, [
        new Set<number>([]),
        new Set<number>([]),
        new Set<number>([]),
      ],
    ),
    addToVisible = (idx: number) => {
      setVisibleSets(
        ([_ls, _vs, _rs]) => {
          const ls = new Set(_ls),
            vs = new Set(_vs),
            rs = new Set(_rs);

          ls.delete(idx);
          vs.add(idx);
          rs.delete(idx);

          return [ls, vs, rs];
        },
      );
    },
    addToLeft = (idx: number) => {
      setVisibleSets(
        ([_ls, _vs, _rs]) => {
          const ls = new Set(_ls),
            vs = new Set(_vs),
            rs = new Set(_rs);

          ls.add(idx);
          vs.delete(idx);
          rs.delete(idx);

          return [ls, vs, rs];
        },
      );
    },
    addToRight = (idx: number) => {
      setVisibleSets(
        ([_ls, _vs, _rs]) => {
          const ls = new Set(_ls),
            vs = new Set(_vs),
            rs = new Set(_rs);

          ls.delete(idx);
          vs.delete(idx);
          rs.add(idx);

          return [ls, vs, rs];
        },
      );
    };

  return {
    sets: visibleSets,
    removeFromBoth: addToVisible,
    addToLeft: addToLeft,
    addToRight: addToRight,
  };
}

function useCarouselState(
  carouselId: string,
  ref: MutableRefObject<HTMLDivElement | null>,
  threshold: number,
) {
  const { observerId } = useCarouselIds(carouselId),
    { removeFromBoth, addToLeft, addToRight } = useCarouselScrollState(carouselId),
    setObserver = useAtomSet<Observer>(observerId, null),
    handleCreateObserver = (ref: HTMLDivElement | null) => {
      if (!ref) {
        setObserver(null);
        return;
      }

      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {

          entries.forEach(
            (entry) => {
              const { rootBounds } = entry;

              if (!rootBounds) {
                return;
              }

              const target = entry.target as HTMLDivElement,
                idx = Number.parseInt(target.dataset.index || "");

              if (Number.isNaN(idx)) {
                return;
              }

              const { intersectionRatio } = entry;


              if (intersectionRatio >= threshold) {
                removeFromBoth(idx);
                return;
              }

              const {
                boundingClientRect: {
                  left: childLeft,
                  right: childRight,
                },
              } = entry, {
                left: parentLeft,
                right: parentRight,
              } = rootBounds;


              if (childLeft <= parentLeft) {
                addToLeft(idx);
                return;
              }

              if (childRight >= parentRight) {
                addToRight(idx);
                return;
              }
            },
          );
        }, {
          root: ref,
          threshold: [0, threshold],
        },
      );

      setObserver(observer);
    },
    handleGCObserver = () => {
      setObserver(
        observer => {
          if (observer) {
            observer.disconnect();
          }
          return null;
        },
      );
    };

  useEffect(
    () => {
      handleCreateObserver(ref.current || null);

      return handleGCObserver;
    },
    [ref.current],
  );
}

function useCarouselObserver(
  carouselId: string,
  ref: MutableRefObject<HTMLDivElement | null>,
) {
  const { observerId } = useCarouselIds(carouselId),
    observer = useAtomValue<Observer>(observerId, null),
    handleObserve = (observer: Observer, dom: HTMLDivElement | null) => {
      if (!observer) {
        return;
      }

      if (!dom) {
        return;
      }

      observer.observe(dom);
    },
    handleUnobserve = (observer: Observer, dom: HTMLDivElement | null) => {
      if (!observer) {
        return;
      }

      if (!dom) {
        return;
      }

      observer.unobserve(dom);
    };

  useEffect(
    () => {
      handleObserve(observer, ref.current || null);

      return handleUnobserve.bind(null, observer, ref.current || null);
    },
    [observer, ref.current],
  );
}

function useCarouselScroller(carouselId: string) {
  const { scrollerId } = useCarouselIds(carouselId),
    { sets } = useCarouselScrollState(carouselId),
    setScrollToIndex = useAtomSet<number>(scrollerId, -1),
    leftCount = useMemo(
      () => sets[0].size,
      [sets],
    ),
    visibleCount = useMemo(
      () => sets[1].size,
      [sets],
    ),
    rightCount = useMemo(
      () => sets[2].size,
      [sets],
    ),
    scrollLeft = () => {
      const leftSet = sets[0],
        idx = Math.max(...leftSet);

      if (!Number.isFinite(idx)) {
        return;
      }

      setScrollToIndex(idx);
    },
    scrollRight = () => {
      const rightSet = sets[2],
        idx = Math.min(...rightSet);

      if (!Number.isFinite(idx)) {
        return;
      }

      setScrollToIndex(idx);
    };

  return {
    leftCount: leftCount,
    visibleCount: visibleCount,
    rightCount: rightCount,
    sets: sets,
    leftSet: sets[0],
    visibleSet: sets[1],
    rightSet: sets[2],
    scrollLeft: scrollLeft,
    scrollRight: scrollRight,
    scrollTo: setScrollToIndex,
  };
}

function useCarouselScrollTo(
  carouselId: string,
  ref: HTMLDivElement | null,
) {
  const { scrollerId } = useCarouselIds(carouselId),
    [scrollToIndex, setScrollToIndex] = useAtomState<number>(scrollerId, -1),
    handleScrollTo = (scrollToIdx: number) => {
      if (!ref) {
        return;
      }

      const { children: c } = ref,
        children = Array.from(c).filter(
          each => {
            const elem = each as HTMLElement;
            return elem.dataset.index;
          },
        );

      if (scrollToIdx < 0 || scrollToIdx > children.length - 1) {
        return;
      }

      const focused = children[scrollToIdx] as HTMLDivElement,
        { clientWidth: ccw } = focused,
        startOffset = ccw * scrollToIdx;

      ref.scroll({
        left: startOffset,
        behavior: "smooth",
      });

      setScrollToIndex(-1);
    };

  useEffect(
    () => handleScrollTo(scrollToIndex),
    [scrollToIndex],
  );

  useEffect(() => handleScrollTo(-1), []);
}

export {
  useCarouselState,
  useCarouselObserver,
  useCarouselScroller,
  useCarouselScrollTo,
};

const CAROUSEL_OBSERVER_KEY = "CAROUSEL_OBSERVER";

const CAROUSEL_SCROLLER_KEY = "CAROUSEL_SCROLLER";

const CAROUSEL_VISIBLE_KEY = "CAROUSEL_VISIBLE";

type VisibleSet = [Set<number>, Set<number>, Set<number>];

type Observer = IntersectionObserver | null;

