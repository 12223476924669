import React, { ReactNode } from "react";
import { Route, Routes as NativeRoutes } from "react-router-dom";
import { Page as P } from "../../../../typings/page";
import AnalyticsManager from "../AnalyticsManager";
import Page from "./Page";
import SecureRoute from "./SecureRoute";

interface RoutesProps {
  pages: P[];
}

function Routes (props: RoutesProps) {
  const { pages } = props;

  return (
    <>
      <NativeRoutes>
        {pages.reduce(
          (nodes, page) => {
            const {
              Component,
              path,
              page: p,
              isPrivate,
            } = page;

            if (!Component) {
              return nodes;
            }

            return [
              ...nodes, (
                <Route
                  key={path}
                  path={path}
                  element={(
                    <SecureRoute isPrivate={isPrivate}>
                      <>
                        <Component />
                        {p && <Page page={p} />}
                        <AnalyticsManager page={p} />
                      </>
                    </SecureRoute>
                  )}
                />
              ),
            ];
          },
          [] as ReactNode[],
        )}
      </NativeRoutes>
    </>
  );
}

export default Routes;
