import * as Yup from "yup";

const CREATE_ACCOUNT_SCHEMA = Yup.object({
  phone_ext: Yup.string().trim().required("Required"),
  phone_no: Yup.string()
    .trim()
    .matches(/^[0-9]{8,12}$/gi, "Invalid phone no")
    .required("Required"),
});

export default CREATE_ACCOUNT_SCHEMA;
