import { FormikValues, useFormik } from "formik";
import { FormikErrors, FormikHelpers, FormikTouched } from "formik/dist/types";
import * as React from "react";
import { FormEventHandler, useEffect, useMemo } from "react";
import { useAtomState, useAtomValue } from "./use-atom";

function useFormState(
  formId: string,
  defaultValue: FormikValues,
  onSubmit: FormSubmitHandler,
  validationSchema?: any | (() => any),
  enableReinitialize?: boolean,
  onChange?: (values: FormikValues) => void,
) {
  const nativeState = useFormik({
      initialValues: defaultValue,
      onSubmit: onSubmit,
      enableReinitialize: enableReinitialize || true,
      validationSchema: validationSchema,
    }),
    {
      values,
      errors,
      touched,
      isSubmitting,
      submitCount,
      handleBlur,
      handleChange,
      setFieldValue,
    } = nativeState,
    state = useMemo(
      () => ({
        values: values,
        errors: errors,
        touched: touched,
        isSubmitting: isSubmitting,
        submitCount: submitCount,
        handleBlur: handleBlur,
        handleChange: handleChange,
        setFieldValue: setFieldValue,
      }),
      [
        values,
        errors,
        touched,
        isSubmitting,
        submitCount,
        handleBlur,
        handleChange,
        setFieldValue,
      ],
    );

  const [formState, setFormikState] = useAtomState<FormikState>(formId, state);

  const handleFormChange = (state: FormikState) => {
      if (formState === state) {
        return;
      }

      setFormikState(state);

      if (!onChange) {
        return;
      }

      onChange(state.values);
    };

  useEffect(() => handleFormChange(state), [state]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (nativeState.isSubmitting) {
      return;
    }

    nativeState.handleSubmit(e);
  };

  return {
    ...nativeState,
    handleSubmit: handleSubmit,
  };
}

function useField(formId: string, name: string) {
  const {
    values: { [name]: value },
    errors: { [name]: error } = { [name]: null },
    touched: { [name]: touched } = { [name]: false },
    handleBlur,
    handleChange,
    setFieldValue,
    isSubmitting,
  } = useAtomValue<FormikState>(formId, DEFAULT_FORMIK_STATE);

  const simpleError = useMemo(
    () => {
      if (!error) {
        return "";
      }

      if (typeof error === "string") {
        return error;
      }

      const isArray = Array.isArray(error);

      if (isArray) {
        return error.map(
          each => Object.values(each)
            .join(", "),
          ).join(", ");
      }

      return Object.values(error).join(", ");
    },
    [error],
  );

  return {
    valid: !Boolean(error).valueOf(),
    value: value,
    error: touched ? simpleError : "",
    touched: touched,
    handleBlur: handleBlur,
    handleChange: handleChange,
    setFieldValue: setFieldValue,
    isSubmitting: isSubmitting,
  };
}

function useForm(formId: string) {
  return useAtomValue<FormikState>(formId, DEFAULT_FORMIK_STATE);
}

export {
  useFormState,
  useField,
  useForm,
};

const NO_OP = () => {
  return;
};

const DEFAULT_FORMIK_STATE: FormikState = {
  values: {},
  errors: {},
  touched: {},
  isSubmitting: false,
  submitCount: 0,
  handleBlur: NO_OP,
  handleChange: NO_OP,
  setFieldValue: NO_OP,
};

export type FormSubmitHandler = (
  values: FormikValues,
  formikHelpers: FormikHelpers<FormikValues>,
) => void | Promise<any>;

type FormikState = {
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  submitCount: number;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
};
