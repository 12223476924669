import {useEffect} from "react";

function useJssSsr() {
  useEffect(
    () => {
      const jssStyles: HTMLElement | null = document.querySelector("#jss-server-side");
      if (jssStyles) {
        jssStyles.parentElement?.removeChild(jssStyles);
      }
    },
    [],
  );
}

export default useJssSsr;
