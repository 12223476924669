import React, { useEffect } from "react";
import CHEVRON_LEFT from "../../assets/icons/chevron-left.svg";
import DELETE_ACCOUNT_TRASH from "../../assets/icons/delete-account-trash.svg";
import { useQueryParamValue } from "../../hooks/use-query-param";
import useWindowNavigate from "../../hooks/use-window-navigate";
import Button from "../Button";
import clxs from "./delete-account-success.module.css";

function DeleteAccountSuccess () {
  const push = useWindowNavigate();

  const callback = useQueryParamValue("string", "callback");

  const handleGoBack = () => {
    const search = new URLSearchParams();

    localStorage.removeItem(SESSION_KEY);
    sessionStorage.removeItem("session_id");

    search.set("intent", "logout");

    push({
      pathname: callback,
      search: search,
    });
  };

  useEffect(
    () => {
      setTimeout(handleGoBack, 5000);
    },
    [],
  );

  return (
    <div className={clxs.container}>
      <div className={clxs.headerContainer}>
        <img
          loading="lazy"
          src={CHEVRON_LEFT}
          alt="chevron-left"
          onClick={handleGoBack}
        />
        Delete My Account
      </div>
      <img
        src={DELETE_ACCOUNT_TRASH}
        alt="delete-account"
        className={clxs.icon}
      />
      <div className={clxs.title}>
        Your Account has been deleted
      </div>
      <div className={clxs.content}>
        You will now be logged off from all devices. If you are not redirected to the app, you can go back manually by
        clicking below button
      </div>
      <Button
        className={clxs.goBack}
        type="submit"
        onClick={handleGoBack}
      >
        Go Back
      </Button>
    </div>
  );
}

export default DeleteAccountSuccess;

const SESSION_KEY = "SESSION";
