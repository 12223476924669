import React, { useMemo } from "react";
import useClx from "../../../../hooks/use-clx";
import { Track } from "../../../AnalyticsManager";
import NetworkImg from "../../../NetworkImg";
import clxs from "./gallery-preview.module.css";
import CHEVRON_RIGHT_PRIMARY from "../../../../assets/lohono-black/icons/chevron-right-primary.svg";

interface GalleryPreviewProps {
  images: PropertyImage[];
  className?: string;
  onShowGallery: () => void;
}

function GalleryPreview(props: GalleryPreviewProps) {
  const { images, className, onShowGallery } = props,
    first = useMemo(
      () => images.length ? images[0] : null,
      [images],
    ),
    rest = useMemo(
      () => images.slice(1, 5),
      [images],
    ),
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Gallery
      </div>
      <div className={clxs.previews}>
        {first ? (
          <Track event="property_detail_photos_preview_clicked">
            <NetworkImg
              loading="lazy"
              src={first.url}
              alt={first.alt}
              className={clxs.first}
              onClick={onShowGallery}
              title={first.caption}
            />
          </Track>
        ) : null}
        {rest.map(
          (each, i) => {
            const { url, alt, caption } = each;

            return (
              <Track
                key={i}
                event="property_detail_photos_preview_clicked"
              >
                <NetworkImg
                  loading="lazy"
                  src={url}
                  alt={alt}
                  className={clxs.rest}
                  onClick={onShowGallery}
                  title={caption}
                />
              </Track>
            );
          },
        )}
      </div>
      <Track event="property_detail_photos_view_all_clicked">
        <div
          className={clxs.viewAll}
          onClick={onShowGallery}
        >
          View all
          <img
            src={CHEVRON_RIGHT_PRIMARY}
            alt="view-all"
            className={clxs.viewAllIcon}
          />
        </div>
      </Track>
    </div>
  );
}

export default GalleryPreview;
