import React from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./highlights.module.css";
import HIGHLIGHT_LOCATION from "../../../assets/highlight-location.svg";
import HIGHLIGHT_CURATION from "../../../assets/highlight-curation.svg";
import HIGHLIGHT_CONCIERGE from "../../../assets/highlight-concierge.svg";
import HIGHLIGHT_UPKEEP from "../../../assets/highlight-upkeep.svg";

interface HighlightProps {
  className?: string;
}

function Highlights(props: HighlightProps) {
  const { className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div className={clxs.title}>
        Handpicked and thoughtfully chosen
      </div>
      <p className={clxs.description}>
        Be a part of Lohono’s curated list of over 250 homes across Goa, Alibaug, Karjat, Bali, Phuket and Koh Samui.
      </p>
      <div className={clxs.highlights}>
        {HIGHLIGHTS_TEMPLATE.map(
          (each, key) => (
            <div
              className={clxs.highlight}
              key={key}
            >
              <img
                src={each.thumbnail}
                alt={each.title}
              />
              <div className={clxs.highlightTitle}>
                {each.title}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default Highlights;

const HIGHLIGHTS_TEMPLATE = [
  {
    thumbnail: HIGHLIGHT_LOCATION,
    title: "Amazing Locations",
  },
  {
    thumbnail: HIGHLIGHT_CONCIERGE,
    title: "Concierge Service",
  },
  {
    thumbnail: HIGHLIGHT_CURATION,
    title: "Curated Experiences",
  },
  {
    thumbnail: HIGHLIGHT_UPKEEP,
    title: "Thorough UpKeep",
  },
];
