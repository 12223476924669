import React, { ReactNode } from "react";
import clxs from "./tooltip.module.css";
import INFO_ICON from "../../assets/icons/info.svg";

interface TooltipProps {
  content: ReactNode; // Can accept any content
  iconSrc?: string;   
  iconAlt?: string;  
  iconClass?: string;
}

function Tooltip(props: TooltipProps) {
  const { content, iconSrc = INFO_ICON, iconAlt = "Info", iconClass } = props;

  return (
    <div className={clxs.tooltip}>
      <img
        loading="lazy"
        src={iconSrc}
        alt={iconAlt}
        className={iconClass ? iconClass : clxs.icon} // Allow for icon customization
      />
      <span className={clxs.tooltiptext}>
        <div className={clxs.tooltipContent}>
          {content} {/* Content is passed as children */}
        </div>
      </span>
    </div>
  );
}

export default Tooltip;
