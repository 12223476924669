function sanitizePayload (
  payload: any,
  skipKeys: string[] = [],
): any {
  const skipKeysSet = new Set(skipKeys);

  const sanitized: any = {};

  for (const key in payload) {
    const value = payload[key];

    if (!isAllowed(key, skipKeysSet)) {
      continue;
    }

    sanitized[key] = stringifyValue(value);
  }

  return sanitized;
}

export default sanitizePayload;


function isAllowed (key: string, skipKeys: Set<string>): boolean {
  const skipped = (
    skipKeys.has(key)
    // || key.includes("phone")
    // || key.includes("email")
    // || key.includes("name")
  );

  return !skipped;
}

function stringifyValue (value: any): any {
  if (value instanceof Date) {
    return value.toISOString();
  }

  return value;
}