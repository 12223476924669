import React, { useMemo } from "react";
import clxs from "./custom-discount.module.css";
import useClx from "../../../hooks/use-clx";

interface CustomDiscountProps {
  method?: string;
  className?: string;
  discountPercentage?: number | string;
}

function CustomDiscount (props: CustomDiscountProps) {
  const { className, method, discountPercentage = 0 } = props;

  const content = useMemo(
    () => `${discountPercentage}%`,
    [discountPercentage],
  );

  const ccx = useClx(clxs.container, className);

  const isHidden = useMemo(
    () => !method || method != "coupon",
    [method],
  );

  if (isHidden) {
    return null;
  }

  return (
    <div
      data-discount-type={method}
      className={ccx}
    >
      {content}
    </div>
  );
}

export default CustomDiscount;
