import React, {ChangeEventHandler, useMemo} from "react";
import NativeOccupancy, {
  OccupancyProps as NativeOccupancyProps,
} from "../../../Occupancy";
import {useField} from "../../../../hooks/use-form";

interface OccupancyProps extends BaseOccupancyProps {
  form: string;
  name: string;
  onOccupancyChange?:ChangeEventHandler<any>;
}

function Occupancy(props: OccupancyProps) {
  const {
      form,
      onOccupancyChange,
      ...rest
    } = props,
    {name} = rest,
    {
      value,
      error: err,
      setFieldValue,
      handleChange,
    } = useField(form, name),
    error = useMemo(
      () => {
        if (!err) {
          return "";
        }

        if (typeof err === "string") {
          return err;
        }

        const {
            adult_count,
            child_count,
          } = err as any,
          errors: string[] = [];

        if (adult_count) {
          errors.push(adult_count);
        }

        if (child_count) {
          errors.push(child_count);
        }

        return errors.join(", ");
      },
      [err],
    ),
    handleChangeLocal= (value: OccupancyValue)=>{
      setFieldValue.bind(null, name);
      if (!onOccupancyChange) {
        return;
      }
      const target = { name: name, value: value },
      payload: any = { target: target, currentTarget: target };

      handleChange(payload);
      onOccupancyChange(payload);
    }
    ;

  return (
    <NativeOccupancy
      {...rest}
      value={value}
      error={error}
      onChange={handleChangeLocal}
    />
  )
}

export default Occupancy;


type BaseOccupancyProps = Omit<NativeOccupancyProps, "value" | "onChange" | "onBlur" | "error">;

export type OccupancyValue = {
  adult_count: number;
  child_count: number;
};
