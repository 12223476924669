import usePropertyService from "./use-property-service";
import usePrefetch from "./use-prefetch";
import { useAtomState } from "./use-atom";
import { PROPERTY_DETAIL_KEY } from "../../core/constants";
import { useEffect } from "react";

function usePropertyDetail(
  propertySlug: string,
  checkin_date?: Date | null,
  checkout_date?: Date | null,
) {
  const { service } = usePropertyService(),
    { hasPrefetchKey, removePrefetch } = usePrefetch(),
    [propertyDetail, setPropertyDetail] = useAtomState<Property | null>(PROPERTY_DETAIL_KEY, null),
    handleGetPropertyDetail = async (
      propertySlug: string,
      checkin_date?: Date | null,
      checkout_date?: Date | null,
    ) => {
      if (hasPrefetchKey(PROPERTY_DETAIL_KEY)) {
        removePrefetch(PROPERTY_DETAIL_KEY);

        return;
      }

      const { error, response } = await service.getProperty(
        propertySlug,
        checkin_date?.toISOString(),
        checkout_date?.toISOString(),
      );

      if (error || !response) {
        return;
      }

      setPropertyDetail(response);
    };

  useEffect(
    () => {
      handleGetPropertyDetail(propertySlug, checkin_date, checkout_date);
    },
    [propertySlug, checkin_date, checkout_date],
  );

  return {
    propertyDetail: propertyDetail,
  };
}

export default usePropertyDetail;
