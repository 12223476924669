import React from "react";
import QR_CODE from "../../../assets/app-qr-code.svg";
import PLAY_STORE_ICON from "../../../assets/app-store-android.svg";
import APP_STORE_ICON from "../../../assets/app-store-ios.svg";
import CLOSE_ICON from "../../../assets/icons/close.svg";
import useEnv from "../../../hooks/use-env";
import { Track } from "../../AnalyticsManager";
import stl from "./app-store.module.css";

interface AppStoreProps {
  onSubmit?: (out: any) => void;
}

function AppStore (props: AppStoreProps) {
  const { APP_APP_STORE_URL, APP_PLAY_STORE_URL } = useEnv();

  const { onSubmit } = props;

  return (
    <div className={stl.container}>
      <div className={stl.title}>
        Download the Lohono Stays Mobile App
      </div>
      <img
        src={CLOSE_ICON}
        alt="close"
        className={stl.close}
        onClick={onSubmit?.bind(null, null)}
      />
      <ul className={stl.howItWorks}>
        <div className={stl.howItWorksTitle}>
          How it Works
        </div>
        {HOW_IT_WORKS_TEMPLATE.map(
          (each, key) => (
            <li key={key}>{each}</li>
          ),
        )}
      </ul>
      <img
        src={QR_CODE}
        alt="qr code"
        className={stl.qrCode}
      />
      <div className={stl.appStoreContainer}>
        <div className={stl.downloadFrom}>
          Download From
        </div>
        <Track event="app_play_store_clicked">
          <a href={APP_PLAY_STORE_URL}>
            <img
              src={PLAY_STORE_ICON}
              alt="play store"
            />
          </a>
        </Track>
        <Track event="app_app_store_clicked">
          <a href={APP_APP_STORE_URL}>
            <img
              src={APP_STORE_ICON}
              alt="app store"
            />
          </a>
        </Track>
      </div>
    </div>
  );
}

export default AppStore;

const HOW_IT_WORKS_TEMPLATE = [
  "Open your mobile phone's camera app and simply point it at the QR code on the screen",
  "Alternatively, download a QR code scanner and scan the code",
  "Click on the link generated to download the app",
];
