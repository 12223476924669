function utcEquivalent (date: Date): Date {
  return equivalentBase(date, -1);
}

function localEquivalent (date: Date): Date {
  return equivalentBase(date, 1);
}

function calculateTzAdjustment (date: Date) {
  const offset = date.getTimezoneOffset();

  const adjustment = Math.floor(offset / (24 * 60));

  return adjustment;
}

export {
  utcEquivalent,
  localEquivalent,
  calculateTzAdjustment,
};

function equivalentBase (date: Date, multiplier: -1 | 1) {
  const tzOffset = date.getTimezoneOffset();

  const tzHourOffset = Math.floor(tzOffset / 60);

  const tzMinuteOffset = Math.abs(tzOffset) % 60;

  const utc = new Date(date);

  utc.setHours(
    utc.getHours() + multiplier * tzHourOffset,
    utc.getMinutes() + multiplier * tzMinuteOffset,
  );

  return utc;
}
