import React from "react";
import Portal from "../../components/Portal";
import { useSnackbarState } from "./use-snackbar";
import clxs from "./snackbar-manager.module.css";

function SnackbarManager () {
  const { state, handleDismiss } = useSnackbarState();

  if (!state.open) {
    return null;
  }

  return (
    <Portal>
      <div
        className={clxs.container}
        data-severity={state.severity}
      >
        {state.message}
        <div
          onClick={handleDismiss}
          className={clxs.clear}
        >
          ×
        </div>
      </div>
    </Portal>
  );
}

export default SnackbarManager;
