import React from "react";
import clxs from "./corporate-villas.module.css";
import NetworkImg from "../../NetworkImg";
// import CHEVRON_RIGHT_GRAY from "../../../assets/icons/chevron-right-grey.svg";
// import ISPRAVA_BUILT_LOGO from "../../../assets/logo/isprava-built.png";
import Button from "../../Button";
import CORPORATE_VILLAS from "./corporate-villas"

function CorporateVillas() {
  return (
    <div className={clxs.container}>
      <div className={clxs.sectionTitle}>Our Marquee Homes</div>

      <div className={clxs.villaContainer}>
        {CORPORATE_VILLAS.map((property, i) => {
          const {
            name,
            thumbnail_url,
						thumbnail_alt,
            address,
            currency_symbol,
            starts_from,
            // isprava_built,
						guests,
						bedroom_count,
						baths_count,
						pools_count,
            location_slug,
            property_slug,
          } = property,
          url = `${location_slug}/${property_slug}`;

          return (
            <a
              key={i}
              href={url}
              className={clxs.property}
              title={name}
            >
              <NetworkImg
                loading="lazy"
                src={thumbnail_url}
                alt={thumbnail_alt}
                className={clxs.thumbnail}
                mobileQuality={50}
                desktopQuality={75}
                mobileDimensions={[800, 600]}
                desktopDimensions={[800, 600]}
              />
              <div className={clxs.title}>{name}</div>
              <div className={clxs.subtitle}>{address}</div>
							<div className={clxs.specContainer}>
								<div className={clxs.spec}>
									{guests}
								</div>
								<div className={clxs.spec}>
									{bedroom_count}
								</div>
								<div className={clxs.spec}>
									{baths_count}
								</div>
                {pools_count ? (
                  <div className={clxs.spec}>
                    {pools_count}
                  </div>
                ) : null}
							</div>
              <div className={clxs.list}>
                <div>Start from</div>
                <span className={clxs.rate}>
                  {`${currency_symbol}${starts_from}`}
                </span>
                <span className={clxs.night}>{" /night*"}</span>
                <div className={clxs.tax}>
                 * Exclusive of taxes
                </div>
                {/* <img
                  src={CHEVRON_RIGHT_GRAY}
                  alt="select"
                  className={clxs.select}
                /> */}
                <Button className={clxs.viewDetailsBtn}>
                  View Details
                </Button>
              </div>
              {/* {isprava_built && (
                <img
                  src={ISPRAVA_BUILT_LOGO}
                  alt="isprava-built"
                  className={clxs.ispravaIcon}
                />
              )} */}
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default CorporateVillas;
