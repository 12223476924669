import { useMemo } from "react";
import { useMatch } from "react-router-dom";

const DEFAULT_PARAMS = { params: {} };

function usePathParam (route: string, key: string) {
  const { params }: { params: any } = useMatch(route) ?? DEFAULT_PARAMS;

  const value = useMemo(
    () => params[key] || "",
    [params, key],
  );

  return value;
}

export default usePathParam;
