import { useCallback, useEffect } from "react";
import ACCOUNT_ICON from "../assets/icons/account-grey.svg";
import { useAtomState } from "./use-atom";

function useAccountIcon (src?: string) {
  const [brandAccountIcon, setAccountIcon] = useAtomState<string>(BRAND_LOGO_KEY, ACCOUNT_ICON);

  const handleSetAccountIcon = useCallback(
    (src?: string) => {
      if (!src) {
        return;
      }

      setAccountIcon(src);
    },
    [setAccountIcon],
  );

  useEffect(
    () => handleSetAccountIcon(src),
    [handleSetAccountIcon, src],
  );

  return brandAccountIcon;
}

export default useAccountIcon;

const BRAND_LOGO_KEY = "ACCOUNT_ICON";
