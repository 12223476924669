import React from "react";
import useClx from "../../hooks/use-clx";
import clxs from "./scroll-down.module.css";
import SCROLL_ICON from "../../assets/icons/chevron-down-white.svg";

interface ScrollDownProps {
  className?: string;
}

function ScrollDown(props: ScrollDownProps) {
  const { className } = props,
    ccx = useClx(clxs.scroll, className);

  return (
    <div className={ccx}>
      Scroll down
      <img
        src={SCROLL_ICON}
        alt="scroll down"
      />
    </div>
  );
}

export default ScrollDown;
