import React, { useEffect, useMemo, useState } from "react";
import useAnalytics from "../../hooks/use-analytics";
import { useLogin, useSession, useUser, useUserFromSession } from "../../hooks/use-session";
import { useSnackbar } from "../../hooks/use-snackbar";
import useUserService from "../../hooks/use-user-service";
import AdditionalDetails from "./AdditionalDetails";
import CreateAccount from "./CreateAccount";
import CreateAccountOtp from "./CreateAccountOtp";
import ReferralDetails from "./ReferralDetails";
import clxs from "./login-flow.module.css";
import CLOSE_ICON from "../../assets/icons/close.svg";
import LOHONO_LOGO from "../../assets/logo/lohono-logo.svg";
import useQueryParamValue from "../../hooks/use-query-param/use-query-param-value";
import Loader from "../Loader";

interface LoginFlowProps {
  onSubmit?: (value: any) => void;
  open?: boolean;
}

function LoginFlow(props: LoginFlowProps) {
  const enqueueSnackbar = useSnackbar(),
    { track } = useAnalytics(),
    { open, onSubmit } = props,
    { service: userService } = useUserService(),
    session = useSession(),
    user = useUser(),
    getUserFromSession = useUserFromSession(),
    handleLogin = useLogin(),
    [phoneNo, setPhoneNo] = useState(""),
    referralCode = useQueryParamValue("string", REFERRAL_CODE_QUERY_KEY),
    isReferralPresent = referralCode && referralCode.length > 0,
    [isReferralLogin, setIsReferralLogin] = useState<boolean>(false),
    [loader, setLoader] = useState(false),
    showLoginPage = () => {
      if (session) {
        handleClose();
      } else {
        setIsReferralLogin(true);
        loginFlowState = "phoneNo";
      }
    };

    /**
     *  Memoized function to determine the current login flow state
     *  based on user, session, phoneNo, and referral presence
     */
    let loginFlowState = useMemo(
      () => {
         // Show referral code section if a referral code is present and user is not logged in.
          if (isReferralPresent && !user && !isReferralLogin) {
          return "referralCode";
        } if (isReferralLogin && !user && !phoneNo) {
          return "phoneNo"
         } else if(!user && !phoneNo) { // Show phone number section if user is not logged in.
          return "phoneNo";
        } else if(!user && phoneNo) {  // Show OTP section when phone number is entered.
          return "otp";
        } else if (user && (!user.full_name || !user.email)) {  
          // Show additionalDetails section if user is logged in but full name or email is missing.
          return "additionalDetails";
        } else if (user && user.full_name && user.email && isReferralPresent) {  
          // Show validate referral code if user is verified.
          return "validateReferralCode";
        } else {  // Default state: prompt for phone number
          return "phoneNo"; 
        }
      },[user, isReferralPresent,session, phoneNo, isReferralLogin],
      // Dependencies that trigger re-evaluation of this memoized value
    );

   const handlePhoneNoSubmit = (values: any) => {
      if (!values) {
        return;
      }

      const { phone_ext, phone_no } = values;

      if (!phone_ext || !phone_no) {
        return;
      }

      setPhoneNo(`${phone_ext} ${phone_no}`);

      track("login_phone_no_entered");
    },
    handleOtpSubmit = async (values: any) => {
      if (!values) {
        return;
      }

      setLoader(true);

      if (values === "change") {
        setPhoneNo("");

        setLoader(false);

        track("login_phone_no_change");

        return;
      }

      const { otp } = values;

      const user = await handleLogin(phoneNo, otp);

      if (user instanceof Error) {
        track("login_error", { error: user.message });
        setLoader(false);
        return;
      }

      // if (user?.cid) {
      //   track("login_successful_flow", { cid: user.cid });
      // }

      //New user since he has no name associated to it's profile.
      if (!user?.full_name || !user?.email) {
        loginFlowState = "additionalDetails";
        setLoader(false);
        if (user?.cid) {
          track("new_registration", { isUserUpdated: true, userObj: user } );
        }
        return;
      }

      //Existing user trying to use referral code.
      if (user?.full_name && user?.cid && isReferralPresent) {
      
        setLoader(false);
        return;
      }

      setLoader(false);
      onSubmit?.(true);
    },
    handleAdditionalDetailsSubmit = async (values: any) => {
      if (!values) {
        return;
      }

      //const { full_name = "", email = "" } = values;

      const { full_name, email } = values;

      if (!full_name || !email) {
        return;
      }

      setLoader(true);

      const { error } = await userService.updateUser(session, full_name, email);

      if (error) {
        enqueueSnackbar(error.message, "error");

        track("login_additional_details_error", { error: error.message });
        setLoader(false);
        return;
      }

      const user = await getUserFromSession();
      let isUserUpdated = true;

      if (!user) {
        isUserUpdated = false; 
      }

      if (user instanceof Error) {
        isUserUpdated = false;
      }

      track("login_additional_details_submitted", { isUserUpdated: isUserUpdated, userObj: user });

    
      if (isReferralPresent) {
       
        setLoader(false);
        return;
      }

      setLoader(false);
      onSubmit?.(true);
    },
    handleCodeVerified = () => {
     
      loginFlowState = "codeVerified";
      handleClose();
    },
    handleClose = () => {
      track("login_close");

      setPhoneNo("");
      setIsReferralLogin(false);
      
      loginFlowState = "phoneNo";

      onSubmit?.(null);
    },
    handleLogout = (session: string) => {
      if (session) {
        return;
      }

      setPhoneNo("");
    };

  useEffect(() => {
    handleLogout(session);
  }, [session, isReferralPresent, user]);

  return (
    <div 
      className={clxs.modalContainer}
    >{/* added empty container so that only dailog can be clickable  outside it should not click */}
      {/* Dialog Content */}
      <div className={clxs.container}>
      <div className={clxs.headerContainer}>
        <img
          className={clxs.logo}
          src={LOHONO_LOGO}
          alt="Lohono"
        />
        <img
          src={CLOSE_ICON}
          alt="close"
          className={ loginFlowState === "additionalDetails" ? clxs.closeHidden : clxs.close }
          onClick={handleClose}
        />
      </div>
      {loginFlowState == "referralCode" ? (
        <ReferralDetails onClick={showLoginPage} />
      ) : loginFlowState == "phoneNo" ? (
        <CreateAccount
          key={open ? 1 : 0}
          onSubmit={handlePhoneNoSubmit}
        />
      ) : loginFlowState == "otp" ? (
        <CreateAccountOtp
          onSubmit={handleOtpSubmit}
          phoneNo={phoneNo}
          key={open ? 1 : 0}
        />
      ) : (loginFlowState == "additionalDetails") ? (
        <AdditionalDetails
          fullName={user?.full_name}
          email={user?.email}
          onSubmit={handleAdditionalDetailsSubmit}
        />
      ) : loginFlowState == "validateReferralCode" ? (
        <ReferralDetails
          isValidateCode={true}
          onCodeValidation={handleCodeVerified}
          session={session}
        />
      ) : null}

      <Loader isLoading={loader}></Loader>

      <div className={clxs.tncContent}>
        {"By proceeding, you are agreeing to Lohono's "}
        <a
          href="/terms-and-conditions"
          target="_blank"
          rel="noreferrer noopener"
        >
          {"Terms & Conditions"}
        </a>
        {" and "}
        <a
          href="/privacy-policy"
          target="_blank"
          rel="noreferrer noopener"
        >
          Privacy Policies
        </a>
      </div>
    </div>
    </div>
  );
}

export default LoginFlow;

const REFERRAL_CODE_QUERY_KEY = "referral_code";
