import React, { createElement, HTMLAttributes, MutableRefObject, ReactElement, useEffect, useRef } from "react";
import { ItemProps } from "./Item";
import { useCarouselScrollTo, useCarouselState } from "../../hooks/use-carousel";
import useClx from "../../hooks/use-clx";
import clxs from "./carousel.module.css";

interface CarouselProps extends Omit<HTMLAttributes<HTMLDivElement>, "ref" | "children"> {
  id: string;
  children: ReactElement<ItemProps>[];
  threshold?: number;
  forwardRef?: MutableRefObject<HTMLDivElement | null>;
  adjust?: boolean;
  align?: "center" | "start";
  className?: string;
}

function Carousel(props: CarouselProps) {
  const ref = useRef<HTMLDivElement>(null),
    {
      id,
      threshold = 1,
      forwardRef,
      className: _ccx,
      children,
      adjust,
      ...rest
    } = props,
    ccx = useClx(clxs.container, _ccx),
    handleRefChange = () => {
      if (!forwardRef) {
        return;
      }
      forwardRef.current = ref.current;
    };

  useCarouselState(id, ref, threshold);

  useCarouselScrollTo(id, ref.current);

  useEffect(
    () => handleRefChange(),
    [ref.current],
  );

  return (
    <div
      {...rest}
      className={ccx}
      ref={ref}
      id={id}
    >
      {adjust && <span className={clxs.adjust}>&nbsp;</span>}
      {children.map(
        (child, idx) => {
          const { type, key, props } = child;

          return createElement(
            type,
            {
              ...props,
              key: key,
              "data-index": idx,
              carouselId: id,
            },
          );
        },
      )}
      {adjust && <span className={clxs.adjust}>&nbsp;</span>}
    </div>
  );
}

export default Carousel;
