import React, { ChangeEvent, useMemo } from "react";
import useClx from "../../../../../hooks/use-clx";
import Carousel from "../../../../Carousel";
import Item from "../../../../Carousel/Item";
import ScrollCrumb from "../../../../Home/PropertyCarousel/ScrollCrumb";
import LoyaltyPointShortTicker from "../../../../LoyaltyPointShortTicker";
import Select from "../../../../Select";
import clxs from "./room-option-item.module.css";

interface RoomOptionItemProps {
  config: PropertyConfig;
  currencySymbol: string;
  tierDiscountPercentage?: number;
  price: number;
  discountedPrice: number;
  discountMethod: string;
  noOfNights: number;
  value: string;
  label?: string;
  disabled?: boolean;
  onChange?: (config: PropertyConfig, value: string) => void;
  onViewRoomDetails?: (config: PropertyConfig) => void;
}

function RoomOptionItem(props: RoomOptionItemProps) {
  const {
      config,
      currencySymbol,
      tierDiscountPercentage,
      price,
      discountedPrice,
      discountMethod,
      noOfNights,
      value,
      disabled = false,
      onChange,
      onViewRoomDetails,
    } = props,
    {
      room_name,
      available_inventory,
      sold_out,
    } = config,
    {
      carouselId,
      firstImageUrl,
      topAmenities,
      topImages,
      roomOccupancyFriendly,
    } = useMemo(
      () => {
        const {
            config_id,
            max_occupancy,
            bed_type,
            images,
            amenities,
          } = config,
          topAmenities = amenities.slice(0, 3),
          topImages = images.slice(0, 4),
          roomOccupancyFriendly = `Fits ${max_occupancy} (${bed_type})`,
          firstImageUrl = topImages[0]?.url ?? "";

        return {
          carouselId: `room-option-carousel-${config_id}`,
          topAmenities: topAmenities,
          topImages: topImages,
          firstImageUrl: firstImageUrl,
          roomOccupancyFriendly: roomOccupancyFriendly,
        };
      },
      [
        config,
      ],
    ),
    {
      priceFriendly,
      discountedPriceFriendly,
      isShowDiscountedPrice,
    } = useMemo(() => {
        const priceFriendly = price
            ? `${currencySymbol} ${price.toLocaleString("en-IN")}`
            : "",
          discountedPriceFriendly = discountedPrice
            ? `${currencySymbol} ${discountedPrice.toLocaleString("en-IN")}`
            : priceFriendly,
          isShowDiscountedPrice = (discountedPrice > 0) && (price >0) && (discountedPrice < price);  

        return {
          priceFriendly: priceFriendly,
          discountedPriceFriendly: discountedPriceFriendly,
          isShowDiscountedPrice: isShowDiscountedPrice,
        };
      },
      [currencySymbol, price, discountedPrice],
    ),
    options = useMemo(
      () => {
        const { available_inventory } = config;

        const options = [
          {
            label: "Select",
            value: "0",
          },
          ...Array.from(
            { length: available_inventory },
            (_, offset) => {
              const count = offset + 1;

              const label = `${count} Room`;

              return {
                value: count.toString(),
                label: label,
              };
            },
          ),
        ];

        return options;
      },
      [config],
    ),
    inventoryStatusContent = useMemo(
      () => {
        if (disabled) {
          return "Select travel dates and group size";
        }

        return `Only ${available_inventory} Rooms left`;
      },
      [disabled, available_inventory],
    ),
    nightContent = useMemo(
      () => noOfNights ? "/ night" : " / night",
      [noOfNights],
    ),
    cscx = useClx(clxs.carousel, "carousel"),
    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget as HTMLInputElement;

      const value = target.value;

      onChange?.(config, value);
    },
    handleViewRoomDetails = () => {
      onViewRoomDetails?.(config);
    };

  return (
    <div className={clxs.room}>
      <div className={clxs.carouselContainer}>
        <Carousel
          className={cscx}
          id={carouselId}
          threshold={0.8}
        >
          {topImages.map((each, key) => {
            const {
              url,
              alt,
            } = each;
            return (
              <Item
                className={clxs.imageItem}
                key={key}
              >
                <img
                  src={url}
                  alt={alt}
                  className={clxs.imgSelect}
                />
              </Item>
            );
          })}
        </Carousel>
        <ScrollCrumb
          carouselId={carouselId}
          count={topImages.length}
          className={clxs.scrollCrumb}
        />
      </div>
      <div className={clxs.roomHeader}>
        <img
          className={clxs.roomPreviewImage}
          src={firstImageUrl}
          alt="Room thumbnail"
        />
        <div className={clxs.roomNameContainer}>
          <span className={clxs.roomName}>{room_name}</span>
          <span className={clxs.bedType}>{roomOccupancyFriendly}</span>
        </div>
        <div className={clxs.amenityContainer}>
          {topAmenities.map((eachAmenity, i) => {
            return (
              <div
                key={i}
                className={clxs.amenity}
              >
                <img
                  className={clxs.amenityIcon}
                  alt={eachAmenity.name}
                  src={eachAmenity.icon}
                />
                <span className={clxs.amenityName}>
                  {eachAmenity.name}
                </span>

              </div>
            );
          })}
        </div>
      </div>
      <div className={clxs.roomInventory}>
        {sold_out ? (
          <div className={clxs.inventoryStatusSoldOut}>
            Sold Out
          </div>
        ) : (
          <div className={clxs.inventoryStatusAvailable}>
            {inventoryStatusContent}
          </div>
        )}
      </div>
      <div
        className={clxs.viewRoomDetails}
        onClick={handleViewRoomDetails}
      >
        View Room Details
      </div>
      <div className={clxs.roomPrice}>
        <div className={clxs.startFrom}>
          Start from
        </div>
        <div className={clxs.price}>
          {(priceFriendly && isShowDiscountedPrice) ? (
            <span className={clxs.strike}>
              {priceFriendly}
            </span>
          ) : null}
          <span className={clxs.amount}>
            {discountedPriceFriendly}
          </span>
          {nightContent}
          <sup>*</sup>
        </div>
        <div className={clxs.excTax}>
          <LoyaltyPointShortTicker
            discountPercentage={tierDiscountPercentage}
            method={discountMethod}
            className={clxs.llpTicker}
          />
          (* Exclusive of taxes)
        </div>
      </div>
      <div className={clxs.mobileRoomPrice}>
        <div className={clxs.startFrom}>
          Start from
          {(priceFriendly && isShowDiscountedPrice) ? (
            <span className={clxs.strike}>
              {priceFriendly}
            </span>
          ) : null}
        </div>
        <div className={clxs.price}>
          <span className={clxs.amount}>
            {discountedPriceFriendly}
          </span>
          {nightContent}
          <sup>*</sup>
        </div>
        <div className={clxs.excTax}>
          <LoyaltyPointShortTicker
            discountPercentage={tierDiscountPercentage}
            method={discountMethod}
            className={clxs.llpTicker}
          />
          <div>
            (* Exclusive of taxes)
          </div>
        </div>
      </div>
      <Select
        options={options}
        label="Select Rooms"
        className={clxs.roomSelect}
        placeholder="Select"
        searchable={false}
        value={value}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
}

export default RoomOptionItem;
