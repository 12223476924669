import React from "react";
import { hydrateRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./client/components/App";
import buildPageTemplate from "./client/core/pages";
import AnalyticsService from "./client/core/services/analytics-service";
import OfferService from "./client/core/services/offer-service";
import PaymentService from "./client/core/services/payment-service";
import PropertyService from "./client/core/services/property-service";
import ReservationService from "./client/core/services/reservation-service";
import SendRequestService from "./client/core/services/send-request-service";
import StaticContentService from "./client/core/services/static-content-service";
import UserService from "./client/core/services/user-service";
import { register } from "./sw";
import SeoService from "./client/core/services/seo-service";

const node = document.getElementById("root");

if (!node) {
  throw new Error("unable to initialize root node");
}

const { initialState, pageTemplates } = buildClientParameters();

hydrateRoot(
  node,
  <App
    Router={BrowserRouter}
    routerProps={{}}
    pages={pageTemplates}
    initialState={initialState}
    staticContentService={StaticContentService}
    propertyService={PropertyService}
    reservationService={ReservationService}
    sendRequestService={SendRequestService}
    paymentService={PaymentService}
    userService={UserService}
    analyticsService={AnalyticsService}
    offerService={OfferService}
    seoService={SeoService}
  />,
);

function buildClientParameters () {
  const initialState = window.RECOIL_STATE ?? [];

  const pageTemplates = buildPageTemplate();

  return {
    initialState: initialState,
    pageTemplates: pageTemplates,
  };
}

if (module.hot) {
  module.hot.accept();
}

register();
