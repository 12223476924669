import React, { useMemo } from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./discount-alert.module.css";

interface DiscountAlertProps {
  className?: string;
  method?: string;
  discountMessage?: string;
  discountAmount?: number;
  currencySymbol?: string;
}

function DiscountAlert (props: DiscountAlertProps) {
  const {
    className,
    discountMessage = "",
    discountAmount = 0,
    currencySymbol,
    method = "",
  } = props;

  const amountContent = useMemo(
    () => {
      const amountFriendly = discountAmount.toLocaleString("en-IN", { maximumFractionDigits: 0 });

      return `${currencySymbol} ${amountFriendly}`;
    },
    [discountAmount, currencySymbol],
  );

  const ccx = useClx(clxs.container, className);

  if (!method || method === "coupon" || !discountMessage || !discountAmount) {
    return null;
  }

  return (
    <div
      className={ccx}
      data-discount-type={method}
    >
      {discountMessage}
      <div className={clxs.amount}>
        {amountContent}
      </div>
      <div className={clxs.subtitle}>
        Offer applied on the bill
      </div>
    </div>
  )
}

export default DiscountAlert;

