import { useCallback } from "react";
import { useAtomState } from "./use-atom";

function useToggle(
  id: string,
  initial: boolean = false,
) {
  const [toggle, setToggle] = useAtomState<boolean>(id, initial);

  const handleToggle = useCallback(
    () => setToggle(toggle => !toggle),
    [setToggle],
  )

  return {
    toggle: toggle,
    setToggle: setToggle,
    handleToggle: handleToggle,
  };
}

export default useToggle;
