import React, { useCallback, useMemo, useState } from "react";
import useClx from "../../../hooks/use-clx";
import Collapse from "../../Collapse";
import clxs from "./cancellation-policy.module.css";
import EXPAND_MORE from "../../../assets/icons/expand-more-grey.svg";
import EXPAND_LESS from "../../../assets/icons/expand-less-grey.svg";

interface CancellationPolicyProps {
  className?: string;
  policies: string[];
}

function CancellationPolicy(props: CancellationPolicyProps) {
  const [expanded, setExpanded] = useState<boolean>(false),
    { className, policies } = props,
    subtitle = useMemo(
      () => policies.length ? policies[0] : "",
      [policies],
    ),
    handleToggle = useCallback(
      () => setExpanded(collapsed => !collapsed),
      [setExpanded],
    ),
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <div
        className={clxs.header}
        onClick={handleToggle}
      >
        <div className={clxs.title}>Cancellation Policy</div>
        <img
          src={expanded ? EXPAND_LESS : EXPAND_MORE}
          alt="expand"
          className={clxs.expand}
        />
        <div className={clxs.subtitle}>{subtitle}</div>
      </div>
      <Collapse
        expanded={expanded}
        className={clxs.policies}
      >
        {policies.map(
          (each, key) => (
            <li
              className={clxs.policy}
              key={key}
            >
              {each}
            </li>
          ),
        )}
      </Collapse>
    </div>
  );
}

export default CancellationPolicy;
