import React from "react";
import clxs from "./rewards.module.css";
import SILVER from "../../../assets/refer-n-earn/silver.png";
import GOLD from "../../../assets/refer-n-earn/gold.png";
import PLATINUM from "../../../assets/refer-n-earn/platinum.png";
import useClx from "../../../hooks/use-clx";

interface RewardsProps {
  title: string;
  containerClassName?: string;
  titleClassName?: string;
}

const Rewards = (props: RewardsProps) => {
  const { title, titleClassName } = props;
  const tcx = useClx(titleClassName, clxs.title);
  return (
    <div className={clxs.container}>
      <div className={tcx}>{title}</div>
      <a
        href="/loyalty-points"
        className={clxs.card}
      >
        <div className={clxs.cardTitle}>Silver Member Benefits</div>
        <div className={clxs.cardSubtitle}>
          <span>10,000 Infinity points</span> {" for every successful\nreferral"}
        </div>
        <img
          src={SILVER}
          alt="Silver Member Benefits - Lohono Infinity"
          className={clxs.tierCard}
        />
      </a>
      <a
        href="/loyalty-points"
        className={clxs.card}
      >
        <div className={clxs.cardTitle}>Gold Member Benefits</div>
        <div className={clxs.cardSubtitle}>
          <span>10,000 Infinity points</span>
          {" for every successful\nreferral"}
        </div>
        <img
          src={GOLD}
          alt="Gold Member Benefits - Lohono Infinity"
          className={clxs.tierCard}
        />
      </a>
      <a
        href="/loyalty-points"
        className={clxs.card}
      >
        <div className={clxs.cardTitle}>Platinum Member Benefits</div>
        <div className={clxs.cardSubtitle}>
          <span>10,000 Infinity points</span>
          {" for every successful\nreferral"}
        </div>
        <img
          src={PLATINUM}
          alt="Platinum Member Benefits - Lohono Infinity"
          className={clxs.tierCard}
        />
      </a>
    </div>
  );
};

export default Rewards;
