import { useAtomState } from "./use-atom";

class _DefaultUserService implements IUserService {
  async generateOtp(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async generateLeadOtp(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async verifyAndGenerateLead(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getUser(): Promise<FetchResult<User>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async login(): Promise<FetchResult<string>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async logout(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async updateUser(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async deleteAccount (): Promise<FetchResult<DeleteAccountEligibility>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async deleteAccountGenerateOtp(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async deleteAccountVerifyOtp(): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async deleteAccountRaiseRequest (): Promise<FetchResult<void>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async loyaltyPointsHistory(): Promise<FetchResult<LoyaltyPointsHistory>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getReferralDetails(): Promise<FetchResult<ReferralDetails>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async validateReferralCode(): Promise<FetchResult<ValidReferralCode>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async getReferHistory(): Promise<FetchResult<ReferHistory[]>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }

  async generateReferralCode(): Promise<FetchResult<GenerateReferralCode>> {
    return {
      status: 503,
      error: new Error("not implemented"),
      response: null,
    };
  }
}

const DefaultUserService = new _DefaultUserService();

export const USER_SERVICE_KEY = "USER_SERVICE";

function useUserService() {
  const [service, setService] =
    useAtomState<IUserService>(
      USER_SERVICE_KEY,
      DefaultUserService,
    );

  return {
    service: service,
    setService: setService,
  };
}

export default useUserService;
