import React, {useMemo} from "react";
import NativeOccupancySelect, {
  OccupancySelectProps as NativeOccupancySelectProps,
} from "../../../Occupancy/Select";
import {useField} from "../../../../hooks/use-form";

interface OccupancySelectProps extends BaseOccupancySelectProps {
  form: string;
  name: string;
}

function OccupancySelect(props: OccupancySelectProps) {
  const {
      form,
      ...rest
    } = props,
    {name} = rest, {
      value,
      error: err,
      handleBlur,
      handleChange,
    } = useField(form, name),
    error = useMemo(
      () => {
        if (!err) {
          return "";
        }

        if (typeof err === "string") {
          return err;
        }

        const {
            adult_count,
            child_count,
          } = err as any,
          errors: string[] = [];

        if (adult_count) {
          errors.push(adult_count);
        }

        if (child_count) {
          errors.push(child_count);
        }

        return errors.join(", ");
      },
      [err],
    );

  return (
    <NativeOccupancySelect
      {...rest}
      value={value}
      error={error as string}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export default OccupancySelect;


type BaseOccupancySelectProps = Omit<NativeOccupancySelectProps, "value" | "onChange" | "onBlur" | "error">;