import useReservationService from "./use-reservation-service";
import { useEffect, useState } from "react";

function usePaymentStatus(payment_id: string) {
  const { service } = useReservationService(),
    [paymentStatus, setPaymentStatus] =
      useState<PaymentStatus | null>(null),
    handleGetReservation = async (payment_id: string) => {
      if (!payment_id) {
        return;
      }

      const { error, response } = await service.getPaymentStatus(payment_id);

      if (error || !response) {
        return;
      }

      setPaymentStatus(response);
    };

  useEffect(
    () => {
      handleGetReservation(payment_id);
    },
    [payment_id],
  );

  return {
    objPaymentStatus: paymentStatus,
  };
}

export default usePaymentStatus;
