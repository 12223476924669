import React, { useMemo, useState } from "react";
import clxs from "./meal-plan-menu.module.css";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import useClx from "../../../../../hooks/use-clx";
import Collapse from "../../../../Collapse";
import VIEW_LESS_ICON from "../../../../../assets/icons/view-less-icon.svg";
import VIEW_MORE_ICON from "../../../../../assets/icons/view-more-icon.svg"
import MealPlanItem from "./MealPlanItem/MealPlanItem";
import VEG_ICON from "../../../../../assets/icons/veg-icon.svg";
import NON_VEG_ICON from "../../../../../assets/icons/non-veg-icon.svg";


interface MealPlanMenuProps {
  className?: string;
  menu: MealOptionItem[];
  menuType: string;
  icon?: string;
  id: string;
}
function MealPlanMenu(props: MealPlanMenuProps) {
  const { isMobile } = useIsMobile(),
    { menu, menuType, icon, id } = props,
    primary = useMemo(() => menu.slice(0, 3), [menu, isMobile]),
    secondary = useMemo(() => menu.slice(3), [menu, isMobile]),
    { className } = props,
    [collapsed, setCollapsed] = useState<boolean>(false),
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      
        { menuType == "vegNonVeg" ?
        <div className={clxs.columnContainer}>
        <div className={clxs.columnVegNonVegTitle }>
          <img 
            src={VEG_ICON} 
            alt={menuType} 
            className={clxs.menuIcon} 
          />
          <h2 className={clxs.vegTitle}>Veg</h2>
          &nbsp; and &nbsp;
          <img 
            src={NON_VEG_ICON} 
            alt={menuType} 
            className={clxs.menuIcon} 
          />
          <h2 className={clxs.nonVegTitle}>Non-Veg</h2>
         </div>
         {isMobile ? 
         <div className={clxs.columnHeader}>
         <h2 style={{ textAlign: "start" }}>Meal Plan</h2>
         </div> : null 
         }
         <div className={clxs.columnHeader}>
         <h2>Per Adult</h2>
         </div>
        <div className={clxs.columnHeader}>
        <h2>
          Per Child <br />
          <span>(Below 10 yrs)</span>
        </h2>
      </div>
      </div>
        :
        <div className={clxs.columnContainer}>
        <div className={ clxs.columnTitle }>
          <img 
            src={icon} 
            alt={menuType} 
            className={clxs.menuIcon} 
          />
          <h2 className={menuType == "Non-Veg" ? clxs.nonVegTitle : clxs.vegTitle}>{menuType}</h2>
        </div> 
        <div className={clxs.columnHeader}>
          <h2>Per Adult</h2>
        </div>
        <div className={clxs.columnHeader}>
          <h2 style={{ textAlign: "end" }}>
            Per Child <br />
            <span>(Below 10 yrs)</span>
          </h2>
        </div>
        </div>} 

      {primary.map((each, i) => {
        return (
          <React.Fragment key={`${id}-${i}-primary`}>
           <MealPlanItem
             menu={each}
             menuType={menuType}
             id={id}
           /> 
          </React.Fragment>
        );
      })}
      {/* if number of dish is less than and equal to 3 then show null */}
      {menu.length <= 3 ? null : (
        <Collapse
          expanded={collapsed}
          className={clxs.collapseInner}
          outerClassName={clxs.collapse}
        >
          {secondary.map((each, i) => {
            return (
              <React.Fragment key={`${id}-${i}-secondary`}>
                <MealPlanItem
                  menu={each}
                  menuType={menuType}
                  id={id}
                /> 
              </React.Fragment>
            );
          })}
        </Collapse>
      )}
      {/* if number od dish is less than and equal to 3 then show null otherwise show view more button */}
      {menu.length <= 3 ? null : (
        <div
          className={clxs.viewAll}
          onClick={setCollapsed.bind(null, !collapsed)}
        >
          {collapsed ? 
            <div className={clxs.viewLess}>
              View Less
              <img 
                src={VIEW_LESS_ICON} 
                alt="icon" 
              />
            </div> 
            : 
            <div className={clxs.viewMore}>
              View More 
              <img 
                src={VIEW_MORE_ICON} 
                alt="icon" 
              />
            </div>
          }
        </div>
      )}
    </div>
  );
}

export default MealPlanMenu;
