import React from "react";
import clxs from "./lohono-story.module.css";
import LOHONO_STORY from "../../../assets/lohono-corporate/lohono-story.jpg";
import fwclxs from "../../../styles/full-width.module.css";
import useClx from "../../../hooks/use-clx";

function LohonoStory() {
  const ccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.container);
  return (
    <div className={ccx}>
      <img
        src={LOHONO_STORY}
        alt="background"
        className={clxs.lohonoStoryBg}
      />
      <div className={clxs.title}>Lohono Story</div>
      <div className={clxs.stories}>
      {TEMPLATE.map((each, key) => {
        const { customers, customer_numbers } = each;
        return (
          <div 
            className={clxs.story}
            key={key}
          >
            <div
              className={clxs.customerNumbers}
              key={key}
            >
              {customer_numbers}
            </div>
              <div className={clxs.customers}>
              {customers}
            </div>
          </div>
        );
      })}
    </div>
    </div>
  );
}

export default LohonoStory;

const TEMPLATE = [
  {
    customer_numbers: "1 Lac+",
    customers: "Guests Hosted",
  },
  {
    customer_numbers: "97%",
    customers: "Customer Satisfaction",
  },
  {
    customer_numbers: "500+",
    customers: "Corporate Guests",
  },
  {
    customer_numbers: "50+",
    customers: "Off-Sites Hosted",
  },
  // {
  //   customer_numbers: "20%",
  //   customers: "Repeat Guests",
  // },
];