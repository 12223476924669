import React from "react";
import clxs from "./inclusions.module.css";
import PRIVATE_POOL from "../../../assets/lohono-corporate/inclusions/private-pool.png";
import CONFERENCE_ROOM from "../../../assets/lohono-corporate/inclusions/conference-room.png";
import WIFI from "../../../assets/lohono-corporate/inclusions/wifi.jpg";
import CATERING_SERVICES from "../../../assets/lohono-corporate/inclusions/catering-services.jpg";
import LARGE_GARDEN_SPACE from "../../../assets/lohono-corporate/inclusions/large-garden.jpg";
import COMPLIMENTARY_BREAKFAST from "../../../assets/lohono-corporate/inclusions/breakfast.jpg";
import COFFEE_MACHINE from "../../../assets/lohono-corporate/inclusions/coffee-machine.jpg";
import MUSIC_PLAYER from "../../../assets/lohono-corporate/inclusions/music-player.jpg";


function Inclusions() {
  return(
    <div className={clxs.container}>
      <div className={clxs.title}>
        Inclusions
      </div>
      <div className={clxs.subtitle}>
         Experience the perfect blend of leisure and business in a luxurious and unforgettable setting
      </div>
      <div className={clxs.items}>
        {TEMPLATE.map(
          (each, key) => {
            const {thumbnail, title} = each;
          return(
            <div 
              className={clxs.item}
              key={key}
            >
              <img 
                src={thumbnail} 
                alt={title} 
                className={clxs.itemThumbnail}
              />
              <div className={clxs.itemTitle}>
                {each.title}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default Inclusions;

const TEMPLATE = [
  {
    title: "Private Pool",
    thumbnail: PRIVATE_POOL,
  },
  {
    title: "Conference Room", 
    thumbnail: CONFERENCE_ROOM,
  },
  {
    title: "High Speed WIFI",
    thumbnail: WIFI,
  },
  {
    title: "Catering Services",
    thumbnail: CATERING_SERVICES,
  },
  {
    title: "Large Garden Space",
    thumbnail: LARGE_GARDEN_SPACE,
  },
  {
    title: "Complimentary Breakfast",
    thumbnail: COMPLIMENTARY_BREAKFAST,
  },
  {
    title: "Coffee Machine",
    thumbnail: COFFEE_MACHINE,
  },
  {
    title: "Music Player",
    thumbnail: MUSIC_PLAYER,
  },
];