import React from "react";
import clxs from "./tax-tooltip.module.css";
import Tooltip from "../../ToolTip";

interface TaxTooltipProps {
  tax_data: TaxBreakdown[];
  currency_symbol: string;
}

function TaxTooltip(props: TaxTooltipProps) {
  const { tax_data, currency_symbol } = props;

  if(!tax_data){
    return null;
  }

  const taxBreakdownContent = (
    <div className={clxs.taxContainer}>
      {tax_data.map((each, key) => (
        <div 
          className={clxs.taxBreakdown} 
          key={key}
        >
          <div className={clxs.room}>{each.description}</div>
          <div className={clxs.roomPrice}>{`${currency_symbol} ${each.sub_title}`} </div>
          <div className={clxs.price}>{`${currency_symbol} ${each.taxes}`}</div>
        </div>
      ))}
    </div>
  );

  return (
    <Tooltip content={taxBreakdownContent} />
  );
}

export default TaxTooltip;
