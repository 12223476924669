import React  from "react";
import useClx from "../../../../hooks/use-clx";
import { Track } from "../../../AnalyticsManager";
import { AnchorButton } from "../../../Button";
import PointTag from "../../PointTag";
import TierBenefit from "../../TierBenefit";
import Gimmick from "./Gimmick";
import clxs from "./tier.module.css";

interface TierProps {
  tier?: LoyaltyPointsTier;
  nextTier?: LoyaltyPointsTier;
  nightsCompleted?: number;
  className?: string;
  onShowLoyaltyPointsHistory?: () => void;
}

function Tier (props: TierProps) {
  const { tier, nextTier, nightsCompleted = 0, className, onShowLoyaltyPointsHistory } = props;

  const ccx = useClx(clxs.container, className);

  if (!tier) {
    return null;
  }

  return (
    <div className={ccx}>
      <PointTag 
        className={clxs.points} 
        onShowLoyaltyPointsHistory={onShowLoyaltyPointsHistory}
      />
      <Gimmick
        tier={tier}
        nextTier={nextTier}
        nightsCompleted={nightsCompleted}
        className={clxs.gimmick}
      />
      <TierBenefit
        isTierMainHeader={true}
        tier={tier}
        status=""
        className={clxs.tierBenefit }
        hideNotIncluded={true}
      />
      <Track event="loyalty_points_next_stay_clicked">
        <AnchorButton
          className={clxs.book}
          href="/#locations"
        >
          Find Your Next Stay
        </AnchorButton>
      </Track>
    </div>
  );
}

export default Tier;
