import React, { ChangeEventHandler, useEffect, useMemo, useRef } from "react";
import useAnchorPosition from "../../hooks/use-anchor-position";
import useClx from "../../hooks/use-clx";
import useIsMobile from "../../hooks/use-is-mobile";
import Portal from "../Portal";
import CalendarDialog, { CalendarDialogProps } from "./CalendarDialog";
import useDialogToggle from "../../hooks/use-dialog-toggle";
import useCalendarDialogPosition from "./use-calendar-dialog-position";
import useDateRangeInputState, { DateLike, OnVerifySelection } from "./use-date-range-input-state";
import clxs from "./date-range-input.module.css";

export interface DateRangeInputProps {
  inputFormat?: string;
  labels?: [string, string];
  className?: string;
  start?: DateLike;
  end?: DateLike;
  startDefaultValue?: DateLike;
  endDefaultValue?: DateLike;
  placeholder?: [string, string];
  allowPastSelection?: boolean;
  calendarDialogProps?: BaseCalendarDialogProps;
  onChange?: ChangeEventHandler;
  onVerifySelection?: OnVerifySelection;
  name?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  onOpenDialog?:()=>void;
  isShowDialog?: boolean;
}

function DateRangeInput (props: DateRangeInputProps) {
  const {
      placeholder = ["dd/mm/yyyy", "dd/mm/yyyy"],
      className,
      labels,
      inputFormat,
      start,
      startDefaultValue,
      end,
      endDefaultValue,
      onOpenDialog,
      allowPastSelection = false,
      name,
      onChange,
      onVerifySelection,
      calendarDialogProps = {},
      helperText,
      error,
      disabled = false,
      isShowDialog,
    } = props,
    { isMobile } = useIsMobile(),
    ref = useRef<HTMLDivElement>(null),
    { anchorPosition: desktopPosition } = useAnchorPosition(ref),
    { open, handleOpen } = useDialogToggle(ref, disabled, onOpenDialog),
    {
      start: startValue,
      hasStart,
      startFormatted,
      end: endValue,
      hasEnd,
      endFormatted,
      handleDateSelected,
      handleClearStart,
      handleClearEnd,
    } = useDateRangeInputState(
      open,
      placeholder,
      inputFormat,
      ref.current,
      allowPastSelection,
      start,
      startDefaultValue,
      end,
      endDefaultValue,
      name,
      onChange,
      onVerifySelection,
    ),
    safeInitialDate = useMemo(
      () => {
        return startValue ?? endValue ?? calendarDialogProps.initialDate;
      },
      [calendarDialogProps.initialDate, startValue, endValue],
    ),
    { styles: dialogStyles } = useCalendarDialogPosition(desktopPosition, ref, open),
    ccx = useClx(clxs.container, className),
    vcx = useClx(clxs.value, "value"),
    dcx = useClx(calendarDialogProps.className, clxs.dialog);

    useEffect(
      () => {
        if(isShowDialog) {
          handleOpen();
        }
      },
      [isShowDialog],
    );

  return (
    <div
      className={ccx}
      ref={ref}
      onClick={handleDateSelected}
      data-error={error}
      data-disabled={disabled}
    >
      {labels?.length === 2 && (
        <>
          <div className={clxs.label}>
            {labels[0]}
          </div>
          <div className={clxs.label}>
            {labels[1]}
          </div>
        </>
      )}
      <div
        className={vcx}
        tabIndex={1}
        data-placeholder={!hasStart}
        data-action="open"
        onClick={handleOpen}
      >
        {startFormatted}
        {hasStart && (
          <div
            className="clear"
            onClickCapture={handleClearStart}
            data-action="ignore"
          >
            ×
          </div>
        )}
      </div>
      <div
        className={vcx}
        data-placeholder={!hasEnd}
        tabIndex={1}
        data-action="open"
        onClick={handleOpen}
      >
        {endFormatted}
        {hasEnd && (
          <div
            className="clear"
            onClickCapture={handleClearEnd}
            data-action="ignore"
          >
            ×
          </div>
        )}
      </div>
      {helperText && (
        <div className={clxs.helperText}>
          {helperText}
        </div>
      )}
      <Portal scrollBlock={isMobile && open}>
        <CalendarDialog
          {...calendarDialogProps}
          style={dialogStyles}
          open={open}
          startDate={startValue}
          endDate={endValue}
          initialDate={safeInitialDate}
          position={desktopPosition}
          className={dcx}
        />
      </Portal>
    </div>
  );

}

export default DateRangeInput;

type BaseCalendarDialogProps = Omit<CalendarDialogProps,
  | "open"
  | "position"
  | "startDate"
  | "endDate"
  | "style">;