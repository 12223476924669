import { useAtomState } from "./use-atom";

function useHost () {
  const [host] = useAtomState<string>(HOST_KEY, "");

  return host;
}

export default useHost;

export const HOST_KEY = "DOMAIN";
