const FEATURES_LIST = [
  {
    id: 11,
    title: "Spacious Villas",
    subtitle: "Travel together. Stay Together. 2 to 10 bedroom villas to fit any group",
    icon: "https://cdn.prod.website-files.com/631847455dc9706e20a1f63a/633d8826cc8770c2a3a43c26_large-accomodations.svg",
  },
  {
    id: 12,
    title: "Beautiful locations",
    subtitle: "From hilltops to most serene beach sides, our locations will captivate your sense",
    icon: "https://cdn.prod.website-files.com/631847455dc9706e20a1f63a/633d8827c78460922ab63ed3_beautiful-locations.svg",
  },
  {
    id: 13,
    title: "Intiutive services",
    subtitle: "On Villa staff like Chef, house keeping to ensure comfortable stay",
    icon: "https://cdn.prod.website-files.com/631847455dc9706e20a1f63a/633d8826043f83924d76db6f_intitutive-service.svg",
  },
  {
    id: 14,
    title: "Unique Vibes",
    subtitle: "Beautiful architecture & hand picked antiques giving each home a unique vibe",
    icon: "https://cdn.prod.website-files.com/631847455dc9706e20a1f63a/633d8826ec957e74218786ba_unique-vibe.svg",
  },
  {
    id: 15,
    title: "Fully Private stays",
    subtitle: "Jump to pool anytime or throw a night party. Feel at home with Lohono!",
    icon: "https://cdn.prod.website-files.com/631847455dc9706e20a1f63a/633d88261770b61ac22d5ca0_secure.svg",
  },
];

export { FEATURES_LIST };
