import { useEffect } from "react";

function useScrollToTop() {
  const handleScrollToTop = () => {
    if (typeof window === "undefined") {
      return;
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => handleScrollToTop(), []);
}

export default useScrollToTop;
