import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { generatePropertyConfigKey } from "./use-property-config-values";

function useDefaultPropertyConfig(
  propertySlug: string,
  viewLayout: string,
  configs: PropertyConfig[],
) {
  const [search, setSearch] = useSearchParams();

  const handleDefaultConfig = useCallback(
    () => {
      if (!propertySlug) {
        return;
      }

      if (viewLayout !== "package" && configs.length !== 1) {
        return;
      }

      const updatedSearch = new URLSearchParams(search);

      const [firstConfig] = configs;

      const key = generatePropertyConfigKey(propertySlug, firstConfig);

      if (updatedSearch.has(key)) {
        return;
      }

      updatedSearch.set(key, "1");

      setSearch(updatedSearch, { replace: true });
    },
    [setSearch, propertySlug, configs],
  );

  useEffect(handleDefaultConfig, [handleDefaultConfig]);
}

export default useDefaultPropertyConfig;
