import React, { useEffect, useState } from "react";
import clxs from "./collectionsgallery.module.css";
import useStaticCollections from "./use-static-collections";
import Img from "../../Img";
import useClx from "../../../hooks/use-clx";

interface CollectionsGalleryProps {
    title?: string;
    subtitle?: string;
    activeCollection?: string;// slug of collection which got clicked
    onCollectionClick?: (slug: string) => void;
    staticCollectionList?:StaticDataCollection[];
    className:string;
  }
  
  function CollectionsGallery(props: CollectionsGalleryProps) {
    const {
        title,
        subtitle,
        activeCollection,
        onCollectionClick,
        staticCollectionList,
        className,
      } = props, 
      ccx = useClx(clxs.section, className),
      COLLECTIONS_LIST = useStaticCollections(),
      [collectionClass,setCollectionClass] = useState<string>(),// list of CSS class for the gallery
      [collections, setCollections] = useState<StaticDataCollection[]>([]);// list of collections

      // Function to set the appropriate CSS class based on the number of collections
      const handleClassName = (length:number) => {
        if (length === 7) {
          setCollectionClass(clxs.exploreGallery);
        } else if (length === 8) {
          setCollectionClass(clxs.collectionsEightGallery);
        } else if (length === 9) {
          setCollectionClass(clxs.collectionsGalleries);
        } else if (length === 10) {
          setCollectionClass(clxs.collectionsTenGallery);
        } else if (length === 11) {
          setCollectionClass(clxs.collectionsGalleries);
        }
      }

      // to update the state when the COLLECTIONS_LIST and activeCollection prop changes
      useEffect(() => {
        if (staticCollectionList) {
          const topEightCollection = staticCollectionList.slice(0,8);
          setCollections(topEightCollection);
          handleClassName(topEightCollection.length);
        }
        else if (activeCollection) {
        // Filter out the active collection from the list
        const filteredCollections = COLLECTIONS_LIST.filter(
            (oCollection) => oCollection.slug !== activeCollection,
        );
          const topEightCollection = filteredCollections.slice(0,7);
          setCollections(topEightCollection);
          handleClassName(topEightCollection.length);// handle CSS class based on the new list length
        } else {
          const topEightCollection = COLLECTIONS_LIST.slice(0,8);
          setCollections(topEightCollection);
          handleClassName(topEightCollection.length);
        }

      },[activeCollection,COLLECTIONS_LIST])

      // If the number of collections is not within the specified range, return null (no rendering)
      if (collections.length <= 6 || collections.length >= 12) {
        return null;
      }

    return ( <div className={ccx}>
        <div className={clxs.sectionTitle}>
          {title}
        </div>
        {subtitle && (
          <div className={clxs.sectionSubtitle}>
            {subtitle}
          </div>
        )}

        <div className={collectionClass}>
          {collections?.map((each, key) => {
            const { image, name, slug, desktop_image } = each;
            return (
              <a
                href={`/villas/collections/${slug}`}
                onClick={(e) => {
                   e.preventDefault(); // Prevent default anchor tag navigation
                  if (onCollectionClick) {
                    onCollectionClick(slug); // Trigger the callback when clicked
                  }
                }}
                key={key}
                className={clxs.collectionsPhotos}
              >
                <Img
                  src={image}
                  mobileSrc={image}
                  desktopSrc={desktop_image}
                  alt={name}
                  className={clxs.itemThumbnail}
                />
                <div className={clxs.itemTitle}>{each.name}</div>
                <div 
                  className={onCollectionClick ?clxs.backdrop : clxs.backdropNonPointer }
                >
                  &nbsp;
                </div>
              </a>
            );
          })}
        </div>
      </div> );
}

export default CollectionsGallery;
