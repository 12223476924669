import React from "react";
import clxs from "../Breakdown/breakdown.module.css";
import Skeleton from "../../Skeleton";

function BookingBreakdownSkeleton() {
  return (
    <div className={clxs.breakdownSkeletonContainer}>
      <div style={{ height: 69 }}>
        <Skeleton />
      </div>
      <div style={{ height: 32 }}>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div className={clxs.blackBorder}>
        <Skeleton />
      </div>
      <div>
        <Skeleton />
      </div>
      <div className={clxs.blackBorder}>
        <Skeleton />
      </div>
      <div 
        style={{ height: 51 }} 
        className={clxs.couponSkeleton}
      >
        <Skeleton height={51} />
      </div>
      <div className={clxs.checkboxSkeleton}>
        <div>
          <Skeleton 
            width={24} 
            height={24} 
          />
        </div>
        <div>
          <Skeleton height={16} />
        </div>
      </div>
      <div 
        style={{ height: 48 }} 
        className={clxs.submitSkeleton}
      >
        <Skeleton />
      </div>
    </div>
  );
}

export default BookingBreakdownSkeleton;
