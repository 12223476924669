import { useCallback } from "react";
import { useSession } from "./use-session";
import { useSnackbar } from "./use-snackbar";
import useUserService from "./use-user-service";

function useGetLoyaltyPointsHistory() {

  const session = useSession();

  const enqueueSnackbar = useSnackbar();

  const { service: userService } = useUserService();

  const getLoyaltyPointsHistory = useCallback(
    async () => {
      const { error, response } = await userService.loyaltyPointsHistory(session);

      if (error) {
        enqueueSnackbar(error.message, "error");

        return null;
      }

      if (!response) {
        return null;
      }

      return response;
    },
    [],
  );

  return getLoyaltyPointsHistory;
}

export default useGetLoyaltyPointsHistory;
