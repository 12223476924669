import React from "react";
import clxs from "./loader.module.css";

interface LoaderProps {
  isLoading: boolean;
}

function Loader(props: LoaderProps) {
  const { isLoading } = props;

  return (
    isLoading ? (
      <div className={clxs.ldsRipple}>
        <div></div>
        <div></div>
      </div>
    ) : <></>
  );
}

export default Loader;
