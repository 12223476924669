import Fetch from "../../utils/fetch";

class UserService implements IUserService {
  private readonly fetch: Fetch<ApiResult>;

  constructor (fetch: Fetch<ApiResult>) {
    this.fetch = fetch;
  }

  generateOtp (
    phone_iso_code: string,
    phone_no: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        phone_iso_code: phone_iso_code,
        phone_no: phone_no,
      },
      url = new URL("/api/user/login/otp", window.location.origin);

    return this.fetch.POST(url.toString(), payload);
  }

  generateLeadOtp (
    phone_iso_code: string,
    phone_no: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        phone_iso_code: phone_iso_code,
        phone_no: phone_no,
      },
      url = new URL("/api/user/lead/otp", window.location.origin);

    return this.fetch.POST(url.toString(), payload);
  }

  verifyAndGenerateLead (
    phone_iso_code: string,
    phone_no: string,
    otp: number, 
    name: string,
    email?: string, 
    budget?: string,
    meta?: any,
    location_slug?: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        phone_iso_code: phone_iso_code,
        phone_no: phone_no,
        otp: otp,
        name: name,
        email: email || "",
        budget: budget || "",
        meta: meta,
        location_slug: location_slug || "",
      },
      url = new URL("/api/user/lead/generate", window.location.origin);

    return this.fetch.POST(url.toString(), payload);
  }

  login (
    phone_no: string,
    otp: string,
  ): Promise<FetchResult<string>> {
    const payload = { phone_no: phone_no, otp: otp },
      url = new URL("/api/user/login", window.location.origin);

    return this.fetch.POST(url.toString(), payload);
  }

  logout (session: string): Promise<FetchResult<void>> {
    const url = new URL("/api/user/logout", window.location.origin);

    return this.fetch.DELETE(
      url.toString(),
      null,
      {
        headers: { session: session },
      },
    );
  }

  getUser (session: string): Promise<FetchResult<User>> {
    const url = new URL("/api/user", window.location.origin);

    return this.fetch.GET(
      url.toString(),
      {
        headers: { session: session },
      },
    );
  }

  updateUser (
    session: string,
    full_name: string,
    email: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        session: session,
        full_name: full_name,
        email: email,
      },
      url = new URL("/api/user", window.location.origin);

    return this.fetch.PATCH(
      url.toString(),
      payload, {
        headers: { session: session },
      },
    );
  }

  async deleteAccount (
    accountType: string,
    session: string,
    isoCode: string,
    reason: string,
    dryRun: boolean = true,
  ): Promise<FetchResult<DeleteAccountEligibility>> {
    const payload = {
      account_type: accountType,
      session: session,
      iso_code: isoCode,
      reason: reason,
      dry_run: dryRun,
    };

    const url = new URL("/api/user/delete-account", window.location.origin);

    return this.fetch.DELETE(url.toString(), payload);
  }

  async deleteAccountGenerateOtp (
    accountType: string,
    session: string,
  ): Promise<FetchResult<void>> {
    const payload = {
      account_type: accountType,
      session: session,
    };

    const url = new URL("/api/user/delete-account/otp", window.location.origin);

    return this.fetch.POST(
      url.toString(),
      payload,
      {
        headers: { session: session },
      },
    );
  }

  async deleteAccountVerifyOtp (
    accountType: string,
    session: string,
    otp: string,
  ): Promise<FetchResult<void>> {
    const payload = {
      account_type: accountType,
      session: session,
      otp: otp,
    };

    const url = new URL("/api/user/delete-account/otp/verify", window.location.origin);

    return this.fetch.POST(url.toString(), payload);
  }

  deleteAccountRaiseRequest (
    accountType: string,
    session: string,
    isoCode: string,
    reason: string,
  ): Promise<FetchResult<void>> {
    const payload = {
      account_type: accountType,
      session: session,
      iso_code: isoCode,
      reason: reason,
    };

    const url = new URL("/api/user/delete-account/raise-request", window.location.origin);

    return this.fetch.POST(url.toString(), payload);
  }

  loyaltyPointsHistory (session: string): Promise<FetchResult<LoyaltyPointsHistory>> {
    const url = new URL("/api/user/loyalty-points-history", window.location.origin);

    return this.fetch.GET(
      url.toString(),
      {
        headers: { session: session },
      },
    );
  } 

  getReferralDetails (referralCode:string): Promise<FetchResult<any>> {
    const url = new URL("/api/user/referral-details",window.location.origin);
    url.searchParams.set("code",referralCode);

    return this.fetch.GET(
      url.toString(),
    );
  }

  validateReferralCode(referralCode: string, session: string): Promise<FetchResult<ValidReferralCode>> {
    {
      const url = new URL(
        "/api/user/validate-referral-code",
        window.location.origin,
      );
      const payload = { code: referralCode };

      return this.fetch.POST(url.toString(), payload, {
        headers: { session: session },
      });
    }
  }

  generateReferralCode(userId: number): Promise<FetchResult<GenerateReferralCode>> {
    {
      const url = new URL(
        "/api/user/generate-referral-code",
        window.location.origin,
      );
      const payload = { userId: userId };

      return this.fetch.POST(url.toString(), payload);
    }
  }

  getReferHistory (session:string,referStatus: string, page: number): Promise<FetchResult<ReferHistory[]>> {
    const url = new URL("/api/user/refer-history",window.location.origin);
    url.searchParams.set("referStatus",referStatus);
    url.searchParams.set("page",page.toString());

    return this.fetch.GET(
      url.toString(),
      {
        headers: { session: session },
      },
    );
  }

}

export default UserService;
