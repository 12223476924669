import React, {
  ChangeEventHandler,
  FocusEventHandler,
  HTMLAttributes,
  LabelHTMLAttributes,
  MouseEvent, useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropertyConfig, { PropertyConfigProps, PropertyConfigValue } from "../PropertyConfig";
import useClx from "../../../hooks/use-clx";
import ExpandIcon from "../../Select/ExpandIcon";
import clxs from "./property-config-select.module.css";
import { generatePropertyConfigKey } from "../../../hooks/use-property-config";

export interface PropertyConfigSelectProps extends Omit<PropertyConfigProps, "onChange"> {
  name?: string;
  onChange?: ChangeEventHandler<any>;
  onFocus?: FocusEventHandler<any>;
  onBlur?: FocusEventHandler<any>;
  containerProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
  label?: string;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  error?: string;
}

function PropertyConfigSelect(props: PropertyConfigSelectProps) {
  const {
      className,
      onChange,
      onFocus,
      onBlur,
      containerProps = {},
      label,
      labelProps = {},
      error,
      value: _value,
      propertySlug,
      ...rest
    } = props,
    {
      disabled,
      configs,
      name,
      defaultValue = {},
    } = props,
    generateKeyPrefix = useCallback(
      (configId: string) => {
        return generatePropertyConfigKey(propertySlug, configId);
      },
      [propertySlug],
    ),
    [focused, setFocused] = useState<boolean>(false),
    [value, setValue] = useState<PropertyConfigValue>(
      () => _value || defaultValue,
    ),
    placeholder = useMemo(
      () => {
        const count = configs.reduce(
          (aggregate, config) => {
            const key = generateKeyPrefix(config.config_id);

            const count = value[key] ?? 0;

            return aggregate + count;
          },
          0,
        );


        if (count === 0) {
          if (!configs.length) {
            return "Select Rooms";
          }

          return `Select from ${configs.length} options`;
        }

        if (count === 1) {
          return "1 Room";
        }

        return `${count} Rooms`;
      },
      [generateKeyPrefix, configs, value],
    ),
    selectionTitle = useMemo(
      () => {
        const selection = configs.reduce(
          (compiled, config) => {
            const key = generateKeyPrefix(config.config_id);

            const count = value[key] ?? 0;

            if (!count) {
              return compiled;
            }

            const partial = `${count} ${config.room_name}`;

            if (!compiled) {
              return partial;
            }

            return `${compiled},\n${partial}`;
          },
          "",
        );

        if (!selection) {
          return "No Rooms";
        }

        return selection;
      },
      [generateKeyPrefix, configs, value],
    ),
    { className: _lcx } = labelProps,
    ccx = useClx(clxs.container, className),
    lcx = useClx(clxs.label, _lcx),
    icx = useClx(clxs.input, "input"),
    ecx = useClx(clxs.expandIcon, "expand"),
    handleValueChange = (value: PropertyConfigValue) => {
      setValue(value);

      if (!onChange) {
        return;
      }

      const target = { name: name, value: value },
        payload: any = { target: target, currentTarget: target };

      onChange(payload);
    },
    handleFocus = () => {
      if (disabled) {
        return;
      }

      setFocused(true);

      if (!onFocus) {
        return;
      }

      const target = { name: name, value: value, focused: true },
        payload: any = { target: target, currentTarget: target };

      onFocus(payload);
    },
    handleBlur = () => {
      if (disabled) {
        return;
      }

      setFocused(false);

      if (!onBlur) {
        return;
      }

      const target = { name: name, value: value, focused: true },
        payload: any = { target: target, currentTarget: target };

      onBlur(payload);
    },
    handleClick = () => {
      if (disabled) {
        return;
      }

      setFocused(true);
    },
    handleDone = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setFocused(false);

      return handleValueChange(value);
    },
    handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
    };

  useEffect(() => {
    if (!_value) {
      return;
    }

    setValue(_value);
  }, [_value]);

  return (
    <div
      {...containerProps}
      data-focus={focused}
      className={ccx}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onClick={handleClick}
      data-disabled={disabled}
    >
      {label && (
        <label
          {...labelProps}
          htmlFor={name}
          className={lcx}
        >
          {label}
        </label>
      )}
      <div
        tabIndex={1}
        className={icx}
        data-error={Boolean(error).valueOf()}
        suppressHydrationWarning={true}
        title={selectionTitle}
      >
        {placeholder}
      </div>
      {error && <div className={clxs.error}>{error}</div>}
      <ExpandIcon className={ecx} />
      {focused && (
        <div
          className={clxs.optionsContainer}
          onMouseDown={handleMouseDown}
        >
          <PropertyConfig
            {...rest}
            value={value}
            propertySlug={propertySlug}
            onChange={handleValueChange}
          />
          <div className={clxs.doneContainer}>
            <button
              className={clxs.done}
              onClick={handleDone}
              type="button"
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PropertyConfigSelect;
