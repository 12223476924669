import React from "react";
import clxs from "./lohono-experience.module.css";
import LOCATION from "../../../assets/refer-n-earn/location.svg";
import HOUSEKEEPING from "../../../assets/refer-n-earn/housekeeping.svg";
import WEDDING from "../../../assets/refer-n-earn/wedding.svg";
import RELAX from "../../../assets/refer-n-earn/relax.svg";
import { AnchorButton } from "../../Button";
import useClx from "../../../hooks/use-clx";

interface LohonoExperienceProps {
  title: string;
  containerClassName?: string;
  titleClassName?: string;
}

const LohonoExperience = (props: LohonoExperienceProps) => {
  const { 
    title, 
    titleClassName,
    containerClassName,
  } = props;
  const ccx = useClx(clxs.container, containerClassName);
  const tcx = useClx(clxs.title, titleClassName);
  return(
    <div className={ccx}>
      <div className={tcx}>
        {title}
      </div>
      <div className={clxs.experiences}>
        {EXPERIENCE.map( (each, key) => {
          return(
            <div 
              className={clxs.experience}
              key={key}
            >
              <img 
                src={each.icon} 
                alt="icon"
                className={clxs.icon} 
              />
              <div className={clxs.content}>
                {each.content}
              </div>
            </div>
          );
        })}
      </div>
      <AnchorButton
        href="/#locations"
        className={clxs.explore}
      >
        Explore Lohono
      </AnchorButton>
    </div>
  );
}

export default LohonoExperience;

const EXPERIENCE = [
  {
    icon: LOCATION,
    content: "Luxury villas at breathtaking locations with picturesque aesthetics",
  },
  {
    icon: HOUSEKEEPING,
    content: "Fully serviced stay with housekeeping and concierge just a call away",
  },
  {
    icon: WEDDING,
    content: "Fit for any occassion be it birthdays, family outings, corporate offsites or wedding party",
  },
  {
    icon: RELAX,
    content: "From savouring a 5 course gourmet meal to relaxing wellness therapies, signup for exclusive experiences",
  },  
];