import React from "react";
import clxs from "./senior-citizen.module.css";
import ILLUSTRATION from "../../../../assets/senior-citizen-illustration.svg";

interface SeniorCitizenProps {
  seniorCitizenDescription: string;
}

function SeniorCitizen(props: SeniorCitizenProps) {
  const { seniorCitizenDescription } = props;
  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        Senior Citizen friendly
      </div>
      <div className={clxs.description}>
        {seniorCitizenDescription}
      </div>
      <img
        className={clxs.illustration}
        src={ILLUSTRATION}
      />
    </div>
  );
}

export default SeniorCitizen;