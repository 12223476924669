import React from "react";
import clxs from "./referral-programme.module.css";
import useClx from "../../hooks/use-clx";
import fwclxs from "../../styles/full-width.module.css";
import About from "./About";
import GetEnrolled from "./GetEnrolled";
import Rewards from "./Rewards";
import LohonoExperience from "./LohonoExperience";
import FaqItem from "./FaqItem";
import ReferralPoints from "../ReferralPoints";
import useLoyaltyPointsBalance from "../../hooks/use-loyalty-points-balance";
import { useUser } from "../../hooks/use-session";
import useReferralProgramPolicy from "../../hooks/use-referral-program-faq";

const ReferralProgramme = () => {
  const ccx = useClx(fwclxs.container, fwclxs.containerPadding, clxs.container);
  const fcx = useClx(clxs.faqContainer, clxs.sectionContainer);
  const { points } = useLoyaltyPointsBalance(),
    faqList = useReferralProgramPolicy(),
    {faq} = faqList,
    user = useUser();
    
  return (
    <div className={ccx}>
      <div className={clxs.title}>Welcome to Lohono Referral Program</div>

      {(user) && (
        <div className={clxs.section}>
          <ReferralPoints
            points={points}
            user={user}
          />
        </div>
      )}

      <About
        title={"Share a Luxury Villa Staycation with your \nconnections"}
        titleClassName={clxs.sectionTitle}
        containerClassName={clxs.sectionContainer}
        infinityPoints={points}
      />
      <GetEnrolled
        title="How to refer ?"
        titleClassName={clxs.sectionTitle}
        containerClassName={clxs.sectionContainer}
      />
      <Rewards
        title="Rewards and Benefits"
        titleClassName={clxs.sectionTitle}
      />
      <LohonoExperience
        title="The Lohono Experience"
        titleClassName={clxs.sectionTitle}
        containerClassName={clxs.sectionContainer}
      />
      <div className={fcx}>
        <div className={clxs.sectionTitle}>FAQ</div>
        {faq.map((each, key) => (
          <FaqItem
            key={key}
            faq={each}
          />
        ))}
      </div>
    </div>
  );
};

export default ReferralProgramme;

// const FAQ_TEMPLATE =
//   [
//     {
//       "question": "What is the value of an Infinity point?",
//       "answer": "The value of an Infinity point is ₹1/-.",
//     },
//     {
//       "question": "Who is the referrer?",
//       "answer": "The referrer is the infinity member who refers a guest.",
//     },
//     {
//       "question": "Who is the referee?",
//       "answer": "The referee is the one who is being referred.",
//     },
//     {
//       "question": "How many Infinity points can I earn from each referral?",
//       "answer": "The number of infinity points earned from each referral is determined by the referrer's infinity tier. Platinum members earn 10000 points, Gold members earn 5000 points & Silver members earn 2500 points for each referral. Correspondingly, an equivalent number of points are gifted to the referee.",
//     },
//     {
//       "question": "What is the eligibility to refer a guest?",
//       "answer": "The referrer must be at least a Silver Tier Infinity member to earn points through guest referrals. To become a Silver member, pls refer to the tiers & eligibility.",
//     },
//     {
//       "question": "When will the infinity points get credited?",
//       "answer": "For the referrer- 7 days after the referee checkouts from the villa. For the referee - The moment the referee clicks the link shared by the referrer and logs in to the app, points will be credited to the account. These points can be used for their initial booking.",
//     },
//     {
//       "question": "What is the validity of the referral bonus Infinity points?",
//       "answer": "For the referrer - 1 year from date of earning. For the referee - 3 months from the date of earning for booking a stay. However, the check-in can be made for a period post 3 months.",
//     },
//     {
//       "question": "How can I redeem Infinity points earned from a referral invite?",
//       "answer": "Infinity points earned from a referral invite can be used for booking your first stay through the Lohono Stays mobile application and website. The Referral bonus points will be visible on your checkout screen.",
//     },
//     {
//       "question": "What is the eligibility for redeeming the referral bonus points for a referee?",
//       "answer": "The booking should be for a minimum of 2 nights and a 3 BHK or larger villa.",
//     },
//     {
//       "question": "Can I transfer my Infinity points to someone else?",
//       "answer": "No, Infinity points cannot be transferred to another person. They cannot be encashed either.",
//     },
//     {
//       "question": "What is the process to refer a guest?",
//       "answer": "To refer a guest, you can use the ‘Invite and Earn’ link provided in the Lohono Stays application. Simply share the link with the guest which they will use to make a booking.",
//     },
//     {
//       "question": "Where can I share the referral link?",
//       "answer": "You can share the referral link through platforms such as WhatsApp, Facebook, Instagram, or Email.",
//     },
//     {
//       "question": "Is there a limit to the number of guests I can refer to in a single year?",
//       "answer": "No, there is no limit to the number of guests you can refer to in a single year.",
//     },
//     {
//       "question": "How can I track the status of my referrals?",
//       "answer": "On the Lohono Mobile Application, you will need to tap on the ‘ Invite your friends’ strip, after which you will need to click on refer history. Here you will see Pending/Confirmed & Failed bookings. Pending- Consists of those guests who you have sent a referral link to and haven't made a booking yet & guests who have made a booking but haven't stayed yet. Confirmed- Consists of those guests who have completed their stay. Failed - The referral is failed under 2 circumstances 1) If the referee is already an Infinity member 2)If A refers B, but B has already been referred by someone else, then for A it would show that B’s referral has failed",
//     },
//     {
//       "question": "Can I club my referral points with my existing points?",
//       "answer": "Yes, you can.",
//     },
//     {
//       "question": "Can I use my referral points in multiple bookings?",
//       "answer": "Unfortunately, No. For each booking, all the points available under the referral bonus will be used.",
//     },
//     {
//       "question": "What if my referee forgets to use my link to log in before making a booking?",
//       "answer": "Unfortunately, once the payment has been made, it cannot be reversed. Therefore, if your referee forgets to use your link to log in before making a booking, you will not receive any points for that booking. Please note that the information provided is subject to change. For booking, redemption, cancellation, etc the standard terms and conditions apply. In case of any discrepancy Lohono Stays will do a due and fair assessment of the situation. Lohono Stays’ decision will comply in such cases.  For further assistance, feel free to contact us at any time.",
//     },
//   ];
