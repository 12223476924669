import usePropertyService from "./use-property-service";
import { useEffect, useState } from "react";

function useMealPlanOptions(
  propertySlug: string,
) {
  const { service } = usePropertyService(),
    [mealPlanOptionsList, setMealPlanOptionsList] = useState<MealOptionList>(DEFAULT_MEAL_PLAN),

    handleGetMealPlans = async (
      propertySlug: string,
    ) => {

      const { error, response } = await service.getMealPlanOptions(
        propertySlug,
      );

      if (error || !response) {
        return;
      }

      setMealPlanOptionsList(response);
    };

  useEffect(() => {
    handleGetMealPlans(
      propertySlug,
    );
  }, [propertySlug]);

  return {mealPlanOptions : mealPlanOptionsList};
}

export default useMealPlanOptions;

const DEFAULT_MEAL_PLAN: MealOptionList = {
  veg: [],
  non_veg: [],
  veg_non_veg: [],
};