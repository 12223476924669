import React, { useMemo } from "react";
import useContactUsPhoneNo from "../../hooks/use-contact-us-phone-no";
import { useQueryParamValue } from "../../hooks/use-query-param";
import useIsMobile from "../../hooks/use-is-mobile";
import { useScroll } from "../../hooks/use-scroll";
import useClx from "../../hooks/use-clx";
import useSendRequest from "../../hooks/use-send-request";
import { usePageValue } from "../../hooks/use-page";
import { Track } from "../AnalyticsManager";
import { useLocation, useSearchParams } from "react-router-dom";
import clxs from "./contact-us-floating.module.css";
import MAIL_ICON from "../../assets/icons/mail-outline-white.svg";
import CALL_ICON from "../../assets/icons/call-white.svg";

function ContactUsFloating() {
  const 
    { pathname } = useLocation(),
    isCorporatePage = pathname?.includes("corporate-stays"),
    isEventsPage = pathname?.includes("experiences-and-events"),
    { tel } = useContactUsPhoneNo(isCorporatePage),
    { isMobile } = useIsMobile(),
    { scroll } = useScroll(),
    { showSendRequestModal } = useSendRequest(),
    { page } = usePageValue(),
    isEmbedded = useQueryParamValue("boolean","embedded", false),
    [qp] = useSearchParams(),
    isModalOpen = useMemo(
      () => Boolean(qp.get("modal_id")).valueOf(),
      [qp],
    ),
    hide = useMemo(
      () => {
        return (
          isModalOpen
          || isEmbedded
          || HIDE_IN_PAGE.has(page)
          || HIDE_IN_PAGE.has(pathname)
        );
      },
      [page, pathname, isModalOpen],
    ),
    scrollVisible = useMemo(
      () => scroll > 100,
      [scroll],
    ),
    ncx = useClx(
      clxs.navBar,
      isMobile && scrollVisible ? clxs.navVisible : clxs.navInvisible,
    ),
    fcx = useClx(
      clxs.floating,
      !isMobile && scrollVisible ? clxs.floatingVisible : clxs.floatingInvisible,
    ),
    handleContactUs = async () => {

      const defaultValueObj: any = { 

        interest: "rental",
        location: "goa", 
      }

      if(isCorporatePage) {
        defaultValueObj["isShowGuestField"] = isCorporatePage, 
        defaultValueObj["location"] = "india-goa",
        defaultValueObj["guests"] = "2-10"
      }

      if(isEventsPage){
        defaultValueObj["interest"] = "events_and_experience"
      }

      await showSendRequestModal(defaultValueObj);

    };

  if (hide) {
    return null;
  }

  return (
    <>
      <Track event="contact_us_desktop_clicked">
        <div
          className={fcx}
          onClick={handleContactUs}
        >
          <img
            src={CALL_ICON}
            alt="call-now"
            className={clxs.icon}
          />
          {"Contact us"}
        </div>
      </Track>
      <div className={ncx}>
        <Track event="contact_us_mobile_clicked">
          <div
            className={clxs.navBarButton}
            onClick={handleContactUs}
          >
            <img
              src={MAIL_ICON}
              alt="enquire-now"
              className={clxs.icon}
            />
            Enquire Now
          </div>
        </Track>
        <Track event="call_now_mobile_clicked">
          <a
            href={tel}
            className={clxs.navBarButton}
          >
            <img
              src={CALL_ICON}
              alt="call-now"
              className={clxs.icon}
            />
            Call Now
          </a>
        </Track>
      </div>
    </>
  );

}

export default ContactUsFloating;

const HIDE_IN_PAGE = new Set<string>([
  "PROPERTY_DETAIL",
  "CHECKOUT",
  "HOMEOWNER",
  "LB_ABOUT",
  "FEEDBACK",
  "LOCATIONS",
  "/villas/india/karjat/casa-riviere",
  "DELETE_ACCOUNT_VERIFY_OTP",
  "DELETE_ACCOUNT",
  "PROFILE",
  "FITNESS_RETREAT",
  "REFFERAL_PROGRAMME",
]);
