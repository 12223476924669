import { MouseEvent, useMemo } from "react";
import { SubmitProps, useModal } from "./use-modal";

function useModalState<P extends SubmitProps<V>, V = any> (id: string) {
  const [childProps] = useModal<P, V>(id);

  const meta = useMemo(
    () => {
      const open = Boolean(childProps).valueOf();

      const onClose = () => childProps?.onSubmit(null);

      const handleClose = (e: MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLElement;

        if (target.tagName !== "DIALOG") {
          return;
        }

        onClose();
      };

      return {
        open: open,
        onClose: onClose,
        handleClose: handleClose,
      };
    },
    [childProps],
  );

  return {
    ...meta,
    childProps: childProps,
  };
}

export default useModalState;
