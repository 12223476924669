import React from "react";
import clxs from "./corporate-retreat.module.css";
import ROOM from "../../../assets/lohono-corporate/room.jpg";
import GARDEN from "../../../assets/lohono-corporate/garden.png";

function CorporateRetreat() {
  return (
    <>
      {TEMPLATE.map((each, key) => {
        const {
          title,
          description,
          conferenceRoomImage,
          gardenImage,
        } = each;
        return (
          <div
            className={clxs.corporateContainer}
            key={key}
          >
            <div className={clxs.contentContainer}>
            <div className={clxs.title}>
              {title}
            </div>
            <div className={clxs.description}>
              {description}
            </div>
            </div>
            <div className={clxs.imageContainer}>
              <img 
                src={conferenceRoomImage} 
                alt="Conference Room" 
              />
              <img 
                src={gardenImage} 
                alt="Garden" 
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default CorporateRetreat;

const TEMPLATE = [
  {
    title: "Why Choose our Corporate\nRetreats",
    description: "At Lohono Stays, we believe that successful corporate retreats are more than just business strategies and planning. Our luxurious properties offer the perfect balance of leisure and business, with a range of amenities and activities. We can customize your itinerary to include team-building exercises, outdoor adventures, spa treatments and gourmet dining. Experience world-class hospitality with Lohono Stays and curate unforgettable retreats for your teams and colleagues!",
    conferenceRoomImage: ROOM,
    gardenImage: GARDEN,
  },
];