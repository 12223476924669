import React, {
  HTMLAttributes,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  Fragment,
  useMemo,
  ChangeEvent, useCallback, ReactNode,
} from "react";
import { useField } from "../../../../hooks/use-form";
import useClx from "../../../../hooks/use-clx";
import clx from "classnames";
import clxs from "./rating.module.css";

interface Rating extends BaseInputProps {
    form: string;
    name: string;
    containerProps?: ContainerProps;
    label?: string;
    labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
    max?: number;
    freeTextOn?: number;
    freeTextLabel?: string;
    optionsClassName?: string;
    optionClassName?: string;
    scaleLegend?: ReactNode;
    noneOption?: { value: number; label: string };
}

function Rating(props: Rating) {
    const {
      form,
      name,
      containerProps = {},
      label,
      labelProps = {},
      max = 5,
      freeTextOn = 0,
      freeTextLabel,
      optionsClassName,
      optionClassName,
      scaleLegend,
      noneOption,
      ...rest
    } = props, {
      value,
      error,
      handleBlur,
      handleChange,
    } = useField(form, name),
    { className: _ccx } = rest,
    { className: _lcx } = labelProps,
    valueParsed = useMemo(
      () => toInteger(value.rating),
      [value],
    ),
    showFreeText = useMemo(
      () => {
        if (freeTextOn < 1) {
          return false;
        }

        if (valueParsed < 1) {
          return false;
        }

        return valueParsed <= freeTextOn;
      },
      [freeTextOn, valueParsed],
    ),
    hasError = useMemo(
      () => Boolean(error).valueOf(),
      [error],
    ),
    ccx = useClx(clxs.container, _ccx),
    lcx = useClx(clxs.label, _lcx),
    parsedError = useMemo(
      () => {
        if (!error) {
          return null;
        }

        if (typeof error === "string") {
          return error;
        }

        const { rating, description } = error as any;

        if (rating) {
          return rating;
        }

        if (description) {
          return description;
        }

        return null;
      },
      [error],
    ),
    handleRatingChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const rating = toInteger(e.target.value);

        const target = {
          name: name,
          value: {
            rating: rating,
            description: value.description,
          },
        };

        const event: any = { target: target, currentTarget: target };

        handleChange(event);
      },
      [name, value, handleChange],
    ),
    handleDescriptionChange = useCallback(
      (e: ChangeEvent<HTMLTextAreaElement>) => {
        const description = e.target.value;

        const target = {
          name: name,
          value: {
            rating: value.rating,
            description: description,
          },
        };

        const event: any = { target: target, currentTarget: target };

        handleChange(event);
      },
      [name, value, handleChange],
    );


  if (value === undefined) {
    return null;
  }

  return (
    <div
      {...containerProps}
      className={ccx}
    >
      {label && (
        <label
          {...labelProps}
          className={lcx}
        >
          {label}
        </label>
      )}
      <div
        className={optionsClassName}
        data-error={hasError}
      >
        {Array.from(
          { length: max },
          (_, option) => {
            const value = option + 1,
              id = `${name}-${value}`,
              checked = valueParsed === value,
              disabled = valueParsed === noneOption?.value,
              ccx = clx(clxs.inputLabel, optionClassName);

            return (
              <Fragment key={option}>
                <input
                  {...rest}
                  id={id}
                  name={name}
                  checked={checked}
                  disabled={disabled}
                  value={value}
                  type="radio"
                  className={clxs.input}
                  onBlur={handleBlur}
                  onChange={handleRatingChange}
                  suppressHydrationWarning={true}
                />
                <label
                  className={ccx}
                  htmlFor={id}
                  data-disabled={disabled}
                >
                  {option + 1}
                </label>
              </Fragment>
            );
          },
        )}
      </div>
      {noneOption && (
        <div className={clxs.naContainer}>
          <label 
            className={clxs.noneOptions}
            data-active={valueParsed === noneOption.value}
          >
            {noneOption.label}
            <input
              id={`${name}-na`}
              name={name}
              checked={valueParsed === noneOption.value}
              value={noneOption.value}
              className={clxs.input}
              type="radio"
              onBlur={handleBlur}
              onChange={handleRatingChange}
              suppressHydrationWarning={true}
            />
          </label>
          <label
            className={clxs.crossIcon}
            data-visible={valueParsed === noneOption.value}
          >
            x
            <input
              name={name}
              value={-1}
              className={clxs.input}
              type="radio"
              onBlur={handleBlur}
              onChange={handleRatingChange}
              suppressHydrationWarning={true}
            />
          </label>
        </div>
      )}
      {scaleLegend}
      {showFreeText && (
        <>
          {freeTextLabel && (
            <label className={clxs.label}>
              {freeTextLabel}
            </label>
          )}
          <textarea
            name={name}
            className={clxs.freeText}
            data-error={Boolean(error).valueOf()}
            suppressHydrationWarning={true}
            onBlur={handleBlur}
            onChange={handleDescriptionChange}
            suppressContentEditableWarning={true}
          />
        </>
      )}
      {parsedError && <div className={clxs.error}>{parsedError}</div>}
    </div>
  );
}

export default Rating;

function toInteger(value: string) {
  const parsed = Number.parseInt(value);

  if (Number.isNaN(parsed)) {
    return 0;
  }

  return parsed;
}

type ContainerProps = Omit<HTMLAttributes<HTMLDivElement>, "className">;

type BaseInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "id" | "name" | "value" | "onChange" | "onBlur">;
