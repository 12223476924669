import React, { useMemo } from "react";
import clxs from "./wellness-promotion.module.css";
import FUNCTIONAL from "../../../../assets/icons/functional.svg";
import CARDIO from "../../../../assets/icons/cardio.svg";
import DANCE from "../../../../assets/icons/dance.svg";
import STRENGTH from "../../../../assets/icons/strength.svg";
import YOGA from "../../../../assets/icons/yoga.svg";
import STRETCH from "../../../../assets/icons/stretch.svg";
import DINING from "../../../../assets/icons/dining.svg";
import CALL from "../../../../assets/icons/call.svg";
import ILLUSTRATION_DESKTOP from "../../../../assets/illustration.svg";
import ILLUSTRATION_MOBILE from "../../../../assets/illustration-mobile.svg";
import WELLNESS_MENU from "../../../../assets/icons/wellness-menu.svg";
import NATURE_TRAIL from "../../../../assets/icons/nature-trail.svg";
import SPA from "../../../../assets/icons/spa.svg";
import WELLNESS_YOGA from "../../../../assets/icons/wellness-yoga.svg";
import YIN_YANG from "../../../../assets/icons/yin-yang.svg";
import DETOX from "../../../../assets/icons/detox.svg";
import Img from "../../../Img";
import useClx from "../../../../hooks/use-clx";

interface WellnessPromotion {
  propertySlug: string;
}

function WellnessPromotion(props: WellnessPromotion) {
  const { propertySlug } = props,
    content = useMemo(
      () => WELLNESS_PROMOTION_TEMPLATE[propertySlug] || null,
      [propertySlug],
    ),
    {
      title,
      description,
      workoutDescription,
      workoutOptions,
      workoutTitle,
      wellnessPrograms,
    } = content,
    ccx = wellnessPrograms.length ? useClx(clxs.workoutContainer,clxs.windsongContainer) : useClx(clxs.workoutContainer),
    packageTemplate = useMemo(
      () => {return wellnessPrograms.length ? wellnessTemplate : template},
      [wellnessPrograms],
    );

  return (
    <div className={clxs.container}>
      <h1 className={clxs.title}>{title}</h1>
      <div className={clxs.description}>
        {description}
      </div>
      <div className={clxs.subtitle}>
        {workoutTitle}
      </div>
      <div className={ccx}>
        {workoutOptions.map(
          (each:any, i:number) => {
            const { icon, title } = each;
            return (
              <div
                className={clxs.workout}
                key={i}
              >
                <img
                  src={icon}
                  alt={title}
                  className={clxs.icon}
                />
                {title}
              </div>
            )
          },
        )}
      </div>
      {wellnessPrograms.length ? (
        <div className={clxs.subtitle}>
          Curated comprehensive wellness program
        </div>
      ): ""}
      {wellnessPrograms.length ? (
        <div className={clxs.wellnessContainer}>
          {wellnessPrograms.map(
            (each:any, i:number) => {
              const { icon, title } = each;
              return (
                <div
                  className={clxs.workout}
                  key={i}
                >
                  <img
                    src={icon}
                    alt={title}
                    className={clxs.icon}
                  />
                  {title}
                </div>
              )
            },
          )}
        </div>
      ):""}
      <div className={clxs.description}>
        {workoutDescription}
      </div>
      <div className={clxs.packageContainer}>
        <div className={clxs.textContainer}>
          {packageTemplate.map(
            (each, i) => {
              const { icon, title, subtitle } = each;
              return (
                <div
                  className={clxs.titleContainer}
                  key={i}
                >
                  <img
                    src={icon}
                    alt={title}
                    className={clxs.packageIcon}
                  />
                  <div className={clxs.packageTitle}>
                    {title}
                    <br />
                    <span className={clxs.packageSubtitle}>
                      {subtitle}
                    </span>
                  </div>
                </div>
              )
            },
          )}
        </div>
        <Img
          className={clxs.illustration}
          src={ILLUSTRATION_MOBILE}
          mobileSrc={ILLUSTRATION_MOBILE}
          desktopSrc={ILLUSTRATION_DESKTOP}
        />
      </div>
    </div>
  );
}

export default WellnessPromotion;

const wellnessFeatures = [
  {
    icon: WELLNESS_MENU,
    title: "Wellness Menu",
  },
  {
    icon: WELLNESS_YOGA,
    title: "Yoga",
  },
  {
    icon: NATURE_TRAIL,
    title: "Guided Nature Trail",
  },
  {
    icon: SPA,
    title: "Spa",
  },
];

const wellnessPrograms = [
  {
    icon: YIN_YANG,
    title: "Yin & Yang(3 Days)",
  },
  {
    icon: DETOX,
    title: "Mind Body Soul Detox(5 Days)",
  },
];

const workouts = [
  {
    icon: FUNCTIONAL,
    title: "Functional Training",
  },
  {
    icon: STRETCH,
    title: "Stretching",
  },
  {
    icon: CARDIO,
    title: "Cardio Training",
  },
  {
    icon: DANCE,
    title: "Dance",
  },
  {
    icon: STRENGTH,
    title: "Strength Training",
  },
  {
    icon: YOGA,
    title: "Yoga",
  },
];

const template = [
  {
    icon: DINING,
    title: "Wellness Package Starts - ₹4,100* per pax",
    subtitle: "(*Inclusive of meals.Minimum 4 signups required for each session.)",
  },
  {
    icon: CALL,
    title: "+91 8430600600",
    subtitle: "Call us to book the add-on Wellness Package for your next getaway.",
  },
]

const wellnessTemplate = [
  {
    icon: DINING,
    title: "Wellness features Starts - ₹1,500",
    subtitle: "per person/per day (one program)",
  },
  {
    icon: DINING,
    title: "Comprehensive wellness program Starts - ₹6,500",
    subtitle: "per person/per day (one program)",
  },
  {
    icon: CALL,
    title: "+91 8430600600",
    subtitle: "Call us to book the add-on Wellness Package for your next getaway.",
  },
]

const WELLNESS_PROMOTION_TEMPLATE = {
  "ishavilas": {
    title: "Wellness at Ishavilas",
    description: "While the lush surroundings of Ishavilas put you in a tranquil spell, an hour of expert-guided fitness will help to push your limits higher and will also increase awareness towards your mind and body.",
    workoutDescription: "You can train in a group session, powering your focus within and complementing each others’ energy. Added to this is a specially curated menu sourced from seasonal, fresh produce that nourishes every cell of your being.",
    workoutOptions: workouts,
    workoutTitle: "Choose from a variety of workouts:",
    wellnessPrograms: [],
  },
  "ishavilas-7": {
    title: "Wellness at Ishavilas",
    description: "While the lush surroundings of Ishavilas put you in a tranquil spell, an hour of expert-guided fitness will help to push your limits higher and will also increase awareness towards your mind and body.",
    workoutDescription: "You can train in a group session, powering your focus within and complementing each others’ energy. Added to this is a specially curated menu sourced from seasonal, fresh produce that nourishes every cell of your being.",
    workoutOptions: workouts,
    workoutTitle: "Choose from a variety of workouts:",
    wellnessPrograms: [],
  },
  "villa-azul": {
    title: "Wellness at Villa Azul",
    description: "Look inwards with an hour of expert-guided fitness amidst the lush surroundings of Villa Azul.",
    workoutDescription: "Our personalised wellness program aligns with your unique body needs, including a special menu created with fresh, seasonal produce. Get fitter with our dedicated team of experts in rejuvenating group sessions.",
    workoutOptions: workouts,
    workoutTitle: "Choose from a variety of workouts:",
    wellnessPrograms: [],
  },
  "isp-villa-verde": {
    title: "Wellness at Villa Verde",
    description: "Seek the serene equilibrium between your mind and body with a guided wellness retreat, topped with a freshly curated menu. Crafted to your unique needs, follow our in-house experts for an hour of yoga, dance or fitness in the leafy shade of Villa Verde’s courtyard.",
    workoutDescription: "Turn inwards and tap into the revitalising energy of the group sessions. This getaway, feel anew in Goa.",
    workoutOptions: workouts,
    workoutTitle: "Choose from a variety of workouts:",
    wellnessPrograms: [],
  },
  "isp-villa-branco": {
    title: "Wellness at Villa Branco",
    description: "Inspire your senses as you feast your eyes on the magnificent Villa Branco. Our dedicated team of experts have devised a wellness program that draws from your unique body needs.",
    workoutDescription: "Tone up with your group through guided fitness sessions. Combined with meals prepared using fresh produce, you’ll find yourself refreshed and rejuvenated.",
    workoutOptions: workouts,
    workoutTitle: "Choose from a variety of workouts:",
    wellnessPrograms: [],
  },
  "ishanya": {
    title: "Wellness at Ishanya",
    description: "Breathe in with your body and soul at Ishanya. The dedicated rejuvenation centre and Yoga Shala will restore your strength and balance.",
    workoutDescription: "Enjoy a personalised wellness program designed by our fitness experts, comprising guided group sessions and, specially crafted meals featuring fresh, seasonal produce. Replenish your senses with a stay at Ishanya.",
    workoutOptions: workouts,
    workoutTitle: "Choose from a variety of workouts:",
    wellnessPrograms: [],
  },
  "windsong": {
    title: "Wellness at Windsong",
    description: "Windsong, a modern luxury property in the Himalayas, offers wellness programs for physical, mental and spiritual well-being, with a focus on long-term impact. Come experience our holistic approach to wellness.",
    workoutDescription: "You can train in a group session, powering your focus within and complementing each others’ energy. Added to this is a specially curated menu sourced from seasonal, fresh produce that nourishes every cell of your being.",
    workoutOptions: wellnessFeatures,
    workoutTitle: "Choose from a variety of wellness features on request:",
    wellnessPrograms: wellnessPrograms,
  },
};