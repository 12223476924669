import * as Yup from "yup";

const MINIMAL_SCHEMA = Yup.object({
  full_name: Yup.string().trim().required("Required"),
  phone_ext: Yup.string().trim().required("Required"),
  phone_no: Yup.string()
    .trim()
    .matches(/^[0-9]{8,12}$/gi,"Invalid phone no")
    .required("Required"),
  interest: Yup.string().trim().required("Required"),
});

export default MINIMAL_SCHEMA;
