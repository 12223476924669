import React, { useMemo, useState } from "react";
import clxs from "./feedback-submit-modal.module.css";
import CLOSE_ICON from "../../../assets/icons/close.svg";
import LOHONO_LOGO from "../../../assets/logo/lohono-logo.svg";
import Button, { AnchorButton } from "../../Button";
import GOOGLE_REVIEW from "../../../assets/logo/google-review.svg";
import TRIP_ADVISOR_REVIEW from "../../../assets/logo/trip-advisor-review.svg";
import REFER_LOGO from "../../../assets/logo/refer-logo.svg";
import GOOGLE_LOGO from "../../../assets/logo/google-logo2.svg";
import useUserService from "../../../hooks/use-user-service";
import { useSnackbar } from "../../../hooks/use-snackbar";
import COPY_ICON from "../../../assets/icons/content_copy.svg";
import SHARE_ICON from "../../../assets/icons/ios_share.svg";
import useAnalytics from "../../../hooks/use-analytics";
import { Track } from "../../AnalyticsManager";

interface FeedbackSubmitModalProps {
  userId?: number;
  name?: string;
  userTier?: string;
  property?: string;
  onSubmit?: () => void;
  useCaseType?: string; //Google Reviews, trip advisor, refer and earn link.
}

function FeedbackSubmitModal(props: FeedbackSubmitModalProps) {
  const { name, onSubmit, userTier, property, userId } = props,
    {track} = useAnalytics(),
    enqueueSnackbar = useSnackbar(),
    isHomesteadSrinivas = useMemo(() => {
      if (property) {
        return (
          property?.includes("homestead") || property?.includes("srinivas")
        );
      } else {
        return false;
      }
    }, [property]),
    isNonTierUser = !userTier?.length,
    [referralCodeUrl, setReferralCodeUrl] = useState<string>(""),
    [isCodeGenerated, setIsCodeGenerated] = useState<boolean>(false),
    useCaseUIType = useMemo(() => {
      if (isNonTierUser && !isHomesteadSrinivas) {
        return "google_reviews";
      } else if (isHomesteadSrinivas) {
        return "tripadvisor_reviews";
      } else if (!isNonTierUser) {
        return "referral_link";
      } else {
        return "default";
      }
    }, [userTier, userId]),
    tripadvisorLink = useMemo(() => {
      if (property && property.includes("srinivas")) {
        return TRIP_ADVISOR_SRINIVAS_LINK;
      } else if (property && property.includes("homestead")){
        return TRIP_ADVISOR_HOMESTEAD_LINK;
      } else {
        return TRIP_ADVISOR_HOMESTEAD_LINK;
      }
    }, [property]),
    { service: userService } = useUserService(),
    trackingPayload = useMemo(() => {
      const feedbackObject = {
        llp_tier: userTier,
        property_name: property,
        user_id: userId,
        type: useCaseUIType,
      }

      return {feedback: feedbackObject};
    }, [userId,property]),
    generateCode = async () => {
        if(userId) {

          track("feedback_generate_code",trackingPayload);

          const {response,error} = await userService.generateReferralCode(userId);
          
          if (error) {
            enqueueSnackbar(error.message, "error");
    
            return null;
          }
    
          if (!response) {
            return null;
          }
    
          if (response && response.url) {
            const referralCode = response.url;
            setIsCodeGenerated(true);
            setReferralCodeUrl(referralCode)
          }
          return response;
  
        } else {
          return ;
        }
      },
      copyCode = () => {
        if(navigator) {
          track("feedback_copy_code",trackingPayload);
          navigator.clipboard.writeText(referralCodeUrl);
          enqueueSnackbar("Referral Code Copied", "success");
        }
      },
      shareCode = () => {
        if(navigator && navigator.share) {
          track("feedback_share_code",trackingPayload);
          navigator.share({
            title: "Lohono Referral Link",
            text: "To redeem, click on the link below. " + referralCodeUrl,
            url: referralCodeUrl,
          })
        } else {
          copyCode();
        }
      };
  

  return (
    <div className={clxs.container}>
      <div className={clxs.title}>
        Thank you {name} for your valuable feedback!
        <img
          src={CLOSE_ICON}
          alt="close"
          className={clxs.close}
          onClick={onSubmit?.bind(null)}
        />
      </div>

      <div className={clxs.manualContainer}>
        {useCaseUIType == "google_reviews" ? (
          <div className={clxs.review}>
            <img 
              className={clxs.img}
              src={GOOGLE_REVIEW} 
              alt="google-review" 
            />
            <div className={clxs.content}>
            <h2>Your words matter to us!</h2>
            <p>
              And we would love it if you could take a few minutes to share your experience with us on our Google Profile and add to our stellar rating.
            </p>
            </div>
            <Track 
              event="feedback_google_review_add" 
              payload={trackingPayload}
            >
              <AnchorButton 
                target="_blank" 
                href={GOOGLE_REVIEW_LINK}
              >Add a Review
              </AnchorButton>
            </Track>
          </div>
        ) : useCaseUIType == "tripadvisor_reviews" ? (
          //isHomestead or isSrinivas
          <div className={clxs.review}>
            <img 
              className={clxs.img}
              src={TRIP_ADVISOR_REVIEW} 
              alt="trip-advisor-review" 
            />
            <div className={clxs.content}>
            <h2>Your words matter to us!</h2>
            <p>
              And we would love it if you could take a few minutes to share your experience with us on our Trip Advisor Profile and add to our stellar rating.
            </p>
            </div>
            <Track 
              event="feedback_tripadvisor_review_add" 
              payload={trackingPayload}
            >
              <AnchorButton 
                target="_blank" 
                href={tripadvisorLink}
              >Add a Review
              </AnchorButton>
            </Track>
          </div>
        ) : useCaseUIType == "referral_link" ? (
          <div className={clxs.review}>
            <div className={clxs.refer}>
                <span>Refer and win </span><span className={clxs.points}>10,000 Infinity points!</span>
            </div>
            <img 
              className={clxs.img}
              src={REFER_LOGO} 
              alt="refer-logo" 
            />
            <div className={clxs.content}>
              <p className={clxs.referralp}>
                Share a luxury villa staycation with your connections and earn upto 10,000 Infinitypoints when   they complete their first stay!
              </p>
          </div>
          {(isCodeGenerated && referralCodeUrl) ? (
            <div className={clxs.referralUrlContainer}>
              <div className={clxs.urlDiv}>
                <span>{referralCodeUrl}</span>
              </div>
              <Button 
                className={clxs.generateCopyBtn} 
                onClick={copyCode}
              >
                <img
                  src={COPY_ICON}
                  alt="copy icon"
                />
                Copy Code
              </Button>
              <Button 
                className={clxs.generateIconBtn} 
                onClick={shareCode}
              >
                <img
                  src={SHARE_ICON}
                  alt="share icon"
                />
              </Button>
            </div>
          ) : (
            <Button 
              className={clxs.generateBtn} 
              onClick={generateCode}
            >
              GENERATE CODE
            </Button>
          )}
          <a 
            href={GOOGLE_REVIEW_LINK} 
            className={clxs.googlereview}
            target="_blank"
          >
             <img 
               className={clxs.googleLogo}
               src={GOOGLE_LOGO} 
               alt="google-logo2" 
             />
              <p>Share your joy! 
                <br /> 
                Give us a Google review
              </p>
          </a>
        </div>
        ) : null}
      </div>

      <div className={clxs.footer}>
        <p>We look forward to hosting you again</p>
        <img
          src={LOHONO_LOGO}
          alt="Lohono Logo"
          className={clxs.lohonoLogo}
          onClick={onSubmit?.bind(null)}
        />
        <p className={clxs.teamLohonoText}>Team Lohono Stays</p>
      </div>
    </div>
  );
}

export default FeedbackSubmitModal;

const GOOGLE_REVIEW_LINK = "https://g.page/r/Cegd262uahLtEAg/review";

const TRIP_ADVISOR_SRINIVAS_LINK = " https://www.tripadvisor.in/UserReviewEdit-g26668462-d19071732-LohonoStays_Srinivas_The_Royal_Residence-Kukas_Jaipur_District_Rajasthan.html";

const TRIP_ADVISOR_HOMESTEAD_LINK = "https://www.tripadvisor.in/UserReviewEdit-g1152784-d1645973-Lohono_Stays_Homestead-Jim_Corbett_National_Park_Nainital_District_Uttarakhand.html";