import {useMemo} from "react";
import isEhLocation from "../../utils/is-eh-location";

function useIsEH(location_slug: string) {
  const isEH = useMemo(
    () => isEhLocation(location_slug),
    [location_slug],
  );

  return {isEH: isEH};
}

export default useIsEH;
