import { useShowModal } from "./use-modal";
import useSendRequestService from "./use-send-request-service";
import useAnalytics from "./use-analytics";
import { useUser } from "./use-session";
import { useSnackbar } from "./use-snackbar";
import useUtm from "./use-utm";
import prepareMeta from "../../utils/prepare-meta";

function useSendRequest() {
  const { service } = useSendRequestService(),
    { utm } = useUtm(),
    user = useUser(),
    showModal = useShowModal(MODAL_ID),
    { track } = useAnalytics(),
    enqueueSnackbar = useSnackbar(),
    handleSendRequest = async (values: any) => {
      const guests: string = values?.guests;
      guests?.length && (values.message = "Corporate Enquiry for " + guests + " guests: " + values.message)
      const {
          brand = 1,
          full_name = "",
          phone_ext = "",
          phone_no = "",
          email = "",
          interest = "",
          location = "",
          message = "",
        } = values,
        meta = prepareMeta(utm),
        { error, response } = await service.save(
          brand,
          full_name,
          phone_ext,
          phone_no,
          email,
          interest,
          location,
          message,
          meta,
        );

      if (error) {
        enqueueSnackbar(error.message, "error");

        track(
          "send_request_error",
          {
            ...values,
            ...meta,
            error: error.message,
          },
        );

        return;
      }

      if (!response) {
        enqueueSnackbar("Something went wrong", "error");

        track(
          "send_request_error",
          {
            ...values,
            ...meta,
            error: "unknown error",
          },
        );

        return;
      }

      track(
        "send_request_submitted",
        {
          ...values,
          ...response,
          meta: meta,
        },
      );

      enqueueSnackbar(
        "Thank you for contacting us. We will get in touch with you soon",
        "success",
      );
    },
    showSendRequestModal = async (defaultValue: any = {}) => {
      const full_name: string = defaultValue.full_name || user?.full_name || DEFAULT_VALUE.full_name,
        _phone_no: string = defaultValue.phone_no || user?.phone_no || DEFAULT_VALUE.phone_no,
        phone_no = _phone_no.startsWith("+91") ? _phone_no.replace("+91", "") : _phone_no,
        email = defaultValue.email || user?.email || DEFAULT_VALUE.email,isGuestField = defaultValue.isShowGuestField,
        dv = {
          ...DEFAULT_VALUE,
          ...defaultValue,
          full_name: full_name,
          phone_no: phone_no,
          email: email,
          isShowGuestField: isGuestField,
        };

      const values: any = await showModal({ defaultValue: dv });

      if (!values) {
        return;
      }

      return handleSendRequest(values);
    };

  return {
    modalId: MODAL_ID,
    sendRequest: handleSendRequest,
    showSendRequestModal: showSendRequestModal,
  };
}

export default useSendRequest;

const DEFAULT_VALUE = {
  brand: 1,
  full_name: "",
  phone_no: "",
  email: "",
  interest: "",
  location: "",
  message: "",
};

const MODAL_ID = "send-request-modal";
