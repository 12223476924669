import React from "react";
import useClx from "../../../hooks/use-clx";
import clxs from "./intro.module.css";
import INTRO_BACKGROUND_DESKTOP from "../../../assets/intro-celebration-plain.jpg";

interface IntroProps {
  className?: string;
}

function Intro(props: IntroProps) {
  const { className } = props,
    ccx = useClx(clxs.container, className);

  return (
    <div className={ccx}>
      <a 
        id="intro" 
        href="#intro" 
        className={clxs.introAnchor}
      >
        &nbsp;
      </a>
      <img
        src={INTRO_BACKGROUND_DESKTOP}
        alt="background"
        className={clxs.background}
      />
      <div className={clxs.title}>Celebrate Milestones At Lohono</div>
      <div className={clxs.subtitle}>
        Come Lohono with us on your special day! Whether it&apos;s a corporate event,
        birthday celebration, wedding festivities, or a baby shower, let us help
        you mark life&apos;s milestones. Gather your friends, family, and loved ones
        to raise a toast against breathtaking vistas, while our dedicated staff
        takes care of everything—from stunning decor to flawless execution.
        Enhance your experience with our delectable dine-in menu and enjoy the
        luxury of our villas in stunning locations.{" "}
      </div>
    </div>
  );
}

export default Intro;
